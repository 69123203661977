import Prices from "./Prices";
import BuildMedia from "./BuildMedia";
import Meta from "./Meta";
import BuildModel from "@/store/models/BuildModel";
import Color from "@/store/models/Color";
import Size from "@/store/models/Size";

export default class Build {
  ms_id = null;
  id = null;
  blocked = true;
  is_limited = false;
  compare_available = false;
  multiple = true;
  as_extra = false;
  category = { id: 1, title: "Bike" };
  ms_title = null;
  web_bike_title = null;
  es_web_bike_title = null;
  de_web_bike_title = null;
  fr_web_bike_title = null;
  ru_web_bike_title = null;
  app_bike_title = null;
  es_app_bike_title = null;
  de_app_bike_title = null;
  fr_app_bike_title = null;
  ru_app_bike_title = null;
  ms_description = null;
  description = null;
  es_description = null;
  de_description = null;
  fr_description = null;
  ru_description = null;
  short_description = null;
  es_short_description = null;
  de_short_description = null;
  fr_short_description = null;
  ru_short_description = null;
  model = new BuildModel();
  build_category = new BuildModel();
  slug = null;
  size = new Size();
  ms_color = null;
  color = new Color();
  brand = null;
  ean = null;
  vendor_code = null;
  weight = null;
  length = null;
  height = null;
  width = null;
  cost = null;
  profit = null;
  profit_percent = null;
  prices = new Prices();
  presale_start_at = null;
  presale_end_at = null;
  stock = 0;
  creator = null;
  media = new BuildMedia();
  specs = null;
  meta = new Meta();
  created_at = null;
  included_in_delivery = [
    {
      id: 1,
      title: null,
      text: null,
      type: "image_and_text",
      file: null,
      required: true
    }
  ];
  changeStock = 0;
  compatibility = [];
  delivery_date = null;
  country_taxes = [];
  tax_base = 0;
  res_tax_base = 0;
  ex_tax_base = 0;
  pinned = false;
  dashboard = false;
  bike_type = null;
  filter_category = null;
  build_experience = null;
  build_wheel = null;
  build_motor = null;
  build_suspension_travel = null;
}
