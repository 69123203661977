<template>
  <div class="user__info">
    <div class="main__r-in">
      <div v-if="isShowSearchUser()" class="inp-group__wrapper">
        <div class="inp-group__headline">
          <h5>User</h5>
        </div>

        <div class="inp-group__in">
          <v-select
            v-model="currentOption"
            :disabled="!mode_edit"
            :options="options"
            :searchable="false"
            label="label"
            placeholder="Please select..."
          />
        </div>

        <div
          v-if="isShowAdditionalSearch()"
          class="additional-search inp-group__in"
        >
          <v-select
            v-model="searchModel"
            :options="searchOptions"
            label="label"
            placeholder="Please select..."
            @search="fetchOptions"
          />
        </div>
      </div>

      <div v-if="isShowUserInfo()">
        <div class="inp-group__headline">
          <h5 class="inp-group__headline-title">User Info</h5>
          <a v-if="mode_edit && !edit" class="edit-btn" @click="toggleEdit()"
            >Edit</a
          >
        </div>

        <div v-if="mode_edit && edit">
          <div class="inp-group">
            <label for="name">Name</label>
            <input
              id="name"
              v-model="user.f_name"
              :disabled="!edit"
              type="text"
            />
            <div v-if="errors.f_name" class="error">{{ errors.f_name[0] }}</div>
          </div>

          <div class="inp-group">
            <label for="surname">Surname</label>
            <input
              id="surname"
              v-model="user.l_name"
              :disabled="!edit"
              type="text"
            />
            <div v-if="errors.l_name" class="error">{{ errors.l_name[0] }}</div>
          </div>

          <div class="inp-group">
            <label for="email">Email</label>
            <input
              id="email"
              v-model="user.email"
              :disabled="!edit"
              type="text"
            />
            <div v-if="errors.email" class="error">{{ errors.email[0] }}</div>
          </div>

          <div class="inp-group">
            <label for="mobile">Mobile</label>
            <input
              id="mobile"
              v-model.number="user.mobile_phone"
              :disabled="!edit"
              type="text"
            />
            <div v-if="errors.mobile_phone" class="error">
              {{ errors.mobile_phone[0] }}
            </div>
          </div>

          <div class="inp-group">
            <div class="inp-group">
              <label for="mobile">Birth date</label>
              <date-picker
                v-model="user.birth_date"
                :disabled="!edit"
                :format="'YYYY/MM/DD'"
                class="input"
                type="date"
                valueType="YYYY-MM-DD HH:mm:ss"
              />
              <div v-if="errors.birth_date" class="error">
                {{ errors.birth_date[0] }}
              </div>
            </div>
          </div>
          <div class="user-save">
            <button
              class="btn btn-green"
              @click="createOrSave()"
              v-text="
                isCreateUser()
                  ? request.save
                    ? 'Creating'
                    : 'Create'
                  : request.save
                  ? 'Saving'
                  : 'Save'
              "
            ></button>
            <button
              v-if="!isCreateUser()"
              class="btn btn-gray mt-5"
              @click="cancel()"
              v-text="'Cancel'"
            ></button>
          </div>
        </div>
        <div v-else class="user-info">
          <div v-if="user.full_name" class="user-info-item">
            <a :href="userLink" target="_blank">{{ user.full_name }}</a>
          </div>
          <div v-if="user.email" class="user-info-item">
            <a :href="userMailLink" target="_blank">{{ user.email }}</a>
          </div>
          <div v-if="user.mobile_phone" class="user-info-item no-decoration">
            {{ user.mobile_phone }}
          </div>
          <div v-if="user.birth_date" class="user-info-item no-decoration">
            {{ user.formatBirthDate() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import DatePicker from "vue2-datepicker";

const CREATE_USER = "create";
const FIND_BY_EMAIL = "email";
const FIND_BY_ID = "id";
const FIND_BY_PHONE = "phone";
const FIND_BY_NAME = "name";

export default {
  name: "OrderUserInfo",
  components: { vSelect, DatePicker },
  computed: {
    ...mapGetters({
      canEditOrder: "permissions/canEditOrder",
      mode_edit: "orderItem/canEdit"
    }),
    ...mapState({
      user: state => state.orderItem.user
    }),
    userLink() {
      return `/customers/${this.user.id}`;
    },
    userMailLink() {
      return `mailto:${this.user.email}`;
    }
  },
  data() {
    return {
      edit: false,
      currentOption: null,
      searchModel: null,
      searchOptions: [],
      options: [
        {
          label: "Create new",
          value: CREATE_USER
        },
        {
          label: "Find by e-mail",
          value: FIND_BY_EMAIL
        },
        {
          label: "Find by ID",
          value: FIND_BY_ID
        },
        {
          label: "Find by Phone",
          value: FIND_BY_PHONE
        },
        {
          label: "Find by Name",
          value: FIND_BY_NAME
        }
      ],
      request: {
        save: false
      },
      errors: {}
    };
  },
  methods: {
    fetchOptions(search, loading) {
      loading(true);
      axios
        .get(API_ENDPOINT + "users", {
          params: {
            [this.currentOption.value]: search
          }
        })
        .then(response => {
          this.searchOptions = response.data.data.map(user => {
            if (this.currentOption.value === "email") {
              return { label: user.email, value: user.id };
            }
            if (this.currentOption.value === "phone") {
              return { label: user.mobile_phone, value: user.id };
            }
            if (this.currentOption.value === "name") {
              return {
                label: `${user.f_name} ${user.l_name} (id: ${user.id})`,
                value: user.id
              };
            }
            if (this.currentOption.value === "id") {
              return { label: user.id, value: user.id };
            }
          });
          loading(false);
        });
    },
    isShowAdditionalSearch() {
      return this.currentOption && this.currentOption.value !== CREATE_USER;
    },
    isShowSearchUser() {
      return this.edit || (!this.currentOption && !this.user.id);
    },
    isShowUserInfo() {
      return (
        (this.currentOption && (this.searchModel || this.isCreateUser())) ||
        this.user.id
      );
    },
    isCreateUser() {
      return (
        this.currentOption &&
        this.currentOption.value === CREATE_USER &&
        !this.user.id
      );
    },
    createOrSave() {
      this.errors = {};
      this.request.save = true;
      let method = this.isCreateUser()
        ? "orderItem/createUser"
        : "orderItem/updateUser";
      this.$store
        .dispatch(method)
        .then(response => {
          this.$store.dispatch("orderItem/loadUser", response.data.data.id);
          this.edit = false;
          this.currentOption = null;
          this.$root.$emit("modalShow", {
            type: "info",
            text: response.data.message
          });
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.request.save = false;
        });
    },
    cancel() {
      if (this.user.id) {
        this.$store
          .dispatch("orderItem/loadUser", this.user.id)
          .finally(() => this.toggleEdit());
      } else {
        this.toggleEdit();
      }
    },
    toggleEdit() {
      this.edit = !this.edit;
    }
  },
  watch: {
    currentOption(value) {
      this.currentOption = value;
      this.$store.dispatch("orderItem/resetUser");
      this.searchModel = null;
      if (this.currentOption) {
        this.edit = true;
      }
    },
    searchModel(value) {
      this.searchModel = value;
      if (value) {
        this.edit = false;
        this.$store.dispatch("orderItem/loadUser", value.value);
      } else {
        this.$store.dispatch("orderItem/resetUser");
      }
    }
  }
};
</script>

<style scoped>
.additional-search {
  margin-top: 20px;
}

.inp-group .error {
  color: red;
  font-size: 12px;
  margin-top: 6px;
}

.inp-group__headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inp-group__headline-title {
  display: flex;
}

.user-save {
  text-align: right;
  margin-top: 30px;
}

.user-save button {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
}

.justify-around {
  justify-content: space-around;
  display: flex;
}

.edit-btn {
  text-align: right;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  text-decoration-line: underline;

  color: #454545;
}

.mt-5 {
  margin-top: 5px;
}

.user-info {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #454545;
}

.user-info-item {
  margin-bottom: 4px;
  text-decoration: underline;
}

.user-info-item.no-decoration {
  text-decoration: none;
}
</style>
