<template>
  <draggable v-model="$data.sections" handle=".btn-drag" @end="endSort">
    <div
      v-for="(section, index) in $data.sections"
      :key="`${section.id}`"
      class="main__content-item main__content-item_fw"
    >
      <span class="btn btn-drag"></span>
      <div v-if="section.type === 'text'" class="main__content-item_l">
        <div class="post__type-wrapper toolbar">
          <span>Text ({{ section.id }})</span>
          <div class="post__type">
            <div class="post__type-item post__type-1">
              <input
                :id="`text-type-radio-${prefix}${index}-1`"
                :checked="isTextTypeChecked(index, 'main-text')"
                :name="`text-type-section-${prefix}${index}`"
                title="Main text"
                type="radio"
                value="main-text"
                @change="setTextStyle($event, section.id)"
              />
              <label :for="`text-type-radio-${prefix}${index}-1`">
                <span class="post__type-status"></span><span>main text</span>
              </label>
            </div>
            <div class="post__type-item post__type-2">
              <input
                :id="`text-type-radio-${prefix}${index}-2`"
                :checked="isTextTypeChecked(index, 'heading')"
                :name="`text-type-section-${prefix}${index}`"
                title="Heading"
                type="radio"
                value="heading"
                @change="setTextStyle($event, section.id)"
              />
              <label :for="`text-type-radio-${prefix}${index}-2`">
                <span class="post__type-status"></span><span>Heading</span>
              </label>
            </div>
            <div class="post__type-item post__type-3">
              <input
                :id="`text-type-radio-${prefix}${index}-3`"
                :checked="isTextTypeChecked(index, 'green-descriptor')"
                :name="`text-type-section-${prefix}${index}`"
                title="Green descriptor"
                type="radio"
                value="green-descriptor"
                @change="setTextStyle($event, section.id)"
              />
              <label :for="`text-type-radio-${prefix}${index}-3`">
                <span class="post__type-status"></span
                ><span>green descriptor</span>
              </label>
            </div>
            <div class="post__type-item post__type-4">
              <input
                :id="`text-type-radio-${prefix}${index}-4`"
                :checked="isTextTypeChecked(index, 'grey-descriptor')"
                :name="`text-type-section-${prefix}${index}`"
                title="Grey descriptor"
                type="radio"
                value="grey-descriptor"
                @change="setTextStyle($event, section.id)"
              />
              <label :for="`text-type-radio-${prefix}${index}-4`">
                <span class="post__type-status"></span
                ><span>grey descriptor</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="section.type === 'text'" class="main__content-item_r">
        <div class="inp-group inp-group__textarea mini field">
          <quill-editor
            :content="section.content"
            :options="quillOptions"
            @change="onEditorChange($event, section)"
          >
            <!--            <div slot="toolbar" class="custom-toolbar">-->
            <!--              <button class="ql-nbsp-changer">NBSP</button>-->
            <!--            </div>-->
          </quill-editor>
          <div class="field__counter">
            <span class="field__counter-current">{{
              getTextLength(index)
            }}</span>
          </div>
        </div>
        <button
          class="field__remove"
          @click="removeSection(section.id)"
        ></button>
      </div>
      <div v-if="section.type === 'image'" class="main__content-item_l">
        <span>Image ({{ section.id }})</span>
      </div>
      <div v-if="section.type === 'image'" class="main__content-item_r">
        <stg-uploader
          :images="section.images || []"
          :settings="settings"
          :type="'images'"
          action-text="Drag&Drop or click for upload image (-s) (jpg, webp 1920x1080 up to 15MB)"
          @fileDeleted="removeImageFromSection($event, section.id)"
          @uploadSuccess="addImageToSectionData($event, section.id)"
        />
        <button
          class="field__remove"
          @click="removeSection(section.id)"
        ></button>
      </div>
      <div v-if="section.type === 'video'" class="main__content-item_l">
        <span>Video ({{ section.id }})</span>
      </div>
      <div v-if="section.type === 'video'" class="main__content-item_r">
        <stg-uploader
          :settings="settings"
          :type="'video'"
          :video="section.video"
          action-text="Drag&Drop or click for upload video (1920x1080 up to 150 MB)"
          @fileDeleted="removeVideoFromSection($event, section.id)"
          @uploadSuccess="addVideoToSectionData($event, section.id)"
        />
        <button
          class="field__remove"
          @click="removeSection(section.id)"
        ></button>
      </div>
    </div>
  </draggable>
</template>

<script>
import { API_ENDPOINT } from "@/utils/api";
import draggable from "vuedraggable";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "ContentEditor",
  components: {
    draggable: draggable
  },
  props: {
    lang: {
      type: String,
      required: true
    },
    sourceSections: {
      type: Array,
      required: true
    },
    prefix: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      quillOptions: {
        modules: {
          toolbar: [["bold", "italic"], ["link"], [{ align: [] }]]
          // 'nbsp-changer': true
        },
        placeholder: "Some text...",
        theme: "snow"
      },
      sections: this.sourceSections,
      settings: {
        uploadURL: API_ENDPOINT + "uploadFile",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          Accept: "application/json"
        }
      }
    };
  },
  methods: {
    onEditorChange({ html }, section) {
      this.sections.map(item => {
        if (item.id === section.id) {
          item.content = html;
        }
      });
      this.$emit("updateContent", this.sections);
    },
    isTextTypeChecked(index, style) {
      return this.sections[index].style === style;
    },
    setTextStyle(event, index) {
      let availableValues = [
        "main-text",
        "heading",
        "green-descriptor",
        "grey-descriptor"
      ];

      if (availableValues.indexOf(event.target.value) !== -1) {
        this.$emit("updateStyle", {
          sectionId: index,
          style: event.target.value
        });
      }
    },
    getTextLength(index) {
      if (this.sections[index]) {
        let content = this.sections[index].content;
        if (content) {
          return content.trim() ? content.trim().length : 0;
        }
      }
      return 0;
    },
    removeSection(sectionId) {
      this.$emit("removeSection", sectionId);
    },
    removeVideoFromSection(event, index) {
      let section = this.sections.filter(section => {
        return section.id === index;
      })[0];
      section.video = null;
    },
    removeImageFromSection(event, index) {
      let section = this.sections.filter(section => {
        return section.id === index;
      })[0];
      let image = event;
      section.images = section.images.filter(img => {
        return img !== image;
      });
    },
    addImageToSectionData(event, index) {
      let section = this.sections.filter(single => {
        return single.id === index;
      })[0];
      if (section) {
        this.sections = this.sections.map(single => {
          if (single.id === index) {
            single.images.push(event);
            return single;
          } else {
            return single;
          }
        });
      }
    },
    addVideoToSectionData(event, index) {
      let section = this.sections.filter(single => {
        return single.id === index;
      })[0];
      section.video = event;
      this.$store.state.status = "success";
    },
    endSort() {
      this.$emit("endSort", this.sections);
    }
  },
  watch: {
    sections: {
      handler: function(sections) {
        if (sections) {
          this.$emit("updateContent", sections);
        }
      },
      deep: true
    },
    sourceSections: function(value) {
      this.sections = value;
    }
  }
};
</script>
