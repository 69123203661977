// initial state
import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import parseError from "../../helpers/parseError";

const state = {
  items: []
};

// getters
const getters = {};

// actions
const actions = {
  get_media_request({ commit }, filters) {
    let esc = encodeURIComponent;
    let query = Object.keys(filters)
      .map(k => esc(k) + "=" + esc(filters[k]))
      .join("&");

    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}media?${query}`)
        .then(response => {
          commit("set_items", response.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  update_sort_order(context, items) {
    return new Promise((resolve, reject) => {
      let array = [];
      items.forEach(function(item) {
        array.push({ id: item.id, sort_order: item.sort_order });
      });
      axios
        .post(API_ENDPOINT + "media/sort", { items: array })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  }
};

// mutations
const mutations = {
  set_items(state, value) {
    state.items = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
