import DeliveryAddress from "./DeliveryAddress";

export default class InvoiceAddress extends DeliveryAddress {
  fromDeliveryAddress(deliveryAddress) {
    for (let key in this) {
      // eslint-disable-next-line
      if (deliveryAddress.hasOwnProperty(key)) {
        this[key] = deliveryAddress[key];
      }
    }
    return this;
  }
}
