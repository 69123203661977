<template>
  <div class="order__items" :class="orderItemClass">
    <div class="order__items-headline">
      <span>Preview</span>
      <span>Item</span>
      <span>Price</span>
      <span class="text-right">Quantity</span>
      <span v-if="user.is_b2b">Is testing</span>
      <span class="text-right">Total</span>
    </div>

    <div class="order__items-in bg-transparent">
      <order-product
        v-for="(order_product, index) in order_products"
        :key="order_product.key ? order_product.key : index"
        :index="index"
        :order_product="order_product"
        :user="user"
      />

      <div v-if="!order_products.length" class="bold no-products">
        No products
      </div>

      <div class="bg-white">
        <div class="order__items-footer">
          <div class="order__items-payment">
            <span v-if="false"
              >Paid from <b>VISA *2086 {{ order.created_at }}</b></span
            >
          </div>
        </div>
        <div class="order__item">
          <add-product />
        </div>

        <order-invoice-number />

        <div class="d-flex">
          <order-payment v-if="!pageIsCreate()" class="order__item-payments" />
          <div v-else class="order__item-payments">
            Payment is available after creating an order
          </div>
          <order-total class="order__item-total" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderProduct from "../../partials/orders/OrderProduct";
import OrderTotal from "../../partials/orders/OrderTotal";
import OrderInvoiceNumber from "../../partials/orders/OrderInvoiceNumber";
import AddProduct from "../../partials/orders/AddProduct/AddProduct";
import OrderPayment from "../../partials/orders/OrderPayment";
import { mapGetters, mapState } from "vuex";

export default {
  name: "OrderItems",
  components: {
    OrderPayment,
    AddProduct,
    OrderInvoiceNumber,
    OrderTotal,
    OrderProduct
  },
  computed: {
    ...mapGetters({
      mode_edit: "orderItem/canEdit"
    }),
    ...mapState({
      user: state => state.orderItem.user
    }),

    order_products() {
      return this.$store.state.orderItem.data.products;
    },
    orderItemClass() {
      return {
        create: this.mode_edit,
        'not-b2b': !this.user.is_b2b
      };
    }
  },
  methods: {
    pageIsCreate() {
      return this.$route.name === "CRMOrderCreate";
    }
  }
};
</script>

<style scoped lang="scss">
.no-products {
  padding-top: 20px;
  padding-left: 10px;
}

.order__item-payments {
  padding: 24px;
  max-width: 480px;
  box-sizing: border-box;
}

.order__items .order__items-headline span:nth-child(2) {
  width: calc(40% - 108px);
}

.order__items-headline .text-right {
  text-align: right;
  padding-right: 24px;
  box-sizing: border-box;
}

.order__items.create {
  .order__items-headline {
    padding-right: 48px;
    .text-right {
      text-align: left;
    }
  }
}
</style>
