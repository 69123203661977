<template>
  <CRMLayout>
    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Info</h5>
          </div>
          <!-- .main__headline -->

          <div class="counters">
            <div
              v-for="(counter, index) in counters"
              :key="index"
              class="counter"
              style="text-align: center"
            >
              <vue-circle
                :animation-start-value="0.0"
                :fill="{ color: '#C2E900' }"
                :progress="
                  counter.hasOwnProperty('percent') ? counter.percent : 100
                "
                :reverse="false"
                :show-percent="false"
                :size="100"
                :start-angle="0"
                :thickness="10"
                empty-fill="rgba(0, 0, 0, .1)"
                insert-mode="append"
                line-cap="round"
              >
                <p>{{ counter.count }}</p>
              </vue-circle>
              <p>{{ counter.title }}</p>
            </div>
          </div>
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import VueCircle from "vue2-circle-progress";

export default {
  name: "CRMDashBoard",
  components: { CRMLayout, VueCircle },
  data: () => ({
    counters: []
  }),
  mounted() {
    let ctx = this;
    this.$store
      .dispatch("get_dashboard_stat_request")
      .then(resp => {
        ctx.counters = resp.data.counters;
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>

<style lang="scss" scoped>
.counters {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}
</style>
