<template>
  <div>
    <div v-for="album in albums" :key="album.id">
      <div class="tr">
        <div class="td"></div>
        <div class="td pr-3">
          <a href="#" @click.prevent="showAlbum(album.id)">{{ album.languages.en.title ? album.languages.en.title : 'Unnamed' }}</a>
        </div>
        <div class="td d-flex items-center">
          <button class="btn-activity mmt-1-5 mmb-1-5 mr-3" :class="{'active': album.active}" @click="toggleAlbumActive(album)">{{ album.active ? 'Published' : 'Unpublished' }}</button>
          <button class="btn-link btn-blue-link" @click="deleteAlbum(album.id)">Delete</button>
        </div>
      </div>
      <template v-if="album.children.length">
        <div style="padding-left: 20px">
          <media-tree v-bind:albums="album.children" v-bind:show-album="showAlbum" v-bind:filters="filters" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediaTree",
  props: [
    'albums',
    'showAlbum',
    'filters'
  ],
  methods: {
    toggleAlbumActive(album) {
      album.active = !album.active;

      this
        .$store
        .dispatch("mediaItem/update_album", album)
        .then(() => {
          this.$store.dispatch("mediaList/get_media_request", this.filters);
        });
    },
    deleteAlbum(id) {
      this.$dialog
        .confirm("Are you sure you want to delete album?")
        .then(() => this.$modal.hide("edit-album"))
        .then(() => {
          this
            .$store.dispatch("mediaItem/deleteAlbum", id)
            .then(() => {
              this.$store.dispatch("mediaList/get_media_request", this.filters);
            })
            .catch(err => {
              this.$root.$emit("modalShow", { text: err, type: "error" });

              this.$store.dispatch("mediaList/get_media_request", this.filters);
            });
        })
        .catch(() => { });
    }
  }
};
</script>