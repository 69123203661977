import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "../../helpers/parseError";
import moment from "moment";

const state = {
  comments: [],
  support: [],
  repair: [],
  changes: [],
  b2b_codes: []
};

// getters
const getters = {
  feed: state => {
    let feed = state.comments;
    let changes = state.changes;
    if (history.length) {
      feed = feed.concat(changes);
      feed.sort((a, b) => {
        let keyA = "created_at";
        let keyB = "created_at";
        let dateA = moment.unix(a[keyA]).format();
        if (dateA === "Invalid date") {
          dateA = moment(a[keyA]).format();
        }
        let dateB = moment.unix(b[keyB]).format();
        if (dateB === "Invalid date") {
          dateB = moment(b[keyB]).format();
        }

        return dateA < dateB ? 1 : -1;
      });
    }

    return feed;
  },
  changes(state) {
    return state.changes;
  },
  comments(state) {
    return state.comments;
  },
  support(state) {
    return state.support;
  },
  repair(state) {
    return state.repair;
  },
  b2b_codes(state) {
    return state.b2b_codes;
  }
};

// actions
const actions = {
  parseFromOrderResponse({ commit }, response) {
    commit("clear_comments");
    response.data.comments.map(comment => commit("add_comment", comment));
  },
  addComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}comments`, data)
        .then(response => {
          commit("add_comment", response.data);
          resolve(response.data);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  load({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      let subjectId;
      if (data.type === "orders") {
        subjectId = rootState.orderItem.id;
      } else if (data.type === "bikes") {
        subjectId = data["subject_id"];
      } else {
        subjectId = data["build_id"];
      }

      let v2 = data.type === "builds" || data.type === "bikes" ? "v2/" : "";

      axios
        .get(`${API_ENDPOINT}${v2}${data.type}/history/${subjectId}`, data)
        .then(response => {
          commit("setComments", response.data.comments);
          commit("setSupport", response.data.support);
          commit("setRepair", response.data.repair);
          commit("setChanges", response.data.changes);
          commit("setB2bCodes", response.data.b2b_codes || []);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  }
};

// mutations
const mutations = {
  setComments(state, comments) {
    state.comments = comments.map(item => {
      item.type = "comment";
      return item;
    });
  },
  setSupport(state, comments) {
    if (comments) {
      state.support = comments.map(item => {
        item.type = "comment";
        return item;
      });
    }
  },
  setRepair(state, comments) {
    if (comments) {
      state.repair = comments.map(item => {
        item.type = "comment";
        return item;
      });
    }
  },
  setChanges(state, changes) {
    state.changes = changes.map(item => {
      item.type = "changes";
      return item;
    });
  },
  setB2bCodes(state, b2b_codes) {
    state.b2b_codes = b2b_codes.map(item => {
      item.type = "b2b_codes";
      return item;
    });
  },
  add_comment(state, comment) {
    comment.type = "comment";
    if (comment.repair) {
      state.repair.unshift(comment);
    } else if (comment.support) {
      state.support.unshift(comment);
    } else {
      state.comments.unshift(comment);
    }
  },
  clear_comments(state) {
    state.comments = [];
    state.repair = [];
    state.support = [];
  },
  clearChanges() {
    state.comments = [];
    state.repair = [];
    state.support = [];
    state.changes = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
