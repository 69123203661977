<template>
  <layuot>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMRoleCreate' }"
        class="btn btn-white btn-reset add-models-btn"
        >Add Role
      </router-link>
    </template>
    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Roles</h5>
          </div>

          <table v-if="roles.length > 0" class="table__typical table__orders">
            <thead>
              <tr>
                <th class="pointer id">
                  <span>ID</span>
                  <span class="btn-arrow hidden"></span>
                </th>
                <th>
                  <span>Title</span>
                  <span class="btn-arrow hidden"></span>
                </th>
                <th>
                  <span>Business Type</span>
                  <span class="btn-arrow hidden"></span>
                </th>
                <th class="pointer">
                  <span>Description</span>
                  <span class="btn-arrow hidden"></span>
                </th>
                <th class="pointer">
                  <span>Users</span>
                  <span class="btn-arrow hidden"></span>
                </th>
                <th class="pointer">
                  <span>Added by</span>
                  <span class="btn-arrow hidden"></span>
                </th>
                <th class="pointer">
                  <span>Created</span>
                  <span class="btn-arrow hidden"></span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="role in roles"
                @click.prevent="showRole($event, role)"
                :key="role.id"
              >
                <td>{{ role.id }}</td>
                <td>{{ role.type }}</td>
                <td>{{ getBusinessType(role.business_type) }}</td>
                <td>{{ role.description }}</td>
                <td>{{ getCountUsers(role) }}</td>
                <td>{{ getFullUserName(role) }}</td>
                <td>{{ role.created_at }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>

        <div class="main__r-in">
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By Author</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="creator"
                  :options="users"
                  label="f_name"
                  placeholder="Select user"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Created</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="createdAtFilter"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>
          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-fw btn-bold btn-sm"
              @click="resetFilters()"
            >
              Reset filter
            </button>
          </div>
        </div>
      </div>
    </template>
  </layuot>
</template>

<script>
import CRMLayout from "./CRMLayout";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import _ from "lodash";

export default {
  name: "CrmRoles",
  components: { layuot: CRMLayout, vSelect, DatePicker },
  data() {
    return {
      roles: [],
      creator: {},
      users: [],
      reseting: false,
      createdAtFilter: null
    };
  },
  mounted() {
    this.loadRoles();
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      let ctx = this;
      this.$store
        .dispatch("get_all_customers")
        .then(resp => {
          ctx.users = resp.data;
        })
        .catch(error => {
          this.$root.$emit("modalShow", { text: error, type: "error" });
        });
    },
    getFullUserName(role) {
      if (role.user) {
        return role.user.f_name + " " + role.user.l_name;
      }
      return "";
    },
    getCountUsers(role) {
      return role["users_count"];
    },
    getBusinessType(business_type_id) {
      return _.find(this.$store.getters.userBusinessTypes, {
        id: business_type_id
      }).name;
    },
    showRole(event, role) {
      this.$router.push(`/roles/${role.id}`);
    },
    loadRoles() {
      let ctx = this;
      let options = {
        parameters: []
      };
      if (!!this.creator && this.creator.id > 0) {
        options.parameters.push(`creator_id=${this.creator.id}`);
      }
      if (this.createdAtFilter) {
        options.parameters.push(`created_at=${this.createdAtFilter}`);
      }
      this.$store
        .dispatch("get_roles_request", options)
        .then(resp => {
          ctx.roles = resp.data;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    resetFilters() {
      this.updateList = true;
      this.creator = null;
      this.createdAtFilter = null;
      this.reseting = true;
      this.loadRoles();
    }
  },
  watch: {
    creator: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.loadRoles();
      }
    },
    createdAtFilter: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.loadRoles();
      }
    }
  }
};
</script>

<style scoped>
.main__c-in {
  overflow: auto;
}

.table__orders {
  table-layout: inherit;
}
</style>
