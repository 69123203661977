<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'ManageBuildColor' }"
        class="btn btn-white btn-reset add-models-btn"
      >
        Create new color
      </router-link>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Build colors</h5>
          </div>

          <div class="scroll">
            <table v-if="hasColors" class="table__typical table__orders">
              <tbody>
                <tr>
                  <th>
                    <span>ID</span>
                  </th>
                  <th>
                    <span>Title</span>
                  </th>
                  <th>
                    <span>Price</span>
                  </th>
                  <th>
                    <span>Count builds</span>
                  </th>
                </tr>

                <tr
                  v-for="color in colors"
                  :key="color.id"
                  @click="showColor($event, color)"
                >
                  <td>{{ color.id }}</td>
                  <td>{{ color.ms_title }}</td>
                  <td>{{ $crConverter(color.price, "EUR") }}</td>
                  <td>{{ color["builds_count"] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";
import { mapGetters } from "vuex";

export default {
  name: "CRMPartBrands",
  components: { CRMLayout, Paginator },
  data() {
    return {
      currentPage: 1,
      lastPage: 1
    };
  },
  created() {
    this.loadColors();
  },
  methods: {
    loadColors() {
      let data = {
        page: this.currentPage
      };
      this.$store.dispatch("colors/load", data).then(result => {
        this.lastPage = result.data.meta["last_page"];
      });
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadColors();
    },
    showColor(event, color) {
      this.$router.push({
        name: "ManageBuildColorSingle",
        params: { id: color.id }
      });
    }
  },
  computed: {
    ...mapGetters({
      colors: "colors/list"
    }),
    hasColors() {
      return this.colors.length > 0;
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 1300px) {
  .table__orders td:last-child,
  .table__orders th:last-child {
    width: auto;
  }

  .scroll table {
    min-width: 700px;
  }
}
</style>
