import ColorGroup from "@/store/models/ColorGroup";

export default class Color {
  id = null;
  title = null;
  image = null;
  group = new ColorGroup();
  price = null;
  custom = false;
}
