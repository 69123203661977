import axios from "axios";
import { API_ENDPOINT } from "../../../utils/api";
import parseError from "../../helpers/parseError";

const state = {
  list: []
};

const getters = {};
const actions = {
  load({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(API_ENDPOINT + "v2/specs")
        .then(response => {
          commit("setList", response.data);
          resolve();
        })
        .catch(err => {
          commit("setList", []);
          reject(parseError(err));
        });
    });
  }
};
const mutations = {
  setList(state, list) {
    state.list = list;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
