<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>List of mail templates</span>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Mail templates</h5>
          </div>

          <table
            v-if="templates.length > 0"
            class="table__typical table__orders"
          >
            <tbody>
              <tr>
                <th></th>
                <th class="pointer">
                  <span>Code</span>
                  <span></span>
                </th>

                <th class="pointer">
                  <span>Title</span>
                </th>

                <th class="pointer">
                  <span>Description</span>
                </th>

                <th class="pointer">
                  <span>Actions</span>
                </th>
              </tr>

              <tr v-for="template in templates" :key="template.code">
                <td></td>
                <td>{{ template.code }}</td>
                <td>{{ template.name }}</td>
                <td>{{ template.description }}</td>
                <td class="text-center">
                  <a
                    :href="getTemplatePreviewLink(template.code)"
                    target="_blank"
                    >Preview</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";

export default {
  name: "CRMMailsList",
  components: { CRMLayout },
  data: () => ({
    templates: []
  }),
  mounted() {
    this.loadTemplates();
  },
  methods: {
    getTemplatePreviewLink(name) {
      let token = localStorage.getItem("token");
      return `${this.$path.replace(
        "api",
        "en"
      )}mails/${name}?access_token=${token}`;
    },
    loadTemplates() {
      let ctx = this;
      this.$store
        .dispatch("get_mail_templates_request", { page: this.currentPage })
        .then(response => {
          let templates = [];

          for (let item of Object.keys(response.data.data)) {
            for (let code of Object.keys(response.data.data[item])) {
              if (response.data.data[item][code].used) {
                templates.push({
                  code: response.data.data[item][code].name,
                  name: response.data.data[item][code].humanName,
                  description: response.data.data[item][code].humanDescription
                });
              }
            }
          }

          this.templates = [...templates];
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    }
  }
};
</script>

<style scoped>
.red {
  color: red;
}

@media screen and (max-width: 767px) {
  .table__orders td:nth-child(2),
  .table__orders th:nth-child(2),
  .table__orders td:last-child,
  .table__orders th:last-child {
    width: 40%;
  }

  .table__typical td:first-child,
  .table__typical th:first-child {
    width: 20%;
    padding-left: 20px;
  }

  .main__c-in {
    padding-right: 20px;
  }
}

td {
  white-space: normal !important;
}

.text-center {
  text-align: center;
}

.text-center a {
  color: #99ccff;
}

.text-center a:hover {
  text-decoration: underline;
}
</style>
