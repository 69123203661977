<template>
  <div :class="{ customer: !invoice.manual }" class="invoice-item">
    <div class="invoice-item__im amount-title">
      <div>
        <span class="amount">{{ $crConverter(invoice.amount / 100) }}</span>
        <span
          v-if="invoice.manual && invoice.manager_full_name"
          class="amount-info"
        >
          added by
          <router-link
            :to="{
              name: 'CRMCustomerPage',
              params: { id: invoice.manager.id }
            }"
            target="_blank"
            v-text="invoice.manager_full_name"
          />
        </span>
        <span v-if="!invoice.manual" class="amount-info">
          {{ invoice.isPaid() ? " paid by customer " : " pending " }}
        </span>
        <span v-if="invoice.created_at" class="amount-info">
          {{ invoice.created_at }}
        </span>
      </div>
      <a
        v-if="mode_edit && invoice.canDelete()"
        class="amount-delete"
        @click="deleteInvoice()"
        v-text="request.delete ? 'Deleting...' : 'Delete'"
      ></a>
    </div>
    <div v-if="invoice.description" class="invoice-item__im invoice-desc">
      {{ invoice.description }}
    </div>
    <div v-if="invoice.invoice_file.url" class="invoice-item__im invoice-link">
      <span class="invoice-link-title">Pro forma:</span>
      <a
        :href="invoice.invoice_file.url"
        target="_blank"
        v-text="
          invoice.invoice_file.name
            ? invoice.invoice_file.name
            : invoice.invoice_file.url
        "
      ></a>
    </div>
    <div v-if="invoice.manual" class="invoice-item__im invoice-link-status">
      <div v-if="invoice.isRequiresConfirmation()">
        <input
          :id="`${invoice.id}-upload-file`"
          ref="files"
          class="hidden"
          multiple
          name="upload_file"
          type="file"
          @change="handleFileUpload()"
        />
        <span class="ic-cancel"></span>
        <span>
          Not paid/confirmed.
          <label v-if="mode_edit" :for="`${invoice.id}-upload-file`">{{
            this.request.file ? "Uploading" : "Upload"
          }}</label>
          <span v-if="!mode_edit">Upload</span>
          confirmation scan
        </span>
      </div>
      <div v-if="invoice.isPaid()">
        <div class="ic-paid">
          <span class="ic-paid-1"></span>
          <span class="ic-paid-2"></span>
        </div>
        <span class="">Paid</span>
      </div>
      <div v-if="invoice.isStatusUploaded() && invoice.hasReceiptFile()">
        <div class="ic-paid">
          <span class="ic-paid-1"></span>
          <span class="ic-paid-2"></span>
        </div>
        <span class=""
          >Confirmed<span v-if="invoice.receipt_manager_full_name">
            by
            <router-link
              :to="{
                name: 'CRMCustomerPage',
                params: { id: invoice.receipt.manager.id }
              }"
              target="_blank"
              v-text="invoice.receipt_manager_full_name"
            ></router-link></span
          ><span
            v-if="invoice.receipt.created_at"
            v-text="' ' + invoice.receipt.created_at"
          ></span
          >. <a :href="invoice.receipt.file" target="_blank">View</a>
          confirmation scan
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OrderPaymentItem",
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      mode_edit: "orderItem/canEdit"
    })
  },
  data() {
    return {
      request: {
        delete: false,
        file: false
      },
      file: null
    };
  },
  methods: {
    deleteInvoice() {
      if (this.request.delete) {
        return false;
      }

      this.request.delete = true;

      this.$store
        .dispatch("orderPrice/deleteInvoice", this.invoice.id)
        .finally(() => {
          this.request.delete = false;
        });
    },
    clearFile() {
      this.file = null;
    },
    uploadFile() {
      this.request.file = true;
      let formData = new FormData();
      formData.append("invoice_id", this.invoice.id);
      formData.append("receipt", this.file);

      this.$store
        .dispatch("orderPrice/uploadFileInvoice", formData)
        .then(() => {
          if (this.$store.getters["orderPrice/orderIsFullPaid"]) {
            this.$root.$emit("modalShow", {
              text: "The order is fully paid",
              type: "info"
            });
          }
        })
        .catch(err => {
          this.clearFile();
          this.$root.$emit("modalShow", { text: err, type: "error" });
        })
        .finally(() => {
          this.request.file = false;
        });
    },
    handleFileUpload() {
      if (this.request.file) {
        return false;
      }
      this.file = this.$refs.files.files[0];
      this.uploadFile();
    }
  }
};
</script>

<style scoped>
.amount-title {
  display: flex;
  justify-content: space-between;
}

.amount-delete {
  margin-left: 0.5rem;
}

.invoice-desc {
  font-size: 12px;
  color: #888888;
}

.amount {
  color: #454545;
}

.amount-delete {
  display: inline-flex;
  text-align: right;
  text-decoration-line: underline;

  color: #bf0000;
}

.invoice-item {
  color: #888888;
}

.invoice-item__im {
  margin-bottom: 14px;
}

.invoice-item__im.invoice-desc {
  font-size: 12px;
}

.invoice-item__im:last-child {
  margin-bottom: 0;
}

.invoice-item__im.invoice-link {
  color: #888;
}

.invoice-link {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

.invoice-link-title {
  font-size: 12px;
  line-height: 12px;
}

.invoice-link a {
  font-weight: 500;
  color: #888888;
  text-decoration: underline;
}

.invoice-link-status {
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #888888;
  position: relative;
}

.invoice-link-status label,
.invoice-link-status a {
  cursor: pointer;
  text-decoration: underline;
  color: #888888;
}

.ic-cancel {
  display: inline-block;
  width: 12px;
  height: 12px;
}

.ic-cancel:before,
.ic-cancel:after {
  position: absolute;
  left: 4px;
  top: 2px;
  content: " ";
  height: 12px;
  width: 2px;
  background-color: #bf0000;
}

.ic-cancel:before {
  transform: rotate(45deg);
}

.ic-cancel:after {
  transform: rotate(-45deg);
}

#upload_file {
  display: none;
}

.ic-paid {
  position: relative;
  display: inline-block;
  width: 12px;
}

.ic-paid-1,
.ic-paid-2 {
  position: absolute;
  background: #c2e900;
}

.ic-paid-1 {
  width: 6px;
  height: 2px;
  top: -4px;
  transform: rotate(60deg);
}

.ic-paid-2 {
  width: 12px;
  height: 2px;
  transform: rotate(-60deg);
  left: 2px;
  top: -5px;
}
</style>
