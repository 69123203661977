<template>
  <layout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMProductModelCreate' }"
        class="btn btn-white btn-reset add-models-btn"
      >
        Add Model
      </router-link>
    </template>

    <template v-slot:header-title>
      <span>Product models</span>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Product models</h5>
          </div>
          <div class="scroll">
            <div v-if="hasModels" class="table__typical table__orders">
              <div class="tr">
                <div class="th"></div>
                <div class="th pointer" @click="sortRows('id')">
                  <span>ID</span>
                  <span :class="arrowClass('id')"></span>
                </div>
                <div class="th pointer" @click="sortRows('model')">
                  <span>Title</span>
                  <span :class="arrowClass('model')"></span>
                </div>
                <div class="th pointer">Added by</div>
                <div class="th pointer" @click="sortRows('created_at')">
                  <span>Created</span>
                  <span :class="arrowClass('created_at')"></span>
                </div>
              </div>

              <draggable
                id="sortable-2"
                v-model="models"
                handle=".btn-drag"
                @change="sortModels"
              >
                <div
                  v-for="model in models"
                  :key="model.id"
                  class="tr"
                  @click="showModel($event, model)"
                >
                  <div class="td">
                    <button class="btn btn-drag"></button>
                  </div>
                  <div class="td">
                    <a :href="`/products/models/${model.id}`">{{ model.id }}</a>
                  </div>
                  <div class="td">
                    <a :href="`/products/models/${model.id}`">
                      {{ model.model }}
                    </a>
                  </div>
                  <div class="td">
                    <a href="" @click.prevent="$router.push('/customers/')"
                      >name</a
                    >
                  </div>
                  <div class="td">
                    <a :href="`/products/models/${model.id}`">
                      {{ model.created_at }}
                    </a>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>

        <div class="main__r-in">
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By author</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentAuthor"
                  :options="authors"
                  label="label"
                  placeholder="Select author"
                />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Created</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="createdAtFilter"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Archived</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__choice">
                <div class="inp-group__choice-item">
                  <input
                    id="yes1"
                    :checked="archivedFilter"
                    type="checkbox"
                    @change="acceptArchivedFilter(true)"
                  />
                  <label for="yes1">Yes</label>
                </div>

                <div class="inp-group__choice-item">
                  <input
                    id="no1"
                    :checked="
                      archivedFilter !== null && archivedFilter === false
                    "
                    type="checkbox"
                    @change="acceptArchivedFilter(false)"
                  />
                  <label for="no1">No</label>
                </div>
              </div>
            </div>
          </div>

          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-bold btn-fw btn-sm"
              @click="resetFilters()"
            >
              Reset filter
            </button>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import DatePicker from "vue2-datepicker";
import Paginator from "./partials/Paginator";
import vSelect from "vue-select";
import $ from "jquery";
import draggable from "vuedraggable";

export default {
  name: "CRMProductsModels",
  components: { vSelect, layout: CRMLayout, DatePicker, Paginator, draggable },
  data() {
    return {
      models: [],
      as_option_filter: null,
      createdAtFilter: null,
      archivedFilter: null,
      currentAuthor: {
        code: 0,
        label: "All"
      },
      authors: [],
      currentPage: 1,
      lastPage: 1,
      updateList: true,
      total: 0,
      perPage: 0,
      reseting: false,
      order_by: null,
      ordering: false,
      previous_order_field: null
    };
  },
  created() {
    this.loadProductModels();
    this.loadAuthors();
  },
  methods: {
    sortModels() {
      this.$store.dispatch(
        "models/sort",
        this.models.map(model => {
          return model.id;
        })
      );
    },
    showModel(event, model) {
      if (event.target.localName === "td") {
        this.$router.push({ path: `/products/models/${model.id}` });
      }
    },
    loadAuthors() {
      let ctx = this;
      this.$store
        .dispatch("get_authors_request")
        .then(response => {
          ctx.authors = [
            ...[{ code: 0, label: "All" }],
            ...response.data.map(author => {
              return {
                code: author.id,
                label: this.createAuthorLabel(author)
              };
            })
          ];
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    createAuthorLabel(author) {
      let firstName = author.f_name;
      let lastName = author.l_name;
      if (firstName) {
        firstName = firstName[0].toUpperCase() + firstName.substr(1);
      }
      if (lastName) {
        lastName = lastName[0].toUpperCase() + lastName.substr(1);
      }
      return [firstName, lastName].join(" ");
    },
    loadProductModels() {
      let ctx = this;

      let options = {
        parameters: []
      };

      options.parameters.push(`page=${this.currentPage}`);
      options.parameters.push(`withPagination=yes`);

      if (!!ctx.currentAuthor && ctx.currentAuthor.code !== 0) {
        options.parameters.push(`created_by=${ctx.currentAuthor.code}`);
      }

      if (ctx.createdAtFilter) {
        options.parameters.push(`created_at=${ctx.createdAtFilter}`);
      }

      console.log(ctx.order_by);

      if (ctx.order_by) {
        options.parameters.push(`order_by=${ctx.order_by}`);
      }

      if (this.archivedFilter) {
        options.parameters.push(`archived=true`);
      }

      options.parameters.push(`order=${ctx.ordering ? "desc" : "asc"}`);

      this.$store
        .dispatch("get_product_models_request", options)
        .then(response => {
          if (ctx.updateList) {
            ctx.models = response.data.data;
            ctx.updateList = true;
            ctx.nextPageUrl = response.data["next_page_url"];
            ctx.currentPage = response.data["current_page"];
            ctx.lastPage = response.data["last_page"];
            ctx.total = response.data.total;
            ctx.perPage = response.data["per_page"];

            if (ctx.models.length < 6) {
              // noinspection JSCheckFunctionSignatures
              $("body, html").animate({ scrollTop: 0 }, 500, "swing");
            }
          } else {
            ctx.models = [...ctx.models, ...response.data.data];
          }
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    acceptArchivedFilter(value) {
      if (this.archivedFilter === null) {
        this.archivedFilter = value;
      } else if (value === this.archivedFilter) {
        this.archivedFilter = null;
      } else {
        this.archivedFilter = value;
      }

      this.updateList = true;
      this.loadProductModels();
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
    },
    resetFilters() {
      this.updateList = true;
      this.as_option_filter = null;
      this.createdAtFilter = null;
      this.archivedFilter = null;
      this.reseting = true;
      this.currentAuthor = {
        code: 0,
        label: "All"
      };
      this.currentPage = 1;
      this.lastPage = 1;
      this.ordering = false;
      this.order_by = null;
      this.loadProductModels();
    },
    sortRows(field) {
      this.order_by = field;
      if (
        field === this.previous_order_field ||
        this.previous_order_field === null
      ) {
        this.ordering = !this.ordering;
      } else {
        this.ordering = true;
      }
      this.previous_order_field = this.order_by;
      this.updateList = true;
      this.loadProductModels();
    },
    arrowClass(field) {
      if (field === this.order_by) {
        let classes = "btn-arrow centered";
        return this.ordering ? classes : `${classes} reverse`;
      }
      return "btn-arrow hidden";
    }
  },
  computed: {
    hasModels() {
      return this.models.length > 0;
    }
  },
  watch: {
    currentAuthor: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.loadProductModels();
      }
    },
    currentPage: function(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadProductModels();
    },
    createdAtFilter() {
      this.updateList = true;
      this.loadProductModels();
    }
  }
};
</script>

<style lang="scss" scoped>
.table__orders {
  .tr {
    display: flex;
    align-items: center;
    width: 100%;

    .td,
    .th {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;

      &.pointer {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .td:nth-child(1),
    .th:nth-child(1) {
      width: 50px;
      border: none;
      padding: 0;

      .btn-drag {
        margin: auto;
        display: block;
      }
    }

    .td:nth-child(2),
    .th:nth-child(2) {
      width: 50px;
      padding-left: 8px;
      padding-right: 8px;
      box-sizing: border-box;
      text-align: left;
      white-space: nowrap;
      border: none;
    }

    .td:nth-child(3),
    .th:nth-child(3) {
      width: calc(61% - 100px);
    }

    .td:nth-child(4),
    .th:nth-child(4) {
      width: 15%;
    }

    .td:nth-child(5),
    .th:nth-child(5) {
      width: 24%;
    }
  }
}

.table__orders td:nth-child(2),
.table__orders th:nth-child(2) {
  width: auto;
  max-width: 100%;
}

.table__orders td:nth-child(3),
.table__orders th:nth-child(3) {
  width: 15%;
}

.table__orders td:last-child,
.table__orders th:last-child {
  width: 24%;
}

@media screen and (max-width: 1300px) {
  .scroll .table__orders {
    min-width: 650px;
  }
}
</style>
