import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";

const state = {};

const getters = {};
const actions = {
  verify(context, id) {
    return new Promise((resolve, reject) => {
      const url = `${API_ENDPOINT}v2/users/${id}/verify`;
      axios
        .post(url)
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
