<template>
  <div class="main__content-item main__content-item_center">
    <div class="main__content-item_l">
      <span>{{ title }}:</span>
    </div>
    <div class="main__content-item_r">
      <div class="inp-group__text">
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextReadOnly",
  props: ["value", "title"]
};
</script>
