<template>
  <CRMLayout>
    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in transparent">
          <order-status-info />

          <order-items />

          <order-resend-email />

          <feed :subject-id="$store.state.orderItem.id" subject="order" />
        </div>
      </div>

      <order-sidebar />
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import OrderItems from "./include/orders/OrderItems";
import OrderSidebar from "./include/orders/OrderSidebar";
import OrderStatusInfo from "./partials/orders/OrderStatusInfo";
import Feed from "./partials/orders/OrderFeed";
import OrderResendEmail from "./partials/orders/OrderResendEmail";
import { mapGetters } from "vuex";

export default {
  name: "CRMOrder",
  components: {
    OrderResendEmail,
    feed: Feed,
    OrderStatusInfo,
    OrderSidebar,
    OrderItems,
    CRMLayout
  },
  beforeRouteLeave(to, from, next) {
    if (this.confirmIfWasEdit()) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  beforeDestroy() {
    this.$store.commit("orderFeed/clearChanges");
  },
  created() {
    this.$store.commit("orderItem/set_page_is_created", false);
    this.$store.dispatch("orderStatus/loadStatuses");
    this.$store
      .dispatch("orderItem/loadOrder", this.$route.params.id)
      .then(() => {
        this.$store
          .dispatch("orderFeed/load", {
            type: "orders"
          })
          .then(() => {
            setTimeout(() => {
              this.recalculatePrice();
            }, 300);
          });
      });
  },
  computed: {
    ...mapGetters({
      products: "orderItem/products",
      deliveryCountryId: "orderAddress/getDeliveryCountryId",
      orderId: "orderItem/orderId"
    })
  },
  methods: {
    confirmIfWasEdit() {
      if (this.$store.getters["orderItem/wasEdited"]) {
        return confirm("Are you sure you want to leave the page?");
      }

      return true;
    },
    recalculatePrice() {
      this.$store.dispatch("orderPrice/calculate");
    }
  }
};
</script>

<style scoped>
.user__info-edit_in.user {
  display: block !important;
}

.user__info-edit_in.delivery-address {
  display: block !important;
}

.user__info-edit_in.invoice-address {
  display: block !important;
}

@media screen and (max-width: 767px) {
  .order__items .order__items-headline span:nth-child(2),
  .order__items .order__item-name {
    width: 24%;
  }

  .order__items .order__item-frame-number,
  .order__items-headline span:nth-child(4) {
    width: 15%;
  }

  .order__items .order__item-counter {
    width: 11%;
  }

  .order__items .order__items-total_headline {
    margin-left: 37%;
  }

  .order__items .order__items-total_l {
    width: 13%;
  }
}

.order__items {
  /*overflow: hidden;*/
}

.order__item {
  align-items: flex-end;
}

.order__items-in {
  background: #fff;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .order__items .order__items-headline span:nth-child(2),
  .order__items .order__item-name {
    width: 24%;
  }

  .order__items .order__item-frame-number,
  .order__items-headline span:nth-child(4) {
    width: 15%;
  }

  .order__items .order__item-counter {
    width: 11%;
  }

  .order__items .order__items-total_headline {
    margin-left: 37%;
  }

  .order__items .order__items-total_l {
    width: 13%;
  }

  .comment__items {
    padding-left: 10px;
    padding-right: 10px;
  }

  .comment__item-files_r.indent {
    padding-right: 20px;
  }
}
</style>
