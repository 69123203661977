<template>
  <div v-if="canEditOrder" class="comment__item-files_r indent">
    <button class="btn btn-white btn-bold" @click="resendEmail()">
      Resend e-mail
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "OrderResendEmail",
  computed: {
    ...mapGetters({
      canEditOrder: "permissions/canEditOrder"
    }),
    ...mapState({
      order_id: state => state.orderItem.id
    })
  },
  methods: {
    resendEmail() {
      let ctx = this;
      this.$store
        .dispatch("resend_email_request", this.order_id)
        .then(() => {
          ctx.$root.$emit("modalShow", {
            type: "info",
            text: "e-mail successfully resend"
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    }
  }
};
</script>
