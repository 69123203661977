import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "../../helpers/parseError";

const state = {
  countries: []
};

// getters
const getters = {
  forSelect: state =>
    state.countries.map(country => {
      return {
        label: country.country_name,
        value: country.id
      };
    }),
  getCountry: state => country_id => {
    return state.countries.find(country => country.id === country_id) || null;
  }
};

// actions
const actions = {
  load({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}countries`)
        .then(response => {
          commit("set_countries", response.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  }
};

// mutations
const mutations = {
  set_countries(state, countries) {
    state.countries = countries;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
