<template>
  <div class="serial__wrapper">
    <div class="serial__items">
      <div v-for="(spec, index) in getSpecs" :key="index" class="serial_item">
        <div class="title">{{ spec.title }}</div>
        <div class="spec-info">
          <div class="spec-title">{{ spec["spec_title"] }}</div>
          <div class="spec-description">{{ spec.description }}</div>
        </div>
        <div class="serial-numbers">
          <div v-if="spec.serial" class="serial-number-data">
            <div
              v-for="(serial, index) in getOldSerials(spec)"
              :key="index"
              class="serial-number-item"
            >
              <div v-if="serial.modeEdit" class="edit-serial-form">
                <input
                  :value="serial.tempSerial"
                  type="text"
                  @input="editSpec($event, spec, serial)"
                />
                <button
                  class="save-serial-btn"
                  @click.prevent="saveEditedSerial(spec, serial)"
                >
                  Save
                </button>
                <button
                  class="cancel-serial-btn"
                  @click.prevent="cancelEditingSerial(spec, serial)"
                >
                  Cancel
                </button>
              </div>
              <div
                v-if="serial.serial && !serial.modeEdit"
                class="serial-number old"
              >
                <div class="serial">
                  {{ serial.serial }}
                  <div v-if="serial.edited" class="serial-edited">edited</div>
                </div>
                <div
                  class="serial-edit"
                  @click="showSerialForm(spec, false, true, serial)"
                >
                  edit
                </div>
                <div
                  class="serial-delete"
                  @click="deleteSerial(serial.serial, true, spec)"
                >
                  delete
                </div>
              </div>
              <div v-if="serial.serial" class="serial-number-date">
                {{ getOldSerialDate(serial["added_at"]) }}
                <b>{{ getOldSerialTime(serial["added_at"]) }}</b>
              </div>
            </div>
            <div v-if="spec.serial" class="serial-number-item">
              <div class="serial-number">
                <div v-if="!showSpecSerial(spec)" class="edit-serial-form">
                  <input
                    :value="serials[spec.id].tempSerial"
                    type="text"
                    @input="editSpec($event, spec)"
                  />
                  <button
                    class="save-serial-btn"
                    @click.prevent="saveEditedSerial(spec)"
                  >
                    Save
                  </button>
                  <button
                    class="cancel-serial-btn"
                    @click.prevent="cancelEditingSerial(spec)"
                  >
                    Cancel
                  </button>
                </div>

                <div v-if="showSpecSerial(spec)" class="serial">
                  {{ spec.serial }}
                  <div v-if="spec.edited" class="serial-edited">edited</div>
                </div>

                <div
                  v-if="showSpecSerial(spec)"
                  class="serial-edit"
                  @click="showSerialForm(spec, false, true)"
                >
                  edit
                </div>
                <div
                  v-if="showSpecSerial(spec)"
                  class="serial-delete"
                  @click="deleteSerial(spec, false)"
                >
                  delete
                </div>
              </div>
              <div v-if="spec.serial" class="serial-number-date">
                {{ getSerialDate(spec) }}
                <b>{{ getSerialTime(spec) }}</b>
              </div>
            </div>
          </div>
          <div class="serial-form">
            <span
              v-if="!serials[spec.id].show"
              class="add-new-serial-btn"
              @click="showSerialForm(spec)"
            >
              + <span>{{ addSerialNumberBtnText(spec) }}</span>
            </span>
            <div v-if="inputVisibility(spec)" class="form d-flex">
              <div class="inp-group inp-group__xl">
                <input
                  class="add-new-serial-input"
                  @input="changeSerial($event, spec)"
                />
              </div>

              <button
                class="btn btn-green"
                type="button"
                @click="addSerial(spec)"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mb-4">
      <button class="show-btn" @click.prevent="toggleShortList">
        {{ fullListToggleBtnText }}
      </button>
    </div>
    <div class="text-right">
      <button class="export-btn" @click.prevent="exportSerials">Export</button>
    </div>
    <modal
      :adaptive="true"
      :clickToClose="false"
      :scrollable="false"
      :shiftY="0.1"
      :width="500"
      class="edit-serial-modal"
      height="auto"
      name="edit-serial"
      @closed="clearEdited"
    >
      <div class="wrapper">
        <p class="dg-content">Edit serial number</p>
        <div class="inp-group serial-input">
          <input v-model="edited" type="text" />
        </div>
        <div class="btn-grid">
          <button class="dg-btn dg-btn--cancel" @click.prevent="closeEditModal">
            <span>Close</span>
          </button>
          <button
            :disabled="saveBtnDisabled"
            class="dg-btn dg-btn--ok dg-pull-right edit-serial-modal-btn"
          >
            <span class="dg-btn-content" @click.prevent="saveEdited">
              <span>Save</span>
            </span>
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "SerialNumbers",
  props: ["specs"],
  data() {
    return {
      serials: {},
      shortListTitles: ["Frame", "Motor", "Battery", "Display", "Remote"],
      showFullList: false,
      oldSerial: null,
      edited: null,
      editedSpec: null,
      isOldSerial: false
    };
  },
  created() {
    for (let spec of this.specs) {
      this.serials[spec.id] = {
        show: false,
        value: null
      };
    }
  },
  computed: {
    getSpecs() {
      if (this.showFullList) {
        return this.specs;
      }
      return this.specs.filter(item => {
        return this.shortListTitles.includes(item.title);
      });
    },
    fullListToggleBtnText() {
      return this.showFullList ? "Show less" : "Show more";
    },
    saveBtnDisabled() {
      if (this.edited !== null && this.edited.trim() === "") {
        return true;
      }
      return (
        !this.edited || this.oldSerial === this.edited || this.edited === ""
      );
    }
  },
  methods: {
    cancelEditingSerial(spec, serial = null) {
      let serials = { ...this.serials };
      if (!serial) {
        serials[spec.id].modeEdit = false;
        this.$set(this.$data, "serials", { ...serials });
      } else {
        this.$store.commit("bikes/setModeEditForSerial", {
          spec,
          serial,
          edit: false
        });
      }
    },
    saveEditedSerial(spec, sn = null) {
      let serials = { ...this.serials };
      let bikeId = this.$route.params.id;
      if (!sn) {
        let serial = serials[spec.id];
        this.$store
          .dispatch("bikes/saveEdited", {
            spec_id: spec.id,
            serial: serial.tempSerial,
            bike_id: bikeId,
            old: false
          })
          .then(() => {
            this.closeEdit(spec);
            this.$root.$emit("modalShow", {
              type: "info",
              text: "Serial number saved"
            });
            this.$store
              .dispatch("bikes/load", { bikeID: bikeId, withFullBuild: true })
              .then(() => {
                this.$store.dispatch("orderFeed/load", {
                  type: "bikes",
                  subject_id: bikeId
                });
              });
          })
          .catch(error => {
            this.$root.$emit("modalShow", { text: error, type: "error" });
          });
      } else {
        this.$store
          .dispatch("bikes/saveEdited", {
            spec_id: spec.id,
            serial: sn.tempSerial,
            bike_id: bikeId,
            old: true,
            old_serial: sn.serial
          })
          .then(() => {
            this.cancelEditingSerial(spec, sn);
            this.$root.$emit("modalShow", {
              type: "info",
              text: "Serial number saved"
            });
            this.$store
              .dispatch("bikes/load", { bikeID: bikeId, withFullBuild: true })
              .then(() => {
                this.$store.dispatch("orderFeed/load", {
                  type: "bikes",
                  subject_id: bikeId
                });
              });
          })
          .catch(error => {
            this.$root.$emit("modalShow", { text: error, type: "error" });
          });
      }
    },
    closeEdit(spec) {
      let serials = { ...this.serials };
      delete serials[spec.id].tempSerial;
      delete serials[spec.id].modeEdit;
      this.$set(this.$data, "serials", { ...serials });
    },
    editSpec(event, spec, serial = null) {
      let serials = { ...this.serials };
      if (!serial) {
        serials[spec.id].tempSerial = event.target.value;
        this.$set(this.$data, "serials", { ...serials });
      } else {
        this.$store.commit("bikes/editOldSerial", {
          spec,
          serial,
          value: event.target.value
        });
      }
    },
    showSpecSerial(serial) {
      return !this.serials[serial.id].modeEdit;
    },
    saveEdited() {
      let bikeId = this.$route.params.id;
      this.$store
        .dispatch("bikes/saveEdited", {
          spec_id: this.editedSpec.id,
          serial: this.edited,
          bike_id: bikeId,
          old: this.isOldSerial,
          old_serial: this.oldSerial
        })
        .then(() => {
          this.closeEditModal();
          this.$root.$emit("modalShow", {
            type: "info",
            text: "Serial number saved"
          });
          this.$store
            .dispatch("bikes/load", { bikeID: bikeId, withFullBuild: true })
            .then(() => {
              this.$store.dispatch("orderFeed/load", {
                type: "bikes",
                subject_id: bikeId
              });
            });
        })
        .catch(error => {
          this.$root.$emit("modalShow", { text: error, type: "error" });
        });
    },
    closeEditModal() {
      this.$modal.hide("edit-serial");
    },
    clearEdited() {
      this.edited = null;
      this.editedSpec = null;
      this.oldSerial = null;
      this.isOldSerial = false;
    },
    editSerial(serial, old, spec = null) {
      this.oldSerial = serial.serial;
      this.edited = serial.serial;
      this.editedSpec = spec || serial;
      this.isOldSerial = old;
      this.$modal.show("edit-serial");
    },
    deleteSerial(serial, old = false, spec = null) {
      this.$dialog
        .confirm(
          `Are you sure you want to delete this serial number (${serial.serial})?`
        )
        .then(() => {
          let bikeId = this.$route.params.id;
          this.$store
            .dispatch("bikes/deleteSerial", {
              spec_id: spec ? spec.id : serial.id,
              serial: typeof serial === "string" ? serial : serial.serial,
              bike_id: bikeId,
              old: old
            })
            .then(() => {
              this.closeEditModal();
              this.$root.$emit("modalShow", {
                type: "info",
                text: "Serial number deleted"
              });
              this.$store
                .dispatch("bikes/load", { bikeID: bikeId, withFullBuild: true })
                .then(() => {
                  this.$store.dispatch("orderFeed/load", {
                    type: "bikes",
                    subject_id: bikeId
                  });
                });
            })
            .catch(error => {
              this.$root.$emit("modalShow", { text: error, type: "error" });
            });
        })
        .catch(() => {});
    },
    toggleShortList() {
      this.showFullList = !this.showFullList;
    },
    exportSerials() {
      this.$store
        .dispatch("bikes/exportSerials", this.$route.params.id)
        .then(result => {
          location.href = result.data.url;
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    getOldSerials(spec) {
      if (spec["old_serials"]) {
        return spec["old_serials"];
      }
      return [];
    },
    getOldSerialDate(date) {
      if (date) {
        return this.$moment.unix(parseInt(date)).format("DD.MM.YYYY");
      }
    },
    getOldSerialTime(date) {
      if (date) {
        return this.$moment.unix(parseInt(date)).format("HH:mm");
      }
    },
    getSerialDate(spec) {
      if (spec["added_at"]) {
        return this.$moment
          .unix(parseInt(spec["added_at"]))
          .format("DD.MM.YYYY");
      } else {
        return null;
      }
    },
    getSerialTime(spec) {
      if (spec["added_at"]) {
        return this.$moment.unix(parseInt(spec["added_at"])).format("HH:mm");
      } else {
        return null;
      }
    },
    addSerialNumberBtnText(spec) {
      if (!spec.serial) {
        return "add serial number";
      }
      return "replace with a new one";
    },
    showSerialForm(spec, status = true, edit = false, serial = null) {
      let serials = { ...this.serials };
      serials[spec.id].show = status;
      if (!serial) {
        serials[spec.id].modeEdit = edit;
        serials[spec.id].tempSerial = spec.serial;
        setTimeout(() => {
          this.$set(this.$data, "serials", { ...serials });
        }, 1);
      } else {
        this.$store.commit("bikes/setModeEditForSerial", {
          spec,
          serial,
          edit: true
        });
      }
    },
    inputVisibility(spec) {
      return this.serials[spec.id] && this.serials[spec.id].show === true;
    },
    addSerial(spec) {
      let bikeID = this.$route.params.id;
      this.$store
        .dispatch("bikes/updateSerial", {
          spec_id: spec.id,
          serial: this.serials[spec.id].value,
          bike_id: bikeID
        })
        .then(() => {
          this.showSerialForm(spec, false);
          this.$root.$emit("modalShow", {
            type: "info",
            text: "Serial number added"
          });
          this.$store
            .dispatch("bikes/load", { bikeID, withFullBuild: true })
            .then(() => {
              this.$store.dispatch("orderFeed/load", {
                type: "bikes",
                subject_id: this.$route.params.id
              });
            });
        })
        .catch(error => {
          this.$root.$emit("modalShow", {
            type: "info",
            text: typeof error == 'string' && error.length 
              ? error 
              : "Error while adding serial number. Try later"
          });
        });
    },
    changeSerial(event, spec) {
      let serials = { ...this.serials };
      serials[spec.id].value = event.target.value;
      this.serials = serials;
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-serial-modal-btn:disabled {
  background: rgba(150, 150, 150, 1);
  border-color: rgba(150, 150, 150, 0.3);
  opacity: 0.7;
  cursor: not-allowed;
}

.serial-edited {
  font-size: 10px;
  display: inline-block;
  color: #828282;
  line-height: initial;
}

.edit-serial-modal {
  .wrapper {
    padding: 30px 20px;
    max-width: 500px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .dg-content {
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
    width: 100%;
  }

  .inp-group {
    width: 100%;
    margin-bottom: auto;
  }

  .btn-grid {
    margin: 40px 0 0;
    display: grid;
    grid-gap: 10px;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);

    .dg-btn {
      min-width: auto;

      &.dg-btn--ok {
        span {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
  }
}

.serial__items {
  padding: 20px 50px;

  .serial_item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      width: 10%;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: #1c1c1c;
      margin-right: 50px;
    }

    .spec-info {
      max-width: 30%;
      width: 30%;
      margin-right: 50px;

      .spec-title {
        font-size: 14px;
        line-height: 22px;
        color: #1c1c1c;
        margin-bottom: 2px;
      }

      .spec-description {
        font-size: 14px;
        line-height: 22px;
        color: #828282;
      }
    }

    .edit-serial-form {
      input {
        height: 30px;
        min-width: 400px;
        font-size: 14px;
        background: #ffffff;
        border-radius: 5px;
        margin-right: 10px;
        box-shadow: 0 0 2px #454545;
        padding-left: 10px;
        border: none;
        line-height: 14px;
      }

      button {
        margin-right: 16px;
        border: none;
        font-size: 14px;
        line-height: 14px;
        height: 33px;
        padding-left: 12px;
        padding-right: 12px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border-radius: 5px;

        &:last-of-type {
          margin-right: 0;
        }

        &.save-serial-btn {
          background: #c2e900;
          font-weight: bold;
        }

        &.cancel-serial-btn {
          background: transparent;

          &:hover {
            background: #c4bfbf;
          }
        }
      }
    }

    .serial-numbers {
      width: 60%;
      display: flex;
      flex-wrap: wrap;

      .serial-number-data {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 0;

        .serial-number-item {
          display: flex;
          width: 100%;
          justify-content: space-between;
          //margin-bottom: 6px;
          margin-bottom: 2px;

          &.old {
            .serial-number {
              color: #bf0000;
              opacity: 0.5;
            }
          }
        }

        .serial-number {
          font-size: 14px;
          line-height: 22px;
          color: #1c1c1c;
          margin-bottom: 0;
          display: flex;
          justify-content: space-between;

          &:hover {
            .serial-edit {
              opacity: 1;
            }

            .serial-delete {
              opacity: 1;
            }
          }

          .serial-edit {
            margin-right: 10px;
            margin-left: 10px;
            opacity: 0;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            text-decoration: underline;
            color: #1c1c1c;
          }

          .serial-delete {
            opacity: 0;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            color: #bf0000 !important;
            text-decoration: underline;
          }

          .serial {
            @media screen and (min-width: 1200px) {
              width: 256px;
              padding-right: 8px;
            }
          }

          &.old {
            color: #bf0000;
            //opacity: 0.5;
          }
        }

        .serial-number-date {
          margin: 0 0 0 auto;
          font-size: 14px;
          line-height: 22px;
        }
      }

      .serial-form {
        .add-new-serial-btn {
          display: block;
          cursor: pointer;
          font-size: 14px;
          line-height: 22px;
          color: #1c1c1c;

          & > span {
            text-decoration: underline;
            position: relative;
            top: -2px;

            &:hover {
              text-decoration: none;
            }
          }
        }

        .form {
          margin-top: 8px;
        }

        .add-new-serial-input {
          background: #ffffff;
          border-radius: 5px;
          font-size: 14px;
          line-height: 18px;
          height: 30px;
          min-width: 400px;

          &:focus {
            filter: drop-shadow(0px 0px 2px #454545);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .serial__items {
    .serial_item {
      .serial-numbers {
        .serial-form {
          width: 100%;

          .inp-group__xl {
            width: 100%;
          }

          .add-new-serial-input {
            min-width: 100%;
          }
        }
      }
    }
  }
}
</style>
