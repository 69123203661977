// initial state
import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import parseError from "../../helpers/parseError";
import router from "../../../routes/router";
import { random } from "../../helpers/stringHelper";

const state = {
  id: null,
  active: false,
  languages: [],
  albums: [],
  deleted_at: null,
  start_activity: null,
  end_activity: null,
  first_activity: null,
};

// getters
const getters = {
  getAlbums: (state) => {
    return state.albums.sort((a, b) => a.sort_order - b.sort_order);
  },
};

// actions
const actions = {
  get_media_request({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}media/${id}`)
        .then((response) => {
          dispatch("handle_response", response.data);
          resolve(response);
        })
        .catch((err) => {
          reject(parseError(err));
        });
    });
  },
  save_request({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${API_ENDPOINT}media/${state.id}`, { data: state })
        .then((response) => {
          dispatch("handle_response", response.data);
          resolve(response);
        })
        .catch((err) => {
          reject(parseError(err));
        });
    });
  },
  create_request({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}media`, { data: state })
        .then((response) => {
          commit("set_id", response.data.id);
          router
            .replace({
              name: "CRMMediaLibraryContent",
              params: { id: response.data.id },
            })
            .then(() => {});
          resolve(response);
        })
        .catch((err) => {
          reject(parseError(err));
        });
    });
  },
  delete_request(context, media) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${API_ENDPOINT}media/${media.id}`, { data: media })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(parseError(err));
        });
    });
  },
  restore_request({ commit, dispatch }) {
    commit("set_deleted_at", null);
    return dispatch("save_request");
  },

  handle_response({ commit }, data) {
    commit("set_id", data.id);
    commit("set_active", data.active);
    commit("set_languages", data.languages);
    commit("set_albums", data.albums);
    commit("set_start_activity", data.start_activity);
    commit("set_end_activity", data.end_activity);
    commit("set_first_activity", data.first_activity);
    commit("set_deleted_at", data.deleted_at);
  },
  set_language({ commit, state }, { lang, key, value }) {
    let languages = state.languages;
    // eslint-disable-next-line
    if (languages.hasOwnProperty(lang)) {
      languages[lang][key] = value;
    } else {
      languages = {
        ...languages,
        [lang]: {
          [key]: value,
        },
      };
    }
    commit("set_languages", languages);
  },
  set_active({ commit }, value) {
    commit("set_active", value);
  },
  add_new_album({ commit, rootState }) {
    let languages = {};
    rootState.languages.list.map(
      (lang) =>
        (languages[lang] = {
          title: null,
        })
    );

    commit("add_new_album", {
      id: `random-${random(10)}`,
      files: [],
      languages,
      zip: null,
    });
  },
  update_sort_order({ commit }, albums) {
    commit("set_albums", albums);
  },
  set_start_activity({ commit }, value) {
    commit("set_start_activity", value);
  },
  set_end_activity({ commit }, value) {
    commit("set_end_activity", value);
  },
  clearState({ commit }) {
    commit("set_id", null);
    commit("set_active", false);
    commit("set_languages", []);
    commit("set_albums", []);
    commit("set_deleted_at", null);
    commit("set_start_activity", null);
    commit("set_end_activity", null);
    commit("set_first_activity", null);
  },
  deleteAlbum(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${API_ENDPOINT}media/albums/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(parseError(err));
        });
    });
  },
  get_album_by_id(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}media/getAlbum/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(parseError(err));
        });
    });
  },
  update_album(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}media/updateAlbum`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(parseError(err));
        });
    });
  },
  upload_cover(context, data) {
    return new Promise((resolve, reject) => {
      console.log(data.id);
      axios({
        method: "post",
        url: `${API_ENDPOINT}media/uploadCover/${data.id}`,
        data: data.data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(parseError(err));
        });
    });
  },
  get_tree_media() {
    return fetch(`${API_ENDPOINT}media/getTreeMedia`).then((res) => res.json());
  },
  get_media_by_id(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}media/getMedia/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(parseError(err));
        });
    });
  },
  update_media(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}media/updateMedia`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(parseError(err));
        });
    });
  },
};

// mutations
const mutations = {
  set_id(state, value) {
    state.id = value;
  },
  set_active(state, value) {
    state.active = value;
  },
  set_languages(state, value) {
    state.languages = value;
  },
  set_albums(state, value) {
    state.albums = value;
  },
  add_new_album(state, value) {
    state.albums.push(value);
  },
  set_start_activity(state, value) {
    state.start_activity = value;
  },
  set_end_activity(state, value) {
    state.end_activity = value;
  },
  set_first_activity(state, value) {
    state.first_activity = value;
  },
  set_deleted_at(state, value) {
    state.deleted_at = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
