<template>
  <div class="adding-product-model">
    <div class="adding-product-model-title" @click="toggleProducts()">
      <span :class="{ reverse: !showProducts }" class="arr"></span>
      <span>{{ model.title }}</span>
    </div>

    <div v-if="showProducts" class="adding-product-model-product-wrap">
      <adding-product
        v-for="(build, index) in model['builds']"
        :key="index"
        :build="build"
        @selectProduct="toggleProducts()"
      />
    </div>
  </div>
</template>

<script>
import AddingProduct from "./AddingProduct";

export default {
  name: "AddingProductModel",
  components: { AddingProduct },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showProducts: false
    };
  },
  methods: {
    toggleProducts() {
      this.showProducts = !this.showProducts;
    }
  }
};
</script>

<style scoped>
.adding-product-model {
  padding: 8px 0 0 20px;
}

.adding-product-model,
.adding-product-model-product-wrap {
  width: 100%;
}

.adding-product-model-product-wrap {
  padding: 20px 0 0 8px;
}

.adding-product-model-title,
.adding-product-model-product-wrap {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;

  color: #000000;
}

.adding-product-model-product-wrap {
  font-weight: normal;
}
</style>
