<template>
  <layout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMEditBuild' }"
        class="btn btn-white btn-reset add-models-btn"
        >Create New Build
      </router-link>
    </template>

    <template v-slot:header-title>Products list</template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>
              Builds
            </h5>
          </div>
          <div class="table__products table">
            <div class="thead">
              <div class="tr">
                <div class="pointer id th">
                  <span>ID</span>
                </div>
                <div class="pointer th">
                  <span>Title</span>
                </div>
                <div class="pointer th">
                  <span>Model</span>
                </div>
                <div class="pointer th">
                  <span>Build</span>
                </div>
                <div class="pointer th">
                  <span>Size</span>
                </div>
                <div class="pointer th">
                  <span>Color</span>
                </div>
                <div class="th">Stock</div>
                <div class="pointer th">
                  <span>Price, €</span>
                </div>
                <div class="pointer th">
                  <span>Blocked</span>
                </div>
              </div>
            </div>
            <div class="tbody">
              <div
                v-for="item in items"
                :key="item.id"
                class="ui-state-default tr"
                @click="goToBuild(item.id)"
              >
                <a class="ui-state-default tr">
                  <div class="id td">{{ item.id }}</div>
                  <div class="td">{{ item.title }}</div>
                  <div class="td">{{ item.model }}</div>
                  <div class="td">{{ item.build }}</div>
                  <div class="td">{{ item.size }}</div>
                  <div class="td">{{ item.color }}</div>
                  <div class="td">{{ item.stock }}</div>
                  <div class="td">{{ price(item) }}</div>
                  <div class="td">{{ isBlocked(item) }}</div>
                </a>
              </div>
            </div>
          </div>

          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>

        <div class="inp-group__wrapper">
          <div class="inp-group__headline">
            <h5>Type</h5>
          </div>
          <div class="inp-group__in">
            <div class="inp-group__dropdown">
              <v-select
                v-model="buildType"
                :clearable="false"
                :options="buildTypes"
                label="label"
                placeholder="Select build type"
              />
            </div>
          </div>
        </div>
        <div v-if="buildType.code === 1" class="inp-group__wrapper">
          <div class="inp-group__headline">
            <h5>Title</h5>
          </div>
          <div class="inp-group__in">
            <div class="inp-group__dropdown">
              <div class="inp-group">
                <input
                  v-model="webTitle"
                  placeholder="Filter by web title"
                  title="Title"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="inp-group__wrapper">
          <div class="inp-group__headline">
            <h5>Brand</h5>
          </div>
          <div class="inp-group__in">
            <div class="inp-group__dropdown">
              <v-select
                v-model="brand"
                :options="brands"
                label="title"
                placeholder="Filter by brand"
              />
            </div>
          </div>
        </div>
        <div v-if="false" class="inp-group__wrapper">
          <div class="inp-group__headline">
            <h5>Collection/Year</h5>
          </div>
          <div class="inp-group__in">
            <div class="inp-group__dropdown">
              <v-select label="label" placeholder="Select product type" />
            </div>
          </div>
        </div>
        <div class="inp-group__wrapper">
          <div class="inp-group__headline">
            <h5>Model/Category</h5>
          </div>
          <div class="inp-group__in">
            <div class="inp-group__dropdown">
              <v-select
                v-model="buildModel"
                :options="buildModels"
                label="title"
                placeholder="Filter by model/category"
              />
            </div>
          </div>
        </div>
        <div class="inp-group__wrapper">
          <div class="inp-group__headline">
            <h5>Build/Sub-category</h5>
          </div>
          <div class="inp-group__in">
            <div class="inp-group__dropdown">
              <v-select
                v-model="buildTitle"
                :options="buildTitles"
                label="title"
                placeholder="Filter by build/sub-category"
              />
            </div>
          </div>
        </div>
        <div class="inp-group__wrapper">
          <div class="inp-group__headline">
            <h5>Size</h5>
          </div>
          <div class="inp-group__in">
            <div class="inp-group__dropdown">
              <v-select
                v-model="buildSize"
                :options="buildSizes"
                label="title"
                placeholder="Filter by size"
              />
            </div>
          </div>
        </div>
        <div class="inp-group__wrapper">
          <div class="inp-group__headline">
            <h5>Color</h5>
          </div>
          <div class="inp-group__in">
            <div class="inp-group__dropdown">
              <v-select
                v-model="buildColor"
                :options="buildColors"
                label="ms_title"
                placeholder="Filter by color"
              />
            </div>
          </div>
        </div>
        <div class="inp-group__wrapper">
          <div class="inp-group__headline">
            <h5>Blocked</h5>
          </div>
          <div class="inp-group__in">
            <div class="inp-group__dropdown">
              <v-select
                v-model="blocked"
                :options="blockedStatuses"
                label="label"
                placeholder="Filter by blocked status"
              />
            </div>
          </div>
        </div>

        <div class="main__r-in">
          <div class="inp-group__wrapper inp-group__from-to">
            <div class="inp-group__headline">
              <h5>Stock</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group">
                <label for="from1">from</label>
                <input
                  id="from1"
                  type="number"
                  @input="debounceChange($event, 'stockFrom')"
                />
              </div>

              <div class="inp-group">
                <label for="to1">to</label>
                <input
                  id="to1"
                  type="number"
                  @input="debounceChange($event, 'stockTo')"
                />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper inp-group__from-to">
            <div class="inp-group__headline">
              <h5>Price</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group">
                <label for="from2">from</label>
                <input
                  id="from2"
                  type="number"
                  @input="debounceChange($event, 'priceFrom')"
                />
              </div>

              <div class="inp-group">
                <label for="to2">to</label>
                <input
                  id="to2"
                  type="number"
                  @input="debounceChange($event, 'priceTo')"
                />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Created</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="createdAt"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Edited</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="updatedAt"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>
          <br /><br /><br />

          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-fw btn-sm btn-bold"
              @click="resetFilters"
            >
              Reset filter
            </button>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";
import $ from "jquery";
import { mapGetters } from "vuex";
import { debounce } from "debounce";

export default {
  name: "CRMDashBoard",
  components: {
    vSelect,
    DatePicker,
    layout: CRMLayout,
    Paginator
  },
  data() {
    return {
      currentPage: 1,
      lastPage: 1,
      total: 0,
      perPage: 0,
      buildType: {
        code: 1,
        label: "Bikes"
      },
      webTitle: null,
      buildTitle: null,
      brand: null,
      buildModel: null,
      buildSize: null,
      buildColor: null,
      blocked: null,
      stockFrom: null,
      stockTo: null,
      priceFrom: null,
      priceTo: null,
      createdAt: null,
      updatedAt: null,
      blockedStatuses: [
        {
          code: true,
          label: "Yes"
        },
        {
          code: false,
          label: "No"
        }
      ],
      buildTypes: [
        {
          code: 1,
          label: "Bikes"
        },
        {
          code: 2,
          label: "Parts"
        }
      ]
    };
  },
  created() {
    this.$store.dispatch("build/helper").then(() => {
      this.loadItems();
    });
  },
  computed: {
    ...mapGetters({
      items: "build/items",
      titles: "build/buildTitles",
      brands: "brands/list",
      models: "models/list",
      categories: "build/categories",
      subCategories: "build/subCategories",
      buildSizes: "sizes/list",
      buildColors: "colors/list"
    }),
    showTable() {
      return this.items.length > 0;
    },
    buildModels() {
      if (this.buildType.code === 1) {
        return this.models;
      } else {
        return this.categories;
      }
    },
    buildTitles() {
      if (this.buildType.code === 1) {
        return this.titles;
      } else {
        return this.subCategories;
      }
    },
    getFilters() {
      return JSON.stringify({
        buildType: this.buildType,
        buildTitle: this.buildTitle,
        brand: this.brand,
        buildModel: this.buildModel,
        buildSize: this.buildSize,
        buildColor: this.buildColor,
        blocked: this.blocked,
        stockFrom: this.stockFrom,
        stockTo: this.stockTo,
        priceFrom: this.priceFrom,
        priceTo: this.priceTo,
        createdAt: this.createdAt,
        updatedAt: this.updatedAt,
        currentPage: this.currentPage
      });
    }
  },
  mounted() {
    if (localStorage.getItem("filter_builds")) {
      let filters = JSON.parse(localStorage.getItem("filter_builds"));
      for (let key of Object.keys(filters)) {
        this.$set(this.$data, key, filters[key]);
      }
    }
  },
  beforeDestroy() {
    localStorage.setItem("filter_builds", this.getFilters);
  },
  methods: {
    debounceChange: debounce(function(event, field) {
      this[field] = event.target.value;
    }, 1000),
    goToBuild(item) {
      this.$router.push("/builds/" + item);
    },
    resetFilters() {
      this.buildType = {
        code: 1,
        label: "Bikes"
      };
      this.buildTitle = null;
      this.brand = null;
      this.buildModel = null;
      this.buildSize = null;
      this.buildColor = null;
      this.blocked = null;
      this.stockFrom = null;
      this.stockTo = null;
      this.priceFrom = null;
      this.priceTo = null;
      this.createdAt = null;
      this.updatedAt = null;
      this.currentPage = 1;
      this.loadItems();
    },
    isBlocked(item) {
      return item.blocked ? "Yes" : "No";
    },
    price(item) {
      return this.$crConverter(item.price / 100, "EUR");
    },
    changePage(page) {
      this.currentPage = page;
      this.loadItems();
    },
    loadItems() {
      let ctx = this;

      let options = {
        parameters: [],
        page: this.currentPage
      };

      options.parameters.push({
        name: "type",
        value: this.buildType.code
      });

      if (this.webTitle !== null && this.webTitle.trim() !== "") {
        options.parameters.push({
          name: "web_title",
          value: this.webTitle
        });
      }

      if (this.buildTitle !== null) {
        options.parameters.push({
          name: "title",
          value: this.buildTitle.id
        });
      }

      if (this.brand !== null) {
        options.parameters.push({
          name: "brand",
          value: this.brand.id
        });
      }

      if (this.buildModel !== null) {
        options.parameters.push({
          name: "model",
          value: this.buildModel.id
        });
      }

      if (this.buildSize !== null) {
        options.parameters.push({
          name: "size",
          value: this.buildSize.id
        });
      }

      if (this.buildColor !== null) {
        options.parameters.push({
          name: "color",
          value: this.buildColor.id
        });
      }

      if (this.blocked !== null) {
        options.parameters.push({
          name: "blocked",
          value: this.blocked.code
        });
      }

      if (this.stockFrom !== null) {
        options.parameters.push({
          name: "stock_from",
          value: this.stockFrom
        });
      }

      if (this.stockTo !== null) {
        options.parameters.push({
          name: "stock_to",
          value: this.stockTo
        });
      }

      if (this.priceFrom !== null) {
        options.parameters.push({
          name: "price_from",
          value: this.priceFrom
        });
      }

      if (this.priceTo !== null) {
        options.parameters.push({
          name: "price_to",
          value: this.priceTo
        });
      }

      if (this.createdAt !== null) {
        options.parameters.push({
          name: "created_at",
          value: this.createdAt
        });
      }

      if (this.updatedAt !== null) {
        options.parameters.push({
          name: "updated_at",
          value: this.updatedAt
        });
      }

      this.$store
        .dispatch("build/index", options)
        .then(response => {
          let meta = response.data.meta;
          this.currentPage = meta["current_page"];
          this.lastPage = meta["last_page"];
          this.total = meta.total;
          this.perPage = meta["per_page"];
          if (ctx.items < 6) {
            $("body, html").animate({ scrollTop: 0 }, 500, "swing");
          }
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    }
  },
  watch: {
    buildType(newValue, oldValue) {
      if (newValue.code !== oldValue.code) {
        this.currentPage = 1;
        this.loadItems();
      }
    },
    buildTitle() {
      this.currentPage = 1;
      this.loadItems();
    },
    brand() {
      this.currentPage = 1;
      this.loadItems();
    },
    buildModel() {
      this.currentPage = 1;
      this.loadItems();
    },
    buildSize() {
      this.currentPage = 1;
      this.loadItems();
    },
    buildColor() {
      this.currentPage = 1;
      this.loadItems();
    },
    blocked() {
      this.currentPage = 1;
      this.loadItems();
    },
    stockFrom(value) {
      if (value === "") {
        this.stockFrom = null;
      }
      this.currentPage = 1;
      this.loadItems();
    },
    stockTo(value) {
      if (value === "") {
        this.stockTo = null;
      }
      this.currentPage = 1;
      this.loadItems();
    },
    priceFrom(value) {
      if (value === "") {
        this.priceFrom = null;
      }
      this.currentPage = 1;
      this.loadItems();
    },
    priceTo(value) {
      if (value === "") {
        this.priceTo = null;
      }
      this.currentPage = 1;
      this.loadItems();
    },
    createdAt() {
      this.currentPage = 1;
      this.loadItems();
    },
    updatedAt() {
      this.currentPage = 1;
      this.loadItems();
    },
    webTitle() {
      this.currentPage = 1;
      this.loadItems();
    }
  }
};
</script>

<style scoped>
.table__products {
  font-size: 12px;
}

.table__products .td:not(.toggle__items),
.table__products .th {
  padding-top: 13px;
  padding-bottom: 13px;
}

.main__r-products .btn__wrapper {
  z-index: 10;
}

.no-padding {
  padding: 0;
}

.ui-state-default.open {
  background: #f1f1f1;
}

.ui-state-default.open .td {
  border: none;
}

.product-description {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
  width: auto !important;
  text-align: left !important;
  padding: 0;
  display: flex;
}

.product-description ul {
  list-style: none outside none;
  max-width: 350px;
}

.product-description ul li {
  margin-bottom: 5px;
}

.product-description .column {
  width: 50%;
}

.product-description .column .wrapper {
  display: flex;
}

.product-description .column .wrapper .left {
  margin-left: 0;
  margin-right: 10px;
}

.product-description .column .wrapper .right {
  margin-left: 0;
  margin-right: 0;
}

.product-description.open {
  max-height: 1000px;
  padding: 0 15px 15px 102px;
  background: #f1f1f1;
}

.no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.id.td:first-child,
.id.th:first-child {
  padding-right: 10px;
  width: 60px !important;
  box-sizing: border-box;
  text-align: left !important;
  padding-left: 20px;
}

.id.td:first-child ~ .td:nth-child(2),
.id.th:first-child ~ .th:nth-child(2) {
  /*width: 280px;*/
  width: calc(100% - 900px);
}

.id.td:first-child ~ .td:nth-child(3),
.id.th:first-child ~ .th:nth-child(3) {
  width: 120px;
}

.id.td:first-child ~ .td:nth-child(4),
.id.th:first-child ~ .th:nth-child(4) {
  width: 120px;
}

.id.td:first-child ~ .td:nth-child(5),
.id.th:first-child ~ .th:nth-child(5) {
  width: 100px;
}

.id.td:first-child ~ .td:nth-child(6),
.id.th:first-child ~ .th:nth-child(6) {
  width: 200px;
}

.id.td:first-child ~ .td:nth-child(7),
.id.th:first-child ~ .th:nth-child(7) {
  width: 100px;
}

.id.td:first-child ~ .td:nth-child(8),
.id.th:first-child ~ .th:nth-child(8) {
  width: 100px;
}

.id.td:first-child ~ .td:nth-child(9),
.id.th:first-child ~ .th:nth-child(9) {
  width: 100px;
}

.id.td:first-child ~ .td,
.id.th:first-child ~ .th {
  padding-right: 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 1600px) {
  .id.td:first-child,
  .id.th:first-child {
    padding-right: 10px;
    width: 60px !important;
    box-sizing: border-box;
    text-align: left !important;
    padding-left: 20px;
  }

  .id.td:first-child ~ .td:nth-child(2),
  .id.th:first-child ~ .th:nth-child(2) {
    /*width: 280px;*/
    width: calc(35% - 60px);
  }

  .id.td:first-child ~ .td:nth-child(3),
  .id.th:first-child ~ .th:nth-child(3) {
    width: 10%;
  }

  .id.td:first-child ~ .td:nth-child(4),
  .id.th:first-child ~ .th:nth-child(4) {
    width: 8%;
  }

  .id.td:first-child ~ .td:nth-child(5),
  .id.th:first-child ~ .th:nth-child(5) {
    width: 6%;
  }

  .id.td:first-child ~ .td:nth-child(6),
  .id.th:first-child ~ .th:nth-child(6) {
    width: 17%;
  }

  .id.td:first-child ~ .td:nth-child(7),
  .id.th:first-child ~ .th:nth-child(7) {
    width: 8%;
  }

  .id.td:first-child ~ .td:nth-child(8),
  .id.th:first-child ~ .th:nth-child(8) {
    width: 8%;
  }

  .id.td:first-child ~ .td:nth-child(9),
  .id.th:first-child ~ .th:nth-child(9) {
    width: 8%;
  }
}

@media screen and (max-width: 767px) {
  .table__products .td:not(.toggle__items):nth-child(4),
  .table__products .td:not(.toggle__items):nth-child(5),
  .table__products .td:not(.toggle__items):nth-child(6),
  .table__products .td:not(.toggle__items):nth-child(7),
  .table__products .th:nth-child(4),
  .table__products .th:nth-child(5),
  .table__products .th:nth-child(6),
  .table__products .th:nth-child(7) {
    width: 20%;
  }
}
</style>
