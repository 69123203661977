<template>
  <div>
    <div class="main__headline" :class="{ 'saved-order': order_status == 1 }">
      <h5>{{ order_status == 1 ? 'Saved ' : '' }} Order {{ order_id || null }} status history</h5>
    </div>
    <div v-if="statuses.length" class="order__status">
      <div class="order__status-bar">
        <div
          v-for="status in statuses"
          :key="status.id"
          :class="getOrderHistoryPartClass(status)"
          class="status__bar-item"
        >
          <div class="status__bar-progress"></div>
          <div class="status__bar-round"></div>
        </div>
      </div>
      <div class="order__status-desc">
        <div
          v-for="status in statuses"
          :key="status.id"
          class="order__status-item"
        >
          <div class="order__status-item_desc">
            <h5>{{ getStatusTitle(status) }}</h5>
          </div>
          <div class="order__status-item_date">
            <span>{{ getOrderStatusDateFromHistory(status) }}</span>
            <span>{{ getOrderStatusTimeFromHistory(status) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { PAID } from "@/store/stores/order/status";

export default {
  name: "OrderStatusInfo",
  computed: {
    ...mapState({
      order_status: state => state.orderItem.order_status,
      order_id: state => state.orderItem.id,
      failed: state => state.orderItem.failed,
      order_status_history: state => state.orderStatus.order_status_history
    }),
    ...mapGetters({
      statuses: "orderStatus/getStatusesForChange"
    })
  },
  methods: {
    getOrderHistoryPartClass(status) {
      if (this.failed === 1 && status.label === "Paid") {
        return "is-passed";
      }

      let filteredStatus = this.order_status_history.filter(historyItem => {
        return parseInt(historyItem.rel_status.id) === parseInt(status.value);
      });

      if (
        filteredStatus.length > 0 &&
        (filteredStatus[0].completed === 1 || filteredStatus[0].active === 1)
      ) {
        return "is-passed";
      }

      return "";
    },
    getOrderStatusDateFromHistory(status) {
      let filteredStatus = this.order_status_history.filter(historyItem => {
        return (
          parseInt(historyItem["rel_status"].id) === parseInt(status.value)
        );
      });

      if (
        filteredStatus.length > 0 &&
        (filteredStatus[0]["completed"] === 1 || filteredStatus[0].active === 1)
      ) {
        return this.$moment(filteredStatus[0].created_at).format("DD.MM.YYYY");
      }
      return "";
    },
    getStatusTitle(status) {
      if (this.failed && status.value === PAID) {
        return "Failed";
      }
      return status.label;
    },
    getOrderStatusTimeFromHistory(status) {
      let filteredStatus = this.order_status_history.filter(historyItem => {
        return (
          parseInt(historyItem["rel_status"].id) === parseInt(status.value)
        );
      });

      if (
        filteredStatus.length > 0 &&
        (filteredStatus[0]["completed"] === 1 || filteredStatus[0].active === 1)
      ) {
        return this.$moment(filteredStatus[0].created_at).format("HH:mm");
      }
      return "";
    }
  }
};
</script>

<style scoped>
  /* .saved-order {
    background-color: #C2E900;
    padding: 4px 8px;
    border-radius: 12px;
  } */
</style>