<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Creating page</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Page info</h5>
          </div>

          <div class="main__content">
            <div class="">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="`1-${language.title}`"
                  :class="isActive(language) ? 'active' : ''"
                >
                  <a @click="switchActiveLanguage(language)">
                    {{ language.title }}
                  </a>
                </li>
              </ul>

              <div class="main__content tab-pane">
                <div class="main__content-in">
                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="`2-${language.title}`"
                    class="language-tabs"
                  >
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Title:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.page[language.formNamePrefix + 'title']
                            "
                            :name="language.formNamePrefix + 'title'"
                            title="Title"
                            type="text"
                          />
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>Page content:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div class="inp-group">
                          <quill-editor
                            v-model="
                              $data.page[language.formNamePrefix + 'content']
                            "
                            :options="quillOptions"
                          />
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->
                  </div>

                  <!--                  <div class="main__content-item">-->
                  <!--                    <div class="main__content-item_l">-->
                  <!--                      <span>Slug:</span>-->
                  <!--                    </div> &lt;!&ndash; .main__content-item_l &ndash;&gt;-->

                  <!--                    <div class="main__content-item_r">-->
                  <!--                      <div class="inp-group inp-group__xl">-->
                  <!--                        <input type="text" v-model="page.slug">-->
                  <!--                      </div> &lt;!&ndash; .inp-group &ndash;&gt;-->
                  <!--                    </div> &lt;!&ndash; .main__content-item_r &ndash;&gt;-->
                  <!--                  </div> &lt;!&ndash; .main__content-item &ndash;&gt;-->

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Category:</span>
                    </div>
                    <!-- .main__content-item_l -->

                    <div class="main__content-item_r">
                      <div
                        v-if="cats"
                        class="inp-group__dropdown inp-group__xl"
                      >
                        <v-select
                          v-model="currentPageCategory"
                          :options="cats"
                          label="title"
                          placeholder="Select Page"
                        />
                      </div>
                    </div>
                    <!-- .main__content-item_r -->
                  </div>
                  <!-- .main__content-item -->

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Activity:</span>
                    </div>
                    <!-- .main__content-item_l -->

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-1"
                          v-model="page.is_active"
                          type="checkbox"
                        />
                        <label for="checkbox-1"></label>
                      </div>
                      <!-- .inp-group -->
                    </div>
                    <!-- .main__content-item_r -->
                  </div>
                  <!-- .main__content-item -->

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Open in new tab:</span>
                    </div>
                    <!-- .main__content-item_l -->

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-2"
                          v-model="page.new_tab"
                          type="checkbox"
                        />
                        <label for="checkbox-2"></label>
                      </div>
                    </div>
                  </div>

                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="`3-${language.title}`"
                    class="language-tabs"
                  >
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>External link:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.page[`${language.formNamePrefix}outer_link`]
                            "
                            title="Outer link"
                            type="url"
                          />
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>Upload file:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <dropzone
                          :id="`${language.formNamePrefix}file`"
                          :options="dropzoneSettings"
                          dict-default-message="Drag&drop or <u>click</u> here to upload image (jpg 1920x1080 up to 15MB)"
                          @vdropzone-processing="showLoader()"
                          @vdropzone-removed-file="
                            removeFileLink(language.formNamePrefix)
                          "
                          @vdropzone-success="
                            fileUploaded($event, language.formNamePrefix)
                          "
                        />
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->
                  </div>
                </div>
                <!-- .main__content-in -->
              </div>
              <!-- .main__content -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save()">
              <span><i>Save page</i></span>
            </button>
            <button class="btn btn-big btn-fw" @click="deletePage()">
              <span><i>Delete</i></span>
            </button>
          </div>
          <!-- .main__content-btns -->
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import vSelect from "vue-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "./forms/inputs/Dropzone";

export default {
  name: "CRMPageShow",
  components: {
    CRMLayout,
    vSelect,
    Dropzone
  },
  data() {
    return {
      cats: [],
      quillOptions: {
        modules: {
          toolbar: [["bold", "italic"], ["link"], [{ align: [] }]]
          // 'nbsp-changer': true
        },
        placeholder: "Some text...",
        theme: "snow"
      },
      currentPageCategory: {
        id: -1,
        title: "Not selected"
      },
      page: {
        is_active: false,
        title: null,
        es_title: null,
        de_title: null,
        fr_title: null,
        ru_title: null,
        content: null,
        es_content: null,
        de_content: null,
        fr_content: null,
        ru_content: null,
        category_id: 0,
        new_tab: false,
        outer_link: null,
        es_outer_link: null,
        de_outer_link: null,
        fr_outer_link: null,
        ru_outer_link: null
      },
      editor: ClassicEditor,
      languages: [
        {
          id: "en",
          title: "EN",
          formNamePrefix: ""
        },
        {
          id: "es",
          title: "ES",
          formNamePrefix: "es_"
        },
        {
          id: "de",
          title: "DE",
          formNamePrefix: "de_"
        },
        {
          id: "fr",
          title: "FR",
          formNamePrefix: "fr_"
        },
        {
          id: "ru",
          title: "RU",
          formNamePrefix: "ru_"
        }
      ],
      activeLanguage: {
        id: "en",
        title: "EN",
        formNamePrefix: ""
      }
    };
  },
  methods: {
    isActive(language) {
      return language.id === this.activeLanguage.id;
    },
    switchActiveLanguage(language) {
      this.activeLanguage = language;
    },
    getPage() {
      let ctx = this;
      this.$store
        .dispatch("get_page", this.$route.params.id)
        .then(response => {
          for (let key of Object.keys(response.data.data)) {
            ctx.page[key] = response.data.data[key];
            if (key === "category_id") {
              if (response.data.data[key] !== 0) {
                let currentCategory = ctx.cats.filter(cat => {
                  return cat.id === response.data.data[key];
                })[0];

                ctx.currentPageCategory = {
                  id: currentCategory.id,
                  title: currentCategory.title
                };
              }
            }
          }
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    save() {
      let ctx = this;
      ctx.page.category_id = ctx.currentPageCategory.id;
      this.$store
        .dispatch("update_pages_request", { data: ctx.page, id: ctx.page.id })
        .then(() => {
          ctx.$root.$emit("modalShow", {
            text: "Page successfully updated",
            type: "info"
          });
          ctx.$router.push(`/pages`);
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    deletePage() {
      let ctx = this;
      ctx.$root.$emit("modalShow", {
        type: "confirm",
        text: "are you sure?",
        options: {
          confirmType: "delete_page_request"
        }
      });
    },
    getCat() {
      let ctx = this;
      this.$store
        .dispatch("get_page_categories_request")
        .then(response => {
          ctx.cats = response.data.map(category => {
            return {
              id: category.id,
              title: category.title
            };
          });
          this.getPage();
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    fileUploaded(event, prefix) {
      let response = JSON.parse(event.xhr.response);
      if (response.data.path) {
        this.$data.page[`${prefix}outer_link`] = `${response.data.path}`;
      }
      this.$store.state.status = "success";
    },
    removeFileLink(prefix) {
      this.$data.page[`${prefix}outer_link`] = null;
    },
    showLoader() {
      this.$store.state.status = "loading";
    }
  },
  mounted() {
    let ctx = this;
    this.$root.$on("confirmed", data => {
      if (data.options.confirmType === "delete_page_request") {
        ctx.$store
          .dispatch("delete_page_request", ctx.page.id)
          .then(() => {
            ctx.$router.push({ name: "CRMPageList" });
            ctx.$root.$emit("modalShow", {
              type: "info",
              text: "Page deleted"
            });
          })
          .catch(error => {
            ctx.$root.$emit("modalShow", { type: "error", text: error });
          });
      }
    });

    this.getCat();
  },
  computed: {
    dropzoneSettings() {
      return {
        url: `upload-page-file`,
        uploadMultiple: false,
        paramName: "file",
        autoProcessQueue: true,
        addRemoveLinks: true,
        preventDuplicates: true
      };
    }
  }
};
</script>

<style scoped>
.language-tabs {
  margin-bottom: 15px;
}

.ck-editor__editable_inline {
  min-height: 400px;
}
</style>
