<template>
  <div class="main__content-item main__content-item_center">
    <div class="main__content-item_l">
      <span>{{ title }}:</span>
    </div>

    <div class="main__content-item_r">
      <div class="inp-group inp-group__checkbox">
        <!--suppress HtmlFormInputWithoutLabel -->
        <input
          :id="id"
          :checked="value"
          type="checkbox"
          @change="handleInput"
        />
        <label :for="id"></label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FInputCheckBox",
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      id: null
    };
  },
  created() {
    this.id = this.makeId(6);
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.checked);
    },
    makeId(length) {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }
  }
};
</script>
