<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <a
        class="btn btn-white btn-reset add-models-btn"
        @click="exportAllBikes"
      >
        Export
      </a>
    </template>
    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Users bikes</h5>
          </div>
          <div class="scroll mb-45">
            <table class="table__typical table__orders list-user-bikes">
              <thead>
                <tr>
                  <th class="pointer id">
                    <span>ID</span>
                  </th>
                  <th>
                    <span>Owner</span>
                  </th>
                  <th>
                    <span>Bike name</span>
                  </th>
                  <th>
                    <span>Model</span>
                  </th>
                  <th>
                    <span>Build</span>
                  </th>
                  <th>
                    <span>Size</span>
                  </th>
                  <th>
                    <span>Colour</span>
                  </th>
                  <th>
                    <span>Order</span>
                  </th>
                  <th>
                    <span>Ordered</span>
                  </th>
                  <th>
                    <span>Delivered</span>
                  </th>
                  <th>
                    <span>Activated</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="bike in bikes" :key="bike.id">
                  <td>
                    <router-link :to="bikeLink(bike)" tag="a">
                      {{ bike.id }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="customerLink(bike['owner_id'])" tag="a">
                      {{ bike.owner }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="bikeLink(bike)" tag="a">
                      {{ bike.title }}
                    </router-link>
                  </td>
                  <td>
                    <span>{{ bike["model_title"] }}</span>
                  </td>
                  <td>
                    <span>{{ bike["build_title"] }}</span>
                  </td>
                  <td>
                    <span>{{ bike["size"] }}</span>
                  </td>
                  <td>
                    <span>{{ bike["color"] }}</span>
                  </td>
                  <td>
                    <router-link :to="orderLink(bike['order_id'])" tag="a">
                      {{ bike["order_id"] }}
                    </router-link>
                  </td>
                  <td>
                    <span>{{ formatDate(bike["ordered_at"]) }}</span>
                  </td>
                  <td>
                    <span>{{ formatDate(bike["delivered_at"]) }}</span>
                  </td>
                  <td>
                    {{ bike["activated"] ? "Yes" : "No" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="wrapTotal">
            <paginator
              :current-page="page"
              :last-page="lastPage"
              @changepage="changePage"
            />
            <div class="totalText">Total: {{ total }}</div>
          </div>
        </div>
      </div>
      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>
        <div class="main__r-in">
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By username or email</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group">
                <input v-model="filters.owner" type="text" />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline"><h5>Bike name</h5></div>
            <div class="inp-group__in">
              <div class="inp-group">
                <input v-model="filters.title" type="text" />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Serial #</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group">
                <input v-model="filters.serial" type="text" />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Build id</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group">
                <input v-model="filters.buildId" type="text" />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Is testing</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="filters.testing"
                  :options="testingList"
                  label="title"
                />
              </div>
            </div>
          </div>
          <div v-if="models.length > 0" class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Model</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="filters.model"
                  :options="models"
                  label="title"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Build</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="filters.build"
                  :options="titles"
                  label="title"
                />
              </div>
            </div>
          </div>
          <div v-if="sizes.length > 0" class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Size</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="filters.size"
                  :options="sizes"
                  label="title"
                />
              </div>
            </div>
          </div>
          <div v-if="colors.length > 0" class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Colour</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="filters.color"
                  :options="colors"
                  label="title"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Order</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group">
                <input v-model="filters.order" type="text" />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Activated</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="filters.status"
                  :options="statusList"
                  label="title"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Ordered</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="filters.ordered"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Delivered</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="filters.delivered"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>
          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-fw btn-bold btn-sm"
              @click.prevent="resetFilter"
            >
              Reset filter
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "@/components/CRMLayout";
import Paginator from "./partials/Paginator";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import _ from "lodash";
import axios from "axios";
import { API_ENDPOINT } from "../utils/api";

let defaultFilters = {
  owner: null,
  title: null,
  model: null,
  build: null,
  size: null,
  color: null,
  order: null,
  status: null,
  ordered: null,
  delivered: null,
  serial: null,
  testing: null,
  buildId: null
};

export default {
  name: "UserBikesList",
  components: { CRMLayout, Paginator, vSelect, DatePicker },
  computed: {
    ...mapGetters({
      bikes: "bikes/list",
      models: "models/shortList",
      titles: "build/shortTitleList",
      sizes: "sizes/shortList",
      colors: "colors/shortList"
    })
  },
  data() {
    return {
      page: 1,
      lastPage: 1,
      total: 0,
      filters: { ...defaultFilters },
      statusList: [
        { id: "active", title: "Activated" },
        { id: "non-active", title: "Non Activated" }
      ],
      testingList: [
        { id: 1, title: "Yes" },
        { id: 0, title: "No" }
      ],
      reseting: false
    };
  },
  created() {
    if (this.$route.query.order_id) {
      this.filters.order = this.$route.query.order_id;
    }
    if (this.$route.query.build_id) {
      this.filters.buildId = this.$route.query.build_id;
    }
    if (this.$route.query.is_testing) {
      this.filters.testing = _.find(this.testingList, {
        id: Number(this.$route.query.is_testing)
      });
    }
    this.loadBikes();
  },
  methods: {
    resetFilter() {
      this.reseting = true;
      for (let key of Object.keys(defaultFilters)) {
        this.filters[key] = defaultFilters[key];
      }
      this.loadBikes();
    },
    formatDate(timestamp) {
      if (timestamp) {
        return this.$moment.unix(timestamp).format("DD.MM.YYYY");
      }
      return null;
    },
    loadBikes() {
      this.$store
        .dispatch("bikes/loadList", { page: this.page, filters: this.filters })
        .then(result => {
          this.lastPage = result.meta["last_page"];
          this.total = result.meta["total"];
          this.loadModels();
          this.loadBuildTitles();
          this.loadSizes();
          this.loadColors();
          this.reseting = false;
        });
    },
    loadModels() {
      this.$store.dispatch("models/short");
    },
    loadBuildTitles() {
      this.$store.dispatch("build/short");
    },
    loadSizes() {
      this.$store.dispatch("sizes/short");
    },
    loadColors() {
      this.$store.dispatch("colors/short");
    },
    changePage(page) {
      this.page = page;
      this.loadBikes();
    },
    orderLink(orderID) {
      return `/orders/${orderID}`;
    },
    customerLink(ownerID) {
      return `/customers/${ownerID}`;
    },
    bikeLink(bike) {
      return `/bikes/${bike.id}`;
    },
    createAt(bike) {
      if (bike.created_at) {
        return this.$moment.unix(bike.created_at).fromNow();
      }
      return null;
    },
    exportAllBikes() {
      this.$dialog
        .confirm(`The report will be sent to ${this.$store.getters.user.email}.`)
        .then(() => {
          axios.post(API_ENDPOINT + 'crm/user-bikes/export')
            .then(() => {
              this.$root.$emit("modalShow", {
                text: "As soon as the report is ready, you will receive it by email",
                type: "info"
              });
            })
            .catch(err => {
              this.$root.$emit("modalShow", { text: err, type: "error" });
            })
        })
        .catch(() => {});
    }
  },
  watch: {
    "filters.serial": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.buildId": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.testing": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.owner": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.title": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.model": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.build": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.size": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.color": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.order": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.status": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.ordered": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    },
    "filters.delivered": function() {
      if (!this.reseting) {
        this.loadBikes();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.table__orders.list-user-bikes {
  td,
  th {
    padding-right: 15px;
    width: auto;

    a,
    span {
      white-space: normal;
      display: block;
      width: 100%;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  td:first-child,
  th:first-child {
    width: 55px;

    a {
      text-decoration: none;
    }
  }

  td:nth-child(3),
  th:nth-child(3) {
    min-width: 95px;
    width: 10%;
  }

  th:nth-child(8) {
    a {
      &:after {
        content: " " !important;
        display: none !important;
        background: none;
      }
    }
  }

  @media screen and (min-width: 1420px) {
    td:nth-child(2),
    th:nth-child(2) {
      width: 155px;
    }

    td:nth-child(3),
    th:nth-child(3) {
      min-width: 95px;
      width: 10%;
    }

    td:nth-child(7),
    th:nth-child(7) {
      text-align: left;
      width: 135px;
    }
    td:nth-child(8),
    th:nth-child(8) {
      width: 60px;
    }

    //td:last-child,
    //th:last-child {
    //  width: calc(100% - 50px);
    //}
  }
  @media screen and (max-width: 1419px) {
    td:nth-child(2),
    th:nth-child(2) {
      width: 130px;
    }
  }
}

.table__orders td:nth-child(8) a,
.table__orders th:nth-child(8) a {
  padding-left: 0;
  &:after {
    background-image: none;
    display: none;
  }
}
.inp-group__wrapper {
  &:nth-last-child(2) {
    margin-bottom: 100px;
  }
}
</style>
