class NBSPChanger {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;
    let siblings = this.getSiblings(quill.editor.scroll.domNode.parentNode);
    let btn;
    let customToolbar;
    siblings.forEach(el => {
      if (el.className && el.className.indexOf("custom-toolbar") !== -1) {
        customToolbar = el;
      }
    });
    if (customToolbar) {
      btn = customToolbar.querySelector(".ql-nbsp-changer");
      if (btn) {
        btn.addEventListener("click", this.update.bind(this));
      }
    }
  }

  getSiblings = elem => {
    let siblings = [];
    let sibling = elem.parentNode.firstChild;

    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling;
    }

    return siblings;
  };

  update() {
    let range = this.quill.selection.getRange();
    if (range) {
      range = range[0];
      let index = range.index;
      let length = range.length;
      if (length > 0) {
        let text = this.quill.getText(index, length);
        let newText = text.replace(/ +/g, "\u00a0");
        let allText = this.quill.getText();

        this.quill.setText(allText.replace(text, newText));
      }
    }
  }
}

export default NBSPChanger;
