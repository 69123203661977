<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Creating vacancy</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Vacancy</h5>
          </div>
        </div>

        <div>
          <ul class="nav nav-tabs list-unstyled">
            <li
              v-for="language in languages"
              :key="language.formNamePostfix"
              :class="isActive(language) ? 'active' : ''"
            >
              <a @click="switchActiveLanguage(language)">
                {{ language.title }}
              </a>
            </li>
          </ul>

          <div class="main__content tab-pane">
            <div class="main__content-in">
              <div
                v-for="language in languages"
                v-show="activeLanguage.id === language.id"
                :key="language.formNamePostfix"
              >
                <div class="main__content-item main__content-item_center">
                  <div class="main__content-item_l">
                    <span>Title:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__xl">
                      <input
                        v-model="
                          $data.vacancy[`title${language.formNamePostfix}`]
                        "
                        :name="`title${language.formNamePostfix}`"
                        title="Form Name"
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="main__content-item main__content-margin_bottom">
                  <div class="main__content-item_l">
                    <span>Description:</span>
                  </div>

                  <div class="main__content-item_r">
                    <div class="inp-group inp-group__textarea">
                      <ckeditor
                        v-model="
                          $data.vacancy[
                            `description${language.formNamePostfix}`
                          ]
                        "
                        :editor="editor"
                      >
                      </ckeditor>
                    </div>
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-margin_bottom">
                <div class="main__content-item_l">
                  <span>Description(preview):</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group">
                    <ckeditor
                      v-model="vacancy.short_description"
                      :editor="editor"
                    >
                    </ckeditor>
                  </div>
                </div>
              </div>

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Department:</span>
                </div>

                <div class="main__content-item_r">
                  <div class="inp-group__dropdown inp-group__xl">
                    <v-select
                      v-model="currentDepartment"
                      :options="departments"
                      label="label"
                      placeholder="Select department"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__content-btns">
          <button class="btn btn-big btn-fw" @click="save">
            <span><i>Save vacancy</i></span>
          </button>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import vSelect from "vue-select";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "CRMVacancyDepartmentCreate",
  components: { CRMLayout, vSelect, ckeditor: CKEditor.component },
  data: () => ({
    editor: ClassicEditor,
    languages: [
      {
        id: "en",
        title: "EN",
        formNamePostfix: ""
      },
      {
        id: "es",
        title: "ES",
        formNamePostfix: "_es"
      },
      {
        id: "de",
        title: "DE",
        formNamePostfix: "_de"
      },
      {
        id: "fr",
        title: "FR",
        formNamePostfix: "_fr"
      },
      {
        id: "ru",
        title: "RU",
        formNamePostfix: "_ru"
      }
    ],
    activeLanguage: {
      id: "en",
      title: "EN",
      formNamePostfix: ""
    },
    vacancy: {
      title: null,
      title_es: null,
      title_fr: null,
      title_de: null,
      title_ru: null,
      description: null,
      description_es: null,
      description_fr: null,
      description_de: null,
      description_ru: null,
      short_description: null
    },
    departments: [],
    currentDepartment: null
  }),
  mounted() {
    this.loadDepartments();
  },
  methods: {
    loadDepartments() {
      let ctx = this;
      this.$store
        .dispatch("get_vacancy_department_request")
        .then(response => {
          this.departments = response.data.map(item => {
            return {
              code: item.id,
              label: item.title
            };
          });

          if (this.departments.length > 0) {
            this.currentDepartment = this.departments[0];
          }
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    isActive(language) {
      return language.id === this.activeLanguage.id;
    },
    switchActiveLanguage(language) {
      this.activeLanguage = language;
    },
    save() {
      let ctx = this;
      if (this.currentDepartment) {
        this.vacancy.department_id = this.currentDepartment.code;
      } else {
        this.vacancy.department_id = null;
      }
      this.$store
        .dispatch("store_vacancy_request", this.vacancy)
        .then(response => {
          ctx.$router.push(`/vacancies/${response.data.id}`);
          ctx.$root.$emit("modalShow", {
            text: "Vacancy successfully added",
            type: "info"
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    }
  }
};
</script>

<style scoped>
.main__content-margin_bottom {
  margin-bottom: 15px !important;
}

.ck-editor__editable_inline {
  min-height: 400px;
}
</style>
