// initial state
import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "../../helpers/parseError";

export const ORDERED = 1;
export const PAID = 2;
export const PRODUCTION = 3;
export const PRODUCED = 4;
export const SHIPPED = 5;
export const DELIVERED = 6;
export const CANCELLATION_REQUESTED = 7;
export const CANCELLED = 8;
export const PARTIAL_PAID = 9;

const state = {
  current_status_id: null,
  order_status_history: [],
  statuses: [],
  statuses_for_change: []
};

// getters
const getters = {
  getStatusLabelById: state => status_id => {
    for (let index in state.statuses) {
      // eslint-disable-next-line
      if (!state.statuses.hasOwnProperty(index)) {
        continue;
      }

      if (state.statuses[index].value === status_id) {
        return state.statuses[index].label;
      }
    }

    return null;
  },
  getCurrentStatusForSelect: (state, getters) => {
    let status = state.current_status_id;
    return {
      label: status ? getters["getStatusLabelById"](status) : null,
      code: status
    };
  },
  wasCancelled: state => {
    let cancels = [CANCELLATION_REQUESTED, CANCELLED];
    let filtered = state.order_status_history.filter(
      item =>
        // eslint-disable-next-line
        item.hasOwnProperty("rel_status") &&
        cancels.includes(item.rel_status.id)
    );

    return filtered.length > 0;
  },
  getStatusesForChange: (state, rootState, getters, rootGetters) => {
    let wasSuccessfulPayment = rootGetters["orderPrice/wasSuccessfulPayment"];
    let orderIsFullPaid = rootGetters["orderPrice/orderIsFullPaid"];
    let paid = wasSuccessfulPayment && !orderIsFullPaid ? PARTIAL_PAID : PAID;

    let availableStatuses = rootGetters["orderStatus/wasCancelled"]
      ? [ORDERED, paid, CANCELLATION_REQUESTED, CANCELLED]
      : [ORDERED, paid, PRODUCTION, PRODUCED, SHIPPED, DELIVERED];

    return state.statuses
      .filter(item => availableStatuses.includes(item.value))
      .sort((a, b) => {
        let aValue = a.value === 9 ? 2 : a.value;
        let bValue = b.value === 9 ? 2 : b.value;

        return aValue - bValue;
      });
  },
  getActiveHistoryStatuses: state => {
    return state.order_status_history.filter(item => {
      return item["completed"] === 1 || item.active === 1;
    });
  },
  getPreviousStatus: state => status => {
    let previousStatus = null;
    for (let history of state.order_status_history) {
      if (history.id === status.id) {
        return previousStatus ? previousStatus["status"] : null;
      }
      previousStatus = status;
    }
    return null;
  }
};

// actions
const actions = {
  parseFromOrderResponse({ commit }, response) {
    commit("set_current_status_id", response.data.status_id);
    commit("set_order_status_history", response.data.order_status_history);
  },
  loadStatuses({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}orderStatuses`)
        .then(response => {
          let statuses = response.data.map(status => {
            return {
              label: status.status[0].toUpperCase() + status.status.substr(1),
              value: status.id
            };
          });

          commit("set_statuses", statuses);

          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  changeLocalStatus({ commit }, value) {
    commit("set_current_status_id", value);
  },
  changeStatus({ commit, dispatch, rootState }, value) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}orders/${rootState.orderItem.id}`;
      axios
        .put(url, { status_id: value })
        .then(response => {
          commit("set_current_status_id", value);
          dispatch("loadOrderStatusHistory");
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadOrderStatusHistory({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}orders/${rootState.orderItem.id}/status-history`;
      axios
        .get(url)
        .then(response => {
          commit("set_order_status_history", response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  }
};

// mutations
const mutations = {
  set_current_status_id(state, status_id) {
    state.current_status_id = status_id;
  },
  set_order_status_history(state, history) {
    state.order_status_history = history;
  },
  set_statuses(state, statuses) {
    state.statuses = statuses;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
