const state = {
  list: []
};

const getters = {
  list(state) {
    return state.list;
  }
};
const actions = {
  get() {}
};
const mutations = {
  setList(state, brands) {
    state.list = brands;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
