var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRMLayout',{scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_c('span',[_vm._v("Update Settings")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"main__c main__c-auto"},[_c('div',{staticClass:"main__c-in"},[_c('div',{staticClass:"main__headline"},[_c('h5',[_vm._v("Settings")])]),_c('div',{staticClass:"main__content"},[_c('div',{},[_c('ul',{staticClass:"nav nav-tabs list-unstyled"},_vm._l((_vm.languages),function(language){return _c('li',{key:language.prefix,class:_vm.isActive(language) ? 'active' : ''},[_c('a',{on:{"click":function($event){return _vm.switchActiveLanguage(language)}}},[_vm._v(" "+_vm._s(language.title)+" ")])])}),0),_c('div',{staticClass:"main__content"},[_c('div',{staticClass:"main__content-in"},_vm._l((_vm.languages),function(language){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeLanguage.id === language.id),expression:"activeLanguage.id === language.id"}],key:language.prefix,staticClass:"language-tabs"},[_c('div',{staticClass:"main__content-item main__content-item_center"},[_c('div',{staticClass:"main__content-item_l"},[_c('span',[_vm._v("Footer:")])]),_c('div',{staticClass:"main__content-item_r"},[_c('div',{staticClass:"inp-group inp-group__textarea inp-group__xl"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.$data.settings[language.formNamePrefix + 'footer']
                          ),expression:"\n                            $data.settings[language.formNamePrefix + 'footer']\n                          "}],attrs:{"name":language.formNamePrefix + 'footer',"title":"Footer","type":"text"},domProps:{"value":(
                            _vm.$data.settings[language.formNamePrefix + 'footer']
                          )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$data.settings, language.formNamePrefix + 'footer', $event.target.value)}}})])])]),_c('div',{staticClass:"main__content-item main__content-item_center"},[_c('div',{staticClass:"main__content-item_l"},[_c('span',[_vm._v("Footer Product:")])]),_c('div',{staticClass:"main__content-item_r"},[_c('div',{staticClass:"inp-group inp-group__textarea inp-group__xl"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.$data.settings[
                              language.formNamePrefix + 'productFooter'
                            ]
                          ),expression:"\n                            $data.settings[\n                              language.formNamePrefix + 'productFooter'\n                            ]\n                          "}],attrs:{"name":language.formNamePrefix + 'productFooter',"title":"Footer","type":"text"},domProps:{"value":(
                            _vm.$data.settings[
                              language.formNamePrefix + 'productFooter'
                            ]
                          )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$data.settings, 
                              language.formNamePrefix + 'productFooter'
                            , $event.target.value)}}})])])])])}),0)])])]),_c('div',{staticClass:"main__content-btns"},[_c('button',{staticClass:"btn btn-big btn-fw",on:{"click":function($event){return _vm.save()}}},[_c('span',[_c('i',[_vm._v("Save page")])])])])])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }