<template>
  <div :data-id="album.id" class="main__content album nameFile">
    <!-- <div class="main__headline mb-20 mt-0">
      <h5>
        Media Library \ album {{ index + 1 }}
      </h5>
    </div> -->
    <div class="main__content tab-pane">
      <div class="main__content-in pl-0 pr-0 pb-0">
        <div class="sticky-wrap w-100 mb-20">
          <ul class="nav nav-tabs list-unstyled w-100 ml-0 mr-0">
            <li
                v-for="(language, index) in languages"
                :key="index"
                :class="{ active: isActiveLang(language) }"
            >
              <a @click="changeLang(language)">{{ language }}</a>
            </li>
          </ul>
        </div>

        <div v-if="index === 0" class="main__content-item flex-wrap">
          <div class="main__content-item_top">
            <h5>Category title:</h5>
          </div>
          <div class="main__content-item_bottom mb-20">
            <div
                :class="{ 'inp-error': errors.title }"
                class="inp-group inp-group__textarea h-xl"
            >
              <input ref="category_title" v-model.trim="title" type="text"/>
              <p v-if="errors.title" class="inp-error__desc">
                {{ errors.title }}
              </p>
            </div>
          </div>
        </div>
        <div class="main__content-item flex-wrap mb-20">
          <div class="main__content-item_top">
            <h5>Album title:</h5>
          </div>
          <div class="main__content-item_bottom mb-20">
            <div
                :class="{ 'inp-error': errors.albumTitle }"
                class="inp-group inp-group__textarea h-xl"
            >
              <input
                  ref="album_title"
                  v-model.trim="albumTitle"
                  type="text"
              />
              <p v-if="errors.albumTitle" class="inp-error__desc">
                {{ errors.albumTitle }}
              </p>
            </div>
          </div>
          <div class="main__content-item flex-wrap" style="width: 100%">
            <div class="main__content-item_top">
              <h5>Page content:</h5>
            </div>
            <!-- .main__content-item_l -->

            <div class="main__content-item_bottom">
              <quill-editor
                  v-model="albumContent"
                  :options="quillOptions"
              />
            </div>
          </div>
        </div>
        <div class="main__content-item flex-wrap mb-20">
          <div class="main__content-item_top">
            <h5>Category Parent:</h5>
          </div>
          <div class="main__content-item_bottom">
            <div
                :class="{ 'inp-error': errors.act_parent }"
                class="inp-group inp-group__textarea h-xl"
            >
              <treeselect
                  v-model="album.act_parent"

                  :show-count="true"
                  :options="treeMedia"/>
              <p v-if="errors.act_parent" class="inp-error__desc">
                {{ errors.act_parent }}
              </p>
            </div>
          </div>
        </div>
        <div class="main__content-item flex-wrap mb-20">
          <div class="main__content-item_top">
            <h5>Url HiRes Archive:</h5>
          </div>
          <div class="main__content-item_bottom">
            <div
                :class="{ 'inp-error': errors.url_hires_arch }"
                class="inp-group inp-group__textarea h-xl"
            >
              <input
                  ref="url_hires_arch"
                  v-model.trim="album.url_hires_arch"
                  type="text"
              />
              <p v-if="errors.url_hires_arch" class="inp-error__desc">
                {{ errors.url_hires_arch }}
              </p>
            </div>
          </div>
        </div>
        <div class="main__content-item flex-wrap mb-20">
          <div class="main__content-item_top">
            <h5>Cover:</h5>
          </div>
          <div class="main__content-item_bottom">
            <dropzone
                :id="`cover_image`"
                :default-image="cover"
                :options="getDropzoneCoverSettings()"
                @vdropzone-files-added="saveCoverFile"
                @vdropzone-removed-file="resetCoverFile"
            />
          </div>
        </div>

        <div class="main__content-item flex-wrap mb-0">
          <div class="main__content-item_top pr-0">
            <h5>Content:</h5>
            <div class="checkbox-wrap">
                <f-input-checkbox
                    v-model="fileSortable"
                    title="Sortable"
                />
                <f-input-checkbox
                    v-model="checkboxFileTheme"
                    title="ThemeList"
                />
            </div>
          </div>
          <div class="main__content-item_bottom">
            <vue-file-agent
                :multiple="true"
                :deletable="true"
                :editable="true"
                :sortable="fileSortable"
                :theme="fileTheme"
                :helpText="'Drag&drop or click here to upload file (jpg, mp4, pdf up to 150MB)'"
                v-model="album.files"
                :uploadUrl="uploadMediaUrl"
                @upload:update="onRename($event)"
                @rename="onRename($event)"
            ></vue-file-agent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Dropzone from "../forms/inputs/Dropzone";
import { STORAGE_ENDPOINT } from "@/utils/api";
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import VueFileAgentPlugin from "vue-file-agent";
import { API_ENDPOINT } from "../../utils/api";
import CheckBox from "@/components/inputs/CheckBox";

export default {
  name: "MediaItemComponent",
  components: {
    Dropzone,
    Treeselect,
    "vue-file-agent": VueFileAgentPlugin.VueFileAgent,
    "f-input-checkbox": CheckBox,
  },
  props: {
    album: {
      required: true
    },
    isCreatePage: {
      required: false,
      default: false,
      type: Boolean
    },
    index: {
      type: Number,
      required: false
    }
  },
  computed: {
    ...mapState({
      languages: store => store.languages.list
    }),
    checkboxFileTheme: {
      set(val){
        if (val) {
          this.fileTheme = "list";
        } else {
          this.fileTheme = "default";
        }
      },
      get(){
        return this.fileTheme === "list";
      }
    },
    title: {
      get() {
        let languages = this.$store.state.mediaItem.languages;
        // eslint-disable-next-line
        if (languages.hasOwnProperty(this.activeLang)) {
          return languages[this.activeLang].title;
        }

        return null;
      },
      set(value) {
        this.$store.dispatch("mediaItem/set_language", {
          lang: this.activeLang,
          key: "title",
          value
        });
      }
    },
    albumTitle: {
      get() {
        // eslint-disable-next-line
        if (this.album.languages.hasOwnProperty(this.activeLang)) {
          return this.album.languages[this.activeLang].title;
        }
        return null;
      },
      set(value) {
        // eslint-disable-next-line
        if (this.album.languages.hasOwnProperty(this.activeLang)) {
          this.album.languages[this.activeLang].title = value;
        } else {
          this.album.languages = {
            ...this.album.languages,
            [this.activeLang]: { title: value }
          };
        }
      }
    },
    albumContent: {
      get() {
        // eslint-disable-next-line
        if (this.album.languages.hasOwnProperty(this.activeLang)) {
          return this.album.languages[this.activeLang].content;
        }
        return null;
      },
      set(value) {
        // eslint-disable-next-line
        if (this.album.languages.hasOwnProperty(this.activeLang)) {
          this.album.languages[this.activeLang].content = value;
        } else {
          this.album.languages = {
            ...this.album.languages,
            [this.activeLang]: { content: value }
          };
        }
      },
    },
    uploadMediaUrl: {
      get() {
        return `${API_ENDPOINT}uploadMediaFile/` + (this.album.storage_path_id || (this.album.id && this.album.id > 0));
      }
    },
    files: {
      get() {
        return this.album.files.map(file => {
          let url = file.url;
          if (file && file.url) {
            url = url.replace(file.filename, "");
            url += "thumb_348x348" + file.filename;
            return {
              url: STORAGE_ENDPOINT + url,
              name: file.title,
              size: file.size,
              id: file.id,
              type: 'image/*'
            };
          }

          return {
            url: STORAGE_ENDPOINT + file.url,
            name: file.title,
            size: file.size,
            id: file.id,
            type: 'image/*'
          }
        });
      },
      set() {
        //console.log(val);
        //this.album.files = val;
      }
    },
    cover() {
      return this.album.cover_image
          ? STORAGE_ENDPOINT + this.album.cover_image
          : null;
    },
  },
  data() {
    return {
      activeLang: "en",
      errors: [],
      componentId: null,
      treeMedia: null,
      fileSortable: false,
      fileTheme: "default",
      quillOptions: {
        modules: {
          toolbar: [["bold", "italic"], ["link"], [{ align: [] }]]
          // 'nbsp-changer': true
        },
        placeholder: "Some text...",
        theme: "snow"
      },
    };
  },
  created() {
    this.$store
      .dispatch("mediaItem/get_tree_media")
      .then(response => (this.treeMedia = response))
    ;

  },
  mounted() {
    this.componentId = this._uid;
    this.$refs["album_title"].focus();
    this.initFocus();
  },
  methods: {
    onRename: function(fileRecord){
      console.log(fileRecord);
      return true;
    },
    filesSelected: function(fileRecordsNewlySelected) {
      console.log(fileRecordsNewlySelected);
      let validFileRecords = fileRecordsNewlySelected.filter(
          fileRecord => !fileRecord.error
      );
      console.log(fileRecordsNewlySelected);
      this.$refs.vueFileAgent.upload(this.uploadMediaUrl, {}, validFileRecords);
      /*
      this.album.files.push(
          {

          }
      );
      */
    },
    initFocus() {
      if (this.index === 0) {
        this.$refs["category_title"].focus();
      } else {
        this.$refs["album_title"].focus();
      }
    },
    getDropzoneSettings() {
      let id = this.album.storage_path_id || (this.album.id && this.album.id > 0);
      return {
        url: `uploadMediaFile/${id}`,
        uploadMultiple: true,
        paramName: "file",
        timeout: 1000000,
        autoProcessQueue: true,
        dictDefaultMessage:
          "Drag&drop or <u>click</u> here to upload files (jpg, png, video up to 150 MB)",
        addRemoveLinks: true,
        preventDuplicates: true,
        maxFilesize: null
      };
    },
    getDropzoneCoverSettings() {
      return {
        url: `rand`,
        uploadMultiple: false,
        paramName: "cover",
        dictDefaultMessage:
          "Drag&drop or <u>click</u> here to upload file (jpg, png up to 150 MB)",
        autoProcessQueue: false,
        addRemoveLinks: true
      };
    },
    changeLang(language) {
      this.activeLang = language;
    },
    isActiveLang(language) {
      return this.activeLang === language;
    },
    saveTempImages(files) {
      let self = this;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = function() {
          let file = {
            filename: files[i].name
          };
          self.album.files.push(file);
        };
      }
    },
    deleteTempImage(file) {
      this.album.files = this.album.files.filter(
        fileFromApi => fileFromApi.filename !== file.name
      );
    },
    resetCoverFile() {
      this.album.cover_image = null;
      this.album.cover_file = null;
    },
    saveCoverFile(file) {
      this.album.cover_file = file[0];
    },
  }
};
</script>

<style scoped lang="scss">
.quill-editor {
  width: 100%;
}
.ql-editor{
  min-height: 200px;
}
.ck-editor__editable_inline {
  min-height: 400px;
  width: 100%;
}
*{
  box-sizing: border-box;
}

.inp-group.h-xl input{
  border-color: #CECECE;
}

</style>
<style lang="scss">
.vm--modal .checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .main__content-item {
    margin: 0 48px 0 0;
    &:last-child{
      margin-right: 0;
    }
  }

  .main__content-item_l {
    width: auto;
    padding: 0 12px 0 0;
    span{
      color: #454545;
    }
  }

  .main__content-item_r{
    width: auto;
    padding: 0;
  }
}
</style>
