import Vue from "vue";
import Vuex from "vuex";
import mediaList from "./stores/media/index";
import mediaItem from "./stores/media/item";
import orderItem from "./stores/order/item";
import orderStatus from "./stores/order/status";
import orderAddress from "./stores/order/address";
import orderPrice from "./stores/order/price";
import orderList from "./stores/order/list";
import orderFeed from "./stores/order/feed";
import permissions from "./stores/user/permissions";
import defaultStore from "./stores/default";
import languages from "./stores/languages";
import countries from "./stores/country";
import build from "./stores/build";
import models from "./stores/models";
import sizes from "./stores/sizes";
import colors from "./stores/colors";
import brands from "./stores/brands";
import specs from "./stores/specs";
import bikes from "./stores/bikes";
import user from "./stores/user";
import documents from "./stores/documents";
import repairs from "./stores/repairs";
import serviceCenters from "./stores/service-centers";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    defaultStore,
    mediaList,
    mediaItem,
    orderItem,
    languages,
    permissions,
    countries,
    orderStatus,
    orderAddress,
    orderFeed,
    orderPrice,
    orderList,
    build,
    models,
    sizes,
    colors,
    brands,
    specs,
    bikes,
    user,
    documents,
    repairs,
    serviceCenters: serviceCenters
  }
});
