<template>
  <div class="order__item p-2">
    <div v-if="order_product.img" class="order__item-img shadow">
      <img :alt="order_product.name" :src="order_product.img" />
    </div>

    <div class="order__wrap align-self-end">
      <div class="move-box"></div>
      <div class="order__item-name">
        <!--        <router-link :to="`/builds/${order_product.build.id}`" tag="a">-->
        <!--          {{ productName(order_product) }}-->
        <!--        </router-link>-->
        {{ productName(order_product) }}
      </div>

      <div
        :class="
          user.is_b2b && mode_edit && editablePrice
            ? `order__item-frame-number inp-group d-flex no-wrap`
            : `order__item-price`
        "
      >
        <template v-if="user.is_b2b && can_product_edit">
          <span
            v-if="!editablePrice"
            class="editable-text"
            @click="editPrice"
            >{{ subTotal(false) }}</span
          >
          <template v-else>
            <input
              v-model.number="formatted_custom_price"
              min="0"
              title="Custom price"
              type="number"
              @input="changeCustomPrice"
            />
            <span class="btn-close" style="margin-left: 6px" @click="editPrice"
              >X</span
            >
          </template>
        </template>
        <span v-else>{{ subTotal(false) }}</span>
      </div>

      <div class="order__item-frame-number inp-group text-right">
        <input
          v-model.number="order_product.quantity"
          :disabled="!can_product_edit"
          class="ml-auto"
          min="1"
          type="number"
          @input="recalculate"
        />
      </div>

      <div v-if="user.is_b2b" class="order__item-frame-number">
        <div class="inp-group inp-group__checkbox">
          <input
            :id="`checkbox` + index"
            v-model="order_product.is_testing"
            :disabled="!can_product_edit"
            type="checkbox"
          />
          <label :for="`checkbox` + index"></label>
        </div>
      </div>

      <div class="order__item-price text-right">
        <span>{{ totalPrice }}</span>
      </div>
      <div
        v-if="can_product_edit"
        class="order__item-delete"
        @click="removeProduct(index)"
      ></div>

      <div class="order__text no-pointer d-flex">
        <p
          v-if="order_product.length && productDescription(order_product)"
          class="text w-100"
        >
          {{ productDescription(order_product) }}
        </p>

        <router-link v-if="buildID" :to="`/builds/${buildID}`" class="link">
          Build id {{ buildID }}
        </router-link>
        <template v-if="hasBike">
          <router-link
            v-if="countBikes === 1"
            :to="`/bikes/${bikes[0].id}`"
            class="link"
          >
            Bike id {{ bikes[0].id }}
          </router-link>
          <router-link
            v-else
            :to="
              `/bikes?order_id=${order_product.order_id}&is_testing=${Number(
                order_product.is_testing
              )}&build_id=${Number(order_product.build.id)}`
            "
            class="link"
          >
            Bikes count: {{ countBikes }}
          </router-link>
        </template>
        <div class="brakes-select-wrap">
          <p class="text" @click.prevent="showBrakeVariants">
            Brakes:
            <span class="selected-brakes-variant">
              {{ selectedBrakesVariant }}
            </span>
            <svg
              fill="none"
              height="6"
              viewBox="0 0 10 6"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.75 1.125L5 4.875L1.25 1.125"
                stroke="#323232"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </p>
          <div
            v-if="brakeVariantsVisibility"
            v-click-outside="hideBrakeVariants"
            class="brakes-variants"
          >
            <div
              :class="brakeVariantClass(true)"
              class="variant"
              @click.prevent="setBrakeType(true)"
            >
              Moto
            </div>
            <div
              :class="brakeVariantClass(false)"
              class="variant"
              @click.prevent="setBrakeType(false)"
            >
              Regular
            </div>
          </div>
        </div>

        <p class="text"><strong>Speed limit:</strong> <span>25 K/h</span></p>
      </div>
    </div>

    <div
      v-if="showModal"
      :id="`modal` + index"
      class="modal modal__confirm fade"
      role="dialog"
      style="display: none;"
    >
      <div class="modal-dialog modal-xs">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal__in">
              <div class="modal__txt">
                <div class="modal__txt-in">
                  <p>Merge?</p>
                </div>
                <div class="btn__wrapper flex">
                  <button
                    class="btn btn-gray btn-fw btn-bold btn-up btn-lg"
                    data-dismiss="modal"
                    @click="mergeBikes"
                  >
                    Ok
                  </button>
                  <button
                    class="btn btn-gray btn-fw btn-bold btn-up btn-lg"
                    data-dismiss="modal"
                    @click="closeConfirmation"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  name: "OrderProduct",
  directives: { ClickOutside },
  data() {
    return {
      showModal: false,
      editablePrice: false,
      brakeVariantsVisibility: false,
      brakesSelected: false,
      formatted_custom_price: 0
    };
  },
  props: {
    order_product: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    user: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      mode_edit: "orderItem/canEdit",
      can_product_edit: "orderItem/canProductEdit"
    }),
    buildID() {
      return this.order_product.build.id;
    },
    hasBike() {
      return (
        !!this.order_product.order &&
        !!this.order_product.order.bikes &&
        this.order_product.order.bikes.length > 0 &&
        this.order_product.order.bikes.find(item => {
          return (
            item.bike_id === this.order_product.build.id &&
            item.is_testing === this.order_product.is_testing
          );
        })
      );
    },
    bikes() {
      if (this.hasBike) {
        return this.order_product.order.bikes.filter(item => {
          return (
            item.bike_id === this.order_product.build.id &&
            item.is_testing === this.order_product.is_testing
          );
        });
      }

      return [];
    },
    countBikes() {
      return this.bikes.length;
    },
    totalPrice() {
      return this.$crConverter(
        (this.subTotal(true) * this.order_product.quantity) / 100
      );
    },
    selectedBrakesVariant() {
      if (this.brakesSelected) {
        return "Moto";
      }

      return "Regular";
    }
  },
  mounted() {
    this.brakesSelected = !!this.order_product.moto_style;
  },
  methods: {
    closeConfirmation() {
      this.showModal = !this.showModal;
      this.order_product.is_testing = !this.order_product.is_testing;
    },
    mergeBikes() {
      this.$store
        .dispatch("orderItem/mergeBikes", {
          order_product: this.order_product,
          index: this.index
        })
        .then(() => {
          //this.removeProduct(this.index);
        })
        .catch(err =>
          this.$root.$emit("modalShow", { text: err, type: "error" })
        )
        .finally(() => (this.showModal = !this.showModal));
    },
    editPrice() {
      if (this.editablePrice) {
        this.order_product.custom_price = null;
      } else {
        this.formatted_custom_price = this.subTotal(true) / 100;
        this.changeCustomPrice();
      }
      this.editablePrice = !this.editablePrice;
    },
    changeCustomPrice() {
      this.order_product.custom_price = this.formatted_custom_price * 100;
      this.recalculate();
    },
    showBrakeVariants() {
      this.brakeVariantsVisibility = true;
    },
    hideBrakeVariants() {
      this.brakeVariantsVisibility = false;
    },
    subTotal(clean = true) {
      if (clean) {
        return this.order_product.singlePrice;
      }
      return this.$crConverter(this.order_product.singlePrice / 100);
    },
    removeProduct(index) {
      this.$store.commit("orderItem/remove_product", index);
      this.recalculate();
    },
    recalculate() {
      this.$store.dispatch("orderPrice/calculate");
    },
    checkFrameNumberAvailability() {
      this.$store
        .dispatch("orderItem/checkFrameNumberAvailability", this.order_product)
        .catch(err =>
          this.$root.$emit("modalShow", { text: err, type: "error" })
        );
    },
    productDescription(product) {
      return product.build["short_description"];
    },
    isMotoStyle() {
      return this.brakesSelected;
    },
    brakeVariantClass(variant) {
      return {
        selected: this.isMotoStyle() === variant
      };
    },
    setBrakeType(variant) {
      this.brakesSelected = variant;
      this.hideBrakeVariants();
    },
    productName(product) {
      return product.build.ms_title;
    }
  },
  watch: {
    "order_product.is_testing": {
      handler: function() {
        this.$store
          .dispatch("orderItem/hasSameBike", this.order_product)
          .then(response => {
            if (response) {
              this.showModal = true;
              setTimeout(() => {
                window.$("#modal" + this.index).modal({
                  backdrop: "static",
                  keyboard: false,
                  show: true
                });
              }, 2);
            }
          })
          .catch(err =>
            this.$root.$emit("modalShow", { text: err, type: "error" })
          );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn__wrapper.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.btn__wrapper.flex .btn {
  width: 100px;
  min-width: 100px;
}

.modal.fade .modal-dialog {
  transform: translate(0, -1px) !important;
}

.order__item {
  display: flex;
}

.inp-group {
  padding-top: 39px;
  padding-bottom: 36px;
  padding-right: 10px;
}

.order__wrap .order__text .moto-style label span {
  margin-left: 12px;
}

.order__item-wrap {
  display: inline-flex;
}

.align-right {
  text-align: right;
}

.order__items .order__item-name {
  width: 40%;
  padding-left: 108px;
  margin-left: -108px;
  box-sizing: border-box;
  padding-right: 8px;
}

.inp-group input {
  max-width: 120px;
}

.no-pointer {
  cursor: default !important;
}

.text-right {
  text-align: right;
  padding-right: 16px;
  box-sizing: border-box;
}

.ml-auto {
  margin-left: auto;
}

.order__wrap .order__text {
  width: 100%;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.text {
  font-size: 10px;
  line-height: 12px;
  color: #454545;
}

.text.w-100 {
  margin-bottom: 12px;
}

.link {
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  margin-right: 32px;
  color: #6097ff;
}

.brakes-select {
  margin-right: 32px;
  padding: 0;
  border: none;
  box-shadow: none !important;
}

.vs__dropdown-menu {
  text-align: center;
}

.order__items.create {
  .order__wrap {
    padding: 0 48px 4px 0;

    .order__item-frame-number.inp-group {
      .ml-auto {
        margin-left: 0;
        margin-right: auto;
      }
    }

    .order__item-price {
      text-align: left;
    }

    .order__item-delete {
      position: absolute;
      right: 24px;
      top: 4px;
    }
  }
}

.order__items.not-b2b {
  .order__item-price,
  .order__item-counter,
  .order__item-frame-number,
  .order__items-headline span:nth-child(3),
  .order__items-headline span:nth-child(4),
  .order__items-headline span:nth-child(5),
  .order__items-headline span:nth-child(6),
  .order__items-headline span:nth-child(7) {
    width: 20%;
  }
}
</style>

<style lang="scss">
.brakes-select-wrap {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 10px;
  position: relative;
  margin-right: 35px;
  cursor: pointer;

  .text {
    font-weight: 500;

    .selected-brakes-variant {
      font-weight: normal;
    }

    svg {
      position: relative;
      top: -1px;
      left: 2.25px;
    }
  }

  .brakes-variants {
    position: absolute;
    top: 0;
    width: 68px;
    left: 10px;
    background: #ffffff;
    box-shadow: 0 0 2px #454545;
    border-radius: 10px;
    overflow: hidden;
    padding-left: 8px;
    padding-right: 8px;

    $variantHeight: 26px;

    .variant {
      height: $variantHeight;
      width: 100%;
      font-size: 10px;
      line-height: $variantHeight;
      text-align: center;
      cursor: pointer;
      background: #ffffff;
      transition: all 0.2s ease-in-out;

      &:first-of-type {
        border-bottom: 1px solid #f1f1f1;
      }

      &:last-of-type {
        border-bottom: none;
      }

      &.selected {
        font-weight: bold;
      }
    }
  }
}
</style>
