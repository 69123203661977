var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRMLayout',{scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_c('span',[_vm._v("Creating vacancy")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"main__c main__c-auto"},[_c('div',{staticClass:"main__c-in"},[_c('div',{staticClass:"main__headline"},[_c('h5',[_vm._v("Vacancy")])])]),_c('div',[_c('ul',{staticClass:"nav nav-tabs list-unstyled"},_vm._l((_vm.languages),function(language){return _c('li',{key:language.formNamePostfix,class:_vm.isActive(language) ? 'active' : ''},[_c('a',{on:{"click":function($event){return _vm.switchActiveLanguage(language)}}},[_vm._v(" "+_vm._s(language.title)+" ")])])}),0),_c('div',{staticClass:"main__content tab-pane"},[_c('div',{staticClass:"main__content-in"},[_vm._l((_vm.languages),function(language){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeLanguage.id === language.id),expression:"activeLanguage.id === language.id"}],key:language.formNamePostfix},[_c('div',{staticClass:"main__content-item main__content-item_center"},[_c('div',{staticClass:"main__content-item_l"},[_c('span',[_vm._v("Title:")])]),_c('div',{staticClass:"main__content-item_r"},[_c('div',{staticClass:"inp-group inp-group__xl"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                        _vm.$data.vacancy[("title" + (language.formNamePostfix))]
                      ),expression:"\n                        $data.vacancy[`title${language.formNamePostfix}`]\n                      "}],attrs:{"name":("title" + (language.formNamePostfix)),"title":"Form Name","type":"text"},domProps:{"value":(
                        _vm.$data.vacancy[("title" + (language.formNamePostfix))]
                      )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$data.vacancy, ("title" + (language.formNamePostfix)), $event.target.value)}}})])])]),_c('div',{staticClass:"main__content-item main__content-margin_bottom"},[_c('div',{staticClass:"main__content-item_l"},[_c('span',[_vm._v("Description:")])]),_c('div',{staticClass:"main__content-item_r"},[_c('div',{staticClass:"inp-group inp-group__textarea"},[_c('ckeditor',{attrs:{"editor":_vm.editor},model:{value:(
                        _vm.$data.vacancy[
                          ("description" + (language.formNamePostfix))
                        ]
                      ),callback:function ($$v) {_vm.$set(_vm.$data.vacancy, 
                          ("description" + (language.formNamePostfix))
                        , $$v)},expression:"\n                        $data.vacancy[\n                          `description${language.formNamePostfix}`\n                        ]\n                      "}})],1)])])])}),_c('div',{staticClass:"main__content-item main__content-margin_bottom"},[_c('div',{staticClass:"main__content-item_l"},[_c('span',[_vm._v("Description(preview):")])]),_c('div',{staticClass:"main__content-item_r"},[_c('div',{staticClass:"inp-group"},[_c('ckeditor',{attrs:{"editor":_vm.editor},model:{value:(_vm.vacancy.short_description),callback:function ($$v) {_vm.$set(_vm.vacancy, "short_description", $$v)},expression:"vacancy.short_description"}})],1)])]),_c('div',{staticClass:"main__content-item main__content-item_center"},[_c('div',{staticClass:"main__content-item_l"},[_c('span',[_vm._v("Department:")])]),_c('div',{staticClass:"main__content-item_r"},[_c('div',{staticClass:"inp-group__dropdown inp-group__xl"},[_c('v-select',{attrs:{"options":_vm.departments,"label":"label","placeholder":"Select department"},model:{value:(_vm.currentDepartment),callback:function ($$v) {_vm.currentDepartment=$$v},expression:"currentDepartment"}})],1)])])],2)])]),_c('div',{staticClass:"main__content-btns"},[_c('button',{staticClass:"btn btn-big btn-fw",on:{"click":_vm.save}},[_c('span',[_c('i',[_vm._v("Save vacancy")])])])])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }