<template>
  <CRMLayout>
    <template v-slot:header-title>Edit product</template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Product info</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Model:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <span v-if="currentModel">{{ currentModel.label }}</span>
                  <!--                  <div class="inp-group__dropdown inp-group__xl">-->
                  <!--                    <v-select-->
                  <!--                      :options="models"-->
                  <!--                      label="label"-->
                  <!--                      placeholder="Select model"-->
                  <!--                      v-model="currentModel"/>-->
                  <!--                  </div> &lt;!&ndash; .inp-group__dropdown &ndash;&gt;-->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Color:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div
                    class="inp-group__dropdown inp-group__xl inp-group__colorpicker"
                  >
                    <v-select
                      v-model="currentColor"
                      :options="colors"
                      label="label"
                      placeholder="Select color"
                    >
                      <template v-slot:option="option">
                        <span class="color__option">
                          <span
                            :style="getColorOptionStyle(option)"
                            class="color_option"
                          ></span>
                          <span v-if="option" class="color_option_label">{{
                            option.label
                          }}</span>
                        </span>
                      </template>
                      <template v-slot:selected-option="option">
                        <span class="color__option">
                          <span
                            :style="getColorOptionStyle(option)"
                            class="color_option"
                          ></span>
                          <span v-if="option" class="color_option_label">{{
                            option.label
                          }}</span>
                        </span>
                      </template>
                    </v-select>
                  </div>
                  <!-- .inp-group__dropdown -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Price (without optional components):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="product.price" type="number" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Price (with optional components):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    {{ getFullPrice }}
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Total price of components(in):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    {{ getPartsPriceIn }}
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Total price of components(out):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    {{ getPartsPriceOut }}
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content ">
            <div class="main__content-headline">
              <h5>Basic info</h5>
            </div>
            <!-- .main__content-headline -->

            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>ID:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    {{ id }}
                  </div>
                  <!-- .inp-group__dropdown -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Name:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="product.name" type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Name (website):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="product.name_website" type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Name (mobile apps):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="product.name_app" type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Vendor code:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="product.vendor_code" type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Brand:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group__dropdown inp-group__xl">
                    <v-select
                      v-model="currentBrand"
                      :options="brands"
                      label="label"
                      placeholder="Select brand"
                    />
                  </div>
                  <!-- .inp-group__dropdown -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Weight, gram:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="product.weight_gram" type="number" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Length, mm:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="product.length_mm" type="number" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Width, mm:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="product.width_mm" type="number" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Height, mm:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="product.height_mm" type="number" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>EAN13:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="product.EAN13" type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Store visibility:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__checkbox">
                    <input
                      id="checkbox-1"
                      v-model="product.store_visibility"
                      type="checkbox"
                    />
                    <label for="checkbox-1"></label>
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Start activity:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <date-picker
                      v-model="product.start_activity"
                      :format="'YYYY/MM/DD'"
                      type="date"
                      valueType="YYYY-MM-DD"
                    />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>End activity:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <date-picker
                      v-model="product.end_activity"
                      :format="'YYYY/MM/DD'"
                      type="date"
                      valueType="YYYY-MM-DD"
                    />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div v-if="showCategoryBlock" class="main__content">
            <div class="main__content-headline">
              <h5>Component parts</h5>
            </div>
            <!-- .main__content-headline -->

            <div class="main__content-in">
              <div
                v-for="category in categories"
                :key="category.id"
                class="main__content-item main__content-item_center"
              >
                <div v-if="showCategorySelector(category)">
                  <div class="main__content-item_l">
                    <span>{{ category.en_title }}:</span>
                  </div>
                  <div class="main__content-item_r">
                    <div class="inp-group__dropdown inp-group__xl">
                      <v-select
                        :options="category.comp_parts"
                        :value="selectedParts[category.id.toString()]"
                        label="title_cms"
                        placeholder="Select part"
                        @input="changePartInCategory($event, category)"
                      >
                        <template v-for="option in category.comp_parts">
                          <span v-bind:key="option.index" class="selected-tag">
                            <slot
                              v-bind="
                                typeof option === 'object'
                                  ? option
                                  : { [label]: option }
                              "
                              name="selected-option"
                            >
                              {{ getOptionTitle(option) }}
                            </slot>
                          </span>
                        </template>
                        <template v-slot:option="option">
                          {{ option.title_cms }}
                        </template>
                        <template v-slot:selected-option="option">
                          {{ getOptionTitle(option) }}
                        </template>
                      </v-select>
                    </div>
                    <!-- .inp-group inp-group__xl -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content nameFile">
            <div class="main__content-headline">
              <h5>Detail info</h5>
            </div>
            <!-- .main__content-headline -->

            <div>
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.prefix"
                  :class="
                    language.title === currentLanguage.title ? 'active' : ''
                  "
                >
                  <a @click.prevent="switchLanguage(language)">{{
                    language.title
                  }}</a>
                </li>
              </ul>

              <div
                v-for="language in languages"
                v-show="language.title === currentLanguage.title"
                :key="language.prefix"
              >
                <div class="main__content">
                  <div class="main__content-in">
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Browser title:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.product[`${language.prefix}browser_title`]
                            "
                            type="text"
                          />
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Tags:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="$data.product[`${language.prefix}tags`]"
                            type="text"
                          />
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Meta description:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.product[
                                `${language.prefix}meta_description`
                              ]
                            "
                            type="text"
                          />
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>URL name:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.product[`${language.prefix}url_name`]
                            "
                            type="text"
                          />
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Description (preview):</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.product[
                                `${language.prefix}description_preview`
                              ]
                            "
                            type="text"
                          />
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>Description (full):</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <textarea
                            v-model="
                              $data.product[
                                `${language.prefix}description_full`
                              ]
                            "
                          ></textarea>
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>Instructions:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <dropzone
                          :id="`${language.prefix}instruction`"
                          :default-image="
                            $data[`${language.prefix}instruction`]
                          "
                          :options="dropzoneSettings"
                          dict-default-message="Drag&drop or <u>click</u> here to upload file (pdf up to 15MB)"
                          @vdropzone-files-added="
                            saveLocalInstructions($event, language.prefix)
                          "
                          @vdropzone-removed-file="
                            removeLocalInstructions($event, language.prefix)
                          "
                        />
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->
                  </div>
                  <!-- .main__content-in -->
                </div>
                <!-- .main__content -->
              </div>
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Media content</h5>
            </div>
            <!-- .main__content-headline -->

            <ul class="nav nav-tabs list-unstyled">
              <li
                v-for="language in languages"
                :key="language.prefix"
                :class="
                  language.title === currentLanguage.title ? 'active' : ''
                "
              >
                <a @click.prevent="switchLanguage(language)">{{
                  language.title
                }}</a>
              </li>
            </ul>

            <div
              v-for="language in languages"
              v-show="language.title === currentLanguage.title"
              :key="language.prefix"
            >
              <div class="main__content-in">
                <div class="main__content-item">
                  <div class="main__content-item_l">
                    <span>Preview (catalog):</span>
                  </div>
                  <!-- .main__content-item_l -->

                  <div class="main__content-item_r">
                    <dropzone
                      :id="`${language.prefix}catalog_images`"
                      :default-image="$data[`${language.prefix}catalog_images`]"
                      :options="dropzoneSettings"
                      dict-default-message="Drag&drop or <u>click</u> here to upload image (jpg 1920x1080 up to 15MB)"
                      @vdropzone-files-added="
                        saveLocalCatalogImages($event, language.prefix)
                      "
                      @vdropzone-removed-file="
                        removeLocalCatalogImages($event, language.prefix)
                      "
                    />
                  </div>
                  <!-- .main__content-item_r -->
                </div>
                <!-- .main__content-item -->

                <div class="main__content-item">
                  <div class="main__content-item_l">
                    <span>Preview (product page):</span>
                  </div>
                  <!-- .main__content-item_l -->

                  <div class="main__content-item_r">
                    <dropzone
                      :id="`${language.prefix}page_images`"
                      :default-image="$data[`${language.prefix}page_images`]"
                      :options="dropzoneSettings"
                      dict-default-message="Drag&drop or <u>click</u> here to upload image (jpg 1920x1080 up to 15MB)"
                      @vdropzone-files-added="
                        saveLocalPageImages($event, language.prefix)
                      "
                      @vdropzone-removed-file="
                        removeLocalPageImages($event, language.prefix)
                      "
                    />
                  </div>
                </div>

                <div class="main__content-item">
                  <div class="main__content-item_l">
                    <span>Product video:</span>
                  </div>

                  <div class="main__content-item_r">
                    <dropzone
                      :id="`${language.prefix}videos`"
                      :default-image="$data[`${language.prefix}videos`]"
                      :options="dropzoneSettings"
                      dict-default-message="Drag&drop or <u>click</u> here to upload video (mp4 1920x1080 up to 150MB)"
                      @vdropzone-files-added="
                        saveLocalVideo($event, language.prefix)
                      "
                      @vdropzone-removed-file="
                        removeLocalVideo($event, language.prefix)
                      "
                    />
                  </div>
                  <!-- .main__content-item_r -->
                </div>
                <!-- .main__content-item -->
              </div>
              <!-- .main__content-in -->
            </div>
          </div>
          <!-- .main__content -->

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Social media content</h5>
            </div>
            <!-- .main__content-headline -->

            <div class="main__content-in">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.prefix"
                  :class="
                    language.title === currentLanguage.title ? 'active' : ''
                  "
                >
                  <a @click.prevent="switchLanguage(language)">{{
                    language.title
                  }}</a>
                </li>
              </ul>

              <div
                v-for="language in languages"
                v-show="language.title === currentLanguage.title"
                :key="language.prefix"
              >
                <div class="main__content tab-pane">
                  <div class="main__content-in">
                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>Preview:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <dropzone
                          :id="`${language.prefix}social_media_images`"
                          :default-image="
                            $data[`${language.prefix}social_media_images`]
                          "
                          :options="dropzoneSettings"
                          @vdropzone-files-added="
                            saveLocalSocialMediaImages($event, language.prefix)
                          "
                          @vdropzone-removed-file="
                            removeLocalSocialMediaImages(
                              $event,
                              language.prefix
                            )
                          "
                        />
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Title:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.product[`${language.prefix}social_title`]
                            "
                            type="text"
                          />
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Description:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <textarea
                            v-model="
                              $data.product[`${language.prefix}social_desc`]
                            "
                          ></textarea>
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->
                  </div>
                  <!-- .main__content-in -->
                </div>
                <!-- .main__content -->
              </div>
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div v-if="canEdit()" class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save()">
              <span><i>Save</i></span>
            </button>
            <button class="btn btn-big btn-fw" @click="clone()">
              <span><i>Clone</i></span>
            </button>
            <button class="btn btn-big btn-fw" @click="deleteProduct()">
              <span><i>Delete</i></span>
            </button>
          </div>
          <!-- .main__content-btns -->
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import DatePicker from "vue2-datepicker";
import Dropzone from "./forms/inputs/Dropzone";
import { STORAGE_ENDPOINT } from "../utils/api";
import vSelect from "vue-select";

export default {
  name: "CRMProductsCreate",
  components: { CRMLayout, vSelect, DatePicker, Dropzone },
  data() {
    return {
      product: {
        model_id: null,
        color_id: null,
        price: 0,
        name: null,
        name_app: null,
        name_website: null,
        vendor_code: null,
        brand_id: null,
        weight_gram: 0,
        length_mm: 0,
        width_mm: 0,
        height_mm: 0,
        EAN13: null,
        store_visibility: true,
        start_activity: null,
        end_activity: null,
        browser_title: null,
        es_browser_title: null,
        de_browser_title: null,
        fr_browser_title: null,
        ru_browser_title: null,
        tags: null,
        es_tags: null,
        de_tags: null,
        fr_tags: null,
        ru_tags: null,
        meta_description: null,
        es_meta_description: null,
        de_meta_description: null,
        fr_meta_description: null,
        ru_meta_description: null,
        url_name: null,
        es_url_name: null,
        de_url_name: null,
        fr_url_name: null,
        ru_url_name: null,
        description_preview: null,
        es_description_preview: null,
        de_description_preview: null,
        fr_description_preview: null,
        ru_description_preview: null,
        description_full: null,
        es_description_full: null,
        de_description_full: null,
        fr_description_full: null,
        ru_description_full: null,
        social_title: null,
        es_social_title: null,
        de_social_title: null,
        fr_social_title: null,
        ru_social_title: null,
        social_desc: null,
        es_social_desc: null,
        de_social_desc: null,
        fr_social_desc: null,
        ru_social_desc: null,
        instruction: [],
        es_instruction: [],
        de_instruction: [],
        fr_instruction: [],
        ru_instruction: [],
        catalog_images: [],
        es_catalog_images: [],
        de_catalog_images: [],
        fr_catalog_images: [],
        ru_catalog_images: [],
        images: [],
        es_images: [],
        de_images: [],
        fr_images: [],
        ru_images: [],
        page_images: [],
        es_page_images: [],
        de_page_images: [],
        fr_page_images: [],
        ru_page_images: [],
        videos: [],
        es_videos: [],
        de_videos: [],
        fr_videos: [],
        ru_videos: [],
        social_media_images: [],
        es_social_media_images: [],
        de_social_media_images: [],
        fr_social_media_images: [],
        ru_social_media_images: []
      },
      id: 0,
      instruction: [],
      es_instruction: [],
      de_instruction: [],
      fr_instruction: [],
      ru_instruction: [],
      videos: [],
      es_videos: [],
      de_videos: [],
      fr_videos: [],
      ru_videos: [],
      social_media_images: [],
      es_social_media_images: [],
      de_social_media_images: [],
      fr_social_media_images: [],
      ru_social_media_images: [],
      catalog_images: [],
      es_catalog_images: [],
      de_catalog_images: [],
      fr_catalog_images: [],
      ru_catalog_images: [],
      page_images: [],
      es_page_images: [],
      de_page_images: [],
      fr_page_images: [],
      ru_page_images: [],
      images: [],
      es_images: [],
      de_images: [],
      fr_images: [],
      ru_images: [],
      originalProduct: null,
      partCategories: {},
      partCategoriesAsArray: [],
      currentModel: null,
      currentColor: null,
      currentBrand: null,
      categories: [],
      selectedParts: {},
      models: [],
      colors: [],
      brands: [],
      currentLanguage: {
        prefix: "",
        title: "EN"
      },
      languages: [
        {
          prefix: "",
          title: "EN"
        },
        {
          prefix: "es_",
          title: "ES"
        },
        {
          prefix: "de_",
          title: "DE"
        },
        {
          prefix: "fr_",
          title: "FR"
        },
        {
          title: "RU",
          prefix: "ru_"
        }
      ],
      user: null
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.loadProduct();
  },
  computed: {
    dropzoneSettings() {
      return {
        url: `update`,
        uploadMultiple: true,
        paramName: "random",
        autoProcessQueue: false,
        addRemoveLinks: true,
        preventDuplicates: true
      };
    },
    getFullPrice() {
      let fullPrice = 0;
      fullPrice += parseInt(this.product.price);
      for (let key of Object.keys(this.selectedParts)) {
        if (this.isCategoryIsOption(key)) {
          fullPrice += parseInt(this.selectedParts[key].price_out);
        }
      }

      return this.$crConverter(fullPrice, "EUR");
    },
    getPartsPriceIn() {
      let priceIn = 0;
      for (let key of Object.keys(this.selectedParts)) {
        if (this.isCategoryIsOption(key)) {
          priceIn += parseInt(this.selectedParts[key].price_in);
        }
      }

      return this.$crConverter(priceIn, "EUR");
    },
    getPartsPriceOut() {
      let priceOut = 0;
      for (let key of Object.keys(this.selectedParts)) {
        if (this.isCategoryIsOption(key)) {
          priceOut += parseInt(this.selectedParts[key].price_out);
        }
      }

      return this.$crConverter(priceOut, "EUR");
    },
    showCategoryBlock() {
      return this.categories.length > 0;
    }
  },
  methods: {
    canEdit() {
      if (!this.user) {
        return false;
      }
      for (let permission of this.user.user_type.premissions) {
        if (permission.premisson_id === 1) {
          return permission.edit === 1;
        }
      }
    },
    getOptionTitle(option) {
      if (option.title_cms.length > 40) {
        return option.title_cms.substr(0, 40) + "...";
      }
      return option.title_cms;
    },
    isCategoryIsOption(categoryID) {
      let filteredCategories = this.categories.filter(category => {
        return parseInt(category.id) === parseInt(categoryID);
      });

      return (
        filteredCategories.length > 0 && filteredCategories[0].as_option === 1
      );
    },
    loadProduct() {
      let ctx = this;
      this.$store
        .dispatch("get_product_request", this.$route.params.id)
        .then(response => {
          this.originalProduct = response.data;

          for (let key of Object.keys(response.data)) {
            // eslint-disable-next-line
            if (ctx.product.hasOwnProperty(key)) {
              ctx.product[key] = response.data[key];
            }
          }

          ctx.id = response.data.id;

          for (let language of ctx.languages) {
            if (
              // eslint-disable-next-line
              response.data.hasOwnProperty(`${language.prefix}detail_info`) &&
              !!response.data[`${language.prefix}detail_info`]
            ) {
              for (let key of Object.keys(
                response.data[`${language.prefix}detail_info`]
              )) {
                if (
                  // eslint-disable-next-line
                  ctx.product.hasOwnProperty(language.prefix + key) &&
                  key !== "id" &&
                  key !== "created_at"
                ) {
                  ctx.product[language.prefix + key] =
                    response.data[`${language.prefix}detail_info`][key];
                }
              }

              if (
                // eslint-disable-next-line
                response.data[`${language.prefix}detail_info`].hasOwnProperty(
                  "detail_instructions"
                ) &&
                response.data[`${language.prefix}detail_info`]
                  .detail_instructions.length > 0
              ) {
                let files =
                  response.data[`${language.prefix}detail_info`]
                    .detail_instructions;

                for (let item of files) {
                  if (item.instruction) {
                    ctx[`${language.prefix}instruction`].push(
                      STORAGE_ENDPOINT + item.instruction
                    );
                  }
                }
              }
            }

            if (
              // eslint-disable-next-line
              response.data.hasOwnProperty(
                `${language.prefix}product_social_medias`
              ) &&
              !!response.data[`${language.prefix}product_social_medias`]
            ) {
              for (let key of Object.keys(
                response.data[`${language.prefix}product_social_medias`]
              )) {
                if (
                  // eslint-disable-next-line
                  ctx.product.hasOwnProperty(
                    language.prefix + "social_" + key
                  ) &&
                  key !== "id" &&
                  key !== "created_at"
                ) {
                  ctx.product[language.prefix + "social_" + key] =
                    response.data[`${language.prefix}product_social_medias`][
                      key
                    ];
                }
              }

              if (
                response.data[
                  `${language.prefix}product_social_medias`
                  // eslint-disable-next-line
                ].hasOwnProperty("product_social_media_images") &&
                response.data[`${language.prefix}product_social_medias`]
                  .product_social_media_images.length > 0
              ) {
                let files =
                  response.data[`${language.prefix}product_social_medias`]
                    .product_social_media_images;

                for (let item of files) {
                  if (item.social_media_images) {
                    ctx[`${language.prefix}social_media_images`].push(
                      STORAGE_ENDPOINT + item.social_media_images
                    );
                  }
                }
              }
            }

            if (
              // eslint-disable-next-line
              response.data.hasOwnProperty(
                `${language.prefix}product_media_catalogs`
              ) &&
              response.data[`${language.prefix}product_media_catalogs`].length >
                0
            ) {
              let files =
                response.data[`${language.prefix}product_media_catalogs`];

              for (let item of files) {
                if (item.catalog_images) {
                  ctx[`${language.prefix}catalog_images`].push(
                    STORAGE_ENDPOINT + item.catalog_images
                  );
                }
              }
            }

            if (
              // eslint-disable-next-line
              response.data.hasOwnProperty(
                `${language.prefix}product_media_pages`
              ) &&
              response.data[`${language.prefix}product_media_pages`].length > 0
            ) {
              let files =
                response.data[`${language.prefix}product_media_pages`];

              for (let item of files) {
                if (item.page_images) {
                  ctx[`${language.prefix}page_images`].push(
                    STORAGE_ENDPOINT + item.page_images
                  );
                }
              }
            }

            if (
              // eslint-disable-next-line
              response.data.hasOwnProperty(
                `${language.prefix}product_media_images`
              ) &&
              response.data[`${language.prefix}product_media_images`].length > 0
            ) {
              let files =
                response.data[`${language.prefix}product_media_images`];

              for (let item of files) {
                if (item.images) {
                  ctx[`${language.prefix}images`].push(
                    STORAGE_ENDPOINT + item.images
                  );
                }
              }
            }

            if (
              // eslint-disable-next-line
              response.data.hasOwnProperty(
                `${language.prefix}product_media_videos`
              ) &&
              response.data[`${language.prefix}product_media_videos`].length > 0
            ) {
              let files =
                response.data[`${language.prefix}product_media_videos`];

              for (let item of files) {
                if (item.videos) {
                  ctx[`${language.prefix}videos`].push(
                    STORAGE_ENDPOINT + item.videos
                  );
                }
              }
            }
          }

          this.loadPartCategories(response.data.model_id);
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadPartCategories(model, updateModels = true) {
      let data = {
        type: "single",
        model_id: model
      };
      let ctx = this;
      this.$store
        .dispatch("get_part_categories_request", data)
        .then(response => {
          ctx.categories = response.data.rel_cats_quantity.filter(category => {
            return category.pivot.quantity !== 0;
          });

          if (parseInt(this.product.model_id) === parseInt(model)) {
            for (let category of this.originalProduct.rel_categories) {
              if (category.selected_part.length > 0) {
                this.$set(
                  this.selectedParts,
                  category.id.toString(),
                  category.selected_part[0]
                );
              }
            }
          }

          if (updateModels) {
            this.loadProductModels();
          }

          this.loadBrands();
          this.loadProductColors();
        })
        .catch(err => {
          this.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    showCategorySelector(category) {
      return category.comp_parts.length > 0;
    },
    changePartInCategory(part, category) {
      if (typeof part !== "string") {
        if (part) {
          this.$set(this.selectedParts, category.id.toString(), part);
        } else {
          this.$delete(this.selectedParts, category.id.toString());
        }
      }
    },
    loadProductModels() {
      let ctx = this;
      this.$store
        .dispatch("get_product_models_request", {})
        .then(response => {
          ctx.models = response.data.map(model => {
            if (parseInt(model.id) === parseInt(ctx.product.model_id)) {
              ctx.currentModel = {
                label: model.model,
                code: model.id
              };
            }
            return {
              label: model.model,
              code: model.id
            };
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadProductColors() {
      let ctx = this;
      this.$store
        .dispatch("get_product_colors_request")
        .then(response => {
          ctx.colors = response.data.map(color => {
            if (parseInt(color.id) === parseInt(ctx.product.color_id)) {
              ctx.currentColor = {
                label: color.title[0].toUpperCase() + color.title.substr(1),
                color: color.color,
                code: color.id
              };
            }
            return {
              label: color.title[0].toUpperCase() + color.title.substr(1),
              color: color.color,
              code: color.id
            };
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadBrands() {
      let ctx = this;
      this.$store
        .dispatch("get_part_brands_request")
        .then(response => {
          ctx.brands = response.data.map(brand => {
            if (parseInt(brand.id) === parseInt(ctx.product.brand_id)) {
              ctx.currentBrand = {
                label:
                  brand.brand_name[0].toUpperCase() +
                  brand.brand_name.substr(1),
                code: brand.id
              };
            }
            return {
              label:
                brand.brand_name[0].toUpperCase() + brand.brand_name.substr(1),
              code: brand.id
            };
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    deleteProduct() {
      let ctx = this;
      this.$store
        .dispatch("delete_product_request", this.id)
        .then(() => {
          ctx.$router.push({ name: "CRMProducts" });
          ctx.$root.$emit("modalShow", {
            type: "info",
            text: "Product deleted"
          });
        })
        .catch(error => {
          ctx.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    clone() {
      let ctx = this;
      this.$store
        .dispatch("clone_product_request", this.id)
        .then(response => {
          this.$router.replace(
            { name: "CRMProductsShow", params: { id: response.data.id } },
            () => {
              this.$router.go(0);
            }
          );
          ctx.$root.$emit("modalShow", {
            type: "info",
            text: "Product cloned"
          });
          this.scrollToTop();
        })
        .catch(error => {
          ctx.$root.$emit("modalShow", { type: "error", text: error });
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    categoryChanged(category) {
      this.product.category_id = category;
    },
    switchLanguage(language) {
      this.currentLanguage = language;
    },
    saveLocalInstructions(files, prefix) {
      this.product[`${prefix}instruction`] = files;
    },
    removeLocalInstructions(files, prefix) {
      this.product[`${prefix}instruction`] = files;
    },
    saveLocalCatalogImages(files, prefix) {
      this.product[`${prefix}catalog_images`] = files;
    },
    removeLocalCatalogImages(files, prefix) {
      this.product[`${prefix}catalog_images`] = files;
    },
    saveLocalImages(files, prefix) {
      this.product[`${prefix}images`] = files;
    },
    removeLocalImages(files, prefix) {
      this.product[`${prefix}images`] = files;
    },
    saveLocalPageImages(files, prefix) {
      this.product[`${prefix}page_images`] = files;
    },
    removeLocalPageImages(files, prefix) {
      this.product[`${prefix}page_images`] = files;
    },
    saveLocalVideo(files, prefix) {
      this.product[`${prefix}videos`] = files;
    },
    removeLocalVideo(files, prefix) {
      this.product[`${prefix}videos`] = files;
    },
    saveLocalSocialMediaImages(files, prefix) {
      this.product[`${prefix}social_media_images`] = files;
    },
    removeLocalSocialMediaImages(files, prefix) {
      this.product[`${prefix}social_media_images`] = files;
    },
    getOptions(category) {
      // eslint-disable-next-line
      if (!this.partCategories.hasOwnProperty(category.id)) {
        return [];
      } else {
        return this.partCategories[category.id].comp_parts.filter(part => {
          return part.quantity > 0;
        });
      }
    },

    getType(object) {
      return {}.toString
        .call(object)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase();
    },
    save() {
      let ctx = this;

      let data = new FormData();

      for (let key of Object.keys(this.product)) {
        if (
          (this.getType(this.product[key]) === "filelist" ||
            this.getType(this.product[key]) === "array") &&
          this.product[key].length > 0
        ) {
          for (let i = 0; i < this.product[key].length; i++) {
            data.append(`${key}[]`, this.product[key][i]);
          }
        } else if (
          !Array.isArray(this.product[key]) &&
          this.getType(this.product[key]) !== "filelist" &&
          !!this.product[key]
        ) {
          if (key === "store_visibility") {
            data.append(key, this.product[key] ? "1" : "0");
          } else {
            data.append(key, this.product[key]);
          }
        }
      }

      let categories = [];

      if (this.selectedParts && Object.keys(this.selectedParts).length > 0) {
        for (let key of Object.keys(this.selectedParts)) {
          if (this.selectedParts[key]) {
            categories.push({
              cat_id: parseInt(key),
              part_id: this.selectedParts[key].id
            });
          }
        }
      }

      if (categories.length > 0) {
        data.append("categories", JSON.stringify(categories));
      }

      this.$store
        .dispatch("update_product_request", { data: data, id: this.id })
        .then(() => {
          ctx.$root.$emit("modalShow", {
            type: "info",
            text: "Product successfully updated."
          });
          this.$router.push("/products/");
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    getColorOptionStyle(option) {
      return {
        width: "17px",
        height: "17px",
        display: "block",
        background: option.color,
        marginRight: "4px",
        marginLeft: "4px",
        border: "1px solid #ccc",
        marginTop: "5px"
      };
    }
  },
  watch: {
    product: {
      handler: function(val) {
        let ctx = this;
        if (this.models.length > 0) {
          for (let model of this.models) {
            if (parseInt(model.code) === parseInt(val.model_id)) {
              ctx.currentModel = model;
            }
          }
        }
        if (this.colors.length > 0) {
          for (let color of this.colors) {
            if (parseInt(color.code) === parseInt(val.color_id)) {
              ctx.currentColor = color;
            }
          }
        }
        if (this.brands.length > 0) {
          for (let brand of this.brands) {
            if (parseInt(brand.code) === parseInt(val.brand_id)) {
              ctx.currentBrand = brand;
            }
          }
        }
        if (val.price < 0) {
          this.product.price = 0;
        }
        if (
          val.price.toString().length > 1 &&
          val.price.toString()[0] === "0"
        ) {
          this.product.price = parseInt(val.price.toString().substr(1));
        }
      },
      deep: true,
      immediate: true
    },
    currentModel: {
      handler: function(val) {
        if (val) {
          this.product.model_id = val.code;
          this.loadPartCategories(val.code, false);
        }
      }
    },
    currentColor: {
      handler: function(val) {
        if (val) {
          this.product.color_id = val.code;
        }
      }
    },
    currentBrand: {
      handler: function(val) {
        if (val) {
          this.product.brand_id = val.code;
        }
      }
    }
  }
};
</script>

<style scoped>
.color__option {
  padding-left: 2px;
  display: flex;
}

.color__option-label {
  display: block;
}
</style>
