<template>
  <div class="main__content-item">
    <div class="trans-item">
      <div class="wrapper">
        <div class="main__content-item main__content-item_center">
          <div class="main__content-item_l">
            <span>Text:</span>
          </div>
          <div class="main__content-item_r">
            <div class="inp-group inp-group__xl">
              {{ value.title }}
            </div>
          </div>
        </div>
        <div class="main__content-item">
          <div class="main__content-item_l">
            <span>Image/File:</span>
          </div>
          <div class="main__content-item_r">
            <img :src="value.file" :alt="value.text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.trans-item {
  width: 100%;
  max-width: calc(976px + 6.5%);
  .wrapper {
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "IncludedInDeliveryReadOnly",
  props: ["value"]
};
</script>
