<template>
  <layout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMPostCreate' }"
        class="btn btn-white btn-reset add-models-btn"
        >Add post
      </router-link>
      <router-link
        :to="{ name: 'CRMSupportEntryCreate' }"
        class="btn btn-white btn-reset add-models-btn"
        >Add support entry
      </router-link>
      <router-link
        :to="{ name: 'Settings', params: { model: 'news_setting' }}"
        class="btn btn-white btn-reset add-models-btn"
        >News settings
      </router-link>
    </template>

    <template v-slot:header-title>Posts list</template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in bg-transparent">
          <div class="main__headline">
            <h5>Publications</h5>
          </div>

          <draggable
            id="sortable-2"
            v-model="posts"
            :sort="sortEnable"
            class="news__items"
            tag="div"
            @change="sortPosts"
          >
            <template v-for="post in posts">
              <div :key="post.id" :data-post-id="post.id" class="news__item">
                <div class="news__item-l">
                  <transition name="draggable">
                    <button v-if="sortEnable" class="btn btn-drag"></button>
                  </transition>
                </div>

                <a :href="getItemUrl(post)" class="news__item-c">
                  <div class="news__item-in" @click="show($event, post.id)">
                    <div class="news__item-img">
                      <img :alt="post.title" :src="getPostImage(post)" />
                    </div>

                    <div class="news__item-content">
                      <div class="news__item-headline">
                        <h5>{{ post.title }}</h5>
                      </div>

                      <div class="news__item-desc">
                        <p>{{ post.desc.substr(0, 100) }}...</p>
                      </div>
                    </div>
                  </div>
                </a>

                <div class="news__item-r">
                  <div class="news__item-date">
                    <div
                      v-if="
                        !!post.active &&
                          !!post.public_period &&
                          post.public_period[0] !== '0'
                      "
                    >
                      <span class="news__item-month">
                        {{ formatDate(post.public_period, "MMM, D") }}
                      </span>
                      <span class="news__item-year">
                        <i>
                          {{ formatDate(post.public_period, "YYYY") }}
                        </i>
                      </span>
                    </div>
                    <div v-else>
                      <span class="news__item-month">
                        <span v-if="!!!post.active">Not published</span>
                        <span v-else>Date not set</span>
                      </span>
                    </div>
                    <span class="news__item-author">
                      created by
                      <a :href="getAuthorLink(post)">
                        {{ authorFullName(post) }}
                      </a>
                    </span>
                  </div>

                  <div class="news__item-visibility">
                    <button
                      :class="isHidden(post)"
                      class="btn btn-show"
                      @click="switchActivity(post)"
                    ></button>
                  </div>
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </div>

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>

        <div class="main__r-in">
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>News/Supports</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentItemType"
                  :options="itemTypes"
                  label="label"
                  placeholder="Select product type"
                />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Sort by author</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <StylerSelector
                  :selector-options="authors"
                  @newoptionselected="setCurrentAuthor"
                >
                </StylerSelector>
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Sort by date, from</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="dateFromFilter"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Sort by date, to</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="dateToFilter"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Published</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__choice">
                <div class="inp-group__choice-item">
                  <input
                    id="yes"
                    :checked="published_filter === 1"
                    type="checkbox"
                    @change="acceptPublishedFilter(1)"
                  />
                  <label for="yes">Yes</label>
                </div>

                <div class="inp-group__choice-item">
                  <input
                    id="no"
                    :checked="published_filter === 0"
                    type="checkbox"
                    @change="acceptPublishedFilter(0)"
                  />
                  <label for="no">No</label>
                </div>
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Archived</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__choice">
                <div class="inp-group__choice-item">
                  <input
                    id="yes1"
                    :checked="archivedFilter"
                    type="checkbox"
                    @change="acceptArchivedFilter(true)"
                  />
                  <label for="yes1">Yes</label>
                </div>

                <div class="inp-group__choice-item">
                  <input
                    id="no1"
                    :checked="
                      archivedFilter !== null && archivedFilter === false
                    "
                    type="checkbox"
                    @change="acceptArchivedFilter(false)"
                  />
                  <label for="no1">No</label>
                </div>
              </div>
            </div>
          </div>

          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-fw btn-sm"
              @click="resetFilters"
            >
              Reset filter
            </button>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import { STORAGE_ENDPOINT } from "@/utils/api";
import StylerSelector from "./forms/inputs/StylerSelect";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import $ from "jquery";

export default {
  name: "CRMPosts",
  components: {
    layout: CRMLayout,
    vSelect,
    StylerSelector,
    DatePicker,
    draggable
  },
  data() {
    return {
      posts: [],
      dragging: false,
      published_filter: null,
      archivedFilter: null,
      updateList: false,
      dateFromFilter: null,
      dateToFilter: null,
      total: 0,
      perPage: 0,
      currentAuthorId: "",
      reseting: false,
      currentPage: 1,
      lastPage: 1,
      defaultSortableArray: [],
      sortEnable: true,
      currentItemType: {
        code: "news",
        label: "News"
      },
      itemTypes: [
        {
          code: "news",
          label: "News"
        },
        {
          code: "supports",
          label: "Supports"
        }
      ]
    };
  },
  created() {
    this.loadPosts();
    this.loadAuthors();
  },
  methods: {
    sortPosts() {
      let ctx = this;
      this.$store.dispatch("sort_news_request", this.posts).catch(err => {
        ctx.$root.$emit("modalShow", { type: "error", text: err });
      });
    },
    switchActivity(post) {
      let ctx = this;
      let data = {
        active: !post.active,
        id: post.id,
        currentItemType: this.currentItemType
      };

      let requestName = "switch_post_activity_request";

      this.$store
        .dispatch(requestName, data)
        .then(resp => {
          post.public_period = resp.data.de.public_period;
          post.active = !post.active;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    show(event, id) {
      if (!this.dragging) {
        this.$router.push("/posts/" + id);
      }
    },
    getAuthorLink(post) {
      return "customers/" + post.user.id;
    },
    isHidden(post) {
      return post.active ? "" : "is-hidden";
    },
    authorFullName(post) {
      return post.user.f_name;
    },
    getPostImage(post) {
      if (post.images.length === 0) {
        return "http://lorempixel.com/77/77/";
      }
      return STORAGE_ENDPOINT + post.images[0].image;
    },
    formatDate(date, format) {
      date = this.$moment(date);

      return date.format(format);
    },
    loadPosts() {
      let ctx = this;

      let options = {
        parameters: []
      };

      options.parameters.push(`page=${ctx.currentPage}`);

      if (this.currentAuthorId) {
        options.parameters.push(`userId=${this.currentAuthorId}`);
      }

      if (ctx.dateFromFilter) {
        options.parameters.push(`from=${ctx.dateFromFilter}`);
      }

      if (ctx.dateToFilter) {
        options.parameters.push(`to=${ctx.dateToFilter}`);
      }

      if (ctx.published_filter !== null) {
        options.parameters.push(`published=${ctx.published_filter}`);
      }

      if (this.archivedFilter) {
        options.parameters.push(`archived=true`);
      }

      options.parameters.currentItemType = ctx.currentItemType.code;
      let requestName = "get_posts_request";

      this.$store
        .dispatch(requestName, options)
        .then(resp => {
          ctx.posts = resp.data;
          if (this.posts.length < 6) {
            // noinspection JSCheckFunctionSignatures
            $("body, html").animate({ scrollTop: 0 }, 500, "swing");
          }
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadAuthors() {
      let ctx = this;
      this.$store
        .dispatch("get_authors_request")
        .then()
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    getItemUrl(item) {
      if (!!this.currentItemType && this.currentItemType.code === "supports") {
        return "/support/" + item.id;
      }
      return "/posts/" + item.id;
    },
    acceptPublishedFilter(value) {
      this.sortEnable = false;
      if (this.published_filter === null) {
        this.published_filter = value;
      } else if (value === this.published_filter) {
        this.published_filter = null;
      } else {
        this.published_filter = value;
      }

      this.updateList = true;
      this.loadPosts();
    },
    acceptArchivedFilter(value) {
      if (this.archivedFilter === null) {
        this.archivedFilter = value;
      } else if (value === this.archivedFilter) {
        this.archivedFilter = null;
      } else {
        this.archivedFilter = value;
      }

      this.updateList = true;
      this.loadPosts();
    },
    resetFilters() {
      this.sortEnable = true;
      this.updateList = true;
      this.published_filter = null;
      this.archivedFilter = null;
      this.dateToFilter = null;
      this.dateFromFilter = null;
      this.reseting = true;
      this.currentAuthorId = "";
      this.currentPage = 1;
      this.lastPage = 1;
      this.currentItemType = {
        code: "news",
        label: "News"
      };
      this.loadPosts();
    },
    setCurrentAuthor(event) {
      this.currentAuthorId = event;
    },
    changePage(page) {
      this.currentPage = page;
    }
  },
  computed: {
    filterReseted() {
      return (
        this.published_filter === null &&
        this.dateFromFilter === null &&
        this.dateToFilter === null &&
        (this.currentAuthorId === null || this.currentAuthorId === "")
      );
    },
    showPaginator() {
      return this.total > this.perPage;
    },
    authors() {
      let authors = [
        {
          value: null,
          text: "All"
        }
      ];

      let authorsFromServer = this.$store.getters.authors.map(author => {
        return {
          value: author.id,
          text: author.l_name + " " + author.f_name
        };
      });

      return [...authors, ...authorsFromServer];
    },
    types() {
      return [
        {
          value: "news",
          text: "News"
        },
        {
          value: "support",
          text: "Support entries"
        }
      ];
    }
  },
  watch: {
    currentAuthorId: function(id) {
      if (this.reseting) {
        this.reseting = false;
      } else {
        if (id) {
          this.sortEnable = false;
        }
        this.updateList = true;
        this.loadPosts();
      }
    },
    currentPage: function(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadPosts();
    },
    sortEnable: function() {},
    filterReseted: function(val) {
      this.sortEnable = val;
    },
    dateFromFilter: function() {
      this.sortEnable = false;
      this.updateList = true;
      this.loadPosts();
    },
    dateToFilter: function() {
      this.sortEnable = false;
      this.updateList = true;
      this.loadPosts();
    },
    currentItemType(currentItemType) {
      this.sortEnable = currentItemType.code === "news";
      this.updateList = true;
      this.loadPosts();
    }
  }
};
</script>

<style scoped>
.news__item-c {
  cursor: pointer;
}

.news__item {
  min-height: 90px;
  height: auto;
}

@media screen and (max-width: 1025px) {
  .news__items:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    opacity: 0;
  }

  .news__item-img {
    z-index: 1;
  }

  .news__item-l {
    z-index: 1;
  }

  .btn-show {
    z-index: 1;
  }

  .news__item-date {
    z-index: 1;
  }
}

.draggable-enter-active,
.draggable-leave-active {
  transition: opacity 0.5s;
}

.draggable-enter,
.draggable-leave-to {
  opacity: 0;
}
</style>
