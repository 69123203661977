<template>
  <CRMLayout>
    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in transparent">
          <order-items />
        </div>
      </div>
      <order-sidebar />
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import OrderItems from "./include/orders/OrderItems";
import OrderSidebar from "./include/orders/OrderSidebar";

export default {
  name: "CRMOrderCreate",
  components: {
    OrderSidebar,
    OrderItems,
    CRMLayout
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "CRMOrder" || this.confirmIfWasEdit()) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  created() {
    this.$store.commit("orderItem/set_page_is_created", true);
  },
  methods: {
    confirmIfWasEdit() {
      if (this.$store.getters["orderItem/wasEdited"]) {
        return confirm("Are you sure you want to leave the page?");
      }

      return true;
    }
  }
};
</script>

<style scoped>
.user__info-edit_in.user {
  display: block !important;
}

.user__info-edit_in.delivery-address {
  display: block !important;
}

.user__info-edit_in.invoice-address {
  display: block !important;
}

@media screen and (max-width: 767px) {
  .order__items .order__items-headline span:nth-child(2),
  .order__items .order__item-name {
    width: 24%;
  }

  .order__items .order__item-frame-number,
  .order__items-headline span:nth-child(4) {
    width: 15%;
  }

  .order__items .order__item-counter {
    width: 11%;
  }

  .order__items .order__items-total_headline {
    margin-left: 37%;
  }

  .order__items .order__items-total_l {
    width: 13%;
  }
}

.order__items {
  /*overflow: hidden;*/
}

.order__item {
  align-items: flex-end;
}

.order__items-in {
  background: #fff;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  .order__items .order__items-headline span:nth-child(2),
  .order__items .order__item-name {
    width: 24%;
  }

  .order__items .order__item-frame-number,
  .order__items-headline span:nth-child(4) {
    width: 15%;
  }

  .order__items .order__item-counter {
    width: 11%;
  }

  .order__items .order__items-total_headline {
    margin-left: 37%;
  }

  .order__items .order__items-total_l {
    width: 13%;
  }

  .comment__items {
    padding-left: 10px;
    padding-right: 10px;
  }

  .comment__item-files_r.indent {
    padding-right: 20px;
  }
}
</style>
