import Build from "../../models/Build";
import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import parseError from "../../helpers/parseError";

const state = {
  build: new Build(),
  categories: [],
  buildCategories: [],
  buildTitles: [],
  list: [],
  titles: [],
  shortTitleList: [],
  filterCategories: [],
  experiences: [],
  wheelSizes: [],
  motorTypes: [],
  bikeTypes: [],
  suspensionTravel: [],
};

const getters = {
  shortTitleList(state) {
    return state.shortTitleList;
  },
  items(state) {
    return state.list;
  },
  buildTitles(state) {
    return state.buildTitles;
  },
  categories(state) {
    return state.buildCategories;
  },
  subCategories(state) {
    let categories = [];
    state.buildCategories.map(item => {
      if (item["sub_categories"].length > 0) {
        for (let sub of item["sub_categories"]) {
          categories.push({
            id: sub.id,
            title: sub.title
          });
        }
      }
      return item;
    });
    return categories;
  }
};
const actions = {
  short({ commit }) {
    return new Promise((resolve, reject) => {
      let url = API_ENDPOINT + `v2/builds/short`;
      axios
        .get(url)
        .then(response => {
          commit("setShortList", response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadTitles({ commit }, search) {
    return new Promise((resolve, reject) => {
      let url = API_ENDPOINT + `v2/builds/titles?title=${search}`;
      axios
        .get(url)
        .then(response => {
          commit("setTitles", response.data.titles);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  index({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = API_ENDPOINT + `v2/builds?page=${data.page || 1}`;
      if (data.parameters) {
        for (let parameter of data.parameters) {
          url += `&${parameter.name}=${parameter.value}`;
        }
      }
      axios
        .get(url)
        .then(response => {
          commit("setBuildList", response.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  changeStock({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = API_ENDPOINT + "v2/builds/" + data.id + "/stock";
      axios
        .post(url, {
          value: data.value,
          type: data.type
        })
        .then(response => {
          commit("setBuildStock", response.data.stock);
          commit("setChangeStock", 0);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  clone(context, build) {
    return new Promise((resolve, reject) => {
      let url = API_ENDPOINT + "v2/builds/" + build.id + "/clone";
      axios
        .post(url)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  save(context, build) {
    return new Promise((resolve, reject) => {
      let url = API_ENDPOINT + "v2/builds";
      let method = "post";
      if (build.id !== null) {
        url += `/${build.id}`;
        method = "put";
      }
      if (build.category.id === 2) {
        build.included_in_delivery = [];
      }
      axios[method](url, { ...build })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  load({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(API_ENDPOINT + "v2/builds" + "/" + id)
        .then(response => {
          commit("setBuild", response.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  helper({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(API_ENDPOINT + "v2/builds" + "/helper")
        .then(response => {
          commit("models/setList", response.data.models, { root: true });
          commit("sizes/setList", response.data.sizes, { root: true });
          commit("brands/setList", response.data.brands, { root: true });
          commit("colors/setList", response.data.colors, { root: true });
          commit("setBuildCategories", response.data["build_categories"]);
          commit("colors/setGroupList", response.data["color_groups"], {
            root: true
          });
          commit("setTitles", response.data["build_titles"]);
          commit("setCategories", response.data["build_types"]);
          commit("setFilterCategories", response.data["filter_categories"]);
          commit("setExperiences", response.data["experiences"]);
          commit("setWheelSizes", response.data["wheel_sizes"]);
          commit("setMotorTypes", response.data["motor_types"]);
          commit("setBikeTypes", response.data["bike_types"]);
          commit("setSuspensionTravel", response.data["suspension_travel"]);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  }
};

const mutations = {
  setShortList(state, titles) {
    state.shortTitleList = titles;
  },
  setBuildList(state, data) {
    state.list = data.data;
  },
  setBuildStock(state, value) {
    state.build.stock = value;
  },
  setNonEuPrice(state, data) {
    let build = state.build;
    build.prices[`${data.prefix}price_for_other`] = data.value;
    state.build = build;
  },
  setAndorraPrice(state, data) {
    let build = state.build;
    build.prices[`${data.prefix}price_for_andorra`] = data.value;
    state.build = build;
  },
  setChangeStock(state, value) {
    state.build.changeStock = value;
  },
  setBuildCategories(state, categories) {
    state.buildCategories = categories;
  },
  filterIncludeInDelivery(state, item) {
    state.build.included_in_delivery = state.build.included_in_delivery.filter(
      section => {
        return section.id !== item.id;
      }
    );
  },
  setBuildCategory(state, category) {
    if (typeof category === "string") {
      state.build.build_category.title = category;
      state.build.build_category.id = null;
    } else {
      state.build.build_category = category;
    }
  },
  setResTaxBase(state, value) {
    state.build.res_tax_base = value;
  },
  setExTaxBase(state, value) {
    state.build.ex_tax_base = value;
  },
  setTaxBase(state, value) {
    state.build.tax_base = value;
  },
  setDeliveryDate(state, date) {
    state.build.delivery_date = date;
  },
  setBuild(state, build) {
    for (let key of Object.keys(build)) {
      if (key === "specs") {
        state.build.tempSpecs = build[key];
      }
      if (key === "cost" || key === "profit") {
        state.build[key] = parseFloat((build[key] / 100).toFixed(2));
        continue;
      }
      if (key === "color" && build[key] !== null) {
        let color = build[key];
        color.price = parseFloat((color.price / 100).toFixed(2));
        state.build[key] = color;
        continue;
      }
      if (key === "prices") {
        let prices = build[key];
        for (let priceKey of Object.keys(prices)) {
          if (priceKey !== "id") {
            prices[priceKey] = parseFloat((prices[priceKey] / 100).toFixed(2));
          }
        }
        state.build[key] = prices;
        continue;
      }
      // eslint-disable-next-line
      if (state.build.hasOwnProperty(key)) {
        state.build[key] = build[key];
      }
    }
  },
  setModel(state, model) {
    if (typeof model === "string") {
      state.build.model.title = model;
    } else {
      state.build.model = { ...model };
    }
  },
  setSize(state, size) {
    state.build.size = size;
  },
  setColor(state, color) {
    state.build.color_id = color.id;
  },
  setColorCustom(state, custom) {
    state.build.color.custom = custom;
  },
  setColorGroup(state, group) {
    if (typeof group === "string") {
      state.build.color.group.title = group;
      state.build.color.group.id = null;
    } else {
      if (group === null) {
        state.build.color.group.id = null;
      } else {
        state.build.color.group = group;
      }
    }
  },
  setBrand(state, brand) {
    state.build.brand = brand;
  },
  addMediaPreview(state, image) {
    if (Array.isArray(image)) {
      image = image[0];
    }
    state.build.media.preview = image;
  },
  addMediaImages(state, images) {
    if (!Array.isArray(images)) {
      images = [images];
    }
    for (let image of images) {
      if (!state.build.media.images.includes(image)) {
        state.build.media.images.push(image);
      }
    }
  },
  setSpecs(state, data) {
    let specObject = {};
    for (let spec of data.specs) {
      let value = {
        title: null,
        description: null,
        highlight: false
      };
      if (data.values !== null) {
        value = data.values.find(item => {
          return item.id === spec.id;
        });
        if (value) {
          specObject[spec.id] = {
            title: value.title,
            description: value.description,
            highlight: value.highlight
          };
        }
      } else {
        specObject[spec.id] = value;
      }
    }
    state.build.specs = specObject;
  },
  setMetaImage(state, image) {
    if (Array.isArray(image)) {
      image = image[0];
    }
    state.build.meta.image = image;
  },
  setColorImage(state, image) {
    if (Array.isArray(image)) {
      image = image[0];
    }
    state.build.color.image = image;
  },
  setTitles(state, titles) {
    state.buildTitles = titles;
  },
  setCategories(state, types) {
    state.categories = types;
  },
  setFilterCategories(state, data) {
    state.filterCategories = data;
  },
  setExperiences(state, data) {
    state.experiences = data;
  },
  setWheelSizes(state, data) {
    state.wheelSizes = data;
  },
  setMotorTypes(state, data) {
    state.motorTypes = data;
  },
  setBikeTypes(state, data) {
    state.bikeTypes = data;
  },
  setSuspensionTravel(state, data) {
    state.suspensionTravel = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
