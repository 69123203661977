// initial state
import InvoiceAddress from "../../models/InvoiceAddress";
import DeliveryAddress from "../../models/DeliveryAddress";
import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import parseError from "../../helpers/parseError";
import hash from "object-hash";

export const DELIVERY_TYPE = "delivery";
export const INVOICE_TYPE = "invoice";
export const ALLOW_TYPE_DELIVERIES = [DELIVERY_TYPE, INVOICE_TYPE];

let lastHash = "";

const state = {
  user_invoice_addresses: [],
  user_delivery_addresses: [],
  invoice_address: new InvoiceAddress(),
  delivery_address: new DeliveryAddress()
};

// getters
const getters = {
  getUserAddress: state => (type, address_id) => {
    let addresses = state[`user_${type}_addresses`];
    if (address_id === "pickup" && type === DELIVERY_TYPE) {
      return addresses.find(item => {
        return item.for_pickup;
      });
    }
    for (let index in addresses) {
      // eslint-disable-next-line
      if (!addresses.hasOwnProperty(index)) {
        continue;
      }

      if (addresses[index].id === address_id) {
        return addresses[index];
      }
    }

    return null;
  },
  isSameAddresses: state => {
    return state.delivery_address.id === state.invoice_address.id;
  },
  getDeliveryCountryId: state => {
    return state.delivery_address.country_id || null;
  },
  getDeliveryCountry: (state, rootState, getters, rootGetters) => {
    let deliveryCountryId = rootGetters["orderAddress/getDeliveryCountryId"];
    return rootGetters["countries/getCountry"](deliveryCountryId);
  },
  getHash: state => {
    let data = Object.assign(
      {},
      {
        delivery: state.delivery_address,
        invoice: state.invoice_address
      }
    );

    return hash(data);
  },
  wasEdited: (state, getters) => {
    return getters["getHash"] !== lastHash;
  }
};

// actions
const actions = {
  loadUserAddresses({ rootState, commit }) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}users/${rootState.orderItem.user.id}/all-addresses`;
      axios
        .get(url)
        .then(response => {
          ALLOW_TYPE_DELIVERIES.map(type => {
            let typeAddresses = response.data.data[type].map(address => {
              let object =
                type === DELIVERY_TYPE
                  ? new DeliveryAddress()
                  : new InvoiceAddress();
              return object.fromResponse(address);
            });
            commit(`set_user_${type}_addresses`, typeAddresses);
          });

          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  changeAddress({ commit, getters, dispatch, state }, data) {
    let isPickup = data.address_id === "pickup";
    if (data.address_id === null) {
      if (data.type === DELIVERY_TYPE) {
        dispatch("clearDeliveryAddress");
      } else {
        dispatch("clearInvoiceAddress");
      }
      return false;
    } else if (isPickup && data.type === DELIVERY_TYPE) {
      data.address_id = state[`user_${data.type}_addresses`].filter(item => {
        return item.for_pickup;
      })[0].id;
    }

    return new Promise((resolve, reject) => {
      try {
        let newAddress = getters["getUserAddress"](data.type, data.address_id);

        if (
          !(newAddress instanceof DeliveryAddress) &&
          !(newAddress instanceof InvoiceAddress)
        ) {
          reject(`Address (${data.type} #${data.address_id}) not found`);
        }

        commit(`set_${data.type}_address`, newAddress);

        if (data.type === DELIVERY_TYPE) {
          commit(`orderItem/set_is_pickup`, isPickup, { root: true });
        }

        if (!!newAddress.id && !state.invoice_address.id) {
          dispatch("equateInvoiceAddressToDeliveryAddress");
        }

        resolve(true);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  },
  clearDeliveryAddress({ commit }) {
    commit("set_delivery_address", new DeliveryAddress());
    commit(`orderItem/set_is_pickup`, false, { root: true });
  },
  clearInvoiceAddress({ commit }) {
    commit("set_invoice_address", new InvoiceAddress());
  },
  clearAddresses({ dispatch }) {
    dispatch("clearDeliveryAddress");
    dispatch("clearInvoiceAddress");
  },
  clearUserAddress({ commit }) {
    ALLOW_TYPE_DELIVERIES.map(type => commit(`set_user_${type}_addresses`, []));
  },
  createAddress({ commit, state, rootState }, type) {
    let url = `${API_ENDPOINT}addresses/manual/${type}`;
    let address = state[`${type}_address`];
    address.user_id = rootState.orderItem.user.id;
    address.order_id = rootState.orderItem.id;
    address.phone = rootState.orderItem.phone;

    return axios.post(url, address).then(response => {
      address.id = response.data.data.id;
      commit(`set_${type}_address`, address);
    });
  },
  updateAddress({ state, rootState }, type) {
    let address = state[`${type}_address`];
    address.user_id = rootState.orderItem.user.id;
    address.phone = rootState.orderItem.phone;
    address.order_id = rootState.orderItem.id;
    let url = `${API_ENDPOINT}addresses/manual/${type}/${address.id}`;

    return axios.post(url, address);
  },
  equateInvoiceAddressToDeliveryAddress({ commit }) {
    let invoiceAddress = new InvoiceAddress();
    let deliveryAddress = state.delivery_address;

    if (!(deliveryAddress instanceof DeliveryAddress)) {
      return false;
    }

    invoiceAddress = invoiceAddress.fromDeliveryAddress(deliveryAddress);

    commit("set_invoice_address", invoiceAddress);
  }
};

// mutations
const mutations = {
  set_user_delivery_addresses(state, addresses) {
    state.user_delivery_addresses = addresses;
  },
  set_user_invoice_addresses(state, addresses) {
    state.user_invoice_addresses = addresses;
  },
  set_delivery_address(state, delivery_address) {
    state.delivery_address = delivery_address;
  },
  set_invoice_address(state, invoice_address) {
    state.invoice_address = invoice_address;
  },
  set_delivery_address_country(state, data) {
    state.delivery_address.country_id = data.id;
    state.delivery_address.country_name = data.name;
  },
  set_invoice_address_country(state, data) {
    state.invoice_address.country_id = data.id;
    state.invoice_address.country_name = data.name;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
