<template>
  <div :class="requiredClass" class="trans-item">
    <div class="btn-burger centered pointer"></div>
    <div class="wrapper">
      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l">
          <span>Text:</span>
        </div>
        <div class="main__content-item_r">
          <div class="inp-group inp-group__xl">
            <input
              :value="value.title"
              title="Text"
              type="text"
              @input="handleInput($event, 'title')"
            />
          </div>
        </div>
      </div>
      <div class="main__content-item">
        <div class="main__content-item_l">
          <span>Image:</span>
        </div>
        <div class="main__content-item_r">
          <dropzone
            id="image_and_text"
            :default-image="value.file"
            :options="dropzoneSettings"
            dict-default-message="Drag&drop or <u>click here</u> to upload images (jpg, png 320x200 up to 5MB)"
            @vdropzone-complete="setImage($event)"
          />
        </div>
      </div>
    </div>
    <button class="field__remove" @click="removeSection"></button>
  </div>
</template>

<script>
import Dropzone from "@/components/forms/inputs/Dropzone";

export default {
  name: "ImageAndText",
  props: ["value"],
  components: { Dropzone },
  computed: {
    requiredClass() {
      return {
        gray: this.value.required === true
      };
    },
    dropzoneSettings() {
      return {
        url: "v2/builds/media",
        uploadMultiple: false,
        autoProcessQueue: true,
        addRemoveLinks: true,
        maxFiles: 1
      };
    }
  },
  methods: {
    handleInput(event, key) {
      let emitted = { ...this.value };
      emitted[key] = event.target.value;
      this.$emit("input", emitted);
    },
    removeSection() {
      this.$emit("onRemove");
    },
    setImage(event) {
      if (event.xhr.response) {
        let response = JSON.parse(event.xhr.response);
        let emitted = { ...this.value };
        emitted.file = response[0];
        this.$emit("input", emitted);
      }
    }
  }
};
</script>

<style scoped></style>
