<template>
  <div class="trans-item">
    <div class="btn-burger centered pointer"></div>
    <div class="wrapper">
      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l">
          <span>Text:</span>
        </div>
        <div class="main__content-item_r">
          <p class="inp-group__text">Securing strap</p>
        </div>
      </div>
      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l">
          <span>Text:</span>
        </div>
        <div class="main__content-item_r">
          <p class="inp-group__text">
            *For bike transportation, found outside the box.
          </p>
        </div>
      </div>
      <div class="main__content-item">
        <div class="main__content-item_l">
          <span>Image:</span>
        </div>
        <div class="main__content-item_r">
          <dropzone
            id="securing_strap"
            :default-image="value.file"
            :options="dropzoneSettings"
            dict-default-message="Drag&drop or <u>click here</u> to upload images (jpg, png 112x112 up to 5MB)"
            @vdropzone-complete="setFile($event)"
          />
        </div>
      </div>
    </div>
    <button class="field__remove" @click="removeSection"></button>
  </div>
</template>

<script>
import Dropzone from "@/components/forms/inputs/Dropzone";

export default {
  name: "SecurityStrap",
  props: ["value"],
  components: { Dropzone },
  computed: {
    dropzoneSettings() {
      return {
        url: "v2/builds/media",
        uploadMultiple: false,
        autoProcessQueue: true,
        addRemoveLinks: true,
        maxFiles: 1
      };
    }
  },
  methods: {
    removeSection() {
      this.$emit("onRemove");
    },
    setFile(event) {
      if (event.xhr.response) {
        let response = JSON.parse(event.xhr.response);
        let emitted = { ...this.value };
        emitted.file = response[0];
        this.$emit("input", emitted);
      }
    }
  }
};
</script>

<style scoped></style>
