import Vue from "vue";
import App from "./App";
import router from "./routes/router";
import store from "./store/store";
import Axios from "axios";
import VueDragTree from "vue-drag-tree";
import "vue-drag-tree/dist/vue-drag-tree.min.css";
import { API_ENDPOINT } from "./utils/api";
import Uploader from "stg-uploader/dist/stguploadercomponent.esm";
import VueJsDialog from "vuejs-dialog";
import Quill from "quill";
import NBSPChanger from "./quill/modules/NBSPChanger";
import VueQuillEditor from "vue-quill-editor";
import VueMask from "v-mask";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "vuejs-dialog/dist/vuejs-dialog.min.css";
import "./assets/fonts/stylesheet.css";
import "./assets/css/lib/jquery.formstyler.css";
import "./assets/css/style.min.css";
import "./assets/css/fix.css";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import vueJquery from "vue-jquery";
import Fragment from "vue-fragment";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VModal from "vue-js-modal";
import vClickOutside from "v-click-outside";
//import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";

//Vue.component("VueFileAgent", require("vue-file-agent").default);

Quill.register("modules/nbsp-changer", NBSPChanger);

const moment = require("moment-timezone");
moment.tz.setDefault("Europe/Madrid");
const currencyFormatter = require("currency-formatter");

import { SlickList, SlickItem } from "vue-slicksort";
Vue.component("vfa-sortable-list", SlickList);
Vue.component("vfa-sortable-item", SlickItem);

Vue.use(VModal);
Vue.use(Fragment.Plugin);
Vue.use(VueDragTree);
Vue.use(vueJquery);
Vue.config.productionTip = false;
Vue.use(Uploader);
Vue.use(VueJsDialog);
Vue.use(VueQuillEditor);
Vue.use(VueMask);
Vue.use(CKEditor);
Vue.use(vClickOutside);
Vue.use(Treeselect);
//Vue.use(VueFileAgent);
Vue.prototype.$http = Axios;

Vue.prototype.$path = API_ENDPOINT;

Vue.prototype.$moment = moment;
Vue.prototype.$crConverter = function currencyFormat(value, code) {
  if (code === undefined) {
    code = "EUR";
  }

  let format = currencyFormatter.format(value, {
    code: code,
    locale: "en-US",
    symbolOnLeft: true,
    thousand: ",",
    spaceBetweenAmountAndSymbol: true,
    precision: 2,
    format: "%s %v"
  });

  if (format.indexOf(".00", format.length - 3) !== -1) {
    format = format.replace(new RegExp(".00$"), "");
  }

  return format;
};
const token = localStorage.getItem("token");

if (token) {
  Vue.prototype.$http.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${token}`;
}

new Vue({
  el: "#app",
  router,
  store,
  components: { App },
  template: "<App/>"
});
