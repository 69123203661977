<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Creating product part</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Product Part Info</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Category:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group__dropdown inp-group__xl">
                    <styler-select
                      :selector-options="categories"
                      @newoptionselected="changeCategory($event)"
                    />
                  </div>
                  <!-- .inp-group__dropdown -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Price</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="part.price" autofocus type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Basic info</h5>
            </div>
            <!-- .main__content-headline -->

            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Name</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="part.name" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Vendor Code</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="part.vendor_code" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Category:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group__dropdown inp-group__xl">
                    <styler-select
                      :selector-options="brands"
                      @newoptionselected="changeBrand($event)"
                    />
                  </div>
                  <!-- .inp-group__dropdown -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Weight, gramm</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="part.weight" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Length, mm</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="part.length" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Width, mm</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="part.width" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Height, mm</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="part.height" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>EAN13</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="part.ean13" type="text" />
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content">
            <div class="main__content-headline">
              <h5>Detail info</h5>
            </div>
            <!-- .main__content-headline -->

            <div class="main__content-in">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.formNamePrefix"
                  :class="isActive(language) ? 'active' : ''"
                >
                  <a @click="switchActiveLanguage(language)">{{
                    language.title
                  }}</a>
                </li>
              </ul>

              <div class="main__content-in">
                <div
                  v-for="language in languages"
                  :key="language.formNamePrefix"
                  v-show="activeLanguage.id === language.id"
                >
                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Tags:</span>
                    </div>
                    <!-- .main__content-item_l -->

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__xl">
                        <input
                          v-model="$data.part[language.formNamePrefix + 'tags']"
                          type="text"
                        />
                      </div>
                      <!-- .inp-group -->
                    </div>
                    <!-- .main__content-item_r -->
                  </div>
                  <!-- .main__content-item -->

                  <div class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Description:</span>
                    </div>
                    <!-- .main__content-item_l -->

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__textarea inp-group__xl">
                        <textarea
                          v-model="$data.part[language.formNamePrefix + 'desc']"
                        ></textarea>
                      </div>
                      <!-- .inp-group -->
                    </div>
                    <!-- .main__content-item_r -->
                  </div>
                  <!-- .main__content-item -->
                </div>
              </div>
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw">
              <span><i>Save part</i></span>
            </button>
          </div>
          <!-- .main__content-btns -->
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import StylerSelect from "./forms/inputs/StylerSelect";

export default {
  name: "CRMProductPartCreate",
  components: { CRMLayout, StylerSelect },
  data() {
    return {
      part: {
        category_id: null,
        price: null,
        name: null,
        vendor_code: null,
        brand_id: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        ean13: null,
        tags: null,
        es_tags: null,
        fr_tags: null,
        de_tags: null,
        ru_tags: null,
        desc: null,
        es_desc: null,
        fr_desc: null,
        de_desc: null,
        ru_desc: null
      },
      categories: [],
      brands: [
        {
          value: "1",
          text: "Brand 1"
        },
        {
          value: "2",
          text: "Brand 2"
        }
      ],
      languages: [
        {
          id: "en",
          title: "EN",
          formNamePrefix: ""
        },
        {
          id: "es",
          title: "ES",
          formNamePrefix: "es_"
        },
        {
          id: "de",
          title: "DE",
          formNamePrefix: "de_"
        },
        {
          id: "fr",
          title: "FR",
          formNamePrefix: "fr_"
        },
        {
          id: "ru",
          title: "RU",
          formNamePrefix: "ru_"
        }
      ],
      activeLanguage: {
        id: "en",
        title: "EN",
        formNamePrefix: ""
      }
    };
  },
  created() {
    this.loadCategories();
    this.loadBrands();
  },
  methods: {
    loadCategories() {
      let ctx = this;
      this.$store
        .dispatch("get_part_categories_request")
        .then(response => {
          if (response.data.length > 0) {
            ctx.categories = response.data.map(category => {
              return {
                value: category.id,
                text: category.en_title
              };
            });
          }
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadBrands() {
      let ctx = this;
      this.$store
        .dispatch("get_part_brands_request")
        .then(response => {
          if (response.data.length > 0) {
            ctx.brands = response.data.map(brand => {
              return {
                value: brand.id,
                text: brand.en_title
              };
            });
          }
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    changeCategory: function(category) {
      this.part.category_id = category;
    },
    changeBrand: function(brand) {
      this.part.brand_id = brand;
    },
    isActive(language) {
      return language.id === this.activeLanguage.id;
    },
    switchActiveLanguage(language) {
      this.activeLanguage = language;
    }
  }
};
</script>
