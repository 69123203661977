<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Editing parts category</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Part category</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Title(EN):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="category.title" autofocus type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Title(ES):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="category.es_title" autofocus type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Title(DE):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="category.de_title" autofocus type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Title(FR):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="category.fr_title" autofocus type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Title(RU):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="category.ru_title" autofocus type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Available as option:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__checkbox">
                    <input
                      id="checkbox-1"
                      v-model="category.as_option"
                      type="checkbox"
                    />
                    <label for="checkbox-1"></label>
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save">
              <span><i>Update category</i></span>
            </button>
          </div>
          <!-- .main__content-btns -->
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";

export default {
  name: "CRMPartCategoriesShow",
  components: { CRMLayout },
  data() {
    return {
      category: {
        title: null,
        id: null,
        es_title: null,
        de_title: null,
        fr_title: null,
        ru_title: null,
        as_option: null
      }
    };
  },
  created() {
    this.loadCategoryData();
  },
  methods: {
    loadCategoryData() {
      let ctx = this;
      this.$store
        .dispatch("get_part_category_request", this.$route.params.id)
        .then(response => {
          this.category.id = response.data.id;
          this.category.title = response.data.en_title;
          this.category.es_title = response.data.es_title;
          this.category.de_title = response.data.de_title;
          this.category.fr_title = response.data.fr_title;
          this.category.ru_title = response.data.ru_title;
          this.category.as_option = response.data.as_option !== 0;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    save() {
      let ctx = this;
      let data = JSON.parse(JSON.stringify(ctx.category));
      let id = data.id;
      delete data.id;
      this.$store
        .dispatch("update_product_part_category_request", {
          data: data,
          id: id
        })
        .then(() => {
          ctx.$router.push("/products/parts/categories/");
          ctx.$root.$emit("modalShow", {
            text: "Category successfully updated",
            type: "info"
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    }
  }
};
</script>
