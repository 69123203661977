<template>
  <!--suppress HtmlFormInputWithoutLabel -->
  <select :class="selectClass">
    <option
      v-for="(option, index) in selectorOptions"
      :key="index"
      :class="getClasses(option)"
      :value="option.value"
    >
      {{ option.text }}
    </option>
  </select>
</template>

<script>
import $ from "jquery";

export default {
  name: "StylerSelect",
  data: function() {
    return {
      selectedOption: "",
      selectClass: "",
      reverseOptions: [],
      setDefaults: false,
      shouldFire: true,
      styles: {}
    };
  },
  props: ["selectorOptions", "selected", "type"],
  methods: {
    destroyStyle() {
      // let self = this;
      return new Promise(resolve => {
        // noinspection JSUnresolvedFunction
        // $("." + self.selectClass).styler("destroy");
        resolve(true);
      });
    },
    trimString(str) {
      return str.replace(/^\s+|\s+$/g, "");
    },
    getClasses(option) {
      // eslint-disable-next-line
      if (option.hasOwnProperty("color")) {
        setTimeout(() => {
          if ($("style:contains('.color-" + option.value + "')").length < 1) {
            $("body").append(
              "<style>.jq-selectbox.color-" +
                option.value +
                ", li.color__item {padding-left: 40px!important;position: relative;}li.color-" +
                option.value +
                ":after,.jq-selectbox.color-" +
                option.value +
                ":after{background:" +
                option.color +
                " !important;position: absolute;content: ' ';left: 10px;top: 50%;-webkit-transform: translate(0,-50%);-ms-transform: translate(0,-50%);transform: translate(0,-50%);width: 20px;height: 20px;-webkit-border-radius: 2px;border-radius: 2px;}</style>"
            );
          }
        }, 0);
        return "color__item color-" + option.value;
      }
      return "";
    }
  },
  mounted() {
    if (this.selectorOptions.length) {
      for (let option of this.selectorOptions) {
        this.reverseOptions[`${option.text}`] = option.value;
      }
    }
    /*if (this.selected) {
      this.selectedOption = this.selected;
    }*/

    this.selectClass =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);

    let ctx = this;
    ctx.$root.$on("setDefaultOptions", function() {
      ctx.shouldFire = false;
      ctx.selectedOption = ctx.selectorOptions[0]
        ? ctx.selectorOptions[0].value
        : null;
      ctx.setDefaults = true;
      $("." + ctx.selectClass).val(ctx.selectorOptions[0].value);
      setTimeout(() => {
        $("." + ctx.selectClass).trigger("refresh");
      }, 1);
    });

    this.$parent.$on("setSelectedOptions", function({ text, value }) {
      ctx.selectedOption = ctx.reverseOptions[text];
      ctx.selectedOption = value;
      ctx.setDefaults = true;
      $("." + ctx.selectClass).val(value);
      setTimeout(() => {
        $("." + ctx.selectClass).trigger("refresh");
      }, 1);
    });

    this.selectedOption = this.selectorOptions[0]
      ? this.selectorOptions[0].value
      : null;
    let self = this;
    this.destroyStyle().then(() => {
      setTimeout(() => {
        // noinspection JSUnresolvedFunction
        $("." + this.selectClass).styler({
          onSelectClosed: function() {
            let text = self.trimString(
              $(this)
                .find(".jq-selectbox__select-text")
                .text()
            );
            self.selectedOption = self.reverseOptions[`${text}`];
          }
        });
      }, 0);
    });
  },
  watch: {
    selectorOptions: function(options) {
      for (let option of this.selectorOptions) {
        this.reverseOptions[`${option.text}`] = option.value;
      }

      this.selectedOption = options[0].value;
      let self = this;
      this.destroyStyle().then(() => {
        // noinspection JSUnresolvedFunction
        $("." + this.selectClass).styler({
          onSelectClosed: function() {
            let text = self.trimString(
              $(this)
                .find(".jq-selectbox__select-text")
                .text()
            );
            self.selectedOption = self.reverseOptions[`${text}`];
          }
        });
      });
    },
    selected: function(val) {
      if (val) {
        this.selectedOption = this.selectorOptions[val];
      }
    },
    selectedOption: {
      handler: function() {
        if (this.setDefaults) {
          this.setDefaults = false;
        } else {
          if (this.shouldFire) {
            if (typeof this.selectedOption === "object") {
              if (this.selectedOption) {
                this.$emit("newoptionselected", this.selectedOption.value);
              } else {
                this.$emit("newoptionselected", this.selectedOption);
              }
            } else {
              this.$emit("newoptionselected", this.selectedOption);
            }
          } else {
            this.shouldFire = true;
          }
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
