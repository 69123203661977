<template>
  <div>
    <div class="repair">
      <div v-if="hasRepair" class="repair__content">
        <div class="repair__content_title">
          {{ currentRepairTitle }}
        </div>
        <div class="repair__content_status_line">
          <div class="padding-wrapper">
            <div
              v-for="(status, index) in statuses"
              :key="statusKey(index)"
              :class="statusClass(status)"
              :style="statusItemStyle"
              class="status-item"
            >
              <div class="line-bg"></div>
              <div class="status-info-wrapper">
                <div class="title">{{ status.title }}</div>
                <div class="date">{{ status.date }}</div>
                <div class="time">{{ status.time }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="repair__content_items">
          <table class="table">
            <thead>
              <tr>
                <th class="first text-left align-middle">Item</th>
                <th class="text-center align-middle">Price</th>
                <th class="text-right align-middle">Quantity</th>
                <th class="last text-right align-middle">Total</th>
              </tr>
            </thead>
            <tbody v-if="repairHasItems">
              <tr v-for="(item, index) in repair.items" :key="`item-${index}`">
                <td class="first text-left align-middle">{{ item.title }}</td>
                <td class="text-center align-middle">
                  {{ $crConverter(item.price) }}
                </td>
                <td class="text-right align-middle">{{ item.qty }}</td>
                <td class="last text-right align-middle">
                  {{ $crConverter(itemTotal(item)) }}
                  <button
                    v-if="showRemoveItemBtn"
                    class="remove-item"
                    @click="removeItem(item)"
                  >
                    <svg
                      fill="none"
                      height="13"
                      viewBox="0 0 13 13"
                      width="13"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.94455 6.50087H4.05566"
                        stroke="#BF0000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="0.9"
                      />
                      <path
                        clip-rule="evenodd"
                        d="M6.5 12V12C3.46217 12 1 9.53783 1 6.5V6.5C1 3.46278 3.46217 1 6.5 1C9.53783 1 12 3.46278 12 6.5V6.5C12 9.53783 9.53783 12 6.5 12Z"
                        fill-rule="evenodd"
                        stroke="#BF0000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="0.9"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!repairHasItems && showAddService" class="empty-items">
            <h3>Please add the required service items</h3>
          </div>
          <div v-if="showAddingForm" class="add-repair-item-form">
            <input
              v-model="newItem.title"
              class="repair-item-input item"
              type="text"
            />
            <input
              v-model="newItem.price"
              class="repair-item-input price"
              type="text"
            />
            <input
              v-model="newItem.qty"
              class="repair-item-input qty"
              type="text"
            />
            <button class="repair-item-btn-add" @click="addItem">Add</button>
          </div>
          <button
            v-if="showAddService"
            class="add-repair-item-btn"
            @click="toggleAddingForm"
          >
            <span v-if="showAddingForm">Cancel</span>
            <span>Add service item</span>
          </button>
        </div>
        <div class="repair__content_payments">
          <div class="invoices">
            <div v-if="!hasInvoice" class="empty-invoices">
              The pro-forma will be generated on Service completed status
            </div>
            <div v-if="hasInvoice" class="main-invoice">
              Pro forma #
              <span class="invoice-link" @click.prevent="showMainInvoice">{{
                invoiceNumber
              }}</span>
            </div>
            <div v-if="hasInvoice" class="payments">
              <div
                v-for="payment in payments"
                :key="`payment-${payment.id}`"
                class="payments__item"
              >
                <div class="payments__item-header">
                  <div class="price">{{ $crConverter(payment.amount) }}</div>
                  <div class="date">{{ payment.created_at }}</div>
                </div>
                <div class="payments__item-title">Generated pro forma</div>
                <div class="payments__item-invoice">
                  Pro forma: <a href="">{{ payment["invoice_number"] }}</a>
                </div>
                <div class="payments__item-actions">
                  <div
                    class="action not-paid"
                    v-if="payment.status === 'pending'"
                  >
                    <span>&times;</span> Not paid
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info">
            <div class="part subtotal">
              <div class="title">
                <span class="uppercase">Subtotal</span> {{ countItems }} items
              </div>
              <div class="price">{{ $crConverter(subTotal) }}</div>
            </div>
            <div class="part discount">
              <div class="type">
                <div class="discount-group">
                  <div class="inp-group__in">
                    <v-select
                      v-model="discountType"
                      :clearable="false"
                      :disabled="discountDisabled"
                      :options="discountTypes"
                      :searchable="false"
                      label="label"
                      name="discount_type"
                    />
                  </div>
                </div>
              </div>
              <div class="value">
                <div class="inp-group">
                  <input
                    v-model="discountValue"
                    :disabled="discountDisabled"
                    :max="maxDiscountValue"
                    class="align-right"
                    min="0"
                    type="number"
                  />
                </div>
              </div>
              <div class="apply">
                <button :disabled="discountDisabled" @click.prevent="save">
                  Apply
                </button>
              </div>
              <div class="price">
                <span>{{ $crConverter(discountPriceValue) }}</span>
              </div>
            </div>
            <div class="divider"></div>
            <div class="part tax-base">
              <div class="title">Tax base</div>
              <div class="price">{{ $crConverter(taxBase) }}</div>
            </div>
            <div class="part taxes">
              <div class="title">
                <span>Taxes</span>
              </div>
              <div class="price">
                <div>{{ $crConverter(taxValue) }}</div>
                <div>{{ taxTitle }}</div>
              </div>
            </div>
            <div class="divider"></div>
            <div class="part total">
              <div class="title">
                <span class="uppercase">Total</span> {{ countItems }} items
              </div>
              <div class="price">{{ $crConverter(total) }}</div>
            </div>
            <div class="divider"></div>
            <div class="part paid">
              <div class="title">
                <span>Total paid</span>
              </div>
              <div class="price">
                <div>{{ $crConverter(paid) }}</div>
              </div>
            </div>
            <div class="part remaining">
              <div class="title">
                Remaining
              </div>
              <div class="price">{{ $crConverter(remaining) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hasRepair" class="repair__sidebar">
        <div class="repair__sidebar-title">Current repair status</div>
        <div class="repair__sidebar-status">
          {{ currentStatusHuman }}
        </div>

        <div v-if="showNextStep" class="next-step">
          <div class="next-step-title">Next step</div>
          <div class="buttons">
            <button
              v-if="showNextStepBtn('collection_service_date_confirmed')"
              :class="stepClass('collection_service_date_confirmed')"
              class="next-step-btn"
              @click.prevent="toggleStep('collection_service_date_confirmed')"
            >
              Collection service date confirmed
            </button>
            <button
              v-if="showNextStepBtn('collection_confirmed')"
              :class="stepClass('collection_confirmed')"
              class="next-step-btn"
              @click.prevent="toggleStep('collection_confirmed')"
            >
              Collection confirmed
            </button>
            <button
              v-if="showNextStepBtn('delivered_to_service')"
              :class="stepClass('delivered_to_service')"
              class="next-step-btn"
              @click.prevent="toggleStep('delivered_to_service')"
            >
              Delivered at Service Centre
            </button>
            <button
              v-if="showNextStepBtn('checking')"
              :class="stepClass('checking')"
              class="next-step-btn"
              @click.prevent="toggleStep('checking')"
            >
              Checking
            </button>
            <button
              v-if="showNextStepBtn('warranty_approved')"
              :class="stepClass('warranty_approved')"
              class="next-step-btn"
              @click.prevent="toggleStep('warranty_approved')"
            >
              Warranty approved
            </button>
            <button
              v-if="showNextStepBtn('not_warranty_case')"
              :class="stepClass('not_warranty_case')"
              class="next-step-btn"
              @click.prevent="toggleStep('not_warranty_case')"
            >
              Not Warranty case
            </button>
            <button
              v-if="showNextStepBtn('service_completed')"
              :class="stepClass('service_completed')"
              class="next-step-btn"
              @click.prevent="toggleStep('service_completed')"
            >
              Service completed
            </button>
            <button
              v-if="showNextStepBtn('shipped')"
              :class="stepClass('shipped')"
              class="next-step-btn"
              @click.prevent="toggleStep('shipped')"
            >
              Shipped
            </button>
            <button
              v-if="showNextStepBtn('delivered')"
              :class="stepClass('delivered')"
              class="next-step-btn"
              @click.prevent="toggleStep('delivered')"
            >
              Delivered
            </button>
            <button
              v-if="showNextStepBtn('rewind_confirmed')"
              :class="stepClass('rewind_confirmed')"
              class="next-step-btn"
              @click.prevent="toggleStep('rewind_confirmed')"
            >
              Rewind programme confirmed
            </button>
            <button
              v-if="showNextStepBtn('rewind_declined')"
              :class="stepClass('rewind_declined')"
              class="next-step-btn"
              @click.prevent="toggleStep('rewind_declined')"
            >
              Rewind programme declined
            </button>
          </div>
        </div>
        <div v-if="showUploadFormDocument" class="next-step">
          <div class="next-step-title">Confirmation document</div>
          <div class="buttons">
            <input
              ref="confirmationDocumentInput"
              name="confirmation-document"
              style="display: none"
              type="file"
              @change="uploadConfirmationDocument"
            />
            <button
              v-if="!repairHasConfirmationDocument"
              class="next-step-btn"
              @click.prevent="showUploadConfirmationDialog"
            >
              Upload
            </button>
            <a
              v-if="repairHasConfirmationDocument"
              :href="confirmationDocument"
              class="confirmation-document-link"
              download="download"
              target="_blank"
            >
              {{ confirmationDocumentFileName }}
            </a>
          </div>
        </div>
        <div v-if="showStepBack" class="next-step">
          <div class="next-step-title">Or step back</div>
          <div class="buttons">
            <button class="next-step-btn" @click.prevent="stepBackTo()">
              {{ prevStatus }}
            </button>
          </div>
        </div>
        <div
          v-if="!hasServiceCenter"
          class="select-form service-center-type-form"
        >
          <label>Select service centre type</label>
          <v-select
            :options="serviceTypes"
            label="title"
            placeholder="Please select"
          />
        </div>
        <div class="select-form service-center-form">
          <label v-if="!hasServiceCenter">
            Select service centre
            <span>view all</span>
          </label>
          <label v-if="hasServiceCenter">
            Service centre
            <span v-if="canEditServiceCenter">edit</span>
          </label>
          <v-select
            v-if="!hasServiceCenter"
            v-model="serviceCenter"
            :clearable="false"
            :options="serviceCenters"
            :searchable="true"
            label="label"
            placeholder="Please select"
          />
          <div v-if="hasServiceCenter" class="service-center-static">
            <div
              v-if="repairServiceCenter['title']"
              class="service-center-info title"
            >
              {{ repairServiceCenter["title"] }}
            </div>
            <div v-if="repairServiceCenter['address']">
              <div
                v-if="repairServiceCenter['address']['street']"
                class="service-center-info street"
              >
                {{ repairServiceCenter["address"]["street"] }}
              </div>
              <div
                v-if="repairServiceCenter['address']['city']"
                class="service-center-info city"
              >
                <span v-if="repairServiceCenter['address']['zip']">
                  {{ repairServiceCenter["address"]["zip"] }}
                </span>
                {{ repairServiceCenter["address"]["city"] }}
              </div>
              <div
                v-if="repairServiceCenter['address']['state']"
                class="service-center-info state"
              >
                {{ repairServiceCenter["address"]["state"] }}
              </div>
              <div
                v-if="repairServiceCenter['address']['country']"
                class="service-center-info country"
              >
                {{ repairServiceCenter["address"]["country"] }}
              </div>
            </div>
            <div
              v-if="repairServiceCenter['phone']"
              class="service-center-info phone"
            >
              {{ repairServiceCenter["phone"] }}
            </div>
          </div>
        </div>
        <div class="select-form delivery-address-form">
          <label>
            Delivery address
            <span v-if="repairHasAddress" @click="editDeliveryAddress">
              <span v-if="canEditAddress">edit</span>
            </span>
          </label>
          <div v-if="!addressEditing" class="delivery-address-static">
            <div v-if="deliveryAddress['street']" class="address street">
              {{ deliveryAddress["street"] }}
              <span v-if="deliveryAddress['flat']" class="address flat">
                {{ deliveryAddress["flat"] }}
              </span>
            </div>
            <div v-if="deliveryAddress['city']" class="address city">
              {{ deliveryAddress["city"] }}
            </div>
            <div v-if="deliveryAddress['state']" class="address state">
              {{ deliveryAddress["state"] }}
            </div>
            <div v-if="deliveryAddress['zip']" class="address zip">
              {{ deliveryAddress["zip"] }}
            </div>
            <div v-if="deliveryAddress['country']" class="address country">
              {{ deliveryAddress["country"] }}
            </div>
          </div>
          <div v-if="addressEditing" class="delivery-address-edit-form">
            <div class="form-group">
              <label for="street">Street name and number</label>
              <input
                id="street"
                v-model="editingAddress.street"
                name="street"
                type="text"
              />
            </div>
            <div class="form-group">
              <label for="flat">Flat</label>
              <input
                id="flat"
                v-model="editingAddress.flat"
                name="flat"
                type="text"
              />
            </div>
            <div class="form-group">
              <label for="zip">Zip code</label>
              <input
                id="zip"
                v-model="editingAddress.zip"
                name="zip"
                type="text"
              />
            </div>
            <div class="form-group">
              <!--suppress XmlInvalidId -->
              <label for="country">Country</label>
              <v-select
                id="country"
                v-model="editingAddress.country"
                :options="countries"
                label="label"
                name="country"
              />
            </div>
            <div class="form-group state">
              <label for="state">Province / State</label>
              <input
                id="state"
                v-model="editingAddress.state"
                name="state"
                type="text"
              />
            </div>
            <div class="form-group">
              <label for="city">City</label>
              <input
                id="city"
                v-model="editingAddress.city"
                name="city"
                type="text"
              />
            </div>
            <div class="form-group">
              <button
                class="btn btn-gray"
                @click.prevent="cancelEditingAddress"
              >
                Cancel
              </button>
              <button class="btn btn-green" @click.prevent="saveAddress">
                Save
              </button>
            </div>
          </div>
        </div>
        <div v-if="showStatusActionBTN" class="actions">
          <button
            :disabled="isConfirmDisabled"
            class="confirm-status-btn"
            @click.prevent="confirmStep(false)"
          >
            {{ confirmStatusText }}
          </button>
        </div>
      </div>

      <div v-if="!hasRepair" class="repair__creating-message">
        <h3>Everything is OK, no repair tickets here</h3>
        <button @click="createEmptyRepair">Create repair ticket</button>
      </div>
    </div>
    <div v-if="showHistory" class="archive">
      <p class="archive-title">History</p>
      <div class="archive-list">
        <div
          v-for="repair in archive"
          :key="`repair-${repair.id}`"
          :class="archiveRepairClass(repair)"
          class="archive-list__item"
          @click.prevent="showRepair(repair)"
        >
          <p class="text">warranty {{ repair.id }}</p>
          <p class="data">{{ repairCompletedAt(repair) }}</p>
          <p class="price">{{ $crConverter(repairPrice(repair)) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  name: "Repairs",
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      collection_service_date_confirmed: false,
      collection_confirmed: false,
      delivered_to_service: false,
      checking: false,
      not_warranty_case: false,
      warranty_approved: false,
      rewind_confirmed: false,
      rewind_declined: false,
      service_completed: false,
      shipped: false,
      delivered: false,
      serviceCenter: null,
      adding: false,
      addressEditing: false,
      editingAddress: null,
      newItem: {
        title: null,
        price: null,
        qty: 1
      },
      selectedServiceType: null,
      serviceTypes: [
        {
          code: "ftc",
          title: "FTC"
        },
        {
          code: "distributor",
          title: "Dealer/Distro"
        },
        {
          code: "service",
          title: "Service"
        }
      ]
    };
  },
  mounted() {
    this.loadServiceCenters();
    this.loadCountries();
    this.loadLastRepair();
    this.loadAllRepairs();
  },
  computed: {
    ...mapGetters({
      repair: "repairs/repair",
      countries: "countries/forSelect",
      serviceCenters: "serviceCenters/forSelect",
      serviceCentersFull: "serviceCenters/list",
      archive: "repairs/list",
      repairForShow: "repairs/forShow"
    }),
    payments() {
      if (this.hasRepair) {
        return this.repair.payments;
      }

      return [];
    },
    showHistory() {
      return this.archive.length > 0;
    },
    discountType: {
      get() {
        if (this.hasRepair) {
          return this.repair.discountType;
        }

        return null;
      },
      set(value) {
        this.$store.commit("repairs/setDiscountType", value);
      }
    },
    maxDiscountValue() {
      if (this.discountType && this.discountType.code === "percent") {
        return 50;
      } else if (this.discountType && this.discountType.code === "value") {
        return this.subTotal / 2;
      }

      return 0;
    },
    discountPriceValue() {
      if (this.hasRepair && this.discountType) {
        if (this.discountType.code === "percent") {
          return this.subTotal * (this.discountValue / 100);
        } else if (this.discountType.code === "value") {
          return this.discountValue;
        }
      }
      return 0;
    },
    discountDisabled() {
      let availableStatus = [
        "created",
        "collection_service_date_confirmed",
        "collection_confirmed",
        "delivered_to_service",
        "checking",
        "warranty_approved",
        "not_warranty_case",
        "rewind_confirmed",
        "rewind_declined"
      ];
      return (
        !this.hasRepair ||
        this.subTotal === 0 ||
        !availableStatus.includes(this.currentStatus)
      );
    },
    discountValue: {
      get() {
        if (this.hasRepair) {
          return this.repair.discountValue;
        }

        return null;
      },
      set(value) {
        this.$store.commit("repairs/setDiscountValue", value);
      }
    },
    discountTypes() {
      return [
        {
          code: "percent",
          label: "Discount, %"
        },
        {
          code: "value",
          label: "Discount, €"
        }
      ];
    },
    invoiceNumber() {
      if (this.hasRepair) {
        return this.repair.invoiceNumber;
      }

      return null;
    },
    hasInvoice() {
      return this.hasRepair && this.repair.invoiceNumber !== null;
    },
    countItems() {
      return this.repair.items.length;
    },
    paid() {
      let paid = 0;
      for (let payment of this.repair.payments) {
        paid += payment.amount;
      }

      return paid;
    },
    remaining() {
      return this.total - this.paid / 100;
    },
    total() {
      if (this.subTotal) {
        return this.subTotal - this.discountPriceValue;
      }

      return 0;
    },
    subTotal() {
      let total = 0;
      this.repair.items.map(item => {
        total += item.price * item.qty;
      });

      let tax = this.repair.address["tax"]["percent"];

      return total + (total / 100) * tax;
    },
    taxValue() {
      return this.subTotal - this.taxBase;
    },
    taxTitle() {
      return `${this.repair.address["tax"].code} ${this.repair.address["tax"]["percent"]}%`;
    },
    taxBase() {
      let total = 0;
      this.repair.items.map(item => {
        total += item.price * item.qty;
      });

      return total;
    },
    confirmStatusText() {
      if (this.currentStatus === "shipped") {
        return "Close 🖤";
      }

      return "Confirm";
    },
    showStatusActionBTN() {
      return this.currentStatus !== "delivered";
    },
    canEditServiceCenter() {
      let availableStatuses = ["created", "collection_service_date_confirmed"];
      return availableStatuses.includes(this.currentStatus);
    },
    canEditAddress() {
      let availableStatuses = [
        "created",
        "collection_service_date_confirmed",
        "collection_confirmed",
        "delivered_to_service",
        "checking",
        "warranty_approved",
        "rewind_confirmed",
        "rewind_declined",
        "not_warranty_case"
      ];
      return (
        !this.addressEditing && availableStatuses.includes(this.currentStatus)
      );
    },
    confirmationDocument() {
      if (this.repairHasConfirmationDocument) {
        return this.repair.confirmationDocument;
      }
      return null;
    },
    confirmationDocumentFileName() {
      if (this.repairHasConfirmationDocument) {
        let url = this.repair.confirmationDocument;
        return url.substring(url.lastIndexOf("/") + 1);
      }
      return null;
    },
    repairHasConfirmationDocument() {
      return this.hasRepair && this.repair.confirmationDocument !== null;
    },
    showStepBack() {
      return this.currentStatus === "service_completed";
    },
    prevStatus() {
      let currentStatusIndex = this.repair.history.findIndex(item => {
        return item["title_src"] === this.currentStatus;
      });

      if (currentStatusIndex !== -1) {
        return this.repair.history[currentStatusIndex - 1].title;
      }

      return null;
    },
    prevStatusSrc() {
      let currentStatusIndex = this.repair.history.findIndex(item => {
        return item["title_src"] === this.currentStatus;
      });

      if (currentStatusIndex !== -1) {
        return this.repair.history[currentStatusIndex - 1]["title_src"];
      }

      return null;
    },
    showUploadFormDocument() {
      return (
        this.currentStatus === "rewind_confirmed" ||
        (this.currentStatus === "shipped" && this.repairHasConfirmationDocument)
      );
    },
    showNextStep() {
      switch (this.currentStatus) {
        case "created":
          return this.serviceCenterSelected;
        case "collection_service_date_confirmed":
          return this.currentStatus === "collection_service_date_confirmed";
        case "collection_confirmed":
          return this.currentStatus === "collection_confirmed";
        case "delivered_to_service":
          return this.currentStatus === "delivered_to_service";
        case "checking":
          return this.currentStatus === "checking";
        case "warranty_approved":
          return this.currentStatus === "warranty_approved";
        case "service_completed":
          return this.currentStatus === "service_completed";
        case "shipped":
          return this.currentStatus === "shipped";
        case "not_warranty_case":
          return this.currentStatus === "not_warranty_case";
        case "rewind_confirmed":
          return this.currentStatus === "rewind_confirmed";
        case "rewind_declined":
          return this.currentStatus === "rewind_declined";
      }

      return false;
    },
    isConfirmDisabled() {
      switch (this.currentStatus) {
        case "created":
          return !this.isStepSelected("collection_service_date_confirmed");
        case "collection_service_date_confirmed":
          return !this.isStepSelected("collection_confirmed");
        case "collection_confirmed":
          return !this.isStepSelected("delivered_to_service");
        case "delivered_to_service":
          return !this.isStepSelected("checking");
        case "checking":
          return (
            !this.isStepSelected("not_warranty_case") &&
            !this.isStepSelected("warranty_approved")
          );
        case "warranty_approved":
          return !this.isStepSelected("service_completed");
        case "not_warranty_case":
          return (
            !this.isStepSelected("rewind_confirmed") &&
            !this.isStepSelected("rewind_declined")
          );
        case "rewind_confirmed":
          return (
            !this.isStepSelected("service_completed") ||
            !this.repairHasConfirmationDocument
          );
        case "service_completed":
          return !this.isStepSelected("shipped");
        case "shipped":
          return !this.isStepSelected("delivered");
      }

      return true;
    },
    serviceCenterSelected() {
      return this.hasServiceCenter || this.serviceCenter !== null;
    },
    hasServiceCenter() {
      return this.hasRepair && this.repair.serviceCenter !== null;
    },
    currentStatus() {
      if (this.hasRepair) {
        return this.repair.status;
      }

      return null;
    },
    currentStatusHuman() {
      if (this.hasRepair) {
        let status = this.repair.history.find(item => {
          return item.active;
        });

        if (status) {
          return status.title;
        }
      }

      return null;
    },
    repairHasAddress() {
      return this.hasRepair && this.repair.address !== null;
    },
    repairServiceCenter() {
      return this.repair.serviceCenter;
    },
    deliveryAddress() {
      return this.repair.address;
    },
    showAddingForm() {
      return this.adding;
    },
    showAddService() {
      let availableStatuses = [
        "created",
        "collection_service_date_confirmed",
        "collection_confirmed",
        "delivered_to_service",
        "checking",
        "warranty_approved",
        "rewind_confirmed",
        "rewind_declined",
        "not_warranty_case"
      ];

      return availableStatuses.includes(this.currentStatus);
    },
    showRemoveItemBtn() {
      return this.showAddService;
    },
    statuses() {
      if (this.repair.id) {
        return this.repair.history;
      }

      return [];
    },
    bikeID() {
      return this.$route.params.id;
    },
    hasRepair() {
      return this.repair !== null;
    },
    repairHasItems() {
      return this.hasRepair && this.repair.items.length > 0;
    },
    currentRepairTitle() {
      if (this.repair.id) {
        return `Repair ${this.repair.id} info`;
      }

      return null;
    },
    statusItemStyle() {
      return {
        width: `${100 / (this.statuses.length - 1)}%`
      };
    }
  },
  methods: {
    paymentDate(payment) {
      return this.$moment.unix(payment.created_at).format("DD.MM.YYYY HH:mm");
    },
    showRepair(repair) {
      if (this.repairForShow === repair.id) {
        this.$store.commit("repairs/setRepairForShow", null);
      } else {
        this.$store.commit("repairs/setRepairForShow", repair.id);
      }
      setTimeout(() => {
        this.loadLastRepair();
      }, 1);
    },
    archiveRepairClass(repair) {
      return {
        active: repair.id === this.repairForShow
      };
    },
    repairCompletedAt(repair) {
      return this.$moment.unix(repair["completed_at"]).format("DD.MM.YYYY");
    },
    repairPrice(repair) {
      return repair.price;
    },
    save() {
      this.$store.dispatch("repairs/save").then(() => {
        this.$root.$emit("modalShow", {
          text: "Discount apply and repair saved",
          type: "info"
        });
      });
    },
    showMainInvoice() {
      let url = `${process.env.VUE_APP_API_BASE_PATH}/api/v2/repairs/${this.repair.id}/invoice`;

      url += `?access_token=${localStorage.getItem("token")}`;

      window.open(url, "_blank");
    },
    stepBackTo() {
      this.$dialog
        .confirm(`Are you sure you want to step back to "${this.prevStatus}"?`)
        .then(() => {
          this.confirmStep(true);
        })
        .catch(() => {});
    },
    uploadConfirmationDocument(event) {
      if (event.target.files[0]) {
        this.$store
          .dispatch("repairs/uploadConfirmation", event.target.files[0])
          .then(() => {
            this.$root.$emit("modalShow", {
              text: "Confirmation document uploaded",
              type: "info"
            });
            this.loadLastRepair();
          });
      }
    },
    showUploadConfirmationDialog() {
      this.$refs.confirmationDocumentInput.click();
    },
    showNextStepBtn(status) {
      switch (status) {
        case "collection_service_date_confirmed":
          return this.serviceCenterSelected && this.currentStatus === "created";
        case "collection_confirmed":
          return this.currentStatus === "collection_service_date_confirmed";
        case "delivered_to_service":
          return this.currentStatus === "collection_confirmed";
        case "checking":
          return this.currentStatus === "delivered_to_service";
        case "warranty_approved":
        case "not_warranty_case":
          return this.currentStatus === "checking";
        case "service_completed":
          return (
            this.currentStatus === "warranty_approved" ||
            this.currentStatus === "rewind_confirmed" ||
            this.currentStatus === "rewind_declined"
          );
        case "shipped":
          return this.currentStatus === "service_completed";
        case "delivered":
          return this.currentStatus === "shipped";
        case "rewind_confirmed":
        case "rewind_declined":
          return this.currentStatus === "not_warranty_case";
      }
    },
    toggleStep(step) {
      this.$set(this.$data, step, !this[step]);
    },
    isStepSelected(step) {
      return this[step];
    },
    stepClass(step) {
      return {
        selected: this.isStepSelected(step)
      };
    },
    changeStatus(stepBack = false) {
      if (this.currentStatus === "service_completed" && this.remaining > 0) {
        if (this.prevStatusSrc === "rewind_confirmed") {
          this.$root.$emit("modalShow", {
            text:
              "You can't change status because client don't paid for invoice",
            type: "error"
          });

          return;
        }
      } else if (
        (this.currentStatus === "rewind_confirmed" ||
          this.currentStatus === "warranty_approved" ||
          this.currentStatus === "rewind_declined") &&
        this.repair.items.length === 0
      ) {
        this.$root.$emit("modalShow", {
          text: "Please add service items before complete service",
          type: "error"
        });

        return;
      }
      switch (this.currentStatus) {
        case "created":
          this.$store
            .dispatch("repairs/saveServiceCenter", this.serviceCenter.code)
            .then(() => {
              this.showConfirmStepMessage();
              this.loadLastRepair();
            });
          break;
        case "collection_service_date_confirmed":
          this.$store
            .dispatch("repairs/changeStatus", {
              status: "collection_service_date_confirmed",
              next: null
            })
            .then(() => {
              this.showConfirmStepMessage();
              this.loadLastRepair();
            });
          break;
        case "collection_confirmed":
          this.$store
            .dispatch("repairs/changeStatus", {
              status: "collection_confirmed",
              next: null
            })
            .then(() => {
              this.showConfirmStepMessage();
              this.loadLastRepair();
            });
          break;
        case "delivered_to_service":
          this.$store
            .dispatch("repairs/changeStatus", {
              status: "delivered_to_service",
              next: null
            })
            .then(() => {
              this.showConfirmStepMessage();
              this.loadLastRepair();
            });
          break;
        case "checking":
          this.$store
            .dispatch("repairs/changeStatus", {
              status: "checking",
              next: this.warranty_approved
                ? "warranty_approved"
                : "not_warranty_case"
            })
            .then(() => {
              this.showConfirmStepMessage();
              this.loadLastRepair();
            });
          break;
        case "warranty_approved":
          this.$store
            .dispatch("repairs/changeStatus", {
              status: "warranty_approved",
              next: null
            })
            .then(() => {
              this.showConfirmStepMessage();
              this.loadLastRepair();
            });
          break;
        case "not_warranty_case":
          this.$store
            .dispatch("repairs/changeStatus", {
              status: "not_warranty_case",
              next: this.rewind_confirmed
                ? "rewind_confirmed"
                : "rewind_declined"
            })
            .then(() => {
              this.showConfirmStepMessage();
              this.loadLastRepair();
            });
          break;
        case "rewind_confirmed":
          this.$store
            .dispatch("repairs/changeStatus", {
              status: "rewind_confirmed",
              next: null
            })
            .then(() => {
              this.showConfirmStepMessage();
              this.loadLastRepair();
            });
          break;
        case "service_completed":
          this.$store
            .dispatch("repairs/changeStatus", {
              status: "service_completed",
              next: stepBack ? this.prevStatusSrc : null
            })
            .then(() => {
              this.showConfirmStepMessage();
              this.loadLastRepair();
            });
          break;
        case "shipped":
          this.$store
            .dispatch("repairs/changeStatus", {
              status: "shipped",
              next: null
            })
            .then(() => {
              this.showConfirmStepMessage();
              this.loadLastRepair();
            });
          break;
      }
    },
    confirmStep(stepBack = false) {
      let notSavedItemsExist =
        this.repair.items.filter(item => {
          return !item.id;
        }).length > 0;
      if (notSavedItemsExist) {
        this.$dialog
          .confirm(
            "There are unsaved service items exists. Are you sure you want to continue? Items will be removed."
          )
          .then(() => {
            this.changeStatus(stepBack);
          });
      } else {
        this.changeStatus(stepBack);
      }
    },
    showConfirmStepMessage() {
      this.$root.$emit("modalShow", {
        text: "Status confirmed",
        type: "info"
      });
    },
    loadServiceCenters() {
      this.$store.dispatch("serviceCenters/load");
    },
    loadCountries() {
      this.$store.dispatch("countries/load");
    },
    saveAddress() {
      let requiredFields = ["country", "city", "street", "zip"];
      let hasErrors = false;
      for (let field of requiredFields) {
        if (!this.editingAddress[field]) {
          hasErrors = true;
          break;
        }
      }

      if (hasErrors) {
        this.$root.$emit("modalShow", {
          text: "Fill all required fields for address please",
          type: "error"
        });
      } else {
        this.$store
          .dispatch("repairs/updateDeliveryAddress", this.editingAddress)
          .then(() => {
            this.cancelEditingAddress();
            this.$root.$emit("modalShow", {
              text:
                "Address saved. Don't remember save Repair via button at the bottom",
              type: "info"
            });
          });
      }
    },
    cancelEditingAddress() {
      this.addressEditing = false;
      this.editingAddress = null;
    },
    editDeliveryAddress() {
      let editingAddress = { ...this.repair.address };
      let country = this.countries.find(item => {
        return item.label === editingAddress.country;
      });

      if (country) {
        editingAddress.country = country;
      }
      this.editingAddress = { ...editingAddress };
      this.addressEditing = true;
    },
    removeItem(item) {
      this.$dialog
        .confirm("Are you sure you want to remove this repair item?")
        .then(() => {
          this.$store.commit("repairs/removeRepairItem", item);
        });
    },
    itemTotal(item) {
      return item.qty * item.price;
    },
    addItem() {
      if (!this.newItem.title || !this.newItem.price || !this.newItem.qty) {
        this.$root.$emit("modalShow", {
          text: "Fill all repair item fields please",
          type: "error"
        });
      } else {
        this.$store.commit("repairs/addRepairItem", this.newItem);
        this.clearAddingForm();
      }
    },
    clearAddingForm() {
      this.newItem.title = null;
      this.newItem.price = null;
      this.newItem.qty = null;
      this.adding = false;
    },
    toggleAddingForm() {
      this.adding = !this.adding;
    },
    loadAllRepairs() {
      this.$store
        .dispatch("repairs/loadAll", this.bikeID)
        .catch(err => console.dir(err));
    },
    loadLastRepair() {
      this.$store
        .dispatch("repairs/loadLatest", this.bikeID)
        .then(() => {
          this.loadAllRepairs();
        })
        .catch(err =>
          this.$root.$emit("modalShow", { text: err, type: "error" })
        );
    },
    createEmptyRepair() {
      this.$store
        .dispatch("repairs/createEmptyRepair", this.bikeID)
        .then(() => {
          this.loadLastRepair();
        })
        .catch(err =>
          this.$root.$emit("modalShow", { text: err, type: "error" })
        );
    },
    statusKey(index) {
      return `status-${index}`;
    },
    statusClass(status) {
      return {
        activated: status.active,
        completed: status.state === "completed"
      };
    }
  },
  watch: {
    serviceCenter(value) {
      if (value) {
        let serviceCenter = this.serviceCentersFull.find(item => {
          return item.id === value.code;
        });

        if (serviceCenter) {
          this.$store.commit("repairs/setServiceCenter", serviceCenter);
        }
      }
    },
    not_warranty_case(value) {
      if (value) {
        this.warranty_approved = false;
      }
    },
    warranty_approved(value) {
      if (value) {
        this.not_warranty_case = false;
      }
    },
    rewind_confirmed(value) {
      if (value) {
        this.rewind_declined = false;
      }
    },
    rewind_declined(value) {
      if (value) {
        this.rewind_confirmed = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primaryTextColor: #1c1c1c;

.repair {
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  color: $primaryTextColor;
  min-height: 532px;

  .repair__creating-message {
    margin: auto;
    text-align: center;
    height: 100%;

    h3 {
      font-size: 14px;
      line-height: 14px;
      color: #000000;
    }

    button {
      height: 40px;
      line-height: 40px;
      padding: 0 25.5px;
      box-shadow: 0 0 4px rgba(230, 230, 230, 0.5);
      background: #ffffff;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: #ecebeb;
      }
    }
  }

  .repair__content {
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    border-right: 1px solid #f1f1f1;

    .repair__content_title {
      font-weight: bold;
      font-size: 20px;
      line-height: 21px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .repair__content_status_line {
      width: 100%;
      margin-top: 28px;
      max-width: 100%;
      padding-bottom: 120px;
      margin-bottom: 20px;

      .padding-wrapper {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        box-sizing: border-box;

        .status-item {
          height: 8px;
          background: #f1f1f1;
          position: relative;
          z-index: 10;

          .line-bg {
            position: absolute;
            height: 100%;
            left: 0;
            width: 24px;
            display: none;
          }

          .status-info-wrapper {
            position: absolute;
            top: 100%;
            margin-top: 22px;
            font-size: 12px;
            color: #000000;

            .title {
              font-weight: bold;
              margin-bottom: 22px;
            }
          }

          &:before {
            content: "";
            position: absolute;
            top: -8px;
            left: 0;
            width: 24px;
            height: 24px;
            background: #f1f1f1;
            border-radius: 50%;
            z-index: 0;
          }

          &.activated {
            &:after {
              content: "";
              position: absolute;
              top: -4px;
              left: 4px;
              width: 16px;
              height: 16px;
              border-radius: 50%;
            }

            .line-bg {
              display: block;
              width: 12px;
            }

            &:first-of-type {
              .line-bg {
                display: none;
              }
            }
          }

          &.completed {
            &:after {
              content: "";
              position: absolute;
              top: -4px;
              left: 4px;
              width: 16px;
              height: 16px;
              border-radius: 50%;
            }

            .line-bg {
              display: block;
            }
          }

          &:last-of-type {
            height: 0;
          }

          &:nth-child(1) {
            .line-bg {
              left: 12px;
              width: 12px;
              background: linear-gradient(
                270deg,
                #c3f3ff -31.27%,
                #c5f4ff 100%
              );
            }

            &.activated {
              &:after {
                background: #c5f4ff;
              }
            }

            &.completed {
              //background: #c5f4ff;
              background: linear-gradient(
                270deg,
                #b8f0fe -31.27%,
                #c5f4ff 100%
              );

              &:after {
                background: #c5f4ff;
              }
            }
          }

          &:nth-child(2) {
            .line-bg {
              background: linear-gradient(
                270deg,
                #b5effd -31.27%,
                #bbf1fe 100%
              );
            }

            &.activated {
              &:after {
                background: #b8f0fe;
              }
            }

            &.completed {
              background: linear-gradient(
                270deg,
                #9fe7fa -31.27%,
                #b8f0fe 100%
              );

              &:after {
                background: #b8f0fe;
              }
            }
          }

          &:nth-child(3) {
            .line-bg {
              background: linear-gradient(
                270deg,
                #9de7fa -31.27%,
                #a5eafb 100%
              );
            }

            &.activated {
              &:after {
                background: #9fe7fa;
              }
            }

            &.completed {
              background: linear-gradient(
                270deg,
                #8ce1f8 -31.27%,
                #9fe7fa 100%
              );

              &:after {
                background: #9fe7fa;
              }
            }
          }

          &:nth-child(4) {
            .line-bg {
              background: linear-gradient(
                270deg,
                #89e0f8 -31.27%,
                #91e3f9 100%
              );
            }

            &.activated {
              &:after {
                background: #8ce1f8;
              }
            }

            &.completed {
              background: linear-gradient(
                270deg,
                #77daf5 -31.27%,
                #8ce1f8 100%
              );

              &:after {
                background: #8ce1f8;
              }
            }
          }

          &:nth-child(5) {
            .line-bg {
              background: linear-gradient(
                270deg,
                #74d9f5 -31.27%,
                #7ddcf6 100%
              );
            }

            &.activated {
              &:after {
                background: #77daf5;
              }
            }

            &.completed {
              background: linear-gradient(
                270deg,
                #64d3f3 -31.27%,
                #77daf5 100%
              );

              &:after {
                background: #77daf5;
              }
            }
          }

          &:nth-child(6) {
            .line-bg {
              background: linear-gradient(
                270deg,
                #61d2f2 -31.27%,
                #68d4f3 100%
              );
            }

            &.activated {
              &:after {
                background: #64d3f3;
              }
            }

            &.completed {
              background: linear-gradient(
                270deg,
                #50ccf0 -31.27%,
                #64d3f3 100%
              );

              &:after {
                background: #64d3f3;
              }
            }
          }

          &:nth-child(7) {
            .line-bg {
              background: linear-gradient(
                270deg,
                #4dcbef -31.27%,
                #55cef1 100%
              );
            }

            &.activated {
              &:after {
                background: #50ccf0;
              }
            }

            &.completed {
              background: linear-gradient(
                270deg,
                #3cc4ed -31.27%,
                #50ccf0 100%
              );

              &:after {
                background: #50ccf0;
              }
            }
          }

          &:nth-child(8) {
            .line-bg {
              background: linear-gradient(
                270deg,
                #3ac4ed -31.27%,
                #41c6ee 100%
              );
            }

            &.activated {
              &:after {
                background: #3cc4ed;
              }
            }

            &.completed {
              background: linear-gradient(
                270deg,
                #26bdea -31.27%,
                #3cc4ed 100%
              );

              &:after {
                background: #3cc4ed;
              }
            }
          }

          &:nth-child(9) {
            .line-bg {
              background: linear-gradient(
                270deg,
                #13b7e8 -31.27%,
                #2cbfeb 100%
              );
            }

            &.activated {
              &:after {
                background: #26bdea;
              }
            }

            &.completed {
              background: linear-gradient(
                270deg,
                #13b7e8 -31.27%,
                #26bdea 100%
              );

              &:after {
                background: #26bdea;
              }
            }
          }

          &:nth-child(10) {
            .line-bg {
              background: #13b7e8;
            }

            &.activated {
              &:after {
                background: #13b7e8;
              }
            }

            &.completed {
              background: #13b7e8;

              &:after {
                background: #13b7e8;
              }
            }
          }

          &:nth-child(11) {
            .line-bg {
              background: #13b7e8;
            }

            &.activated {
              &:after {
                background: #13b7e8;
              }
            }

            &.completed {
              background: #13b7e8;

              &:after {
                background: #13b7e8;
              }
            }
          }

          &:nth-child(12) {
            .line-bg {
              background: #13b7e8;
            }

            &.activated {
              &:after {
                background: #13b7e8;
              }
            }

            &.completed {
              background: #13b7e8;

              &:after {
                background: #13b7e8;
              }
            }
          }

          &:nth-child(13) {
            .line-bg {
              background: #13b7e8;
            }

            &.activated {
              &:after {
                background: #13b7e8;
              }
            }

            &.completed {
              background: #13b7e8;

              &:after {
                background: #13b7e8;
              }
            }
          }

          &:last-child {
            .line-bg {
              width: 12px;
              height: 8px;
            }
          }
        }
      }
    }

    .repair__content_items {
      width: 100%;

      .empty-items {
        height: 186px;
        display: flex;

        h3 {
          margin: auto;
          font-size: 14px;
          color: #000000;
          font-weight: bold;
        }
      }

      .add-repair-item-btn {
        display: block;
        width: 100%;
        height: 32px;
        font-size: 14px;
        font-weight: bold;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        line-height: 32px;

        &:hover {
          background: #ecebeb;
        }
      }

      .add-repair-item-form {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 25px;

        .repair-item-input {
          display: block;
          box-shadow: 0 0 2px #454545;
          background: #ffffff;
          border-radius: 5px;
          border: none;
          height: 30px;
          font-size: 14px;
          padding: 0 10px;
          line-height: 30px;

          &.item {
            margin-right: 47.5px;
            margin-left: 0;
            min-width: 495px;
            flex-basis: 50%;
          }

          &.price {
            margin-right: 44.5px;
            margin-left: 0;
            min-width: 118.8px;
            flex-basis: 18%;
          }

          &.qty {
            margin-right: 36.6px;
            margin-left: 0;
            min-width: 79.2px;
            width: 100%;
            flex-basis: 14%;
          }
        }

        button {
          &.repair-item-btn-add {
            min-width: 148.5px;
            height: 32px;
            border: none;
            background: #dcfe36;
            border-radius: 5px;
            font-weight: bold;
            font-size: 14px;
            line-height: 32px;
            cursor: pointer;
            flex-basis: 18%;
          }
        }
      }

      table.table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 40px;
        color: #454545;

        thead,
        tbody {
          font-weight: bold;
          font-size: 14px;

          th,
          td {
            padding-top: 6px;
            padding-bottom: 4px;
          }
        }

        thead {
          tr {
            th {
              background: #f2f2f2;

              &:first-of-type {
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
              }

              &:last-child {
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
              }
            }
          }
        }

        tbody {
          tr:nth-child(even) {
            td {
              background: #f2f2f2;

              &:first-of-type {
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
              }

              &:last-child {
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
              }
            }
          }

          tr {
            &:hover {
              .last {
                button {
                  opacity: 1;
                }
              }
            }
          }

          td {
            //height: 40px;
            font-size: 14px;
            color: $primaryTextColor;
            padding-top: 12px;
            padding-bottom: 12px;

            &.last {
              position: relative;
              padding-right: 22px;

              button {
                border: none;
                background: transparent;
                box-shadow: none;
                padding: 0;
                margin: 0;
                opacity: 0;
                right: 4px;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                position: absolute;
                width: 11px;
                height: 11px;
              }
            }
          }
        }

        .align-middle {
          vertical-align: middle;
        }

        .text-right {
          text-align: right !important;
        }

        .text-left {
          text-align: left !important;
        }

        .text-center {
          text-align: center !important;
        }

        .first {
          width: 60%;
          padding-left: 20px;
        }

        .last {
          padding-right: 22px;
        }
      }
    }

    .repair__content_payments {
      width: 100%;
      display: flex;
      margin-top: 40px;
      justify-content: space-between;

      .payments {
        margin-top: 32px;
        max-width: 460px;

        .payments__item {
          border: 1px solid #e2e2e2;
          padding: 24px 20px;
          width: 100%;

          .payments__item-header {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;

            .price {
              width: 50%;
              margin-left: 0;
              margin-right: auto;
              font-size: 14px;
              color: #888888;
              font-weight: bold;
            }

            .date {
              width: 50%;
              margin-right: 0;
              margin-left: auto;
              font-size: 14px;
              color: #454545;
              text-align: right;
            }
          }

          .payments__item-title {
            margin-top: 15px;
            font-size: 12px;
            color: #888888;
          }

          .payments__item-invoice {
            margin-top: 15px;
            font-size: 12px;
            color: #888888;

            a {
              text-decoration: underline;
              color: #888888;
            }
          }

          .payments__item-actions {
            font-size: 12px;
            color: #888888;

            .action {
              position: relative;
              margin-top: 15px;
              &.not-paid {
                margin-top: 10px;
                span {
                  color: #bf0000;
                  font-size: 26px;
                  position: relative;
                  top: 6px;
                }
              }
            }
          }
        }
      }

      .invoices {
        width: 50%;
        box-sizing: border-box;

        .empty-invoices {
          font-size: 14px;
          color: #1c1c1c;
        }

        .main-invoice {
          font-weight: bold;
          font-size: 14px;
          color: #1c1c1c;

          .invoice-link {
            cursor: pointer;
            color: #6097ff;
            text-decoration: underline;
          }
        }
      }

      .info {
        width: 50%;
        max-width: 480px;
        margin-right: 0;
        box-sizing: border-box;
        @media screen and (min-width: 1200px) {
          padding-right: 24px;
          .divider {
            width: calc(100% + 24px);
          }
        }

        .part {
          margin-top: 24px;
          margin-bottom: 24px;

          &:first-of-type {
            margin-top: 0;
          }

          &.discount {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
          }
        }

        .divider {
          height: 1px;
          background: #f1f1f1;
          margin-bottom: 24px;
          margin-top: 24px;
        }

        .discount {
          .type {
            width: 40%;
            margin-right: 24px;
            max-width: 150px;
            min-width: 150px;
          }

          .value {
            width: 30%;
            max-width: 75px;
            margin-right: 16px;
          }

          .apply {
            button {
              border: none;
              cursor: pointer;
              background: transparent;
              color: #1c1c1c;
              height: 30px;
              line-height: 30px;
              transition: all 0.2s ease-in-out;

              &:hover {
                color: #888888;
              }

              &.disabled,
              &:disabled {
                color: #888888;
                cursor: not-allowed;

                &:hover {
                  color: #888888;
                }
              }
            }
          }

          .price {
            width: 30%;
            text-align: right;
            margin-left: auto;
            align-self: center;
          }
        }

        .subtotal {
          font-size: 14px;
          color: #1c1c1c;
          display: flex;
          font-weight: bold;

          .title {
            width: 70%;
          }

          .price {
            width: 30%;
            text-align: right;
          }
        }

        .total {
          font-size: 14px;
          color: #1c1c1c;
          display: flex;
          font-weight: bold;

          .title {
            width: 70%;
          }

          .price {
            width: 30%;
            text-align: right;
          }
        }

        .remaining {
          font-size: 14px;
          color: #1c1c1c;
          display: flex;
          font-weight: bold;

          .title {
            width: 70%;
          }

          .price {
            width: 30%;
            text-align: right;
          }
        }

        .tax-base {
          font-size: 14px;
          color: #1c1c1c;
          display: flex;

          .title {
            width: 70%;
          }

          .price {
            width: 30%;
            text-align: right;
          }
        }

        .taxes {
          font-size: 14px;
          color: #1c1c1c;
          display: flex;

          .title {
            width: 70%;
            display: flex;
            margin: auto auto auto 0;
          }

          .price {
            width: 30%;
            text-align: right;
            display: flex;
            flex-direction: column;
          }
        }

        .paid {
          font-size: 14px;
          color: #1c1c1c;
          display: flex;

          .title {
            width: 70%;
          }

          .price {
            width: 30%;
            text-align: right;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  .repair__sidebar {
    width: 360px;
    padding: 10px 0 10px 15px;

    .repair__sidebar-title {
      color: $primaryTextColor;
      font-weight: bold;
      font-size: 14px;
    }

    .repair__sidebar-status {
      color: $primaryTextColor;
      font-weight: bold;
      font-size: 25px;
      margin-top: 8px;
      margin-bottom: 40px;
    }

    .next-step {
      margin-bottom: 40px;

      a {
        &.confirmation-document-link {
          font-weight: bold;
          font-size: 14px;
          color: #1c1c1c;
          text-decoration: underline;
        }
      }

      .next-step-title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 8px;
      }

      .next-step-btn {
        font-weight: bold;
        font-size: 14px;
        width: 100%;
        height: 44px;
        background: #ffffff;
        line-height: 44px;
        padding: 0;
        margin: 0;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        cursor: pointer;

        &:nth-child(2) {
          margin-top: 8px;
        }

        &.selected {
          border: 1px solid #1c1c1c;
        }
      }
    }

    .select-form {
      width: 100%;
      margin-bottom: 40px;

      label {
        width: 100%;
        display: block;
        padding: 0;
        margin: 0 0 12px;
        font-size: 14px;
        color: $primaryTextColor;
        font-weight: bold;

        span {
          text-decoration: underline;
          cursor: pointer;
          float: right;
        }
      }

      .service-center-static {
        .title {
          text-decoration: underline;
        }
      }

      .delivery-address-form {
        .delivery-address-static {
          color: $primaryTextColor;
          font-size: 14px;
          line-height: 16px;
        }
      }

      .delivery-address-edit-form {
        .form-group {
          &:last-of-type {
            display: flex;
            justify-content: space-between;
          }

          &.state {
            label {
              margin-top: 10px;
            }
          }

          label {
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 10px;
            padding: 0 0 6px 8px;
            display: block;
            color: #454545;
            margin: -2px;
          }

          input {
            border: none;
            box-shadow: 0 0 2px 0 #000;
            border-radius: 5px;
            height: 30px;
            line-height: 33px;
            padding: 0 10px;
            box-sizing: border-box;
            display: block;
            width: 100%;
            margin-bottom: 10px;
            font-size: 12px;
          }

          button {
            min-width: auto;
            height: 32px;
          }
        }
      }
    }

    .actions {
      button {
        &.confirm-status-btn {
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          border: none;
          border-radius: 5px;
          font-weight: bold;
          font-size: 14px;
          background: #e6e6e6;
          color: $primaryTextColor;
          height: 32px;
          line-height: 34px;
          padding: 0 30px;
          float: right;

          &:hover {
            background: #dcfe36;
          }

          &:disabled,
          &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            color: $primaryTextColor;

            &:hover {
              background: #e6e6e6;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {
    min-height: 200px;
    .repair__content {
      .repair__content_title,
      .repair__content_status_line .padding-wrapper {
        padding-right: 0;
        padding-left: 0;

        .status-item .status-info-wrapper {
          font-size: 10px;
        }
      }

      .repair__content_payments {
        flex-wrap: wrap;

        .invoices {
          width: 100%;
          margin-bottom: 32px;
        }

        .info {
          width: 100%;
          max-width: 100%;

          .discount {
            .type {
              margin-right: 8px;
            }

            .value {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.archive {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  .archive-title {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 5px;
    width: 100%;
  }

  .archive-list {
    width: 100%;
  }

  .archive-list__item {
    width: 100%;
    background: #f2f2f2;
    border-radius: 5px;
    margin: 0 0 5px;
    display: flex;
    align-items: center;
    padding: 13px 20px;
    box-sizing: border-box;
    cursor: pointer;

    &.active {
      background: #dcfe36;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .text {
      font-size: 14px;
      line-height: 16px;
      margin: 0;
      width: 72%;
      padding-right: 16px;
    }

    .data {
      font-size: 14px;
      line-height: 16px;
      margin: 0;
      width: 14%;
      padding-right: 16px;
    }

    .price {
      font-size: 14px;
      line-height: 16px;
      margin: 0;
      width: 14%;
      text-align: right;
    }
  }
}
</style>
