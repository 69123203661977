<template>
  <div class="main__content-item ">
    <div class="main__content-item_l">
      <span>{{ title }}:</span>
    </div>

    <div class="main__content-item_r">
      <div class="inp-group inp-group__textarea inp-group__xl">
        <!--suppress HtmlFormInputWithoutLabel -->
        <textarea :title="title" :value="value" @input="handleInput" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FInputTextArea",
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      required: true
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    }
  }
};
</script>
