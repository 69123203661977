<template>
  <div class="user__info">
    <div class="user__info-headline">
      <span>{{ capitalizeFirstLetter(type) }} address</span>
      <button
        v-if="showEditBtn"
        class="btn user__info-edit"
        @click="toggleEdit()"
        v-text="'Edit'"
      ></button>
    </div>
    <div class="user__info">
      <div class="main__r-in">
        <div
          class="inp-group p-relative ic-arr"
          :class="{ active: openAddressSource }"
        >
          <label>Address source</label>
          <input
            :value="currentAddressLabel()"
            class="cursor-pointer"
            readonly
            title="Current address"
            type="text"
            @click="toggleOpenAddressSource()"
          />
          <button
            class="btn-arr-select"
            type="button"
            @click="toggleOpenAddressSource()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="10"
              role="presentation"
              class="vs__open-indicator"
            >
              <path
                d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div v-if="!user.id" class="user__info fs-12">User must be selected</div>
    <div v-else-if="mode_edit && edit">
      <div
        v-click-outside="hideAddressSource"
        :class="{ edit: edit }"
        class="user__info"
      >
        <div class="main__r-in">
          <div class="inp-group p-relative">
            <div
              :id="`${this.type}-select-address`"
              :class="{ active: openAddressSource }"
              class="select-address"
            >
              <div
                v-if="isInvoice()"
                :class="{ selected: isSameAddresses }"
                class="option option-value-title"
                @click="changeToSameDeliveryAddress()"
              >
                Same as delivery address
              </div>
              <div
                :class="{ selected: !current_address.id }"
                class="option option-value-title"
                @click="changeAddress(null)"
              >
                User`s custom
              </div>
              <div
                :class="{ selected: isPickup }"
                class="option option-value-title"
                @click="changeAddress('pickup')"
              >
                FTC, Andorra (IGI/VAT 4.5%)
              </div>
              <div
                v-for="item in addresses"
                :key="item.id"
                :class="{
                  selected: current_address.id === item.id && !isPickup
                }"
                class="option"
                @click="changeAddress(item.id)"
              >
                <div class="option-value-title">
                  User`s saved address #{{ item.id }}
                </div>
                <div v-if="item.recipient" class="option-value">
                  {{ item.recipient }}
                </div>
                <div v-if="item.streetNo" class="option-value">
                  {{ item.streetNo }}
                </div>
                <div v-if="item.flat" class="option-value">{{ item.flat }}</div>
                <div v-if="item.zip" class="option-value">{{ item.zip }}</div>
                <div v-if="item.country_name" class="option-value">
                  {{ item.country_name }}
                </div>
                <div v-if="item.state" class="option-value">
                  {{ item.state }}
                </div>
                <div v-if="item.town_name" class="option-value">
                  {{ item.town_name }}
                </div>
              </div>
            </div>
            <div v-if="errors.country" class="error">
              {{ errors.country[0] }}
            </div>
          </div>

          <div v-if="showEditFields()">
            <div class="inp-group">
              <label class="inp-group-label"
                ><span>Recipient name</span>
                <input
                  v-model="current_address.recipient"
                  :disabled="!edit || pickup"
                  type="text"
                />
              </label>
              <div v-if="errors.recipient" class="error">
                {{ errors.recipient[0] }}
              </div>
            </div>

            <div class="inp-group">
              <label class="inp-group-label"
                ><span>Mobile phone</span>
                <input
                  v-model="order_phone"
                  v-mask="'+###############'"
                  :disabled="!edit"
                  type="text"
                />
              </label>
              <div v-if="errors.phone" class="error">{{ errors.phone[0] }}</div>
            </div>

            <div class="inp-group">
              <label class="inp-group-label"
                ><span>Street name adn number</span>
                <input
                  v-model="current_address.streetNo"
                  :disabled="!edit || pickup"
                  type="text"
                />
              </label>
              <div v-if="errors.streetNo" class="error">
                {{ errors.streetNo[0] }}
              </div>
            </div>

            <div class="inp-group">
              <label class="inp-group-label"
                ><span>Flat</span>
                <input
                  v-model="current_address.flat"
                  :disabled="!edit || pickup"
                  type="text"
                />
              </label>
              <div v-if="errors.flat" class="error">{{ errors.flat[0] }}</div>
            </div>

            <div class="inp-group">
              <label class="inp-group-label"
                ><span>Zip code</span>
                <input
                  v-model="current_address.zip"
                  :disabled="!edit || pickup"
                  type="text"
                />
              </label>
              <div v-if="errors.zip" class="error">{{ errors.zip[0] }}</div>
            </div>

            <div class="inp-group__in">
              <label>Country</label>
              <v-select
                v-model="country"
                :disabled="!edit || pickup"
                :options="countries"
                label="label"
                placeholder="Please select..."
              />
              <div v-if="errors.country" class="error">
                {{ errors.country[0] }}
              </div>
            </div>

            <div class="inp-group">
              <label class="inp-group-label"
                ><span>Province / State</span>
                <input
                  v-model="current_address.state"
                  :disabled="!edit || pickup"
                  type="text"
                />
              </label>
              <div v-if="errors.state" class="error">{{ errors.state[0] }}</div>
            </div>

            <div class="inp-group__in min-select">
              <label>City</label>
              <multiselect
                id="value"
                v-model="town"
                :allow-empty="true"
                :disabled="!edit || pickup"
                :internal-search="false"
                :limit="5"
                :loading="request.loading"
                :options="towns"
                :placeholder="manualCity || 'Select city'"
                :preserveSearch="true"
                :showNoResults="false"
                label="label"
                noOptions="Start typing"
                track-by="value"
                @search-change="fetchTowns"
              />
              <div v-if="errors.town_id" class="error">
                {{ errors.town_id[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showEditFields()" class="user-save">
        <button
          class="btn btn-green"
          @click="createOrSave"
          v-text="
            isCreating()
              ? request.save
                ? 'Creating...'
                : 'Create'
              : request.save
              ? 'Saving...'
              : 'Save'
          "
        ></button>
        <button
          v-if="!isCreating()"
          class="btn btn-gray mt-5"
          @click="cancel()"
          v-text="'Cancel'"
        ></button>
      </div>
    </div>
    <div v-else class="user-info">
      <div
        v-if="current_address.recipient"
        class="user-info-item no-decoration"
      >
        {{ current_address.recipient }}
      </div>
      <div
        v-if="phone && current_address.streetNo"
        class="user-info-item no-decoration"
      >
        Phone: {{ phone }}
      </div>
      <div
        v-if="pickup && current_address.streetNo"
        class="user-info-item no-decoration"
      >
        Forestal Technology Centre
      </div>
      <div v-if="current_address.streetNo" class="user-info-item no-decoration">
        {{ current_address.streetNo }}
      </div>
      <div v-if="current_address.flat" class="user-info-item no-decoration">
        {{ current_address.flat }}
      </div>
      <div v-if="current_address.zip" class="user-info-item no-decoration">
        {{ current_address.zip }}
      </div>
      <div
        v-if="current_address.country_name"
        class="user-info-item no-decoration"
      >
        {{ current_address.country_name }}
      </div>
      <div v-if="current_address.state" class="user-info-item no-decoration">
        {{ current_address.state }}
      </div>
      <div
        v-if="current_address.town_name"
        class="user-info-item no-decoration"
      >
        {{ current_address.town_name }}
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import {
  ALLOW_TYPE_DELIVERIES,
  INVOICE_TYPE
} from "@/store/stores/order/address";
import { Multiselect } from "vue-multiselect";
import ClickOutside from "vue-click-outside";

export default {
  name: "OrderAddress",
  components: { vSelect, Multiselect },
  directives: { ClickOutside },
  props: {
    type: {
      type: String,
      required: true,
      validator: value => ALLOW_TYPE_DELIVERIES.includes(value)
    },
    phone: {
      type: String,
      required: false,
      default: null
    },
    pickup: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      canEditOrder: "permissions/canEditOrder",
      countries: "countries/forSelect",
      isSameAddresses: "orderAddress/isSameAddresses",
      mode_edit: "orderItem/canEdit",
      isPickup: "orderItem/isPickup"
    }),
    ...mapState({
      user: state => state.orderItem.user
    }),
    showEditBtn() {
      return this.mode_edit && !this.edit && this.user.id;
    },
    addresses() {
      return this.$store.state.orderAddress[
        `user_${this.type}_addresses`
      ].filter(item => {
        return !item["for_pickup"];
      });
    },
    current_address() {
      return this.$store.state.orderAddress[`${this.type}_address`];
    },
    order_phone: {
      get() {
        return this.$store.state.orderItem.phone;
      },
      set(phone) {
        return this.$store.commit("orderItem/set_phone", phone);
      }
    },
    country: {
      get() {
        return {
          label: this.current_address.country_name,
          value: this.current_address.country_id
        };
      },
      set(value) {
        this.$store.commit(`orderAddress/set_${this.type}_address_country`, {
          id: value ? value.value : null,
          name: value ? value.label : null
        });
      }
    },
    town: {
      get() {
        return {
          label: this.current_address.town_name,
          value: this.current_address.town_id
        };
      },
      set(value) {
        this.current_address.town_id = value ? value.value : null;
        this.current_address.town_name = value ? value.label : null;
      }
    }
  },
  mounted() {
    // noinspection JSUnusedGlobalSymbols
    this.popupItem = this.$el;
  },
  data() {
    return {
      edit: this.$route.name === "CRMOrderCreate",
      openAddressSource: false,
      towns: [],
      modelAddress: null,
      errors: {},
      manualCity: null,
      request: {
        save: false,
        loading: false
      }
    };
  },
  methods: {
    isCreating() {
      return !this.current_address.id;
    },
    isInvoice() {
      return this.type === INVOICE_TYPE;
    },
    showEditFields() {
      return (
        this.current_address && !(this.isInvoice() && this.isSameAddresses)
      );
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    currentAddressLabel() {
      if (this.isPickup || this.pickup) {
        return "FTC, Andorra (IGI/VAT 4.5%)";
      }
      if (this.isInvoice() && this.isSameAddresses) {
        return "Same as delivery address";
      }

      return this.current_address.id
        ? `User\`s saved address #${this.current_address.id}`
        : "User`s custom";
    },
    cancel() {
      this.toggleEdit();
    },
    toggleEdit() {
      this.edit = !this.edit;
    },
    createOrSave() {
      let method = this.isCreating()
        ? "orderAddress/createAddress"
        : "orderAddress/updateAddress";
      this.$store
        .dispatch(method, this.type)
        .then(response => {
          this.edit = false;
          this.$root.$emit("modalShow", {
            type: "info",
            text: response.data.message
          });
        })
        .catch(err => {
          if (err.response && err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else if (err.response.data && err.response.data.message) {
            this.$root.$emit("modalShow", {
              type: "error",
              text: err.response.data.message
            });
          }
        });
    },
    fetchTowns(search) {
      if (!search) {
        return false;
      }

      this.town = null;
      this.request.loading = true;
      axios
        .get(`${API_ENDPOINT}towns?city_name=${search}`)
        .then(response => {
          this.towns = response.data.map(town => {
            return {
              label: town.town_name,
              value: town.id
            };
          });

          if (search) {
            this.town = { value: null, label: search };
          }
        })
        .finally(() => (this.request.loading = false));
    },
    toggleOpenAddressSource() {
      if (this.mode_edit && this.edit) {
        this.openAddressSource = !this.openAddressSource;
        document.getElementById(`${this.type}-select-address`).scroll(0, 0);
      }
    },
    hideAddressSource() {
      setTimeout(() => {
        if (this.openAddressSource) {
          this.openAddressSource = false;
        }
      }, 0);
    },
    changeAddress(value) {
      this.$store
        .dispatch("orderAddress/changeAddress", {
          type: this.type,
          address_id: value
        })
        .then(() => {
          if (value !== null) {
            this.edit = false;
          }
        })
        .catch(err => {
          let message = null;
          if (typeof err === "string") {
            message = err;
          } else if (err.response && typeof err.response.message === "string") {
            message = err.response.message;
          }
          if (message) {
            this.$root.$emit("modalShow", { type: "error", text: message });
          }
        });
      this.toggleOpenAddressSource();
    },
    changeToSameDeliveryAddress() {
      this.$store.dispatch(
        "orderAddress/equateInvoiceAddressToDeliveryAddress"
      );
      this.toggleOpenAddressSource();
    }
  }
};
</script>
