const state = {
  user: JSON.parse(localStorage.getItem("user"))
};

// getters
const getters = {
  canEditOrder() {
    if (!state.user || !state.user.user_type) {
      return false;
    }

    for (let permission of state.user.user_type.premissions) {
      if (permission.premisson_id === 3) {
        return permission.edit === 1;
      }
    }
  },
  canEditComment() {
    if (!state.user || !state.user.user_type) {
      return false;
    }
    for (let permission of state.user.user_type.premissions) {
      if (permission.premisson_id === 6) {
        return permission.edit === 1;
      }
    }
  },
  canViewComment() {
    if (!state.user || !state.user.user_type) {
      return false;
    }
    for (let permission of state.user.user_type.premissions) {
      if (permission.premisson_id === 6) {
        return permission.view === 1;
      }
    }
  }
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
