export default class DeliveryAddress {
  id = null;
  user_id = null;
  recipient = null;
  streetNo = null;
  flat = null;
  zip = null;
  state = null;
  town_id = null;
  town_name = null;
  country_id = null;
  country_name = null;
  for_pickup = false;

  fromResponse(data) {
    this.id = data.id || null;
    this.user_id = data.user_id || null;
    this.recipient = data.recipient || null;
    this.streetNo = data.streetNo || null;
    this.flat = data.flat || null;
    this.zip = data.zip || null;
    this.state = data.state || null;
    this.town_id = data.town_id || null;
    this.town_name = data.town_name || null;
    this.country_id = data.country_id || null;
    this.country_name = data.country_name || null;
    this.for_pickup = data.for_pickup || false;
    return this;
  }
}
