class Prices {
  id = null;
  price_tax_base = null;
  price_for_eu = null;
  price_for_andorra = null;
  price_for_other = null;
  ex_price_tax_base = null;
  ex_price_for_eu = null;
  ex_price_for_andorra = null;
  ex_price_for_other = null;
  res_price_tax_base = null;
  res_price_for_eu = null;
  res_price_for_andorra = null;
  res_price_for_other = null;
}

export default Prices;
