<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Creating vacancy department</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Vacancy Department</h5>
          </div>
        </div>

        <div class="main__content">
          <div class="main__content-in">
            <div
              v-for="lang in langs"
              :key="lang.code"
              class="main__content-item main__content-item_center"
            >
              <div class="main__content-item_l">
                <span
                  >Title ({{ lang.code.toUpperCase() }})
                  <span v-if="lang.required" class="red">*</span></span
                >
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__xl">
                  <input v-model="$data[`title${lang.postfix}`]" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__content-btns">
          <button class="btn btn-big btn-fw" @click="save">
            <span><i>Save department</i></span>
          </button>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";

export default {
  name: "CRMVacancyDepartmentCreate",
  components: { CRMLayout },
  data: () => ({
    langs: [
      {
        code: "en",
        postfix: "",
        required: true
      },
      {
        code: "fr",
        postfix: "_fr",
        required: false
      },
      {
        code: "de",
        postfix: "_de",
        required: false
      },
      {
        code: "es",
        postfix: "_es",
        required: false
      },
      {
        code: "ru",
        postfix: "_ru",
        required: false
      }
    ],
    title: null,
    title_es: null,
    title_fr: null,
    title_de: null,
    title_ru: null
  }),
  methods: {
    save() {
      let ctx = this;
      let data = {
        title: ctx.title,
        title_es: ctx.title_es,
        title_fr: ctx.title_fr,
        title_de: ctx.title_de,
        title_ru: ctx.title_ru
      };
      this.$store
        .dispatch("store_vacancy_department_request", data)
        .then(() => {
          ctx.$router.push("/departments");
          ctx.$root.$emit("modalShow", {
            text: "Vacancy department successfully added",
            type: "info"
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    }
  }
};
</script>

<style scoped>
.red {
  color: red;
}
</style>
