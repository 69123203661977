<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Error codes</span>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Error codes</h5>
          </div>

          <div class="error-list">
            <div class="left">
              <span>0</span>
              <span>1000</span>
              <span>1001</span>
              <span>1002</span>
              <span>1003</span>
            </div>
            <div class="right">
              <span>Success</span>
              <span>Pending transfer already exist.</span>
              <span>Email verification required.</span>
              <span>Wrong transfer code</span>
              <span>Bike not found for transfer</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "@/components/CRMLayout";

export default {
  name: "ErrorCodes",
  components: { CRMLayout }
};
</script>

<style lang="scss" scoped>
.error-list {
  width: 100%;
  display: flex;
  padding-top: 20px;

  .left,
  .right {
    width: 100%;

    span {
      display: block;
      width: 100%;
    }
  }

  .left {
    text-align: right;
    padding-right: 10px;
  }

  .right {
    text-align: left;
    padding-left: 10px;
  }
}
</style>
