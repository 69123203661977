<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Editing parts brand</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Part brand</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Brand Name:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="brand_name" autofocus type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save">
              <span><i>Update brand</i></span>
            </button>
          </div>
          <!-- .main__content-btns -->
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";

export default {
  name: "CRMPartBrandShow",
  components: { CRMLayout },
  data() {
    return {
      brand_name: null,
      id: null
    };
  },
  created() {
    this.loadBrandData();
  },
  methods: {
    loadBrandData() {
      let ctx = this;
      this.$store
        .dispatch("get_part_brand_request", this.$route.params.id)
        .then(response => {
          this.id = response.data.id;
          this.brand_name = response.data.brand_name;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    save() {
      let ctx = this;
      this.$store
        .dispatch("update_product_part_brand_request", {
          data: ctx.brand_name,
          id: ctx.id
        })
        .then(response => {
          console.log(response);
          ctx.$router.push("/products/parts/brands/");
          ctx.$root.$emit("modalShow", {
            text: "Brand successfully updated",
            type: "info"
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    }
  }
};
</script>
