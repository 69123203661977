<template>
  <div class="main__content-item main__content-item_center">
    <div class="main__content-item_l">
      <span>{{ title }}:</span>
    </div>

    <div class="main__content-item_r">
      <div class="inp-group inp-group__xl">
        <div v-click-outside="hideDropDown">
          <input
            :disabled="disabled"
            :value="value"
            title="Build title"
            type="text"
            @focus="toggleDropDown(true)"
            @input="handleInput"
            @keypress="reset"
          />
          <ul v-if="showDropDown" class="dropdown-menu is-active">
            <li
              v-for="(item, index) in showingOptions"
              :key="index"
              @click.prevent="selectFromList(item)"
            >
              {{ getValue(item) }}
            </li>
          </ul>
          <arrow />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from "@/components/inputs/partials/Arrow";
import ClickOutside from "vue-click-outside";

export default {
  name: "SelectWithSearch",
  props: {
    value: {
      required: true
    },
    options: {
      required: true,
      type: Array
    },
    label: {
      required: false,
      type: String
    },
    title: {
      required: true,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  directives: { ClickOutside },
  components: {
    arrow: Arrow
  },
  data() {
    return {
      showDropDownFlag: false,
      query: null
    };
  },
  mounted() {
    // noinspection JSUnusedGlobalSymbols
    this.popupItem = this.$el;
  },
  computed: {
    labelKey() {
      return this.label || "label";
    },
    showingOptions() {
      if (this.query) {
        return this.options.filter(item => {
          if (typeof item === "object") {
            return item[this.labelKey]
              .toLowerCase()
              .includes(this.query.toLowerCase());
          } else if (typeof item === "string") {
            return item.toLowerCase().includes(this.query.toLowerCase());
          }
          return false;
        });
      }

      return this.options;
    },
    showDropDown() {
      return (
        this.showingOptions.length > 0 &&
        this.showDropDownFlag &&
        !this.disabled
      );
    }
  },
  methods: {
    hideDropDown() {
      this.showDropDownFlag = false;
    },
    toggleDropDown(status) {
      if (this.showDropDownFlag !== status) {
        this.showDropDownFlag = status;
      }
    },
    reset() {
      this.$emit("reset");
    },
    handleInput(event) {
      this.$emit("input", event.target.value);
      this.query = event.target.value;
    },
    getValue(item) {
      if (typeof item === "object") {
        return item[this.labelKey];
      } else if (typeof item === "string") {
        return item;
      }

      return "Unknown";
    },
    selectFromList(item) {
      this.$emit("onSelectExist", item);
      this.showDropDownFlag = false;
      this.query = item.title;
    }
  }
};
</script>
