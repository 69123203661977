<template>
  <div class="main__r main__r-products sidebar orderSidebar">
    <div class="main__headline">
      <h5>Settings</h5>
    </div>

    <order-status v-if="!pageIsCreate()" />
    <order-user-info />

    <div class="main__r-in">
      <order-address :phone="orderPhone" :pickup="isPickup" type="delivery" />
      <order-address v-if="!pickup" type="invoice" />
    </div>
    <div class="fix-wrap">
      <div class="btn__wrapper">
        <button
          v-if="!modeEdit && !pageIsCreate()"
          class="btn btn-reset btn-fw btn-bold btn-lg loading btn-green"
          @click="toggleModeEdit()"
          v-text="'EDIT'"
        ></button>
        <button
          v-if="modeEdit && !pageIsCreate()"
          :class="{ 'btn-green': !request.save, 'btn-white': request.save }"
          class="btn btn-reset btn-fw btn-bold btn-lg loading"
          @click="save()"
          v-text="request.save ? 'SAVING...' : 'SAVE'"
        ></button>
        <button
          v-if="pageIsCreate()"
          :class="{ 'btn-green': !request.create, 'btn-white': request.create }"
          class="btn btn-reset btn-fw btn-bold btn-lg loading"
          @click="create()"
          v-text="request.create ? 'CREATING...' : 'CREATE'"
        ></button>
        <button
          class="btn btn-white btn-reset btn-fw btn-bold btn-lg loading"
          @click="cancel()"
          v-text="'Cancel'"
        ></button>
        <button
          v-if="false"
          class="btn btn-white btn-reset btn-fw btn-bold btn-lg loading"
          @click="cancel()"
          v-text="'DELETE order'"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import OrderUserInfo from "../../partials/orders/Sidebar/OrderUserInfo";
import { mapGetters, mapState } from "vuex";
import OrderAddress from "../../partials/orders/Sidebar/OrderAddress";
import OrderStatus from "../../partials/orders/Sidebar/OrderStatus";

export default {
  name: "OrderSidebar",
  components: {
    OrderStatus,
    OrderAddress,
    OrderUserInfo
  },
  computed: {
    ...mapGetters({
      canEditOrder: "permissions/canEditOrder",
      checkoutPhone: "orderItem/checkoutPhone",
      pickup: "orderItem/isPickup"
    }),
    ...mapState({
      modeEdit: state => state.orderItem.mode_edit
    }),
    orderPhone() {
      return this.checkoutPhone;
    },
    isPickup() {
      return this.pickup;
    }
  },
  data() {
    return {
      request: {
        create: false,
        save: false,
        delete: false
      },
      countries: [],
      statusesForChange: [],
      currentStatus: null,
      admin: JSON.parse(localStorage.getItem("user"))
    };
  },
  created() {
    this.$store.dispatch("orderItem/setModeEdit", false);
    this.clear();
    this.loadCountries();
    let self = this;
    window.onbeforeunload = function() {
      if (self.$store.getters["orderItem/wasEdited"]) {
        return "Are you sure you want to leave the page?";
      }
    };
  },
  methods: {
    toggleModeEdit() {
      this.$store.dispatch("orderItem/toggleModeEdit");
    },
    loadCountries() {
      this.$store.dispatch("countries/load");
    },
    pageIsCreate() {
      return this.$route.name === "CRMOrderCreate";
    },
    create() {
      this.request.create = true;
      this.$store
        .dispatch("orderItem/createOrder")
        .then(response => {
          this.$router.replace({
            name: "CRMOrder",
            params: { id: response.data.data.id }
          });
          this.$root.$emit("modalShow", {
            type: "info",
            text: response.data.message
          });
        })
        .catch(err => {
          this.$root.$emit("modalShow", { type: "error", text: err });
        })
        .finally(() => {
          this.request.create = false;
        });
    },
    save() {
      this.request.save = true;
      this.$store
        .dispatch("orderItem/updateOrder")
        .then(response => {
          this.$root.$emit("modalShow", {
            type: "info",
            text: response.data.message
          });
          this.$store.dispatch("orderFeed/load", {
            type: "orders"
          });
        })
        .catch(err => {
          this.$root.$emit("modalShow", { type: "error", text: err });
        })
        .finally(() => {
          this.request.save = false;
        });
    },
    clear() {
      this.$store.dispatch("orderItem/clear");
    },
    confirmIfWasEdit() {
      if (this.$store.getters["orderItem/wasEdited"]) {
        return confirm("Are you sure you want to leave the page?");
      }

      return true;
    },
    cancel() {
      this.$router.push({ name: "CRMOrders" }).then(() => {
        this.clear();
      });
    }
  }
};
</script>

<style scoped>
.main__r {
  padding-bottom: 160px;
}
</style>
