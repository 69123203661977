<template>
  <div class="admin">
    <div class="admin__form">
      <div class="admin__form-headline">
        <h5>Admin panel</h5>
      </div>
      <!-- .admin__form-headline -->

      <div class="admin__form-form" v-on:keyup.enter="login">
        <div class="inp-group">
          <input v-model="email" placeholder="Email" type="email" />
        </div>
        <!-- .inp-group -->

        <div class="inp-group">
          <input v-model="password" placeholder="Password" type="password" />
        </div>
        <!-- .inp-group -->

        <button class="btn btn-primary btn-fw btn-bold" @click="login">
          <span>Log in</span>
        </button>

        <div v-if="error" class="inp-error__desc">
          <span>{{ error }}</span>
        </div>
        <!-- .inp-error__desc -->

        <router-link :to="{ name: 'CRMForgotPasswordScreen' }">
          <a class="btn btn-link btn-forgot">Forgot password?</a>
        </router-link>
      </div>
      <!-- .admin__form-form -->
    </div>
    <!-- .admin__form -->
  </div>
  <!-- .admin -->
</template>

<script>
export default {
  name: "CRMLoginScreen",
  data() {
    return {
      email: "",
      password: "",
      error: null
    };
  },
  methods: {
    login: function() {
      this.error = null;

      let email = this.email;
      let password = this.password;

      this.$store
        .dispatch("login", { email, password })
        .then(() => {
          this.$router.push({
            name: "CRMDashBoard"
          });
        })
        .catch(error => {
          this.error = error;
        });
    }
  }
};
</script>
