<template>
  <div class="main__content-item">
    <div class="main__content-item_l">
      <span>{{ title }}:</span>
    </div>
    <div class="main__content-item_r">
      <div class="inp-group__img">
        <img
          :alt="title"
          :src="img"
          v-for="(img, index) in value"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultipleImageReadOnly",
  props: {
    value: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
img {
  background-color: #fff;
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 2px rgba(66, 66, 66, 0.5));
}
</style>
