import moment from "moment";

export default class OrderUser {
  id = null;
  f_name = null;
  l_name = null;
  mobile_phone = null;
  email = null;
  birth_date = null;
  user_type_id = null;
  is_b2b = false;

  get full_name() {
    if (!this.f_name && !this.l_name) {
      return null;
    }
    return [this.f_name, this.l_name].join(" ");
  }

  static fromResponse(data) {
    let self = new OrderUser();
    self.id = data.id || null;
    self.f_name = data.f_name || null;
    self.l_name = data.l_name || null;
    self.email = data.email || null;
    self.mobile_phone = data.mobile_phone || null;
    self.birth_date = data.birth_date || null;
    self.user_type_id = data.user_type_id || null;
    self.is_b2b = data.is_b2b || false;

    return self;
  }

  formatBirthDate(format) {
    if (this.birth_date === null) {
      return null;
    }
    format = format || "DD.MM.YYYY";

    return moment(this.birth_date, "YYYY-MM-DD HH:mm:ss").format(format);
  }
}
