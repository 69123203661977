import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "../../helpers/parseError";

const state = {
  docs: [],
  for_removing: []
};

// getters
const getters = {
  list(state) {
    return state.docs;
  },
  for_removing(state) {
    return state.for_removing;
  }
};

// actions
const actions = {
  store(context, data) {
    let request = new FormData();
    request.append("file", data.file);
    request.append("type", data.type);
    request.append("id", data.id);
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}v2/documents`, request, {
          headers: {
            "Content-type": "multipart/form-data"
          }
        })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  load({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_ENDPOINT}v2/documents/list`, data)
        .then(response => {
          commit("set_docs", response.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  remove({ commit }, doc) {
    commit("set_loading", {
      doc,
      status: true
    });
    return new Promise((resolve, reject) => {
      axios
        .delete(`${API_ENDPOINT}v2/documents/${doc.id}`)
        .then(response => {
          commit("remove_doc", doc);
          resolve(response);
        })
        .catch(err => {
          commit("set_loading", {
            doc,
            status: false
          });
          reject(parseError(err));
        });
    });
  }
};

// mutations
const mutations = {
  remove_local(state, doc) {
    state.docs = state.docs.filter(item => {
      return item !== doc;
    });
  },
  add_for_removing(state, doc) {
    state.for_removing.push(doc);
    state.docs = state.docs.filter(item => {
      if (!item.id) {
        return true;
      }
      return item.id !== doc.id;
    });
  },
  set_docs(state, docs) {
    state.docs = [...docs];
  },
  add_doc(state, doc) {
    state.docs.unshift(doc);
  },
  replace_doc(state, data) {
    state.docs = state.docs.map(doc => {
      if (doc === data.old) {
        doc = data.new;
      }
      return doc;
    });
  },
  remove_doc(state, doc) {
    state.docs = state.docs.filter(item => {
      return item.id !== doc.id;
    });
  },
  set_loading(state, data) {
    state.docs = state.docs.map(doc => {
      if (doc.id === data.doc.id) {
        doc.loading = data.status;
      }
      return doc;
    });
  },
  clear_removing_list(state) {
    state.for_removing = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
