<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Public file uploader</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Main info</h5>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <f-select
                v-model="request.folder"
                :options="['manuals']"
                title="Type"
              />

              <div class="main__content-item">
                <div class="main__content-item_l">
                  <span>File:</span>
                </div>

                <div class="main__content-item_r">
                  <dropzone
                    id="public_file"
                    :options="dropzoneSettings"
                    dict-default-message="Drag&drop or <u>click</u> here to upload file (pdf up to 15MB)"
                    @vdropzone-files-added="handleFile"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save">
              <span><i>Upload</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Dropzone from "./forms/inputs/Dropzone";
import Select from "@/components/inputs/Select";

require("verte/dist/verte.css");

export default {
  name: "ManageBuildColor",
  components: {
    CRMLayout,
    dropzone: Dropzone,
    "f-select": Select
  },
  data() {
    return {
      request: {
        file: null,
        folder: null
      }
    };
  },
  computed: {
    dropzoneSettings() {
      return {
        url: null,
        uploadMultiple: false,
        autoProcessQueue: false,
        addRemoveLinks: false,
        maxFiles: 1
      };
    }
  },
  methods: {
    handleFile(fileList) {
      if (fileList.length > 0) {
        this.request.file = fileList[0];
      }
    },
    save() {
      const formData = new FormData();
      formData.append("folder", this.request.folder);
      formData.append("file", this.request.file);
      let ctx = this;
      this.$store
        .dispatch("publicUpload", formData)
        .then(result => {
          ctx.$root.$emit("modalShow", {
            text: "File link: " + result.data.path,
            type: "info"
          });
        })
        .catch(() => {
          ctx.$root.$emit("modalShow", {
            text: "Error while uploading file",
            type: "error"
          });
        });
    }
  }
};
</script>
