<template>
  <div v-if="canEditOrder" class="inp-group__wrapper">
    <div class="inp-group__headline">
      <h5>Change status</h5>
    </div>

    <div class="inp-group__in">
      <div class="inp-group__dropdown">
        <v-select
          v-model="status_id"
          :clearable="false"
          :disabled="!mode_edit"
          :options="statuses_for_change"
          label="label"
          placeholder="Select order status"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import vSelect from "vue-select";

export default {
  name: "OrderStatus",
  components: { vSelect },
  computed: {
    ...mapGetters({
      canEditOrder: "permissions/canEditOrder",
      mode_edit: "orderItem/canEdit"
    }),
    ...mapState({
      status_id: state => state.orderStatus.current_status_id,
      statuses_for_change: state => state.orderStatus.statuses
    }),
    status_id: {
      get() {
        return this.$store.getters["orderStatus/getCurrentStatusForSelect"];
      },
      set(value) {
        this.$dialog
          .confirm("Are you sure you want to change the order status")
          .then(() => {
            this.changeStatus(value);
          })
          .catch(() => {});
      }
    }
  },
  data() {
    return {
      canceled: false
    };
  },
  created() {},
  methods: {
    changeStatus(value) {
      this.$store
        .dispatch("orderStatus/changeStatus", value ? value.value : null)
        .then(() => {
          this.$root.$emit("modalShow", {
            type: "info",
            text: "Status change successfully"
          });
        })
        .catch(err => {
          this.$root.$emit("modalShow", { type: "error", text: err });
        });
    }
  }
};
</script>
