<template>
  <div id="app">
    <transition name="fade">
      <router-view />
    </transition>
    <modal :body="body" :options="modalOptions" :type="type" />
    <Origami v-if="showLoader" />
  </div>
</template>

<script>
import Modal from "./components/modals/Modal";
import { Origami } from "vue-loading-spinner";
import "vue2-datepicker/index.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-select/dist/vue-select.css";

export default {
  components: { Modal, Origami },
  name: "App",
  data() {
    return {
      body: "",
      type: "",
      modalOptions: null
    };
  },
  created: function() {
    let self = this;
    this.$http.interceptors.response.use(undefined, function(err) {
      return new Promise(() => {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          delete self.$http.defaults.headers.common["Authorization"];
          self.$store.state.status = "";
          self.$root.$router.push("/login");
          return;
        }
        throw err;
      });
    });
    this.loadMeta();
  },
  computed: {
    showLoader() {
      return this.$store.state.status === "loading";
    }
  },
  mounted() {
    this.$root.$on("modalShow", data => {
      this.body = data.text;
      this.type = data.type;
      this.modalOptions = data.options;

      setTimeout(() => {
        window.$("#" + this.type).modal({
          backdrop: "static",
          keyboard: false,
          show: true
        });
      }, 2);
    });
  },
  methods: {
    loadMeta() {
      this.$store
        .dispatch("get_meta")
        .then(response => {
          this.meta = response.data;
        })
        .catch(error => {
          this.$root.$emit("modalShow", { text: error, type: "error" });
        });
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0.4s;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  z-index: 1000;
}

.spinner:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.btn-arrow.reverse {
  transform: rotate(180deg);
}

.btn-small {
  border-radius: 6px;
  height: 32px;
  line-height: 30px;
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.mx-datepicker {
  width: 100% !important;
}

.inp-group__colorpicker .jq-selectbox__dropdown ul li:not(.sel):first-child {
  display: block !important;
}

.table__products td:nth-child(2),
.table__products th:nth-child(2) {
  width: auto !important;
}

.table__products th:nth-child(2).id,
.table__products td:nth-child(2).id {
  width: 80px !important;
}

.v-select {
  box-shadow: 0 0 2px 0 #000 !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.vs__dropdown-toggle {
  border: none !important;
}

.v-select.vs--open .vs__dropdown-menu {
  box-shadow: 0 0 2px 0 #000 !important;
}

.vs__dropdown-option--selected {
  background: transparent !important;
  color: #000000 !important;
}

.vs__dropdown-option {
  background: transparent !important;
  color: #000000 !important;
}

.vs__dropdown-option--selected:hover {
  background: #f1f1f1 !important;
  color: #000000 !important;
}

.vs__dropdown-option:hover {
  background: #f1f1f1 !important;
}

.v-select.vs--open .vs__dropdown-menu {
  padding: 0 !important;
  margin: 0 !important;
}

.v-select.vs--open .vs__dropdown-option {
  padding: 10px !important;
  transition: all 0.4s !important;
}

.v-select .disabled {
  color: #828282;
  cursor: no-drop;
}

.multiselect__placeholder {
  padding: 0 !important;
}

.multiselect__option--highlight:after {
  content: "" !important;
  display: none !important;
}

.multiselect__option--highlight {
  background: #ffffff;
}

.multiselect__tags {
  box-shadow: 0 0 2px 0 #000 !important;
  border: none !important;
  padding: 6px 40px 6px 6px;
}

.multiselect__input {
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 20px !important;
}

.multiselect__input,
.multiselect__single {
  box-shadow: none !important;
  top: 3px !important;
}

.multiselect__single {
  margin-bottom: 0 !important;
  top: 4px !important;
  padding-left: 0;
}

.multiselect__placeholder {
  padding-top: 6px !important;
  margin-bottom: 0 !important;
}

.multiselect__content-wrapper {
  border: none !important;
  box-shadow: 0 0 2px 0 #000;
}

.multiselect__element > span {
  display: block !important;
}

.table__typical td:first-child,
.table__typical th:first-child {
  width: 50px;
}

tr.pointer,
th.pointer,
div.pointer {
  cursor: pointer !important;
}

.full_width {
  width: 100%;
}

.main__content-btns {
  margin-top: 15px !important;
}

.main__content-btns .btn {
  background: #ccc !important;
}

.dropzone .dz-preview img {
  width: 150px !important;
  height: 150px !important;
}

.btn-arrow.hidden {
  visibility: hidden;
}
</style>

<style lang="scss">
.edit-dialog {
  background: white;
  opacity: 0;
  z-index: -200;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 150px;
  margin-left: -200px;
  margin-top: -150px;
  transition: opacity 0.2s ease-in-out;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 5px #000000;

  &.active {
    opacity: 1;
    z-index: 300;
  }
}
</style>
