<template>
  <div v-if="loaded">
    <B2BPage v-if="isB2b"></B2BPage>
    <B2CPage v-else></B2CPage>
  </div>
</template>

<script>
import B2CPage from "./CRMCustomerPage";
import B2BPage from "./CRMCustomerB2bPage";

export default {
  name: "CRMCustomerRoutingPage",
  components: {
    B2CPage,
    B2BPage
  },
  data() {
    return {
      isB2b: false,
      loaded: false
    };
  },
  beforeMount() {
    this.$store
      .dispatch("get_customer", this.$route.params.id)
      .then(response => {
        this.isB2b = response.data.is_b2b;
        this.loaded = true;
      })
      .catch(error => {
        this.$root.$emit("modalShow", { type: "error", text: error });
      });
  }
};
</script>
