<template>
  <div class="payment-wrap">
    <div class="payment-title bold">Payments:</div>
    <div v-if="invoices.length" class="invoices-wrap manager">
      <order-payment-item
        v-for="(invoice, key) in invoices"
        :key="key"
        :invoice="invoice"
      ></order-payment-item>
    </div>

    <div v-if="!this.mode_edit && !invoices.length" class="mt-15">
      Generate invoice is available in edit mode
    </div>

    <div
      v-if="isShowLinkCreateInvoice"
      class="create-invoice-link"
      @click="toggleShowCreateInvoice()"
    >
      <a>create new invoice</a>
    </div>
    <div v-if="!showCreateInvoice" class="payment-amount">
      <div class="amount-wrap d-flex mb-15">
        <label class="d-flex" for="payment-amount">Amount</label>
        <div class="inp-group d-flex">
          <input
            id="payment-amount"
            v-model.number="paymentAmount"
            min="0"
            type="number"
          />
        </div>
      </div>
      <div
        class="d-flex inp-group inp-group__textarea inp-group__xl mb-15 w100"
      >
        <textarea
          v-model="commentAmount"
          placeholder="Attention, this text will be shown to the user"
        ></textarea>
      </div>
      <button
        class="btn btn-white btn-bold w100 mb-15"
        @click="createInvoice()"
        v-text="
          request.createInvoice ? 'Generating invoice...' : 'Generate invoice'
        "
      >
        {{ request.createInvoice ? "Generating" : "Generate" }} invoice
      </button>
      <button class="btn btn-none w100" @click="toggleShowCreateInvoice()">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import OrderPaymentItem from "./OrderPaymentItem";

export default {
  name: "OrderPayment",
  components: { OrderPaymentItem },
  computed: {
    ...mapState({
      invoices: state => state.orderPrice.invoices
    }),
    ...mapGetters({
      mode_edit: "orderItem/canEdit",
      orderIsFullPaid: "orderPrice/orderIsFullPaid",
      was_edited: "orderItem/wasEdited"
    }),
    isShowLinkCreateInvoice() {
      return this.showCreateInvoice && this.mode_edit && !this.orderIsFullPaid;
    }
  },
  data() {
    return {
      request: {
        createInvoice: false,
        deleteInvoice: false
      },
      showCreateInvoice: true,
      paymentAmount: 0,
      commentAmount: ""
    };
  },
  methods: {
    toggleShowCreateInvoice() {
      this.showCreateInvoice = !this.showCreateInvoice;
    },
    deleteInvoice(id) {
      if (this.request.deleteInvoice) {
        return false;
      }

      this.request.deleteInvoice = true;

      this.$store.dispatch("orderPrice/deleteInvoice", id).finally(() => {
        this.request.deleteInvoice = false;
      });
    },
    createInvoice() {
      if (this.request.createInvoice) {
        return false;
      }

      this.request.createInvoice = true;
      let data = {
        payment_method_id: null,
        amount: parseFloat(this.paymentAmount * 100).toFixed(2),
        description: this.commentAmount
      };

      this.$store
        .dispatch("orderPrice/createInvoice", data)
        .then(() => {
          this.toggleShowCreateInvoice();
          this.clear();
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        })
        .finally(() => {
          this.request.createInvoice = false;
        });
    },
    clear() {
      this.paymentAmount = null;
      this.commentAmount = null;
    }
  }
};
</script>

<style scoped>
.payment-wrap {
  display: inline-flex;
  flex-direction: column;
  font-size: 14px;
}

.payment-title {
  width: 100%;
}

.create-invoice-link {
  width: 100%;
  text-align: center;
  text-decoration-line: underline;
  padding: 45px 0;
}

.create-invoice-link a {
  color: #4a90e2;
}

.payment-amount {
  display: flex;
  flex-direction: column;
}

.amount-wrap {
  margin-top: 15px;
  justify-content: space-between;
  align-items: baseline;
}

.amount-wrap input {
  border: 1px solid #e2e2e2;
  box-shadow: 0 0 4px 0 rgba(230, 230, 230, 0.5);
  text-align: right;
  width: 150px;
}

.payment-amount label {
  font-weight: normal;
  color: #454545;
}

.inp-group__textarea textarea {
  box-shadow: 0 0 4px 0 rgba(230, 230, 230, 0.5);
  border: 1px solid #e6e6e6;
}

.invoices-wrap {
  font-weight: normal;
  color: #454545;
  margin-top: 20px;
}

.invoices-wrap.manager .invoice-item {
  padding: 24px 20px;
  margin-bottom: 8px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 5px;
  flex: none;
  order: 1;
  align-self: center;
}

.invoices-wrap.manager .invoice-item.customer {
  padding-left: 0;
  border: none;
}

textarea::placeholder {
  color: #888888;
  opacity: 0.6;
}
</style>
