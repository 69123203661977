<template>
  <div class="b2b_codes">
    <div :key="code.id" v-for="code in b2b_codes" class="b2b_code__item">
      <img :src="code.qr_img" :alt="code.code" />
      <span v-if="code.user_bike_id">
        Activated -
        <router-link :to="`/bikes/${code.user_bike_id}`" class="link">
          Bike id {{ code.user_bike_id }}
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "B2bCodes",
  computed: {
    ...mapGetters({
      b2b_codes: "orderFeed/b2b_codes"
    })
  },
  methods: {}
};
</script>
