<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Editing roles</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Role</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="main__content-in pb-6">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l"><span>Id:</span></div>
                <div class="main__content-item_r">
                  <span>{{ role.id }}</span>
                </div>
              </div>
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Name:</span>
                </div>
                <!-- .main__content-item_l -->
                <div class="main__content-item_r">
                  <div
                    :class="{ error: errorTitle }"
                    class="inp-group inp-group__xl"
                  >
                    <input v-model="role.type" autofocus type="text" />
                    <p class="text-error">Required field!</p>
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Business Type:</span>
                </div>
                <!-- .main__content-item_l -->
                <div class="main__content-item_r">
                  <div
                    :class="{ error: errorBusinessType }"
                    class="inp-group inp-group__xl"
                  >
                    <select v-model="role.business_type">
                      <option
                        :value="type.id"
                        :key="type.id"
                        v-for="type in $store.getters.userBusinessTypes"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                    <p class="text-error">Required field!</p>
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
              <div class="main__content-item" style="margin-bottom: 40px">
                <div class="main__content-item_l">
                  <span>Description:</span>
                </div>
                <!-- .main__content-item_l -->
                <div class="main__content-item_r">
                  <div
                    :class="{ error: errorDesc }"
                    class="inp-group inp-group__textarea inp-group__xl"
                  >
                    <textarea v-model="role.description"></textarea>
                    <p class="text-error">Required field!</p>
                  </div>
                  <!-- .inp-group -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l"></div>
                <div class="main__content-item_r">
                  <div class="flex">
                    <div class="col"><p>View:</p></div>
                    <div class="col"><p>Edit:</p></div>
                  </div>
                </div>
              </div>
              <div
                v-for="premission in role.premissions"
                :key="premission.id"
                class="main__content-item main__content-item_center"
              >
                <div class="main__content-item_l">
                  <span>{{ premission.premission.title }}:</span>
                </div>
                <div class="main__content-item_r">
                  <div class="flex">
                    <div class="col">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          :id="'checkbox' + premission.id + 'view'"
                          v-model="premission.view"
                          :disabled="canEdit(role)"
                          type="checkbox"
                        />
                        <label
                          :for="'checkbox' + premission.id + 'view'"
                        ></label>
                      </div>
                      <!-- .inp-group -->
                    </div>
                    <div class="col">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          :id="'checkbox' + premission.id + 'edit'"
                          v-model="premission.edit"
                          :disabled="canEdit(role)"
                          type="checkbox"
                        />
                        <label
                          :for="'checkbox' + premission.id + 'edit'"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
            <!-- .main__content-in -->
          </div>
          <!-- .main__content -->

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw bg-white" @click="save">
              <span><i>Save role</i></span>
            </button>
          </div>
          <!-- .main__content-btns -->
          <div class="main__content-btns">
            <router-link
              :to="{ name: 'CrmRoles' }"
              class="btn btn-big btn-fw bg-white"
              tag="button"
              ><span><i>Cancel</i></span></router-link
            >
          </div>
          <!-- .main__content-btns -->
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";

export default {
  name: "CRMRoleEdit",
  components: { CRMLayout },
  data() {
    return {
      role: {},
      premissions: {},
      errorTitle: false,
      errorBusinessType: false,
      errorDesc: false
    };
  },
  mounted() {
    this.loadRole();
  },
  methods: {
    canEdit(role) {
      let disabledRoles = [1, 3];
      if (disabledRoles.indexOf(role.id) !== -1) {
        return "disabled";
      }
      return false;
    },
    loadRole() {
      let ctx = this;
      this.$store
        .dispatch("get_role_request", this.$route.params.id)
        .then(resp => {
          console.log(resp);
          ctx.role = resp.data;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    save() {
      if (!this.validate()) {
        return false;
      }
      let ctx = this;
      this.$store
        .dispatch("save_role_request", this.role)
        .then(() => {
          ctx.$root.$emit("modalShow", {
            type: "info",
            text: "Role Successfully update"
          });
          this.$router.push("/roles");
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    validate() {
      if (!this.role.type || this.role.type.lenght < 1) {
        this.errorTitle = true;
        setTimeout(
          function() {
            this.errorTitle = false;
          }.bind(this),
          5000
        );
        return false;
      }
      if (!this.role.business_type) {
        this.errorBusinessType = true;
        setTimeout(
          function() {
            this.errorBusinessType = false;
          }.bind(this),
          5000
        );
        return false;
      }
      if (!this.role.description || this.role.description.lenght < 1) {
        this.errorDesc = true;
        setTimeout(
          function() {
            this.errorDesc = false;
          }.bind(this),
          5000
        );
        return false;
      }
      return true;
    }
  },
  watch: {
    role: {
      handler: function() {
        let ctx = this;
        if (!!ctx.role.type && ctx.role.type.length > 0) {
          ctx.errorTitle = false;
        }
        if (ctx.role.business_type) {
          ctx.errorTitle = false;
        }
        if (!!ctx.role.description && ctx.role.description.length > 0) {
          ctx.errorDesc = false;
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .main__content-item_l {
    width: 50%;
  }
}
</style>
