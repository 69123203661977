import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import parseError from "@/store/helpers/parseError";

const state = {
  list: [],
  shortList: []
};

const getters = {
  list(state) {
    return state.list;
  },
  shortList(state) {
    return state.shortList;
  }
};
const actions = {
  short({ commit }) {
    return new Promise((resolve, reject) => {
      const url = `${API_ENDPOINT}v2/models/short`;
      axios
        .get(url)
        .then(result => {
          commit("setShortList", result.data.data);
          resolve(result);
        })
        .catch(error => {
          reject(parseError(error));
        });
    });
  },
  get() {},
  sort(context, models) {
    return new Promise((resolve, reject) => {
      const url = `${API_ENDPOINT}v2/models/sort`;
      axios
        .post(url, { models })
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
const mutations = {
  setList(state, models) {
    state.list = models;
  },
  setShortList(state, models) {
    state.shortList = models;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
