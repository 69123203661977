<template>
  <CRMLayout>
    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Part info</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="main__content-in">
              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Category:</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group__dropdown inp-group__xl">
                    <styler-select
                      :selected="part.category_id"
                      :selector-options="categories"
                      @newoptionselected="categoryChanged"
                    />
                  </div>
                  <!-- .inp-group__dropdown -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->

              <div class="main__content-item main__content-item_center">
                <div class="main__content-item_l">
                  <span>Price (purchase price):</span>
                </div>
                <!-- .main__content-item_l -->

                <div class="main__content-item_r">
                  <div class="inp-group inp-group__xl">
                    <input v-model="part.price" type="text" />
                  </div>
                  <!-- .inp-group inp-group__xl -->
                </div>
                <!-- .main__content-item_r -->
              </div>
              <!-- .main__content-item -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import StylerSelect from "./forms/inputs/StylerSelect";

export default {
  name: "CRMPartShow",
  components: { CRMLayout, StylerSelect },
  data() {
    return {
      part: {
        category_id: null
      },
      categories: []
    };
  },
  created() {
    this.loadPart();
    this.loadCategories();
  },
  methods: {
    categoryChanged(category) {
      this.part.category_id = category;
    },
    loadPart() {
      let ctx = this;
      this.$store
        .dispatch("get_part_request", this.$route.params.id)
        .then(response => {
          ctx.part = response.data;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadCategories() {
      let ctx = this;
      this.$store
        .dispatch("get_part_categories_request", {})
        .then(response => {
          ctx.categories = response.data.map(category => {
            return {
              value: category.id,
              text: category.en_title
            };
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    }
  }
};
</script>
