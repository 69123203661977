import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "@/store/helpers/parseError";
import Repair from "@/store/models/Repair";

export default {
  namespaced: true,
  state() {
    return {
      repair: null,
      isRepairTab: false,
      list: [],
      forShow: null
    };
  },
  actions: {
    delete({ getters }) {
      let repair = getters.repair;
      return new Promise((resolve, reject) => {
        let url = `${API_ENDPOINT}v2/repairs/${repair.id}`;
        axios
          .delete(url)
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(parseError(error));
          });
      });
    },
    uploadConfirmation({ getters }, file) {
      let repair = getters.repair;
      let formData = new FormData();
      formData.append("confirmation", file);
      return new Promise((resolve, reject) => {
        let url = `${API_ENDPOINT}v2/repairs/${repair.id}/confirmation`;
        axios
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(parseError(error));
          });
      });
    },
    saveServiceCenter({ getters }, service_center_id) {
      let repair = getters["repair"];
      return new Promise((resolve, reject) => {
        let url = `${API_ENDPOINT}v2/repairs/${repair.id}/service-center`;
        axios
          .post(url, { service_center_id })
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(parseError(error));
          });
      });
    },
    changeStatus({ getters }, { status, next }) {
      let repair = getters["repair"];
      return new Promise((resolve, reject) => {
        let url = `${API_ENDPOINT}v2/repairs/${repair.id}/status`;
        if (
          status === "checking" ||
          status === "not_warranty_case" ||
          status === "service_completed"
        ) {
          if (next) {
            url += `?next=${next}`;
          }
        }
        axios
          .post(url, { status })
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(parseError(error));
          });
      });
    },
    save({ getters }) {
      return new Promise((resolve, reject) => {
        if (getters["repair"] && getters["repair"].id) {
          let url = `${API_ENDPOINT}v2/repairs/${getters["repair"].id}`;
          axios
            .post(url, getters["repair"].request)
            .then(() => {
              resolve();
            })
            .catch(error => {
              reject(parseError(error));
            });
        } else {
          reject(new Error("Repair is empty"));
        }
      });
    },
    createEmptyRepair(context, bike_id) {
      return new Promise((resolve, reject) => {
        let url = `${API_ENDPOINT}v2/repairs`;
        axios
          .post(url, { bike_id })
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(parseError(error));
          });
      });
    },
    loadAll({ commit }, bike_id) {
      return new Promise((resolve, reject) => {
        let url = `${API_ENDPOINT}v2/repairs/${bike_id}`;
        axios
          .get(url)
          .then(result => {
            commit("setList", result.data);
            resolve();
          })
          .catch(error => {
            reject(parseError(error));
          });
      });
    },
    loadLatest({ commit, getters }, bike_id) {
      return new Promise((resolve, reject) => {
        let url = `${API_ENDPOINT}v2/repairs/latest/${bike_id}`;
        if (getters.forShow) {
          url += `?repair=${getters.forShow}`;
        }
        axios
          .get(url)
          .then(result => {
            commit("setRepair", result.data.data);
            resolve();
          })
          .catch(error => {
            reject(parseError(error));
          });
      });
    },
    updateDeliveryAddress({ commit }, address) {
      return new Promise(resolve => {
        address.country = address.country.label;
        commit("updateDeliveryAddress", address);
        setTimeout(() => {
          resolve();
        }, 0);
      });
    }
  },
  mutations: {
    setRepairForShow(state, value) {
      state.forShow = value;
    },
    setDiscountType(state, value) {
      state.repair.discountType = value.code;
    },
    setDiscountValue(state, value) {
      state.repair.discountValue = value;
    },
    setServiceCenter(state, center) {
      state.repair.serviceCenter = { ...center };
    },
    updateDeliveryAddress(state, address) {
      let stateAddress = state.repair.address;
      Object.assign(stateAddress, address);
      state.repair.address = stateAddress;
    },
    setList(state, list) {
      state.list = list;
    },
    setRepair(state, value) {
      let repair = new Repair();
      if (value) {
        repair.id = value.id;
        repair.history = value.history;
        repair.address = value.address;
        repair.items = value.items;
        repair.payments = value.payments;
        repair.status = value.status;
        repair.invoiceNumber = value.invoice_number;
        repair.serviceCenter = value["service_center"];
        repair.confirmationDocument = value["confirmation_document"];
        repair.discountType = value["discount_type"];
        repair.discountValue = value["discount_value"];
        state.repair = repair;
      } else {
        state.repair = null;
      }
    },
    addRepairItem(state, item) {
      item.id = null;
      state.repair.items.push({ ...item });
    },
    removeRepairItem(state, item) {
      state.repair.items = state.repair.items.filter(repair => {
        return !(repair.title === item.title && repair.price === item.price);
      });
    },
    isRepairTab(state, value) {
      state.isRepairTab = value;
    }
  },
  getters: {
    forShow(state) {
      return state.forShow;
    },
    repair(state) {
      return state.repair;
    },
    isRepairTab(state) {
      return state.isRepairTab;
    },
    list(state) {
      return state.list;
    }
  }
};
