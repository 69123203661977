<template>
  <CRMLayout>
    <template v-if="email" v-slot:header-title>
      <span>CV from {{ email }}</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div v-if="email" class="main__headline">
            <h5>CV from {{ email }}</h5>
          </div>
        </div>

        <div class="main__content">
          <div class="main__content-in">
            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Name</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__xl">
                  <input v-model="name" :disabled="true" type="text" />
                </div>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>E-Mail</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__xl">
                  <input v-model="email" :disabled="true" type="text" />
                </div>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Position</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__xl">
                  <input v-model="position" :disabled="true" type="text" />
                </div>
              </div>
            </div>

            <div class="main__content-item main__content-item_center">
              <div class="main__content-item_l">
                <span>Message</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__textarea inp-group__xl">
                  <textarea
                    v-model="message"
                    :disabled="true"
                    type="text"
                  ></textarea>
                </div>
              </div>
            </div>

            <div
              v-if="attached_file"
              class="main__content-item main__content-item_center"
            >
              <div class="main__content-item_l">
                <span>Attached File</span>
              </div>

              <div class="main__content-item_r">
                <div class="inp-group inp-group__xl">
                  <a :href="downloadLink" target="_blank">Download CV</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";

export default {
  name: "CRMCVShow",
  components: { CRMLayout },
  data: () => ({
    email: "",
    name: "",
    position: "",
    message: "",
    attached_file: "",
    departments: [],
    currentDepartment: null
  }),
  mounted() {
    this.loadDepartments();
  },
  computed: {
    downloadLink() {
      return this.attached_file;
    }
  },
  methods: {
    loadDepartments() {
      let ctx = this;
      this.$store
        .dispatch("get_vacancy_department_request")
        .then(response => {
          this.departments = response.data.map(item => {
            return {
              code: item.id,
              label: item.title
            };
          });

          this.loadCV();
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    loadCV() {
      this.id = parseInt(this.$route.params.id);
      let ctx = this;
      this.$store
        .dispatch("get_cv_single_request", this.$route.params.id)
        .then(response => {
          ctx.parseCV(response);
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    parseCV(response) {
      for (let key of Object.keys(response.data)) {
        // eslint-disable-next-line
        if (this.hasOwnProperty(key)) {
          this[key] = response.data[key];
        }
      }

      if (this.departments.length > 0) {
        this.currentDepartment =
          this.departments.filter(item => {
            return item.code === response.data.department_id;
          })[0] || null;
      }
    }
  }
};
</script>

<style scoped>
.red {
  color: red;
}
</style>
