<template>
  <input
    ref="custom-input"
    :disabled="disabled"
    :title="title"
    :value="inputValue"
    @input="handleInput"
  />
</template>

<script>
export default {
  name: "FSmallText",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    type: {
      type: String,
      required: false,
      default: "text"
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      inputValue: null
    };
  },
  mounted() {
    let input = this.$refs["custom-input"];
    if (this.type === "number") {
      input.addEventListener("paste", this.handlePaste);
    }
  },
  beforeDestroy() {
    if (this.type === "number") {
      let input = this.$refs["custom-input"];
      input.removeEventListener("paste", this.handlePaste);
    }
  },
  methods: {
    formatValue(value) {
      if (this.type === "number") {
        if (typeof value === "string") {
          value = value.replace(",", ".");
          value = value.replace(/[^0-9.]/g, "");
        }
        if (this.max !== null && value > this.max) {
          value = this.max;
        }
      }
      return value;
    },
    handlePaste(event) {
      this.inputValue = this.formatValue(
        (event.clipboardData || window.clipboardData).getData("text")
      );

      event.preventDefault();
    },
    handleInput(e) {
      this.inputValue = e.target.value;
    }
  },
  watch: {
    inputValue: function(newValue) {
      this.inputValue = this.formatValue(newValue);
      this.$emit("input", this.inputValue);
    },
    value(newValue, oldValue) {
      if ((oldValue === null || oldValue === 0) && newValue !== null) {
        this.inputValue = newValue;
      }
    }
  }
};
</script>
