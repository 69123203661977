<template>
  <div class="order__items-footer">
    <div class="order__items-payment">
      <span v-if="order_id && invoice_number">
        Pro forma #<a class="blueLink" href="#" @click="downloadPdf()">{{
          invoice_number
        }}</a>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OrderInvoiceNumber",
  computed: {
    ...mapState({
      order_id: state => state.orderItem.id,
      invoice_number: state => state.orderItem.invoice_number
    })
  },
  methods: {
    downloadPdf() {
      this.$store
        .dispatch("download_order_pdf_request", this.order_id)
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          let fileName = `pro-forma-${this.order_id}`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        });
    }
  }
};
</script>

<style scoped>
.order__items-footer {
  margin-top: 30px;
}

.order__items-payment {
  margin-left: 12px;
}
</style>
