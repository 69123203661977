import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "@/store/helpers/parseError";

export default {
  namespaced: true,
  state() {
    return {
      list: []
    };
  },
  actions: {
    load({ commit }) {
      return new Promise((resolve, reject) => {
        let url = `${API_ENDPOINT}v2/service-centers`;
        axios
          .get(url)
          .then(result => {
            commit("setList", result.data);
            resolve();
          })
          .catch(error => {
            reject(parseError(error));
          });
      });
    }
  },
  mutations: {
    setList(state, list) {
      state.list = list;
    }
  },
  getters: {
    list(state) {
      return state.list;
    },
    forSelect(state) {
      return [...state.list].map(item => {
        return {
          code: item.id,
          label: item.title
        };
      });
    }
  }
};
