<template>
  <div class="trans-item">
    <div class="btn-burger centered pointer"></div>
    <div class="wrapper">
      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l">
          <span>Text:</span>
        </div>
        <div class="main__content-item_r">
          <p class="inp-group__text">Accessories box</p>
        </div>
      </div>
      <div class="main__content-item">
        <div class="main__content-item_l">
          <span>List:</span>
        </div>
        <div class="main__content-item_r">
          <div class="inp-group__dropdown inp-group__textarea inp-group__xl">
            <textarea v-model="value.text" title="List" @input="handleInput" />
          </div>
        </div>
      </div>
    </div>
    <button class="field__remove" @click="removeSection"></button>
  </div>
</template>

<script>
export default {
  name: "AccessoriesBox",
  props: ["value"],
  methods: {
    handleInput(event) {
      let emitted = { ...this.value };
      emitted.text = event.target.value;
      this.$emit("input", emitted);
    },
    removeSection() {
      this.$emit("onRemove");
    }
  }
};
</script>
