// initial state

const DEFAULT_LANGUAGE = "en";

const state = {
  list: ["en", "es", "de", "fr", "ru"],
  activeLanguage: DEFAULT_LANGUAGE
};

// getters
const getters = {
  isActive: state => language => state.activeLanguage === language
};

// actions
const actions = {
  change({ commit }, lang) {
    commit("set_active_language", lang);
  }
};

// mutations
const mutations = {
  set_active_language(state, value) {
    state.activeLanguage = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
