<template>
  <div v-if="show" class="pagination">
    <ul class="list-unstyled">
      <li>
        <a @click="showFirstPage()">First</a>
      </li>
      <li
        v-for="page in getFirstPartPages"
        :class="getActiveClass(page)"
        :key="page.id"
      >
        <a @click="showPage(page)">{{ page }}</a>
      </li>
      <li v-if="showEllipsis">
        <a class="no__pointer">...</a>
      </li>
      <li
        v-for="page in getSecondPartPages"
        :class="getActiveClass(page)"
        :key="page.id"
      >
        <a @click="showPage(page)">{{ page }}</a>
      </li>
      <li>
        <a @click="showLastPage()">Last</a>
      </li>
    </ul>
  </div>
  <!-- .pagination -->
</template>

<script>
export default {
  name: "Paginator",
  data() {
    return {
      show: false,
      pages: [1],
      activePage: 1,
      fireEvent: true
    };
  },
  created() {
    this.generatePages(this.lastPage);
    this.show = this.pages.length !== 1;
  },
  mounted() {
    let ctx = this;
    this.$root.$on("setDefaultOptions", () => {
      ctx.activePage = 1;
      ctx.fireEvent = false;
    });
  },
  methods: {
    generatePages(lastPage) {
      this.pages = [];
      for (let i = 1; i < lastPage + 1; i++) {
        this.pages.push(i);
      }
    },
    showFirstPage() {
      this.activePage = this.pages[0];
    },
    showLastPage() {
      this.activePage = this.pages[this.pages.length - 1];
    },
    showPage(number) {
      this.activePage = number;
    },
    getActiveClass(page) {
      if (page === this.activePage) {
        return "active";
      }
      return "";
    }
  },
  computed: {
    getFirstPartPages() {
      if (this.pages.length < 6) {
        return this.pages;
      } else {
        let pagesLength = this.pages.length;
        let activePagePosition = this.pages.indexOf(this.activePage);
        let startPosition = 0;
        let countBefore = 3;
        let countAfter = 2;
        if (activePagePosition > 2) {
          startPosition = activePagePosition - 2;
        }

        let diff = pagesLength - (activePagePosition + 1);
        if (diff < 2) {
          countAfter = diff;
        }

        if (diff === 1) {
          countBefore = 4;
          startPosition -= 1;
        }

        if (diff === 0) {
          countBefore = 5;
          startPosition -= 2;
        }

        let pagesBeforeActive = this.pages.slice(
          startPosition,
          startPosition + countBefore
        );
        let pagesAfterActive = this.pages.slice(
          startPosition + countBefore,
          startPosition + countBefore + countAfter
        );

        if (diff === 0) {
          return pagesBeforeActive;
        } else {
          return [...pagesBeforeActive, ...pagesAfterActive];
        }
      }
    },
    getSecondPartPages() {
      let pagesLength = this.pages.length;
      let activePagePosition = this.pages.indexOf(this.activePage) + 1;
      if (pagesLength < 9) {
        return [];
      } else {
        let diff = 0;

        if (pagesLength - activePagePosition === 3) {
          diff = 1;
        }

        if (pagesLength - activePagePosition === 2) {
          diff = 2;
        }

        if (pagesLength - activePagePosition <= 1) {
          return [];
        }

        return this.pages.slice(pagesLength - (2 - diff), pagesLength);
      }
    },
    showEllipsis() {
      let activePagePosition = this.pages.indexOf(this.activePage) + 1;
      return (
        this.pages.length > 9 && this.pages.length - activePagePosition > 4
      );
    }
  },
  props: {
    lastPage: {
      type: Number,
      default: 1
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  watch: {
    pages: {
      handler: function(pages) {
        this.show = pages.length !== 1;
      }
    },
    lastPage: {
      handler: function(number) {
        this.generatePages(number);
      }
    },
    activePage: {
      handler: function() {
        if (this.fireEvent) {
          this.$emit("changepage", this.activePage);
        } else {
          this.fireEvent = true;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.wrapTotal {
  margin: 45px 0 35px;
  min-height: 13px;
  .pagination {
    margin: 0 0 15px;
  }
}
</style>
