<template>
  <div class="main__content-item main__content-item_center">
    <div class="main__content-item_l">
      <span>{{ title }}:</span>
    </div>
    <div class="main__content-item_r">
      <div class="inp-group inp-group__checkbox">
        <input
          :id="`test-${title}`"
          :checked="value"
          :disabled="true"
          type="checkbox"
        />
        <label :for="`test-${title}`"></label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckBoxReadOnly",
  props: ["value", "title"]
};
</script>
