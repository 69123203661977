<template>
  <div class="main__l">
    <nav class="navigation">
      <ul class="list-unstyled">
        <li class="navigation__item">
          <router-link
            :to="{ name: 'CRMDashBoard' }"
            active-class="is-active"
            exact
            href=""
          >
            <span class="navigation__icon">
              <img
                alt="Dashboard"
                src="../../assets/img/icons/navigation/dashboard.svg"
              />
            </span>

            <span class="navigation__desc">Dashboard</span>
          </router-link>
        </li>

        <li
          v-if="isCanViewProducts()"
          class="navigation__item"
        >
          <router-link
            :to="{ name: 'CRMNewProducts' }"
            active-class="is-active"
            exact
            href=""
          >
            <span class="navigation__icon">
              <img
                alt="Builds"
                src="../../assets/img/icons/navigation/builds.svg"
              />
            </span>

            <span class="navigation__desc">Builds</span>
          </router-link>
        </li>

        <li
          v-if="isCanViewCustomers()"
          class="navigation__item"
        >
          <router-link
            :to="{ name: 'CRMCustomerList' }"
            active-class="is-active"
            exact
            href=""
          >
            <span class="navigation__icon">
              <img
                alt="Customers"
                src="../../assets/img/icons/navigation/customers.svg"
              />
            </span>

            <span class="navigation__desc">Customers</span>
          </router-link>
        </li>

        <li
          v-if="isCanViewOrders()"
          class="navigation__item"
        >
          <router-link
            :to="{ name: 'CRMOrders' }"
            active-class="is-active"
            exact
            href=""
          >
            <span class="navigation__icon">
              <img
                alt="Orders"
                src="../../assets/img/icons/navigation/orders.svg"
              />
            </span>

            <span class="navigation__desc"
              >Orders
              <span
                class="badge"
                v-if="savedOrders > 0"
                >{{ savedOrders }}</span
              ></span
            >
          </router-link>
        </li>

        <li
          v-if="isCanViewBikes()"
          class="navigation__item"
        >
          <router-link
            :to="{ name: 'UserBikesList' }"
            active-class="is-active"
            exact
          >
            <span class="navigation__icon">
              <img
                alt="User bikes"
                src="../../assets/img/icons/navigation/user-bikes.svg"
              />
            </span>

            <span class="navigation__desc">Bikes</span>
          </router-link>
        </li>

        <li
          v-if="isCanViewNews()"
          class="navigation__item"
        >
          <router-link
            :to="{ name: 'CRMPosts' }"
            active-class="is-active"
            exact
          >
            <span class="navigation__icon">
              <img
                alt="News"
                src="../../assets/img/icons/navigation/news.svg"
              />
            </span>

            <span class="navigation__desc">News</span>
          </router-link>
        </li>

        <li class="navigation__item">
          <router-link
            :to="{ name: 'CRMAdmin' }"
            active-class="is-active"
            exact
            href=""
          >
            <span class="navigation__icon">
              <img
                alt="Admin"
                src="../../assets/img/icons/navigation/admin.svg"
              />
            </span>

            <span class="navigation__desc">Admin</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import { API_BASE_PATH } from "../../utils/api";

export default {
  name: "MainLeft",
  data() {
    return {
      savedOrders: 0,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  mounted() {
    if (this.isCanViewOrders()) {
      axios
        .get(API_BASE_PATH + "/api/crm/savedOrders")
        .then((response) => {
          this.savedOrders = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {
    isCanViewNews() {
      for (let permission of this.user["user_type"].premissions) {
        if (permission["premisson_id"] === 4) {
          return permission.view === 1;
        }
      }
    },
    isCanViewProducts() {
      for (let permission of this.user["user_type"].premissions) {
        if (permission["premisson_id"] === 1) {
          return permission.view === 1;
        }
      }
    },
    isCanViewCustomers() {
      for (let permission of this.user["user_type"].premissions) {
        if (permission["premisson_id"] === 2) {
          return permission.view === 1;
        }
      }
    },
    isCanViewOrders() {
      for (let permission of this.user["user_type"].premissions) {
        if (permission["premisson_id"] === 3) {
          return permission.view === 1;
        }
      }
    },
    isCanViewRoles() {
      return this.user.user_type_id === 1;
    },
    isCanViewBikes() {
      return [1, 2, 4].includes(this.user.user_type_id);
    },
  },
};
</script>

<style lang="scss">
.badge {
  background: #000;
  color: #fff;
  border-radius: 50rem;
  // font-size: 0.75em;
  padding: 0.35em 0.65em;
  text-align: center;
}
</style>
