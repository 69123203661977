<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMVacancyDepartmentCreate' }"
        class="btn btn-white btn-reset add-models-btn"
        >Add Department
      </router-link>
    </template>

    <template v-slot:header-title>
      <span>List of vacancy departments</span>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Departments</h5>
          </div>

          <table v-if="hasDepartments" class="table__typical table__orders">
            <thead>
              <tr>
                <th class="pointer">
                  <span>ID</span>
                  <span></span>
                </th>

                <th class="pointer">
                  <span>Title (EN)</span>
                </th>

                <th class="pointer">
                  <span>Title (ES)</span>
                </th>

                <th class="pointer">
                  <span>Title (FR)</span>
                </th>

                <th class="pointer">
                  <span>Title (DE)</span>
                </th>

                <th class="pointer">
                  <span>Title (RU)</span>
                </th>

                <th class="pointer">
                  <span>Created</span>
                </th>
              </tr>
            </thead>
            <draggable
              v-model="departments"
              :sort="true"
              tag="tbody"
              @change="sort"
            >
              <tr
                v-for="department in departments"
                :key="department.id"
                @click="showDepartment($event, department)"
              >
                <td>{{ department.id }}</td>
                <td>{{ department.title }}</td>
                <td>{{ department.title_es }}</td>
                <td>{{ department.title_fr }}</td>
                <td>{{ department.title_de }}</td>
                <td>{{ department.title_ru }}</td>
                <td>{{ department.created_at }}</td>
              </tr>
            </draggable>
          </table>

          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";
import draggable from "vuedraggable";

export default {
  name: "CRMVacancyDepartmentList",
  components: { CRMLayout, Paginator, draggable },
  data: () => ({
    departments: [],
    currentPage: 1,
    lastPage: 1,
    updateList: true
  }),
  mounted() {
    this.loadDepartments();
  },
  computed: {
    hasDepartments() {
      return this.departments.length > 0;
    }
  },
  methods: {
    loadDepartments() {
      let ctx = this;
      this.$store
        .dispatch("get_vacancy_department_request", { page: this.currentPage })
        .then(response => {
          this.lastPage = response.data.meta["last_page"];
          this.currentPage = response.data.meta["current_page"];

          this.departments = response.data.data.map(item => {
            return {
              id: item.id,
              title: item.title,
              title_es: item.title_es || "NOT SET",
              title_fr: item.title_fr || "NOT SET",
              title_de: item.title_de || "NOT SET",
              title_ru: item.title_ru || "NOT SET",
              created_at: ctx.$moment(this.created_at).format("DD.MM.YYYY")
            };
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
    sort() {
      let sortable = Array.from(this.departments, department => department.id);
      this.$store
        .dispatch("sort_vacancy_department_request", sortable)
        .catch(err => {
          this.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadDepartments();
    },
    showDepartment(event, department) {
      if (event.target.localName === "td") {
        this.$router.push({ path: `/departments/${department.id}` });
      }
    }
  }
};
</script>

<style scoped>
.red {
  color: red;
}

table {
  width: 100%;
}

.main__c-in {
  overflow: auto;
}
</style>
