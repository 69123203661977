import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import parseError from "@/store/helpers/parseError";

const state = {
  list: [],
  groups: [],
  shortList: []
};

const getters = {
  list(state) {
    return state.list;
  },
  groups(state) {
    return state.groups;
  },
  shortList(state) {
    return state.shortList;
  }
};
const actions = {
  loadSingle(context, id) {
    return new Promise((resolve, reject) => {
      if (id && id !== "create" && id !== undefined && id !== "undefined") {
        let url = `${API_ENDPOINT}v2/colors/${id}`;
        axios
          .get(url)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(parseError(err));
          });
      } else {
        resolve(null);
      }
    });
  },
  load({ commit }, data = null) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/colors?page=`;
      if (data && data.page) {
        url += data.page;
      }
      axios
        .get(url)
        .then(response => {
          commit("setList", response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadGroups({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}v2/colors/groups`)
        .then(response => {
          commit("setGroupList", response.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  short({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}v2/colors/short`)
        .then(response => {
          commit("setShortList", response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  save(context, data) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}v2/colors`;
      let method = "post";
      if (data.id !== null) {
        url += `/${data.id}`;
        method = "put";
      }
      delete data.id;
      axios[method](url, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  }
};
const mutations = {
  setList(state, colors) {
    state.list = colors;
  },
  setGroupList(state, groups) {
    state.groups = groups;
  },
  setShortList(state, colors) {
    state.shortList = colors;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
