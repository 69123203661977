<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Admin\Support category create</span>
    </template>

    <template v-slot:content>
      <div class="main__c bg-transparent">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Support category</h5>
          </div>
          <!-- .main__headline -->

          <div class="main__content">
            <div class="">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.formNamePrefix"
                  :class="isActive(language) ? 'active' : ''"
                >
                  <a @click="switchActiveLanguage(language)">{{
                    language.title
                  }}</a>
                </li>
              </ul>

              <div class="main__content tab-pane">
                <div class="main__content-in">
                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="language.formNamePrefix"
                  >
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Title:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.category[language.formNamePrefix + 'title']
                            "
                            autofocus
                            type="text"
                          />
                        </div>
                        <!-- .inp-group inp-group__xl -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->

                    <div
                      v-if="!currentParentCategory"
                      class="main__content-item"
                      style="margin-bottom: 15px"
                    >
                      <div class="main__content-item_l">
                        <span>Description:</span>
                      </div>
                      <!-- .main__content-item_l -->

                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <textarea
                            v-model="
                              $data.category[language.formNamePrefix + 'desc']
                            "
                          ></textarea>
                        </div>
                        <!-- .inp-group -->
                      </div>
                      <!-- .main__content-item_r -->
                    </div>
                    <!-- .main__content-item -->
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Parent category:</span>
                    </div>
                    <!-- .main__content-item_l -->

                    <div class="main__content-item_r">
                      <div class="inp-group__dropdown inp-group__xl">
                        <v-select
                          v-model="currentParentCategory"
                          :options="categories"
                          label="label"
                          placeholder="Select parent category(optional)"
                        />
                      </div>
                      <!-- .inp-group__dropdown -->
                    </div>
                    <!-- .main__content-item_r -->
                  </div>
                  <!-- .main__content-item -->

                  <div v-if="!currentParentCategory" class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Model image(carousel):</span>
                    </div>
                    <!-- .main__content-item_l -->

                    <div class="main__content-item_r">
                      <dropzone
                        :id="'slider_image'"
                        :options="getDropzoneSettings"
                        dict-default-message="Drag&drop or <u>click</u> here to upload image (jpg 512x512 up to 15MB)"
                        @vdropzone-files-added="saveTempCarouselImage($event)"
                        @vdropzone-removed-file="
                          deleteTempCarouselImage($event)
                        "
                      />
                    </div>
                    <!-- .main__content-item_r -->
                  </div>
                  <!-- .main__content-item -->
                </div>
                <!-- .main__content-in -->
              </div>
            </div>
          </div>
          <!-- .main__content -->
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Publication settings</h5>
        </div>
        <!-- .main__headline -->

        <div class="main__r-in">
          <div class="inp-group__wrapper inp-group__from-to">
            <div class="inp-group__headline">
              <h5>Published</h5>
            </div>
            <!-- .inp-group__headline -->

            <div class="inp-group__in">
              <div class="inp-group__radio-wrapper">
                <div class="inp-group__radio">
                  <input
                    id="on"
                    v-model="category.active"
                    :value="1"
                    name="radio"
                    type="radio"
                  />
                  <label for="on">ON</label>
                </div>
                <!-- .inp-group__radio -->

                <div class="inp-group__radio">
                  <input
                    id="off"
                    v-model="category.active"
                    :value="0"
                    name="radio"
                    type="radio"
                  />
                  <label for="off">OFF</label>
                </div>
                <!-- .inp-group__radio -->
              </div>
              <!-- .inp-group__radio-wrapper -->
            </div>
            <!-- .inp-group__in -->
          </div>
          <!-- .inp-group__from-to -->

          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-fw btn-bold btn-sm"
              @click="save"
            >
              Save
            </button>
          </div>
          <!-- .btn__wrapper -->
        </div>
        <!-- .main__r-in -->
      </div>
      <!-- .main__r -->
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import vSelect from "vue-select";
import Dropzone from "./forms/inputs/Dropzone";

export default {
  name: "CRMSupportCategoriesCreate",
  components: { CRMLayout, vSelect, Dropzone },
  data() {
    return {
      category: {
        title: null,
        es_title: null,
        de_title: null,
        fr_title: null,
        ru_title: null,
        desc: null,
        es_desc: null,
        de_desc: null,
        fr_desc: null,
        ru_desc: null,
        parent_id: null,
        active: 0
      },
      img: null,
      categories: [],
      currentParentCategory: null,
      languages: [
        {
          id: "en",
          title: "EN",
          formNamePrefix: ""
        },
        {
          id: "es",
          title: "ES",
          formNamePrefix: "es_"
        },
        {
          id: "de",
          title: "DE",
          formNamePrefix: "de_"
        },
        {
          id: "fr",
          title: "FR",
          formNamePrefix: "fr_"
        },
        {
          id: "ru",
          title: "RU",
          formNamePrefix: "ru_"
        }
      ],
      activeLanguage: {
        id: "en",
        title: "EN",
        formNamePrefix: ""
      }
    };
  },
  computed: {
    getDropzoneSettings() {
      return {
        url: `update`,
        uploadMultiple: false,
        paramName: "random",
        autoProcessQueue: false,
        addRemoveLinks: true,
        preventDuplicates: true
      };
    }
  },
  mounted() {
    this.loadCategories();
  },
  methods: {
    loadCategories() {
      let ctx = this;
      this.$store
        .dispatch("get_support_categories_request", { withChildren: false })
        .then(response => {
          let categories = response.data.categories.filter(category => {
            return !category.parent_id;
          });
          ctx.categories = categories.map(category => {
            return {
              code: category.id,
              label: category.title
            };
          });
        })
        .catch(err => {
          this.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    isActive(language) {
      return language.id === this.activeLanguage.id;
    },
    switchActiveLanguage(language) {
      this.activeLanguage = language;
    },
    saveTempCarouselImage(file) {
      this.img = file[0];
    },
    deleteTempCarouselImage() {
      this.img = null;
    },
    save() {
      let ctx = this;

      let data = new FormData();
      data.append("title", this.category.title);
      data.append("es_title", this.category.es_title);
      data.append("de_title", this.category.de_title);
      data.append("fr_title", this.category.fr_title);
      data.append("ru_title", this.category.ru_title);
      data.append("desc", this.category.desc);
      data.append("es_desc", this.category.es_desc);
      data.append("de_desc", this.category.de_desc);
      data.append("fr_desc", this.category.fr_desc);
      data.append("ru_desc", this.category.ru_desc);
      data.append("active", this.category.active);
      data.append(
        "parent_id",
        this.currentParentCategory ? this.currentParentCategory.code : null
      );
      data.append("img", this.img);

      this.$store
        .dispatch("store_support_category_request", data)
        .then(() => {
          ctx.$router.push("/supports");
          ctx.$root.$emit("modalShow", {
            text: "Category successfully added",
            type: "info"
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    }
  }
};
</script>
