import moment from "moment";

class Repair {
  id = null;
  status = null;
  discountValue = null;

  _invoiceNumber = null;

  get invoiceNumber() {
    return this._invoiceNumber;
  }

  set invoiceNumber(value) {
    this._invoiceNumber = value;
  }

  _discountType = null;

  get discountType() {
    if (this._discountType) {
      if (this._discountType === "value") {
        return {
          code: "value",
          label: "Discount, €"
        };
      } else {
        return {
          code: "percent",
          label: "Discount, %"
        };
      }
    }

    return {
      code: "percent",
      label: "Discount, %"
    };
  }

  set discountType(value) {
    if (typeof value === "object" && value !== null) {
      this._discountType = value.code;
    } else {
      this._discountType = value;
    }
  }

  _serviceCenter = null;

  get serviceCenter() {
    return this._serviceCenter;
  }

  set serviceCenter(value) {
    this._serviceCenter = value;
  }

  _address = null;

  get address() {
    return this._address;
  }

  set address(value) {
    this._address = value;
  }

  _history = [];

  get history() {
    return this._history;
  }

  set history(list) {
    this._history = list.map(item => {
      if (item["date"]) {
        let timestamp = moment.unix(item["date"]);
        item.date = timestamp.clone().format("DD/MM/YYYY");
        item.time = timestamp.clone().format("HH:mm");
      }
      return item;
    });
  }

  _items = [];

  get items() {
    return this._items;
  }

  set items(items) {
    this._items = items;
  }

  get request() {
    let address = { ...this._address };
    return {
      items: this._items,
      address: address,
      discount_type: this._discountType,
      discount_value: this.discountValue
    };
  }

  _payments = [];

  get payments() {
    return this._payments;
  }

  set payments(list) {
    this._payments = list.map(item => {
      if (item.paid_at) {
        item.paid_at = moment.unix(item.paid_at).format("DD/MM/YYYY HH:mm");
      }
      item.created_at = moment.unix(item.created_at).format("DD/MM/YYYY HH:mm");
      return item;
    });
  }

  _confirmationDocument = null;

  get confirmationDocument() {
    return this._confirmationDocument;
  }

  set confirmationDocument(value) {
    this._confirmationDocument = value;
  }
}

export default Repair;
