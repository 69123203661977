<template>
  <layout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMProductsCreate' }"
        class="btn btn-white btn-reset add-models-btn"
        >Add product
      </router-link>
      <router-link
        :to="{ name: 'CRMImportComponents' }"
        class="btn btn-white btn-reset add-models-btn"
        >Import Parts
      </router-link>
      <router-link
        :to="{ name: 'CRMImportCountComponents' }"
        class="btn btn-white btn-reset add-models-btn"
        >Import Count And Price
      </router-link>
    </template>

    <template v-slot:header-title>Products list</template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>{{ pageHeadTitle }}</h5>
          </div>
          <div v-if="showTable" class="table__products table">
            <div class="thead">
              <div class="tr">
                <div v-if="isProductType" class="th"></div>
                <div class="pointer id th" @click="sortRows('id')">
                  <span>ID</span>
                  <span :class="arrowClass('id')"></span>
                </div>
                <div
                  class="pointer th"
                  @click="sortRows(isProductType ? 'name' : 'title_cms')"
                >
                  <span>Title</span>
                  <span
                    :class="arrowClass(isProductType ? 'name' : 'title_cms')"
                  ></span>
                </div>
                <div
                  v-if="!isProductType"
                  class="pointer th"
                  @click="sortRows('quantity')"
                >
                  <span>In stock</span>
                  <span :class="arrowClass('quantity')"></span>
                </div>
                <div v-if="isProductType" class="th">
                  Can be<br />
                  produced
                </div>
                <div
                  class="pointer th"
                  @click="sortRows(isProductType ? 'price' : 'price_out')"
                >
                  <span>Price, €</span>
                  <span
                    :class="arrowClass(isProductType ? 'price' : 'price_out')"
                  ></span>
                </div>
                <div
                  v-if="isProductType"
                  class="pointer th"
                  @click="sortRows('store_visibility')"
                >
                  <span>Activity</span>
                  <span :class="arrowClass('store_visibility')"></span>
                </div>
              </div>
            </div>
            <div class="tbody">
              <draggable v-model="items" :sort="true" tag="div" @change="log">
                <template v-for="item in items">
                  <div
                    :key="item.id"
                    :ref="`product-head.${item.id}`"
                    class="ui-state-default tr"
                    @click="showItem($event, item)"
                  >
                    <a :href="getItemLink(item)" class="ui-state-default tr">
                      <div v-if="isProductType" class="td">
                        <div
                          class="btn-burger centered pointer"
                          @click.prevent="showDescription(item)"
                        ></div>
                      </div>
                      <div class="id td">{{ item.id }}</div>
                      <div class="td">{{ item.title_cms }}</div>
                      <div v-if="!isProductType" class="td">
                        {{ item.quantity || "Not set" }}
                      </div>
                      <div v-if="isProductType" class="td">{{ item.cbp }}</div>
                      <div class="td">{{ getProductPrice(item) }}</div>
                      <div v-if="isProductType" class="td">
                        {{ item.active }}
                      </div>
                    </a>
                  </div>
                  <div v-if="isProductType" :key="item.id" class="tr">
                    <div class="no-padding td">
                      <div
                        :ref="`product.${item.id}`"
                        class="product-description"
                      >
                        <div class="column">
                          <div class="wrapper">
                            <div class="left">
                              <ul>
                                <li>liFrame e- carbon 29" enduro multilink</li>
                                <li>
                                  liHorquilla fox 36 factory 29" grip2 kashima
                                  160mm negra rake 51mm
                                </li>
                                <li>liAmortiguador dvo topaz t3air 230 x 60</li>
                                <li>
                                  liDireccion acros integrada (sup. Ai-41
                                  is41/28,6 inf. Ai-52 is52/30+40 )
                                </li>
                                <li>
                                  liAmortiguador fox float x2 factory kashima
                                  230 x 60
                                </li>
                                <li>liManillar carbon gemini kastor</li>
                                <li>liTija rock shox reverb axs 150mm 31.6</li>
                                <li>liSillin fizik monte kium enduro</li>
                                <li>liPuños production privee oem</li>
                                <li>
                                  liPulsador trigger sram xx1 eagle axs
                                  electronic
                                </li>
                                <li>
                                  liCambio trasero sram xx1 eagle axs electronic
                                </li>
                                <li>
                                  liCassette sram xx1 eagle axs electronic
                                </li>
                                <li>liCadena sram eagle axs</li>
                                <li>
                                  liRuedas crank brothers synthesis e11 carbon
                                  29" enduro
                                </li>
                                <li>
                                  liNeulimatico panaracer aliso 29" x 2.4 tr
                                  3comp. ( 900 grs )
                                </li>
                                <li>liFreno delantero magura mt-7</li>
                                <li>liFreno trasero magura mt-7</li>
                                <li>liDisco magura storm hc 183mm 6t.</li>
                                <li>liDisco magura storm hc 203mm 6t.</li>
                              </ul>
                            </div>
                            <div class="right">
                              <ul>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="column">
                          <div class="wrapper">
                            <div class="left">
                              <ul>
                                <li>liFrame e- carbon 29" enduro multilink</li>
                                <li>
                                  liHorquilla fox 36 factory 29" grip2 kashima
                                  160mm negra rake 51mm
                                </li>
                                <li>liAmortiguador dvo topaz t3air 230 x 60</li>
                                <li>
                                  liDireccion acros integrada (sup. Ai-41
                                  is41/28,6 inf. Ai-52 is52/30+40 )
                                </li>
                                <li>
                                  liAmortiguador fox float x2 factory kashima
                                  230 x 60
                                </li>
                                <li>liManillar carbon gemini kastor</li>
                                <li>liTija rock shox reverb axs 150mm 31.6</li>
                                <li>liSillin fizik monte kium enduro</li>
                                <li>liPuños production privee oem</li>
                                <li>
                                  liPulsador trigger sram xx1 eagle axs
                                  electronic
                                </li>
                                <li>
                                  liCambio trasero sram xx1 eagle axs electronic
                                </li>
                                <li>
                                  liCassette sram xx1 eagle axs electronic
                                </li>
                                <li>liCadena sram eagle axs</li>
                                <li>
                                  liRuedas crank brothers synthesis e11 carbon
                                  29" enduro
                                </li>
                                <li>
                                  liNeulimatico panaracer aliso 29" x 2.4 tr
                                  3comp. ( 900 grs )
                                </li>
                                <li>liFreno delantero magura mt-7</li>
                                <li>liFreno trasero magura mt-7</li>
                                <li>liDisco magura storm hc 183mm 6t.</li>
                                <li>liDisco magura storm hc 203mm 6t.</li>
                              </ul>
                            </div>
                            <div class="right">
                              <ul>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                                <li>65</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </div>

          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>

        <div class="inp-group__wrapper">
          <div class="inp-group__headline">
            <h5>Products/Parts</h5>
          </div>

          <div class="inp-group__in">
            <div class="inp-group__dropdown">
              <v-select
                v-model="currentItemType"
                :options="itemTypes"
                label="label"
                placeholder="Select product type"
              />
            </div>
          </div>
        </div>

        <div class="main__r-in">
          <div class="inp-group__wrapper inp-group__from-to">
            <div class="inp-group__headline">
              <h5>Price</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group">
                <label for="from">from</label>
                <input id="from" v-model="price_filter_from" type="number" />
              </div>

              <div class="inp-group">
                <label for="to">to</label>
                <input id="to" v-model="price_filter_to" type="number" />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Created</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="createdAtFilter"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Archived</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__choice">
                <div class="inp-group__choice-item">
                  <input
                    id="yes"
                    :checked="archivedFilter"
                    type="checkbox"
                    @change="acceptArchivedFilter(true)"
                  />
                  <label for="yes">Yes</label>
                </div>

                <div class="inp-group__choice-item">
                  <input
                    id="no"
                    :checked="
                      archivedFilter !== null && archivedFilter === false
                    "
                    type="checkbox"
                    @change="acceptArchivedFilter(false)"
                  />
                  <label for="no">No</label>
                </div>
              </div>
            </div>
          </div>

          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-fw btn-sm btn-bold"
              @click="resetFilters()"
            >
              Reset filter
            </button>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";
import { API_ENDPOINT } from "@/utils/api";
import draggable from "vuedraggable";
import $ from "jquery";

export default {
  name: "CRMDashBoard",
  components: {
    vSelect,
    DatePicker,
    layout: CRMLayout,
    Paginator,
    draggable
  },
  data() {
    return {
      reseting: false,
      updateList: true,
      items: [],
      currentPage: 1,
      lastPage: 1,
      price_filter_from: null,
      price_filter_to: null,
      createdAtFilter: null,
      archivedFilter: null,
      dragging: false,
      total: 0,
      perPage: 0,
      ordering: true,
      order_by: "id",
      previous_order_field: "id",
      currentItemType: {
        code: "products",
        label: "Products"
      },
      itemTypes: [
        {
          code: "products",
          label: "Products"
        },
        {
          code: "parts",
          label: "Parts"
        }
      ]
    };
  },
  created() {
    this.loadItems();
  },
  computed: {
    showTable() {
      return this.items.length;
    },
    pageHeadTitle() {
      return (
        this.currentItemType.code[0].toUpperCase() +
        this.currentItemType.code.substr(1)
      );
    },
    isProductType() {
      return this.currentItemType.code === "products";
    }
  },
  methods: {
    log() {
      if (this.isProductType) {
        this.sortItems(this.items);
      }
    },
    acceptArchivedFilter(value) {
      if (this.archivedFilter === null) {
        this.archivedFilter = value;
      } else if (value === this.archivedFilter) {
        this.archivedFilter = null;
      } else {
        this.archivedFilter = value;
      }

      this.updateList = true;
      this.loadItems();
    },
    getProductPrice(item) {
      if (this.isProductType) {
        return item.price_in || "Not Set";
      }
      return item["price_out"] || "Not Set";
    },
    arrowClass(field) {
      if (field === this.order_by) {
        let classes = "btn-arrow centered";
        return this.ordering ? classes : `${classes} reverse`;
      }
      return "btn-arrow hidden";
    },
    showItem(event, item) {
      if (!$(event.target).hasClass("btn-burger")) {
        if (this.currentItemType.code === "products") {
          this.$router.push(`/products/${item.id}`);
        }
      }
    },
    getItemLink(item) {
      if (this.currentItemType.code === "parts") {
        return false;
      } else if (this.currentItemType.code === "products") {
        return `/products/${item.id}`;
      }
    },
    sortRows(field) {
      this.order_by = field;
      if (
        field === this.previous_order_field ||
        this.previous_order_field === null
      ) {
        this.ordering = !this.ordering;
      } else {
        this.ordering = true;
      }
      this.previous_order_field = this.order_by;
      this.updateList = true;
      this.loadItems();
    },
    resetFilters() {
      this.archivedFilter = null;
      this.updateList = true;
      this.createdAtFilter = null;
      this.reseting = true;
      this.currentPage = 1;
      this.order_by = "id";
      this.ordering = true;
      this.currentItemType = {
        code: "products",
        label: "Products"
      };
      this.lastPage = 1;
      this.loadItems();
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadItems();
    },
    sortItems(array) {
      this.$store.dispatch("sort_product_items_request", array).then(() => {});
    },
    loadItems() {
      let ctx = this;

      let options = {
        parameters: []
      };

      options.parameters.push(`page=${this.currentPage}`);
      options.type = this.currentItemType.code;

      if (this.createdAtFilter) {
        options.parameters.push(`created_at=${this.createdAtFilter}`);
      }

      if (this.archivedFilter !== null) {
        options.parameters.push(
          `archived=${ctx.archivedFilter ? "true" : "false"}`
        );
      }

      if (this.price_filter_from) {
        options.parameters.push(`price_from=${this.price_filter_from}`);
      }

      if (this.price_filter_to) {
        options.parameters.push(`price_to=${this.price_filter_to}`);
      }

      options.parameters.push(`order_by=${this.order_by}`);
      options.parameters.push(`order=${this.ordering ? "desc" : "asc"}`);

      this.$store
        .dispatch("get_product_items_request", options)
        .then(response => {
          if (ctx.updateList) {
            if (
              ctx.currentItemType.code === "parts" &&
              response.data.path === API_ENDPOINT + "getParts"
            ) {
              ctx.items = response.data.data;
            } else if (
              ctx.currentItemType.code === "products" &&
              response.data.path === API_ENDPOINT + "products"
            ) {
              ctx.items = response.data.data.map(item => {
                return {
                  id: item.id,
                  title_cms: item.name,
                  price_in: item.price,
                  quantity: 0,
                  cbp: item.cbp,
                  active: item.store_visibility === 1 ? "Yes" : "No"
                };
              });
            }
            ctx.updateList = true;
            ctx.currentPage = response.data["current_page"];
            ctx.lastPage = response.data["last_page"];
            ctx.total = response.data.total;
            ctx.perPage = response.data["per_page"];

            if (ctx.items.length < 6) {
              // noinspection JSCheckFunctionSignatures
              $("body, html").animate({ scrollTop: 0 }, 500, "swing");
            }
          } else {
            ctx.items = [...ctx.items, ...response.data.data];
          }
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    showDescription(item) {
      let descWrapper = $(this.$refs[`product.${item.id}`]);
      let productHead = $(this.$refs[`product-head.${item.id}`]);
      descWrapper.toggleClass("open");
      productHead.toggleClass("open");
    }
  },
  watch: {
    price_filter_from: function() {
      this.updateList = true;
      this.loadItems();
    },
    price_filter_to: function() {
      this.updateList = true;
      this.loadItems();
    },
    createdAtFilter: function() {
      this.updateList = true;
      this.loadItems();
    },
    currentItemType() {
      this.ordering = true;
      this.order_by = "id";
      this.updateList = true;
      this.loadItems();
    }
  }
};
</script>

<style scoped>
.no-padding {
  padding: 0;
}

.ui-state-default.open {
  background: #f1f1f1;
}

.ui-state-default.open .td {
  border: none;
}

.product-description {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
  width: auto !important;
  text-align: left !important;
  padding: 0;
  display: flex;
}

.product-description ul {
  list-style: none outside none;
  max-width: 350px;
}

.product-description ul li {
  margin-bottom: 5px;
}

.product-description .column {
  width: 50%;
}

.product-description .column .wrapper {
  display: flex;
}

.product-description .column .wrapper .left {
  margin-left: 0;
  margin-right: 10px;
}

.product-description .column .wrapper .right {
  margin-left: 0;
  margin-right: 0;
}

.product-description.open {
  max-height: 1000px;
  padding: 0 15px 15px 102px;
  background: #f1f1f1;
}

.no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.id.td:first-child,
.id.th:first-child {
  padding-right: 10px;
  width: 60px !important;
  box-sizing: border-box;
  text-align: left !important;
  padding-left: 20px;
}

.id.td:first-child ~ .td:nth-child(2),
.id.th:first-child ~ .th:nth-child(2) {
  width: 200px;
}

.id.td:first-child ~ .td:nth-child(3),
.id.th:first-child ~ .th:nth-child(3) {
  width: calc(100% - 360px);
}

.id.td:first-child ~ .td:last-child,
.id.th:first-child ~ .th:last-child {
  width: 100px;
}

.id.td:first-child ~ .td,
.id.th:first-child ~ .th {
  padding-right: 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .table__products .td:not(.toggle__items):nth-child(4),
  .table__products .td:not(.toggle__items):nth-child(5),
  .table__products .td:not(.toggle__items):nth-child(6),
  .table__products .td:not(.toggle__items):nth-child(7),
  .table__products .th:nth-child(4),
  .table__products .th:nth-child(5),
  .table__products .th:nth-child(6),
  .table__products .th:nth-child(7) {
    width: 20%;
  }
}
</style>
