import { unix } from "moment";

export const STATUS_ORDERED = "ordered";
export const STATUS_PAID = "paid";
export const STATUS_FAILED = "failed";
export const STATUS_UPLOADED = "uploaded";

export default class OrderInvoice {
  id = null;
  amount = 0;
  description = null;
  invoice_file = {
    url: null,
    name: null
  };
  manager = {
    id: null,
    f_name: null,
    l_name: null
  };
  created_at = null;
  status = "";
  manual = false;
  receipt = {
    id: null,
    file: "",
    created_at: null,
    manager: {
      id: null,
      f_name: null,
      l_name: null
    }
  };
  payment_method = {
    id: null,
    title: ""
  };

  get manager_full_name() {
    return [this.manager.l_name, this.manager.f_name].join(" ");
  }

  get receipt_manager_full_name() {
    return [this.receipt.manager.l_name, this.receipt.manager.f_name].join(" ");
  }

  static parseFromResponse(data) {
    let self = new OrderInvoice();
    self.id = data.id;
    self.amount = data.amount;
    self.description = data.description;
    self.invoice_file = {
      url: data.invoice_file,
      name: data.invoice_file_name
    };
    if (data.manager) {
      self.manager = {
        id: data.manager.id || null,
        f_name: data.manager.f_name || null,
        l_name: data.manager.l_name || null
      };
    }
    self.created_at = unix(data.created_at).format("DD.MM.YYYY H:mm");
    self.status = data.status;
    self.manual = data.manual;
    if (data.receipt) {
      self.receipt = {
        id: data.receipt.id,
        file: data.receipt.file,
        created_at: unix(data.receipt.created_at).format("DD.MM.YYYY H:mm")
      };

      if (data.receipt.manager) {
        self.receipt.manager = {
          id: data.receipt.manager.id || null,
          f_name: data.receipt.manager.f_name || null,
          l_name: data.receipt.manager.l_name || null
        };
      }
    }
    if (data.payment_method) {
      self.payment_method = {
        id: data.payment_method.id,
        title: data.payment_method.title
      };
    }

    return self;
  }

  canDelete() {
    return this.status === STATUS_ORDERED && this.manual;
  }

  isStatusUploaded() {
    return this.status === STATUS_UPLOADED;
  }

  isRequiresConfirmation() {
    return this.status === STATUS_ORDERED;
  }

  isPaid() {
    return this.status === STATUS_PAID;
  }

  hasReceiptFile() {
    return !!this.receipt.id && !!this.receipt.file;
  }

  isPaymentWasConfirmed() {
    return this.isStatusUploaded() || this.isPaid();
  }

  hasPaymentMethod() {
    return (
      this.isPaymentWasConfirmed() &&
      !!this.payment_method.id &&
      !!this.payment_method.title
    );
  }
}
