<template>
  <div class="add-product-wrap">
    <div
      v-if="can_product_edit"
      class="add-product-btn"
      @click="toggleModels()"
    >
      <span :class="{ reverse: !showModels }" class="arrow"></span>
      Add product
    </div>

    <div v-if="can_product_edit" v-show="showModels">
      <adding-product-model
        v-for="(model, index) in models"
        :key="index"
        :model="model"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_ENDPOINT } from "@/utils/api";
import parseError from "../../../../store/helpers/parseError";
import AddingProductModel from "./AddingProductModel";
import { mapGetters } from "vuex";

export default {
  name: "AddProduct",
  components: { AddingProductModel },
  computed: {
    ...mapGetters({
      mode_edit: "orderItem/canEdit",
      was_successful_payment: "orderPrice/wasSuccessfulPayment",
      can_product_edit: "orderItem/canProductEdit"
    })
  },
  data() {
    return {
      showModels: false,
      models: []
    };
  },
  created() {
    this.loadAllowProducts();
  },
  methods: {
    toggleModels() {
      this.showModels = !this.showModels;
    },
    toggleProductFromModels(id) {
      this.showProductFromModels[id] = !this.showProductFromModels[id];
    },
    loadAllowProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_ENDPOINT}products/list`)
          .then(resp => {
            this.models = resp.data;
            this.models.map(model => {
              model.display = false;
              if (model.builds) {
                model.builds = model.builds.sort((a, b) => {
                  return a.prices.price_for_eu - b.prices.price_for_eu;
                });
              } else {
                model.builds = [];
              }
              return model;
            });
            resolve(resp);
          })
          .catch(err => {
            reject(parseError(err));
          });
      });
    }
  }
};
</script>

<style scoped>
.add-product-wrap {
  width: calc(100% - 8px);
  margin-bottom: 10px;
}

.add-product-btn {
  padding: 8px 10px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  color: #454545;
}

.arrow {
  margin-right: 8px;
}
</style>
