<template>
  <layout>
    <template v-slot:header-title>
      <span>Creating products model</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Model info</h5>
          </div>

          <div class="main__content">
            <div class="">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.formNamePrefix"
                  :class="isActive(language) ? 'active' : ''"
                >
                  <a @click="switchActiveLanguage(language)">
                    {{ language.title }}
                  </a>
                </li>
              </ul>

              <div class="main__content">
                <div class="main__content-in">
                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="language.formNamePrefix"
                  >
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Title:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="$data[language.formNamePrefix + 'title']"
                            autofocus
                            title="Title"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="main__content-item" style="margin-bottom: 15px">
                      <div class="main__content-item_l">
                        <span>Short Description:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <ckeditor
                            v-model="$data[language.formNamePrefix + 'desc']"
                            :config="editorConfig"
                            :editor="editor"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="main__content-item" style="margin-bottom: 15px">
                      <div class="main__content-item_l">
                        <span>Second Short Description:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <ckeditor
                            v-model="
                              $data[language.formNamePrefix + 'second_desc']
                            "
                            :config="editorConfig"
                            :editor="editor"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="main__content-item main__content-item_center"
                      style="margin-bottom: 15px"
                    >
                      <div class="main__content-item_l">
                        <span>Delivery date:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data[language.formNamePrefix + 'delivery_info']
                            "
                            autofocus
                            title="Delivery date"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="main__content-item main__content-item_center mb-5"
                      style="margin-bottom: 15px"
                    >
                      <div class="main__content-item_l">
                        <span>Carousel description:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data[
                                language.formNamePrefix + 'carousel_description'
                              ]
                            "
                            autofocus
                            title="Carousel description"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>Manuals:</span>
                      </div>

                      <div class="main__content-item_r">
                        <dropzone
                          id="manuals"
                          :default-image="manuals"
                          :options="manualsDropzoneSettings"
                          dict-default-message="Drag&drop or <u>click</u> here to upload manual file (pdf up to 15MB)"
                          @vdropzone-files-added="saveTempManuals($event)"
                          @vdropzone-removed-file="deleteTempManuals($event)"
                        />
                      </div>
                    </div>

                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>Manuals:</span>
                      </div>

                      <div class="main__content-item_r">
                        <dropzone
                          id="suspensions"
                          :default-image="suspensions"
                          :options="suspensionsDropzoneSettings"
                          dict-default-message="Drag&drop or <u>click</u> here to upload suspension guide file (pdf up to 15MB)"
                          @vdropzone-files-added="saveTempSuspensions($event)"
                          @vdropzone-removed-file="
                            deleteTempSuspensions($event)
                          "
                        />
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Coming Soon:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-1"
                          v-model="coming_soon"
                          type="checkbox"
                        />
                        <label for="checkbox-1"></label>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Available for purchase:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-3"
                          v-model="purchasable"
                          type="checkbox"
                        />
                        <label for="checkbox-3"></label>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Show in menu:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-4"
                          v-model="show_menu"
                          type="checkbox"
                        />
                        <label for="checkbox-4"></label>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Frame number selection:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-2"
                          v-model="frame_number_selection"
                          type="checkbox"
                        />
                        <label for="checkbox-2"></label>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Reserved numbers:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group">
                        <vue-tags-input
                          v-model="reservedNumber"
                          :tags="reserved"
                          placeholder="Add frame number"
                          @before-adding-tag="checkTag($event)"
                          @tags-changed="
                            newTags => {
                              this.reserved = newTags;
                            }
                          "
                        />
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Model image(customize page):</span>
                    </div>

                    <div class="main__content-item_r">
                      <dropzone
                        :id="'model_image'"
                        :options="getDropzoneSettings"
                        dict-default-message="Drag&drop or <u>click here</u> to upload image (jpg, 2800x1575 up to 15MB)"
                        @vdropzone-files-added="saveTempImage($event)"
                        @vdropzone-removed-file="deleteTempImage($event)"
                      />
                    </div>
                  </div>

                  <div class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Model image(carousel):</span>
                    </div>

                    <div class="main__content-item_r">
                      <dropzone
                        :id="'slider_image'"
                        :options="getDropzoneSettings"
                        dict-default-message="Drag&drop or <u>click</u> here to upload image (jpg 1860x850 up to 15MB)"
                        @vdropzone-files-added="saveTempCarouselImage($event)"
                        @vdropzone-removed-file="
                          deleteTempCarouselImage($event)
                        "
                      />
                    </div>
                  </div>

                  <div class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Model video:</span>
                    </div>

                    <div class="main__content-item_r">
                      <dropzone
                        :id="'model_video'"
                        :options="getDropzoneSettings"
                        dict-default-message="Drag&drop or <u>click</u> here to upload video (mp4 1920x1080 up to 150MB)"
                        @vdropzone-files-added="saveTempVideo($event)"
                        @vdropzone-removed-file="deleteTempVideo($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save">
              <span><i>Save model</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Dropzone from "./forms/inputs/Dropzone";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  name: "CRMProductModelCreate",
  components: {
    layout: CRMLayout,
    Dropzone,
    ckeditor: CKEditor["component"],
    VueTagsInput
  },
  data() {
    return {
      title: null,
      es_title: null,
      de_title: null,
      fr_title: null,
      ru_title: null,
      desc: null,
      es_desc: null,
      de_desc: null,
      fr_desc: null,
      ru_desc: null,
      fr_second_desc: null,
      de_second_desc: null,
      second_desc: null,
      es_second_desc: null,
      ru_second_desc: null,
      img: null,
      manuals: [],
      suspensions: [],
      slider_img: null,
      video: null,
      reserved: [],
      reservedNumber: "",
      oldReservedNumber: "",
      coming_soon: false,
      purchasable: true,
      show_menu: true,
      frame_number_selection: false,
      delivery_info: "",
      es_delivery_info: "",
      de_delivery_info: "",
      fr_delivery_info: "",
      ru_delivery_info: "",
      carousel_description: "",
      manual_link: "",
      partCategoriesAsArray: [],
      partCategories: { 1: 0 },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ["bold", "italic", "|", "undo", "redo"]
      },
      languages: [
        {
          id: "en",
          title: "EN",
          formNamePrefix: ""
        },
        {
          id: "es",
          title: "ES",
          formNamePrefix: "es_"
        },
        {
          id: "de",
          title: "DE",
          formNamePrefix: "de_"
        },
        {
          id: "fr",
          title: "FR",
          formNamePrefix: "fr_"
        },
        {
          id: "ru",
          title: "RU",
          formNamePrefix: "ru_"
        }
      ],
      activeLanguage: {
        id: "en",
        title: "EN",
        formNamePrefix: ""
      }
    };
  },
  created() {
    this.loadPartCategories();
  },
  computed: {
    getDropzoneSettings() {
      return {
        url: `update`,
        uploadMultiple: false,
        paramName: "random",
        autoProcessQueue: false,
        addRemoveLinks: true,
        preventDuplicates: true
      };
    },
    manualsDropzoneSettings() {
      return {
        url: `update`,
        uploadMultiple: false,
        paramName: "random",
        autoProcessQueue: false,
        addRemoveLinks: true,
        preventDuplicates: true
      };
    },
    suspensionsDropzoneSettings() {
      return {
        url: `update`,
        uploadMultiple: false,
        paramName: "random",
        autoProcessQueue: false,
        addRemoveLinks: true,
        preventDuplicates: true
      };
    }
  },
  methods: {
    checkTag(tagObject) {
      let tagText = tagObject.tag.text;

      let regExp = new RegExp("^\\d+$");
      let isValid = regExp.test(tagText);

      if (tagText.length === 3 && isValid) {
        tagObject.addTag();
      } else {
        if (this.oldReservedNumber !== tagText) {
          this.$root.$emit("modalShow", {
            type: "error",
            text: "Frame number must be at least 3 numeric symbols"
          });
        }
      }

      this.oldReservedNumber = tagText;
    },
    isActive(language) {
      return language.id === this.activeLanguage.id;
    },
    switchActiveLanguage(language) {
      this.activeLanguage = language;
    },
    saveTempManuals(files) {
      this.manuals = [...this.manuals, ...files];
    },
    saveTempSuspensions(files) {
      this.suspensions = [...this.suspensions, ...files];
    },
    deleteTempManuals(file) {
      this.manuals = this.manuals.filter(manual => {
        return manual !== file;
      });
    },
    deleteTempSuspensions(file) {
      this.suspensions = this.suspensions.filter(suspension => {
        return suspension !== file;
      });
    },
    saveTempImage(file) {
      this.img = file[0];
    },
    deleteTempImage() {
      this.img = null;
    },
    saveTempCarouselImage(file) {
      this.slider_img = file[0];
    },
    deleteTempCarouselImage() {
      this.slider_img = null;
    },
    saveTempVideo(file) {
      this.video = file[0];
    },
    deleteTempVideo() {
      this.video = null;
    },
    loadPartCategories() {
      let ctx = this;
      this.$store
        .dispatch("get_part_categories_request", { type: "all" })
        .then(response => {
          let i,
            j,
            tempArray,
            chunk = 6;
          for (i = 0, j = response.data.length; i < j; i += chunk) {
            tempArray = response.data.slice(i, i + chunk);
            ctx.partCategoriesAsArray.push(tempArray);
          }

          let categories = {};

          for (let category of response.data) {
            categories[category.id] = 0;
          }

          ctx.partCategories = categories;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    save() {
      let ctx = this;

      let data = new FormData();
      data.append("model", this.title);
      data.append("es_model", this.es_title);
      data.append("de_model", this.de_title);
      data.append("fr_model", this.fr_title);
      data.append("ru_model", this.ru_title);
      data.append("desc", this.desc);
      data.append("es_desc", this.es_desc);
      data.append("de_desc", this.de_desc);
      data.append("fr_desc", this.fr_desc);
      data.append("ru_desc", this.ru_desc);
      data.append("fr_second_desc", this.fr_second_desc);
      data.append("de_second_desc", this.de_second_desc);
      data.append("second_desc", this.second_desc);
      data.append("es_second_desc", this.es_second_desc);
      data.append("ru_second_desc", this.ru_second_desc);
      data.append("img", this.img);
      if (this.manuals.length > 0) {
        for (let manual of this.manuals) {
          data.append("manuals[]", manual);
        }
      }
      if (this.suspensions.length > 0) {
        for (let suspension of this.suspensions) {
          data.append("suspensions[]", suspension);
        }
      }
      data.append("video", this.video);
      data.append("slider_img", this.slider_img);
      data.append("coming_soon", this.coming_soon ? "1" : "0");
      data.append("purchasable", this.purchasable ? "1" : "0");
      data.append("show_menu", this.show_menu ? "1" : "0");
      data.append(
        "frame_number_selection",
        this.frame_number_selection ? "1" : "0"
      );
      data.append(
        "reserved",
        JSON.stringify(
          this.reserved.map(item => {
            return item.text;
          })
        )
      );
      data.append("delivery_info", this.delivery_info);
      data.append("es_delivery_info", this.es_delivery_info);
      data.append("de_delivery_info", this.de_delivery_info);
      data.append("fr_delivery_info", this.fr_delivery_info);
      data.append("ru_delivery_info", this.ru_delivery_info);
      data.append("carousel_description", this.carousel_description);
      data.append("manual_link", this.manual_link);

      let categories = [];

      for (let key of Object.keys(ctx.partCategories)) {
        categories.push({
          cat_id: key,
          quantity: ctx.partCategories[key]
        });
      }

      categories = JSON.stringify(categories);

      data.append("categories", categories);

      this.$store
        .dispatch("store_product_model_request", data)
        .then(() => {
          ctx.$router.push("/products/models");
          ctx.$root.$emit("modalShow", {
            text: "Model successfully added",
            type: "info"
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    }
  }
};
</script>

<style scoped>
.columns {
  display: flex;
  justify-content: space-between;
}

.column {
  display: block;
}

.column .inp-group__xl {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.column .inp-group__xl input {
  width: 20%;
  display: block;
  margin-right: 10px;
}

.block {
  display: block;
  position: relative;
  top: 3px;
  margin-right: auto;
}
</style>
