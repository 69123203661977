<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'CRMManageCustomers' }"
        active-class="is-active"
        class="btn btn-white btn-reset add-models-btn"
      >
        Add B2C customer
      </router-link>
      <router-link
        :to="{ name: 'CRMManageCustomersB2b' }"
        active-class="is-active"
        class="btn btn-white btn-reset add-models-btn"
      >
        Add B2B customer
      </router-link>
      <a
        class="btn btn-white btn-reset add-models-btn"
        @click="exportAllUsers"
      >
        Export
      </a>
    </template>

    <template v-slot:header-title>Customer list</template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Customers</h5>
          </div>
          <div class="main__fs scroll">
            <table v-if="hasCustomer" class="table__typical table__customers">
              <tbody>
                <tr>
                  <th class="pointer" @click="sortRows('id')">
                    <span>ID</span>
                    <span :class="arrowClass('id')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('email')">
                    <span>Email (login)</span>
                    <span :class="arrowClass('email')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('f_name')">
                    <span>Name</span>
                    <span :class="arrowClass('f_name')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('l_name')">
                    <span>Last name</span>
                    <span :class="arrowClass('l_name')"></span>
                  </th>
                  <th class="pointer" @click="sortRows('created_at')">
                    <span>Created</span>
                    <span :class="arrowClass('created_at')"></span>
                  </th>
                </tr>

                <tr
                  v-for="customer in customers"
                  :key="customer.id"
                  @click="$router.push({ path: customerLink(customer) })"
                >
                  <td>
                    <a :href="customerLink(customer)">
                      {{ customer.id }}
                    </a>
                  </td>
                  <td>
                    <a :href="customerLink(customer)">
                      {{ customer.email }}
                    </a>
                  </td>
                  <td>
                    <a :href="customerLink(customer)">
                      {{ customer.f_name }}
                    </a>
                  </td>
                  <td>
                    <a :href="customerLink(customer)">
                      {{ customer.l_name }}
                    </a>
                  </td>
                  <td>
                    <a :href="customerLink(customer)">
                      {{ customerCreatedAt(customer) }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="wrapTotal">
            <paginator
              :current-page="currentPage"
              :last-page="lastPage"
              @changepage="changePage"
            >
            </paginator>
            <div class="totalText">Total: {{ total }}</div>
          </div>
        </div>
      </div>

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>

        <div class="main__r-in">
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By type</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentUserType"
                  :options="userTypes"
                  label="label"
                  placeholder="Select user type"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By E-mail</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group">
                <input
                  v-model="byEmailFilter"
                  placeholder="Enter e-mail"
                  title="Email"
                  type="text"
                  @input="loadCustomers"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By name</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group">
                <input
                  v-model="byNameFilter"
                  autocomplete="off"
                  placeholder="Start typing"
                  title="Name"
                  type="text"
                  @input="loadCustomers"
                />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Created</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="createdAtFilter"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Last log in</h5>
            </div>

            <div class="inp-group__in">
              <date-picker
                v-model="lastLoginAtFilter"
                :format="'YYYY/MM/DD'"
                type="date"
                valueType="YYYY-MM-DD"
              />
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Subscribe</h5>
            </div>

            <div class="inp-group__in">
              <label
                :class="{ active: currentSubscribeFilter === 'yes' }"
                class="inp-line gender-radio-label"
                for="subscribe-yes"
              >
                <input
                  id="subscribe-yes"
                  v-model="currentSubscribeFilter"
                  class="inp-decorate female gender-radio"
                  name="subscribe"
                  type="radio"
                  value="yes"
                />
                <span>Yes</span>
              </label>

              <label
                :class="{ active: currentSubscribeFilter === 'no' }"
                class="inp-line gender-radio-label"
                for="subscribe-no"
              >
                <input
                  id="subscribe-no"
                  v-model="currentSubscribeFilter"
                  class="inp-decorate male gender-radio"
                  name="subscribe"
                  type="radio"
                  value="no"
                />
                <span>No</span>
              </label>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Archived</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__choice">
                <div class="inp-group__choice-item">
                  <input
                    id="yes1"
                    :checked="archivedFilter"
                    type="checkbox"
                    @change="acceptArchivedFilter(true)"
                  />
                  <label for="yes1">Yes</label>
                </div>

                <div class="inp-group__choice-item">
                  <input
                    id="no1"
                    :checked="
                      archivedFilter !== null && archivedFilter === false
                    "
                    type="checkbox"
                    @change="acceptArchivedFilter(false)"
                  />
                  <label for="no1">No</label>
                </div>
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Email confirmed</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="emailConfirmedFilter"
                  :options="emailConfirmedOptions"
                  label="label"
                  placeholder="Select email confirmed"
                />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>By country</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentCountry"
                  :options="countries"
                  label="label"
                  placeholder="Select email confirmed"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Has order</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="hasOrderFilter"
                  :options="orderOptions"
                  label="label"
                  placeholder="Select email confirmed"
                />
              </div>
            </div>
          </div>

          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Order model</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentProductModel"
                  :options="productModels"
                  label="label"
                  placeholder="Select email confirmed"
                />
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Order product</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__dropdown">
                <v-select
                  v-model="currentProduct"
                  :options="products"
                  label="label"
                  placeholder="Select email confirmed"
                />
              </div>
            </div>
          </div>

          <div class="btn__wrapper">
            <button
              v-if="false"
              class="btn btn-white btn-reset btn-bold btn-fw btn-sm"
              @click="sendPushModal()"
            >
              Send push
            </button>
            <button
              class="btn btn-white btn-reset btn-bold btn-fw btn-sm"
              @click="resetFilters()"
            >
              Reset filter
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import vSelect from "vue-select";
import CRMLayout from "./CRMLayout";
import DatePicker from "vue2-datepicker";
import Paginator from "./partials/Paginator";
import axios from "axios";
import { API_ENDPOINT } from "../utils/api";
import moment from "moment";
//import _ from "lodash";

export default {
  name: "CRMCustomerList",
  components: { CRMLayout, vSelect, DatePicker, Paginator },
  data() {
    return {
      emailConfirmedOptions: [
        {
          code: 0,
          label: "Never mind"
        },
        {
          code: 1,
          label: "Yes"
        },
        {
          code: 2,
          label: "No"
        }
      ],
      orderOptions: [
        {
          code: 0,
          label: "All"
        },
        {
          code: 1,
          label: "Yes"
        },
        {
          code: 2,
          label: "No"
        }
      ],
      countries: [],
      customers: [],
      userTypes: [],
      currentCountry: {
        code: 0,
        label: "All"
      },
      currentSubscribeFilter: null,
      emailConfirmedFilter: {
        code: 0,
        label: "never mind"
      },
      currentUserType: {
        code: 0,
        label: "All"
      },
      hasOrderFilter: {
        code: 0,
        label: "All"
      },
      currentProductModel: {
        code: 0,
        label: "All"
      },
      currentProduct: {
        code: 0,
        label: "All"
      },
      productModels: [],
      products: [],
      ordering: true,
      descendingOrder: true,
      updateList: true,
      order_by: "id",
      previous_order_field: null,
      createdAtFilter: null,
      lastLoginAtFilter: null,
      archivedFilter: null,
      reseting: false,
      currentPage: 1,
      lastPage: 1,
      total: 0,
      perPage: 0,
      byNameFilter: null,
      byEmailFilter: null
    };
  },
  beforeMount() {
    this.loadUserTypes();
  },
  mounted() {
    this.loadCustomers();
    this.loadCountries();
    this.loadModels();
    this.loadProducts();
  },
  methods: {
    customerLink(customer) {
      return "/customers/" + customer.id;
      /*let base = "/customers/";
      let b2bType = _.find(this.$store.getters.userBusinessTypes, {
        name: "b2b"
      }).id;
      if (customer.user_type.business_type === b2bType) {
        base = "/customers_b2b/";
      }
      return base + customer.id;*/
    },
    customerCreatedAt(customer) {
      return moment(customer.created_at).format('DD.MM.YYYY hh:mm');
    },
    loadCountries() {
      let ctx = this;
      this.$store
        .dispatch("get_countries_request")
        .then(response => {
          ctx.countries = [
            ...[{ code: 0, label: "All" }],
            ...response.data.map(country => {
              return {
                code: country.id,
                label: country.country_name
              };
            })
          ];
        })
        .catch(err => {
          console.log(err);
        });
    },
    loadProducts() {
      this.currentProduct = {
        code: 0,
        label: "All"
      };
      let options = {
        parameters: []
      };
      options.type = "products";
      options.parameters.push("without_paginate", 1);
      this.$store
        .dispatch("get_product_items_request", options)
        .then(response => {
          let products = response.data.filter(
            prod =>
              this.currentProductModel.code === 0 ||
              prod.model_id === this.currentProductModel.code
          );
          this.products = [
            ...[{ code: 0, label: "All" }],
            ...products.map(type => {
              return {
                code: type.id,
                label: type.name.toUpperCase()
              };
            })
          ];
        })
        .catch(error => {
          this.$root.$emit("modalShow", { text: error, type: "error" });
        });
    },
    loadModels() {
      let options = {
        parameters: []
      };
      this.$store
        .dispatch("get_product_models_request", options)
        .then(response => {
          this.productModels = [
            ...[{ code: 0, label: "All" }],
            ...response.data.map(type => {
              return {
                code: type.id,
                label: type.model.toUpperCase()
              };
            })
          ];
        })
        .catch(error => {
          this.$root.$emit("modalShow", { text: error, type: "error" });
        });
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadCustomers();
    },
    async loadCustomers() {
      try {
        let options = {
          parameters: []
        };

        options.parameters.push(`page=${this.currentPage}`);

        if (this.currentUserType && this.currentUserType.code !== 0) {
          options.parameters.push(`user_type_id=${this.currentUserType.code}`);
        }

        if (this.currentCountry && this.currentCountry.code !== 0) {
          options.parameters.push(`country_id=${this.currentCountry.code}`);
        }

        if (this.order_by) {
          options.parameters.push(`order=${this.order_by}`);
          options.parameters.push(
            `order_type=${this.ordering ? "desc" : "asc"}`
          );
        }

        if (this.createdAtFilter) {
          options.parameters.push(`created_at=${this.createdAtFilter}`);
        }

        if (this.emailConfirmedFilter && this.emailConfirmedFilter.code !== 0) {
          options.parameters.push(
            `email_confirmed=${this.emailConfirmedFilter.code}`
          );
        }

        if (this.hasOrderFilter && this.hasOrderFilter.code > 0) {
          options.parameters.push(`has_order=${this.hasOrderFilter.code}`);
        }
        if (this.currentProductModel && this.currentProductModel.code > 0) {
          options.parameters.push(
            `product_model=${this.currentProductModel.code}`
          );
        }

        if (this.currentProduct && this.currentProduct.code > 0) {
          options.parameters.push(`product=${this.currentProduct.code}`);
        }

        if (this.currentSubscribeFilter) {
          options.parameters.push(`subscribe=${this.currentSubscribeFilter}`);
        }

        if (!!this.byNameFilter && this.byNameFilter.length > 0) {
          options.parameters.push(`name=${this.byNameFilter}`);
        }

        if (!!this.byEmailFilter && this.byEmailFilter.length > 0) {
          options.parameters.push(`email=${this.byEmailFilter}`);
        }

        if (this.lastLoginAtFilter) {
          options.parameters.push(`last_login=${this.lastLoginAtFilter}`);
        }

        if (this.archivedFilter) {
          options.parameters.push(`archived=true`);
        }

        let response = await this.$store.dispatch("get_customers", options);

        if (this.updateList) {
          this.customers = response["data"].data;
          this.updateList = true;
          this.currentPage = response["data"]["current_page"];
          this.lastPage = response["data"]["last_page"];
          this.total = response["data"].total;
          this.perPage = response["data"]["per_page"];

          if (this.customers.length < 6) {
            // noinspection JSCheckFunctionSignatures
            window.scrollTo(0, 0);
          }
        } else {
          this.customers = [...this.customers, ...response["data"].data];
        }
      } catch (e) {
        this.$root.$emit("modalShow", { text: e, type: "error" });
      }
    },
    loadUserTypes() {
      this.$store
        .dispatch("get_user_types")
        .then(response => {
          this.userTypes = [
            ...response.data.map(type => {
              return {
                code: type.id,
                label: type.type[0].toUpperCase() + type.type.substr(1)
              };
            })
          ];
        })
        .catch(error => {
          this.$root.$emit("modalShow", { text: error, type: "error" });
        });
    },
    resetFilters() {
      this.updateList = true;
      this.lastLoginAtFilter = null;
      this.createdAtFilter = null;
      this.archivedFilter = null;
      this.ordering = true;
      this.order_by = null;
      this.byNameFilter = null;
      this.byEmailFilter = null;
      this.reseting = true;
      this.currentSubscribeFilter = null;
      this.emailConfirmedFilter = {
        code: 0,
        label: "Never mind"
      };
      this.currentUserType = {
        code: 0,
        label: "All"
      };
      this.hasOrderFilter = {
        code: 0,
        label: "All"
      };
      this.currentProductModel = {
        code: 0,
        label: "All"
      };
      this.currentProduct = {
        code: 0,
        label: "All"
      };
      this.currentCountry = {
        code: 0,
        label: "All"
      };
      this.currentPage = 1;
      this.lastPage = 1;
      this.loadCustomers();
    },
    arrowClass(field) {
      if (field === this.order_by) {
        let classes = "btn-arrow centered";
        return this.ordering ? classes : `${classes} reverse`;
      }
      return "btn-arrow hidden";
    },
    acceptArchivedFilter(value) {
      if (this.archivedFilter === null) {
        this.archivedFilter = value;
      } else if (value === this.archivedFilter) {
        this.archivedFilter = null;
      } else {
        this.archivedFilter = value;
      }

      this.updateList = true;
      this.loadCustomers();
    },
    sortRows(field) {
      this.order_by = field;
      if (
        field === this.previous_order_field ||
        this.previous_order_field === null
      ) {
        this.ordering = !this.ordering;
      } else {
        this.ordering = true;
      }
      this.previous_order_field = this.order_by;
      this.updateList = true;
      this.currentPage = 1;
      this.lastPage = 1;
      this.loadCustomers();
    },
    sendPushModal() {
      if (this.total === 0) {
        this.$root.$emit("modalShow", {
          type: "error",
          text: "You have not selected any users"
        });
      } else {
        this.$root.$emit("modalShow", {
          type: "push",
          options: {
            total: this.total,
            type: this.currentUserType,
            lastLogin: this.lastLoginAtFilter,
            createdAt: this.createdAtFilter,
            archived: this.archivedFilter,
            byName: this.byNameFilter
          }
        });
      }
    },
    exportAllUsers() {
      this.$dialog
        .confirm(`The report will be sent to ${this.$store.getters.user.email}.`)
        .then(() => {
          axios.post(API_ENDPOINT + 'crm/users/export')
            .then(() => {
              this.$root.$emit("modalShow", {
                text: "As soon as the report is ready, you will receive it by email",
                type: "info"
              });
            })
            .catch(err => {
              this.$root.$emit("modalShow", { text: err, type: "error" });
            })
        })
        .catch(() => {});
    }
  },
  computed: {
    showPaginator() {
      return this.total > this.perPage;
    },
    hasCustomer() {
      return this.customers.length > 0;
    }
  },
  watch: {
    currentUserType: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.currentPage = 1;
        this.lastPage = 1;
        this.loadCustomers();
      }
    },
    createdAtFilter: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.currentPage = 1;
        this.lastPage = 1;
        this.loadCustomers();
      }
    },
    currentProduct: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.currentPage = 1;
        this.lastPage = 1;
        this.loadCustomers();
      }
    },
    currentSubscribeFilter: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.currentPage = 1;
        this.lastPage = 1;
        this.loadCustomers();
      }
    },
    currentCountry: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.currentPage = 1;
        this.lastPage = 1;
        this.loadCustomers();
      }
    },
    emailConfirmedFilter: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.currentPage = 1;
        this.lastPage = 1;
        this.loadCustomers();
      }
    },
    lastLoginAtFilter: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.currentPage = 1;
        this.lastPage = 1;
        this.loadCustomers();
      }
    },
    hasOrderFilter: function() {
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.currentPage = 1;
        this.lastPage = 1;
        this.loadCustomers();
      }
    },
    currentProductModel: function() {
      this.loadProducts();
      if (this.reseting) {
        this.reseting = false;
      } else {
        this.updateList = true;
        this.currentPage = 1;
        this.lastPage = 1;
        this.loadCustomers();
      }
    }
  }
};
</script>

<style>
.table__customers tr {
  cursor: pointer;
}

.gender-radio-label {
  position: relative;
  padding-left: 30px;
  height: 26px;
}

.inp-line {
  margin-left: 0;
  margin-bottom: 4px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  width: 32%;
  box-sizing: border-box;
}

.gender-radio-label:before {
  content: "";
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #454545;
  box-sizing: border-box;
  padding: 0;
}

.gender-radio-label input {
  display: none;
}

.gender-radio-label.active:after {
  content: "";
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background: #454545;
  position: absolute;
  left: 1px;
  top: 1px;
  box-sizing: border-box;
  border: 4px solid #fff;
}
</style>

<style lang="scss" scoped>
.main__r-products .btn__wrapper {
  position: relative;
  bottom: auto;
  right: auto;
}
</style>
