import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "@/store/helpers/parseError";

const state = {
  list: [],
  shortList: []
};

const getters = {
  list(state) {
    return state.list;
  },
  shortList(state) {
    return state.shortList;
  }
};
const actions = {
  get() {},
  short({ commit }) {
    return new Promise((resolve, reject) => {
      let url = API_ENDPOINT + `v2/sizes/short`;
      axios
        .get(url)
        .then(response => {
          commit("setShortList", response.data.data);
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  }
};
const mutations = {
  setList(state, sizes) {
    state.list = sizes;
  },
  setShortList(state, sizes) {
    state.shortList = sizes;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
