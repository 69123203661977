<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Update Settings</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Settings</h5>
          </div>

          <div class="main__content">
            <div class="">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.prefix"
                  :class="isActive(language) ? 'active' : ''"
                >
                  <a @click="switchActiveLanguage(language)">
                    {{ language.title }}
                  </a>
                </li>
              </ul>

              <div class="main__content">
                <div class="main__content-in">
                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="language.prefix"
                    class="language-tabs"
                  >
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Footer:</span>
                      </div>
                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <textarea
                            v-model="
                              $data.settings[language.formNamePrefix + 'footer']
                            "
                            :name="language.formNamePrefix + 'footer'"
                            title="Footer"
                            type="text"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Footer Product:</span>
                      </div>
                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <textarea
                            v-model="
                              $data.settings[
                                language.formNamePrefix + 'productFooter'
                              ]
                            "
                            :name="language.formNamePrefix + 'productFooter'"
                            title="Footer"
                            type="text"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save()">
              <span><i>Save page</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";

export default {
  name: "CRMPartCategoriesCreate",
  components: { CRMLayout },
  data() {
    return {
      settings: {
        footer: null,
        es_footer: null,
        de_footer: null,
        fr_footer: null,
        ru_footer: null,
        productFooter: null,
        es_productFooter: null,
        de_productFooter: null,
        fr_productFooter: null,
        ru_productFooter: null
      },
      languages: [
        {
          id: "en",
          title: "EN",
          formNamePrefix: ""
        },
        {
          id: "es",
          title: "ES",
          formNamePrefix: "es_"
        },
        {
          id: "de",
          title: "DE",
          formNamePrefix: "de_"
        },
        {
          id: "fr",
          title: "FR",
          formNamePrefix: "fr_"
        },
        {
          id: "ru",
          title: "RU",
          formNamePrefix: "ru_"
        }
      ],
      activeLanguage: {
        id: "en",
        title: "EN",
        formNamePrefix: ""
      }
    };
  },
  methods: {
    isActive(language) {
      return language.id === this.activeLanguage.id;
    },
    switchActiveLanguage(language) {
      this.activeLanguage = language;
    },
    save() {
      let ctx = this;
      this.$store
        .dispatch("save_settings_request", ctx.settings)
        .then(() => {
          ctx.$root.$emit("modalShow", {
            type: "info",
            text: "Settings successfully updated."
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    getSettings() {
      let ctx = this;
      this.$store
        .dispatch("get_settings_request")
        .then(response => {
          for (let key of Object.keys(response.data.settings)) {
            ctx.settings[key] = response.data.settings[key];
          }
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    }
  },
  mounted() {
    this.getSettings();
  }
};
</script>

<style scoped>
.language-tabs {
  margin-bottom: 15px;
}
</style>
