<template>
  <div class="admin">
    <div class="admin__form">
      <div class="admin__form-headline">
        <h5>Admin panel</h5>
      </div>
      <!-- .admin__form-headline -->

      <div class="admin__form-form">
        <div class="inp-group">
          <input v-model="email" placeholder="Email" type="email" />
        </div>
        <!-- .inp-group -->

        <button class="btn btn-primary btn-fw btn-bold" @click="reset">
          <span v-if="$store.state.status === 'loading'">Wait...</span>
          <span v-else>Reset password</span>
        </button>

        <div v-if="error" class="inp-error__desc">
          <span>{{ error }}</span>
        </div>
        <!-- .inp-error__desc -->
      </div>
      <!-- .admin__form-form -->
    </div>
    <!-- .admin__form -->
  </div>
  <!-- .admin -->
</template>

<script>
export default {
  name: "CRMForgotPasswordScreen",
  data() {
    return {
      email: "",
      error: ""
    };
  },
  methods: {
    reset() {
      this.error = "";
      let email = this.email;
      this.$store
        .dispatch("reset_password_request", { email })
        .then(() => {
          this.$root.$emit("modalShow", {
            type: "success",
            text: "Verification link sent to provided email"
          });
        })
        .catch(error => {
          this.error = error;
        });
    }
  }
};
</script>
