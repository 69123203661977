<template>
  <div>
    <header-menu>
      <template v-slot:default>
        <slot name="header-back-btn"></slot>
      </template>
      <template v-slot:title>
        <slot name="header-title"></slot>
      </template>
    </header-menu>
    <div class="main">
      <main-left />
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import MainLeft from "./include/MainLeft";
import HeaderMenu from "./include/HeaderMenu";

export default {
  name: "CRMLayout",
  components: { MainLeft, HeaderMenu }
};
</script>
