import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "../../helpers/parseError";

const getters = {};
const state = {};
const mutations = {};
const actions = {
  exportAllOrdersToExcel() {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}orders/export`;

      axios
        .post(url, getters["getDataForRequest"])
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  exportUserOrdersToExcel(context, id) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}users/${id}/orders/export`;

      axios
        .post(url)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
