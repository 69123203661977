<template>
  <div>
    <div
      v-if="type === 'error' || type === 'info'"
      :id="type"
      :ref="'info'"
      class="modal modal__confirm fade"
      role="dialog"
      style="display: none;"
    >
      <div class="modal-dialog modal-xs">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal__in">
              <div class="modal__txt">
                <div class="modal__txt-in">
                  <p v-if="type === 'info' || type === 'error'">
                    {{ getBody }}
                  </p>
                </div>
                <!-- .modal__txt-in -->

                <div class="btn__wrapper">
                  <button
                    v-if="type === 'info' || type === 'error'"
                    class="btn btn-gray btn-fw btn-bold btn-up btn-lg"
                    data-dismiss="modal"
                  >
                    Ok
                  </button>
                </div>
                <!-- .btn__wrapper -->
              </div>
              <!-- .modal__txt -->
            </div>
            <!-- .modal__in -->
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="type === 'confirm'"
      :id="type"
      :ref="'confirm'"
      class="modal modal__confirm fade"
      role="dialog"
      style="display: none;"
    >
      <div class="modal-dialog modal-xs">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal__in">
              <div class="modal__txt">
                <div class="modal__txt-in">
                  <p v-if="type === 'confirm'">{{ getBody }}</p>
                </div>
                <!-- .modal__txt-in -->

                <div class="btn__wrapper flex">
                  <button
                    v-if="type === 'confirm'"
                    class="btn btn-gray btn-fw btn-bold btn-up btn-lg"
                    data-dismiss="modal"
                    @click="pushModal()"
                  >
                    Ok
                  </button>
                  <button
                    v-if="type === 'confirm'"
                    class="btn btn-gray btn-fw btn-bold btn-up btn-lg"
                    data-dismiss="modal"
                    @click="cancelConfirmation()"
                  >
                    Cancel
                  </button>
                </div>

                <!-- .btn__wrapper -->
              </div>
              <!-- .modal__txt -->
            </div>
            <!-- .modal__in -->
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="type === 'push'"
      :id="type"
      :ref="'pushModal'"
      class="modal modal__customers fade"
      role="dialog"
    >
      <div class="modal-dialog modal-sm">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal__headline">
              <h5>
                Send push notification to <b>{{ options.total }} users</b> with
                following content:
              </h5>
            </div>
            <!-- .modal__headline -->

            <div class="modal__headline-details">
              <div v-if="!!options.createdAt" class="modal__headline-detail">
                <div class="modal__headline-detail_l">
                  <span>Created</span>
                </div>
                <!-- .modal__headline-detail_l -->

                <div class="modal__headline-detail_r">
                  <span>at {{ options.createdAt }}</span>
                </div>
                <!-- .modal__headline-detail_r -->
              </div>
              <!-- .modal__headline-detail -->

              <div v-if="!!options.lastLogin" class="modal__headline-detail">
                <div class="modal__headline-detail_l">
                  <span>Last log in</span>
                </div>
                <!-- .modal__headline-detail_l -->

                <div class="modal__headline-detail_r">
                  <span>to {{ options.lastLogin }}</span>
                </div>
                <!-- .modal__headline-detail_r -->
              </div>
              <!-- .modal__headline-detail -->

              <div
                v-if="!!options.byName && options.byName.length > 0"
                class="modal__headline-detail"
              >
                <div class="modal__headline-detail_l">
                  <span>By name</span>
                </div>
                <!-- .modal__headline-detail_l -->

                <div class="modal__headline-detail_r">
                  <span>{{ options.byName }}</span>
                </div>
                <!-- .modal__headline-detail_r -->
              </div>
              <!-- .modal__headline-detail -->
            </div>
            <!-- .modal__headline-details -->

            <div class="modal__in">
              <div class="modal__form-headline">
                <h5>with following content:</h5>
              </div>
              <!-- .modal__form-headline -->

              <div class="modal__form">
                <div class="field">
                  <div class="field__l">
                    <span>Title:</span>
                  </div>
                  <!-- .field__l -->

                  <div class="field__r">
                    <div class="inp-group">
                      <input v-model="pushTitle" type="text" />
                    </div>
                    <!-- .inp-group -->
                  </div>
                  <!-- .field__r -->

                  <div class="field__counter">
                    <span class="field__counter-current">{{
                      pushTitleLength
                    }}</span>
                    <i>/</i>
                    <span class="field__counter-total">{{
                      pushTitleCountMax
                    }}</span>
                  </div>
                  <!-- .field__counter -->
                </div>
                <!-- .field -->

                <div class="field">
                  <div class="field__l">
                    <span>Text message:</span>
                  </div>
                  <!-- .field__l -->

                  <div class="field__r">
                    <div class="inp-group inp-group__textarea">
                      <textarea v-model="pushBody" name="#"> </textarea>
                    </div>
                    <!-- .inp-group -->
                  </div>
                  <!-- .field__r -->

                  <div class="field__counter">
                    <span class="field__counter-current">{{
                      pushBodyLength
                    }}</span>
                    <i>/</i>
                    <span class="field__counter-total">{{
                      pushBodyCountMax
                    }}</span>
                  </div>
                  <!-- .field__counter -->
                </div>
                <!-- .field -->

                <div class="btn__wrapper-double">
                  <div class="btn__wrapper-double_in">
                    <div class="btn__wrapper">
                      <button
                        class="btn btn-gray btn-fw btn-lg btn-bold"
                        @click="sendPush()"
                      >
                        Send
                      </button>
                    </div>
                    <!-- .btn__wrapper -->

                    <div class="btn__wrapper">
                      <button
                        class="btn btn-white btn-fw btn-lg btn-bold"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                    <!-- .btn__wrapper -->
                  </div>
                  <!-- .btn__wrapper-double_in -->
                </div>
                <!-- .btn__wrapper-double -->
              </div>
              <!-- .modal__form -->
            </div>
            <!-- .modal__in -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Modal",
  data: function() {
    return {
      pushTitle: "",
      pushTitleCountMax: 50,
      pushBody: "",
      pushBodyCountMax: 300,
      error: null
    };
  },
  methods: {
    pushModal() {
      let ctx = this;
      setTimeout(() => {
        ctx.$root.$emit("confirmed", ctx);
      }, 300);
    },
    cancelConfirmation() {
      let ctx = this;
      setTimeout(() => {
        ctx.$root.$emit("cancelConfirmation", ctx);
        ctx.$parent.type = null;
      }, 100);
    },
    sendPush() {
      let ctx = this;
      let options = [];
      if (
        // eslint-disable-next-line
        this.options.hasOwnProperty("lastLogin") &&
        !!this.options.lastLogin
      ) {
        options.push(`last_login=${this.options.lastLogin}`);
      }
      if (
        // eslint-disable-next-line
        this.options.hasOwnProperty("createdAt") &&
        !!this.options.createdAt
      ) {
        options.push(`created_at=${this.options.createdAt}`);
      }
      if (
        // eslint-disable-next-line
        this.options.hasOwnProperty("byName") &&
        !!this.options.byName &&
        this.options.byName.length > 0
      ) {
        options.push(`name=${this.options.byName}`);
      }

      let data = {};
      data.options = options;
      data.data = {
        title: this.pushTitle,
        text: this.pushBody
      };

      this.$store
        .dispatch("send_push_notification_request", data)
        .then(() => {
          $(ctx.$refs.pushModal).modal("hide");
        })
        .catch(err => {
          ctx.error = err;
          $(ctx.$refs.pushModal).on("hidden.bs.modal", () => {
            if (ctx.error) {
              ctx.$root.$emit("modalShow", { type: "error", text: err });
              ctx.error = null;
            }
          });
          $(ctx.$refs.pushModal).modal("hide");
        });
    }
  },
  computed: {
    pushTitleLength() {
      return this.pushTitle.length;
    },
    pushBodyLength() {
      return this.pushBody.length;
    },
    getBody() {
      if (typeof this.body === "object") {
        if (this.body.response) {
          return this.body.response.data.message;
        }
      }
      return this.body;
    }
  },
  props: {
    body: [String, Error],
    type: String,
    options: Object
  },
  watch: {
    pushTitle: {
      handler: function(val) {
        if (val.length > this.pushTitleCountMax) {
          this.pushTitle = this.pushTitle.substring(
            0,
            parseInt(this.pushTitleCountMax)
          );
        }
      }
    },
    pushBody: {
      handler: function(val) {
        if (val.length > this.pushBodyCountMax) {
          this.pushBody = this.pushBody.substring(
            0,
            parseInt(this.pushBodyCountMax)
          );
        }
      }
    }
  }
};
</script>

<style scoped>
.btn__wrapper.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.btn__wrapper.flex .btn {
  width: 100px;
  min-width: 100px;
}

.modal.fade .modal-dialog {
  transform: translate(0, -1px) !important;
}
</style>
