<template>
  <div class="trans-item">
    <div class="btn-burger centered pointer"></div>
    <div class="wrapper">
      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l">
          <span>Text:</span>
        </div>
        <div class="main__content-item_r">
          <p class="inp-group__text">User manuals</p>
        </div>
      </div>
      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l">
          <span>Link:</span>
        </div>
        <div class="main__content-item_r">
          <div class="inp-group inp-group__xl">
            <input
              :value="manualLink"
              disabled="disabled"
              title="Manual Link"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
    <button class="field__remove" @click="removeSection"></button>
  </div>
</template>

<script>
export default {
  name: "UsersManual",
  props: ["value", "model"],
  computed: {
    manualLink() {
      if (this.model && this.model.manual_link) {
        return this.model.manual_link;
      }

      return null;
    }
  },
  methods: {
    removeSection() {
      this.$emit("onRemove");
    },
    setFile(event) {
      if (event.xhr.response) {
        let response = JSON.parse(event.xhr.response);
        let emitted = { ...this.value };
        emitted.file = response[0];
        this.$emit("input", emitted);
      }
    }
  }
};
</script>

<style scoped></style>
