<template>
  <div class="order__total">
    <div class="order__total-item d-flex border-none">
      <div class="order__total-title fw-500">
        SUBTOTAL {{ total_quantity }}
        {{ total_quantity > 1 ? "items" : "item" }}
      </div>
      <div class="order__total-value d-flex">
        <span>{{ $crConverter(subTotal / 100) }}</span>
      </div>
    </div>
    <div class="order__total-item d-flex pt-0 discount">
      <div class="discount-group">
        <div class="inp-group__in">
          <v-select
            :clearable="false"
            :disabled="!can_product_edit"
            :options="discount_types"
            :searchable="false"
            :value="discount_type"
            label="label"
            name="discount_type"
            @input="changeDiscountType"
          />
        </div>
        <div class="inp-group">
          <input
            v-model.number="discount_value"
            :disabled="!can_product_edit"
            :max="is_discount_percent ? 50 : total / 2"
            class="align-right"
            min="0"
            type="number"
          />
        </div>
      </div>
      <div class="order__total-value d-flex">
        <span>{{ $crConverter(discount / 100) }}</span>
      </div>
    </div>
    <div class="order__total-item d-flex border-none">
      <div class="order__total-title">Tax base</div>
      <div class="order__total-value d-flex">
        <span>{{ $crConverter(taxBase / 100) }}</span>
      </div>
    </div>
    <div class="order__total-item d-flex pt-0">
      <div class="order__total-title">Taxes</div>
      <div class="order__total-value d-flex">
        <span>{{ $crConverter(taxes / 100) }}</span>
        <span class="min-text">{{ taxFullTitle }}</span>
      </div>
    </div>
    <div class="order__total-item d-flex">
      <div class="order__total-title bold">
        TOTAL {{ total_quantity }} {{ total_quantity > 1 ? "items" : "item" }}
      </div>
      <div class="order__total-value d-flex">
        <span>{{ $crConverter(total / 100) }}</span>
      </div>
    </div>
    <div
      v-for="(method, key) in payment_methods"
      :key="key"
      :class="{
        'border-none': key + 1 !== payment_methods.length,
        'pt-0': key > 0
      }"
      class="order__total-item d-flex"
    >
      <div class="order__total-title">{{ method.title }}</div>
      <div class="order__total-value d-flex">
        <span>{{ $crConverter(method.price) }}</span>
      </div>
    </div>
    <div class="order__total-item d-flex border-none">
      <div class="order__total-title">Total paid</div>
      <div class="order__total-value d-flex">
        <span>{{ $crConverter(paid / 100) }}</span>
      </div>
    </div>
    <div class="order__total-item d-flex pt-0">
      <div class="order__total-title bold">Remaining</div>
      <div class="order__total-value d-flex">
        <span>{{ $crConverter(remaining / 100) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  name: "OrderTotal",
  components: { vSelect },
  computed: {
    ...mapGetters({
      total_quantity: "orderItem/getTotalQuantity",
      discount_types: "orderPrice/forSelectDiscount",
      discount_type: "orderPrice/currentLabelType",
      is_discount_percent: "orderPrice/isPercent",
      payment_methods: "orderPrice/getPaymentMethods",
      can_product_edit: "orderItem/canProductEdit",
      subTotal: "orderPrice/subTotal",
      taxBase: "orderPrice/taxBase",
      taxes: "orderPrice/taxes",
      total: "orderPrice/total",
      taxTitle: "orderPrice/taxTitle",
      taxPercent: "orderPrice/taxPercent",
      remaining: "orderPrice/remaining",
      paid: "orderPrice/paid",
      discount: "orderPrice/discount"
    }),
    taxFullTitle() {
      return `${this.taxTitle} ${this.taxPercent}%`;
    },
    discount_value: {
      get() {
        if (this.is_discount_percent) {
          return this.$store.state.orderPrice.discount_value;
        }
        return this.$store.state.orderPrice.discount_value / 100;
      },
      set(value) {
        if (this.is_discount_percent) {
          this.$store.dispatch("orderPrice/setValue", value);
        } else {
          this.$store.dispatch("orderPrice/setValue", value * 100);
        }
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    changeDiscountType(discount) {
      this.$store.dispatch("orderPrice/changeType", discount.value);
      this.$store.dispatch("orderPrice/calculate");
    }
  },
  watch: {
    discount_value() {
      this.$store.dispatch("orderPrice/calculate");
    }
  }
};
</script>

<style scoped>
.order__total-item:first-child {
  padding-top: 0;
}

.order__total-item {
  width: calc(100% - 48px);
  padding: 24px 24px;
  color: #454545;
  align-items: end;
  border-bottom: 1px solid #f1f1f1;
  justify-content: space-between;
}

.order__total-item:last-child {
  border-bottom: none;
}

.order__total-value {
  flex-direction: column;
  text-align: right;
}

.discount-group {
  flex-direction: column;
}

.discount-group .inp-group,
.discount-group .inp-group__in {
  display: inline-flex;
}

.discount-group .inp-group__in {
  width: 150px;
  vertical-align: middle;
  margin-right: 24px;
}

.discount-group .inp-group__in .vs--open {
  width: 140px;
}

.discount .inp-group {
  width: 75px;
  vertical-align: middle;
}

.order__items .order__item-total {
  max-width: 460px;
  margin-left: auto;
}

.order__total-title {
  font-size: 14px;
}

.v-select {
  width: 100% !important;
}
</style>
