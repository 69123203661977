<template>
  <div class="main__content-item">
    <div class="main__content-item_l">
      <span class="bold">{{ value.title }}:</span>
    </div>
    <div class="main__content-item_r">
      <div class="inp-group__text">
        {{ value.value }}
      </div>
      <div class="inp-group__text" v-if="value.description">
        {{ value.description }}
      </div>
      <div class="inp-group inp-group__checkbox">
        <input :checked="value.highlight" :disabled="true" type="checkbox" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecReadOnly",
  props: ["value"]
};
</script>
