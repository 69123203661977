<template>
  <div class="trans-item">
    <div class="btn-burger pointer"></div>
    <div class="wrapper">
      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l d-flex flex-end">
          <span>Phone number:</span>
        </div>
        <div class="main__content-item_r d-flex flex-start">
          <div class="inp-group inp-group__xl">
            <input
              v-mask="'+###############'"
              type="tel"
              v-model="value.phone"
            />
          </div>
        </div>
      </div>
    </div>
    <button
      class="field__remove"
      @click="removeContact()"
    ></button>
  </div>
</template>

<script>
export default {
  name: "PhoneBlock",
  props: ["value"],
  methods: {
    removeContact() {
      this.$emit("onRemove");
    },
  },
};
</script>

<style scoped lang="scss">
.field__remove {
  position: relative;
  right: 0;
  transform: none;
  margin-right: 0;
  width: 35px;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}
</style>
