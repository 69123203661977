var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice-item",class:{ customer: !_vm.invoice.manual }},[_c('div',{staticClass:"invoice-item__im amount-title"},[_c('div',[_c('span',{staticClass:"amount"},[_vm._v(_vm._s(_vm.$crConverter(_vm.invoice.amount / 100)))]),(_vm.invoice.manual && _vm.invoice.manager_full_name)?_c('span',{staticClass:"amount-info"},[_vm._v(" added by "),_c('router-link',{attrs:{"to":{
            name: 'CRMCustomerPage',
            params: { id: _vm.invoice.manager.id }
          },"target":"_blank"},domProps:{"textContent":_vm._s(_vm.invoice.manager_full_name)}})],1):_vm._e(),(!_vm.invoice.manual)?_c('span',{staticClass:"amount-info"},[_vm._v(" "+_vm._s(_vm.invoice.isPaid() ? " paid by customer " : " pending ")+" ")]):_vm._e(),(_vm.invoice.created_at)?_c('span',{staticClass:"amount-info"},[_vm._v(" "+_vm._s(_vm.invoice.created_at)+" ")]):_vm._e()]),(_vm.mode_edit && _vm.invoice.canDelete())?_c('a',{staticClass:"amount-delete",domProps:{"textContent":_vm._s(_vm.request.delete ? 'Deleting...' : 'Delete')},on:{"click":function($event){return _vm.deleteInvoice()}}}):_vm._e()]),(_vm.invoice.description)?_c('div',{staticClass:"invoice-item__im invoice-desc"},[_vm._v(" "+_vm._s(_vm.invoice.description)+" ")]):_vm._e(),(_vm.invoice.invoice_file.url)?_c('div',{staticClass:"invoice-item__im invoice-link"},[_c('span',{staticClass:"invoice-link-title"},[_vm._v("Pro forma:")]),_c('a',{attrs:{"href":_vm.invoice.invoice_file.url,"target":"_blank"},domProps:{"textContent":_vm._s(
        _vm.invoice.invoice_file.name
          ? _vm.invoice.invoice_file.name
          : _vm.invoice.invoice_file.url
      )}})]):_vm._e(),(_vm.invoice.manual)?_c('div',{staticClass:"invoice-item__im invoice-link-status"},[(_vm.invoice.isRequiresConfirmation())?_c('div',[_c('input',{ref:"files",staticClass:"hidden",attrs:{"id":((_vm.invoice.id) + "-upload-file"),"multiple":"","name":"upload_file","type":"file"},on:{"change":function($event){return _vm.handleFileUpload()}}}),_c('span',{staticClass:"ic-cancel"}),_c('span',[_vm._v(" Not paid/confirmed. "),(_vm.mode_edit)?_c('label',{attrs:{"for":((_vm.invoice.id) + "-upload-file")}},[_vm._v(_vm._s(this.request.file ? "Uploading" : "Upload"))]):_vm._e(),(!_vm.mode_edit)?_c('span',[_vm._v("Upload")]):_vm._e(),_vm._v(" confirmation scan ")])]):_vm._e(),(_vm.invoice.isPaid())?_c('div',[_vm._m(0),_c('span',{},[_vm._v("Paid")])]):_vm._e(),(_vm.invoice.isStatusUploaded() && _vm.invoice.hasReceiptFile())?_c('div',[_vm._m(1),_c('span',{},[_vm._v("Confirmed"),(_vm.invoice.receipt_manager_full_name)?_c('span',[_vm._v(" by "),_c('router-link',{attrs:{"to":{
              name: 'CRMCustomerPage',
              params: { id: _vm.invoice.receipt.manager.id }
            },"target":"_blank"},domProps:{"textContent":_vm._s(_vm.invoice.receipt_manager_full_name)}})],1):_vm._e(),(_vm.invoice.receipt.created_at)?_c('span',{domProps:{"textContent":_vm._s(' ' + _vm.invoice.receipt.created_at)}}):_vm._e(),_vm._v(". "),_c('a',{attrs:{"href":_vm.invoice.receipt.file,"target":"_blank"}},[_vm._v("View")]),_vm._v(" confirmation scan ")])]):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ic-paid"},[_c('span',{staticClass:"ic-paid-1"}),_c('span',{staticClass:"ic-paid-2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ic-paid"},[_c('span',{staticClass:"ic-paid-1"}),_c('span',{staticClass:"ic-paid-2"})])}]

export { render, staticRenderFns }